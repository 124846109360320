import React, { useRef, useState } from 'react';
import { Button, Typography, Row, Col, Space, Tag, Tooltip, Carousel } from 'antd';
import { PlusOutlined, DeleteOutlined, LinkOutlined } from '@ant-design/icons';
import { PortfolioItem } from '../../../types/user';
import { FormSection, SectionTitle } from '../styles';
import { styled } from '@stitches/react';
import AddPortfolioModal from './AddPortfolioModal';

const { Text, Paragraph } = Typography;

const NewItemSlide = styled('div', {
    height: "30vh",
    display: "flex",
    textAlign: 'center',
    marginBottom: "20px",
    '&:hover': {
        cursor: "pointer"
    }
});

const AddItemContent = styled(Space, {
    textAlign: "center",
    justifyContent: "center",
    height: "30vh",
    '& .anticon': {
        fontSize: '32px',
    }
});

export const PortfolioSlide = styled('div', {
    height: "30vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "20px",
});

export const DescriptionSection = styled('div', {
    height: "75%",
    overflow: "auto",
    marginBottom: "12px",
    display: "flex",
    flexDirection: "column",
});

export const TagsRow = styled('div', {
    marginBottom: "8px",
});

const ActionsRow = styled('div', {
    display: "flex",
    justifyContent: "flex-end",
});

export interface PortfolioSectionProps {
    portfolio: PortfolioItem[];
    onPortfolioChange: (items: PortfolioItem[]) => void;
}

export const formatUrl = (url: string): string => {
    try {
        const urlObject = new URL(url.startsWith('http') ? url : `https://${url}`);
        return urlObject.hostname;
    } catch {
        return url;
    }
};

const PortfolioSection: React.FC<PortfolioSectionProps> = ({ portfolio, onPortfolioChange }) => {
    const carouselRef = useRef<any>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleAddItem = (newItem: PortfolioItem) => {
        const updatedPortfolio = [...portfolio, newItem];
        onPortfolioChange(updatedPortfolio);
        setTimeout(() => {
            if (carouselRef.current) {
                carouselRef.current.goTo(updatedPortfolio.length);
            }
        }, 0);
    };

    const removePortfolioItem = (id: string) => {
        onPortfolioChange(portfolio.filter(item => item.id !== id));
    };

    return (
        <FormSection>
            <SectionTitle>Portfolio</SectionTitle>
            <Carousel
                ref={carouselRef}
                dots={false}
                infinite={false}
                arrows={true}
            >
                {portfolio.map((item) => (
                    <PortfolioSlide key={item.id}>
                        <Text strong style={{ marginBottom: "8px"}}>
                            {item.title}
                        </Text>
                        <DescriptionSection>
                            <Paragraph style={{ flex: 1 }}>
                                {item.description}
                            </Paragraph>
                        </DescriptionSection>

                        <TagsRow>
                            <Space wrap>
                                {item.urls?.map((urlItem, index) => (
                                    <Tooltip key={index} title={urlItem.explanation}>
                                        <Tag icon={<LinkOutlined />}>
                                            <a
                                                href={urlItem.url.startsWith('http') ? urlItem.url : `https://${urlItem.url}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {formatUrl(urlItem.url)}
                                            </a>
                                        </Tag>
                                    </Tooltip>
                                ))}
                            </Space>
                        </TagsRow>

                        <ActionsRow>
                            <Button
                                type="text"
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => removePortfolioItem(item.id)}
                            />
                        </ActionsRow>
                    </PortfolioSlide>
                ))}

                <NewItemSlide onClick={() => setIsModalVisible(true)}>
                    <AddItemContent direction="vertical" align="center">
                        <PlusOutlined />
                        <Text>Add Portfolio Item</Text>
                    </AddItemContent>
                </NewItemSlide>
            </Carousel>

            <AddPortfolioModal
                visible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                onAdd={handleAddItem}
            />
        </FormSection>
    );
};

export default PortfolioSection;