import React from 'react';
import { Typography, Spin } from 'antd';
import { HorizontalScrollContainer, CardWrapper, SectionContainer, SectionTitle } from '../common/horizontal-scroll-container';
import ProjectCard from '../cards/project-card';
import AutodidactCourseCard from '../cards/autodidact-course-card';
import { CourseOverview } from '../../services/courses';
import { admin_type, autodidact_type, marketpalce_admin_type, technical_admin_type, professor_type } from '../../services/user';

export interface ContentGalleryProps {
  courses: CourseOverview[];
  projects: CourseOverview[];
  selectedType?: string;
  searchQuery: string;
  selectedDate: any;
  selectedProfessor?: string;
  userID: string;
  userType: string;
  isMarketplace?: boolean;
  isCommunity?: boolean;
  communityID?: string;
  projectsSectionRef?: React.RefObject<HTMLDivElement>;
  coursesSectionRef?: React.RefObject<HTMLDivElement>;
  onRemoveItem?: (id: string) => void;
  buildButtonRef?: React.RefObject<HTMLButtonElement>;
  chatButtonRef?: React.RefObject<HTMLButtonElement>;
  editButtonRef?: React.RefObject<HTMLButtonElement>;
  contributeButtonRef?: React.RefObject<HTMLButtonElement>;
}

export const ContentGallery: React.FC<ContentGalleryProps> = ({
  courses,
  projects,
  selectedType,
  searchQuery,
  selectedDate,
  selectedProfessor,
  userID,
  userType,
  isMarketplace = false,
  isCommunity = false,
  communityID,
  projectsSectionRef,
  coursesSectionRef,
  onRemoveItem,
  buildButtonRef,
  chatButtonRef,
  editButtonRef,
  contributeButtonRef,
}) => {
  // Filter projects based on search query, date, and professor
  const filteredProjects = projects.filter(project => {
    const matchesSearchQuery = project.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      project.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      project.last_updated.toLowerCase().includes(searchQuery.toLowerCase()) ||
      project.professors.some(professor => professor.name.toLowerCase().includes(searchQuery.toLowerCase()));

    const matchesDateFilter = selectedDate ? new Date(project.last_updated) >= selectedDate.toDate() : true;
    const matchesProfessorFilter = selectedProfessor ? project.professors.some(professor => professor.name === selectedProfessor) : true;

    return matchesSearchQuery && matchesDateFilter && matchesProfessorFilter;
  });

  // Filter courses based on search query, date, and professor
  const filteredCourses = courses.filter(course => {
    const matchesSearchQuery = course.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      course.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      course.last_updated.toLowerCase().includes(searchQuery.toLowerCase()) ||
      course.professors.some(professor => professor.name.toLowerCase().includes(searchQuery.toLowerCase()));

    const matchesDateFilter = selectedDate ? new Date(course.last_updated) >= selectedDate.toDate() : true;
    const matchesProfessorFilter = selectedProfessor ? course.professors.some(professor => professor.name === selectedProfessor) : true;

    return matchesSearchQuery && matchesDateFilter && matchesProfessorFilter;
  });

  // Function to determine if the user can edit a course
  const canEditCourse = (course: CourseOverview) => {
    const editableUserTypes = [professor_type, autodidact_type, admin_type, technical_admin_type];
    return course.professors.some(prof => prof._id === userID) && editableUserTypes.includes(userType);
  };

  // Function to get ref for chat button based on course editability and index
  const getChatButtonRef = (isEditable: boolean, index: number) => {
    if (index === 0) {
      return isEditable ? chatButtonRef : buildButtonRef;
    }
    return undefined;
  };

  // Function to get ref for edit button based on course editability and index
  const getEditButtonRef = (isEditable: boolean, index: number) => {
    if (isEditable && index === 0) {
      return editButtonRef;
    }
    return undefined;
  };

  // Function to get ref for contribute button based on index
  const getContributeButtonRef = (index: number) => {
    if (index === 0) {
      return contributeButtonRef;
    }
    return undefined;
  };

  return (
    <>
      {/* Projects Section */}
      {(selectedType === undefined || selectedType === 'both' || selectedType === 'project') && (
        <SectionContainer
          style={{
            minHeight: '300px',
            maxHeight: '300px',
          }}>
          <SectionTitle>
            <Typography.Title
              level={2}
              style={{ marginTop: '10px', marginBottom: '0' }}
            >
              Projects
            </Typography.Title>
          </SectionTitle>
          {filteredProjects.length === 0 ? (
            <div style={{ textAlign: 'center', margin: '20px' }}>
              <Typography.Paragraph>
                {isMarketplace 
                  ? 'No projects available in the marketplace.' 
                  : isCommunity 
                    ? 'No projects in this community yet.' 
                    : 'No projects to display. Create a new project using the Create button above.'}
              </Typography.Paragraph>
            </div>
          ) : (
            <HorizontalScrollContainer
              ref={projectsSectionRef}
              style={{
                minHeight: '240px',
                maxHeight: '240px',
              }}
            >
              {filteredProjects.map((project, index) => {
                const editable = canEditCourse(project);
                return (
                  <CardWrapper key={`${project._id}-${index}`}>
                    <ProjectCard
                      id={project._id}
                      title={project.name}
                      description={project.description}
                      professors={project.professors}
                      removable={false}
                      onRemove={onRemoveItem}
                      chatButtonRef={getChatButtonRef(editable, index)}
                      editButtonRef={getEditButtonRef(editable, index)}
                      contributeButtonRef={getContributeButtonRef(index)}
                    />
                  </CardWrapper>
                );
              })}
            </HorizontalScrollContainer>
          )}
        </SectionContainer>
      )}

      {/* Courses Section */}
      {(selectedType === undefined || selectedType === 'both' || selectedType === 'course') && (
        <SectionContainer
          style={{
            minHeight: '400px',
            maxHeight: '400px',
          }}>
          <SectionTitle>
            <Typography.Title
              level={2}
              style={{ marginTop: '10px', marginBottom: '0' }}
            >
              Courses
            </Typography.Title>
          </SectionTitle>
          {filteredCourses.length === 0 ? (
            <div style={{ textAlign: 'center', margin: '20px' }}>
              <Typography.Paragraph>
                {isMarketplace 
                  ? 'No courses available in the marketplace.' 
                  : isCommunity 
                    ? 'No courses in this community yet.' 
                    : 'No courses to display. Create a new course using the Create button above.'}
              </Typography.Paragraph>
            </div>
          ) : (
            <HorizontalScrollContainer ref={coursesSectionRef}>
              {filteredCourses.map((course, index) => {
                const editable = canEditCourse(course);
                return (
                  <CardWrapper key={`${course._id}-${index}`}>
                    <AutodidactCourseCard
                      id={course._id}
                      title={course.name}
                      description={course.description}
                      professors={course.professors}
                      lastUpdated={course.last_updated}
                      removable={false}
                      onRemove={onRemoveItem}
                      chatButtonRef={getChatButtonRef(editable, index)}
                      editButtonRef={getEditButtonRef(editable, index)}
                      contributeButtonRef={getContributeButtonRef(index)}
                    />
                  </CardWrapper>
                );
              })}
            </HorizontalScrollContainer>
          )}
        </SectionContainer>
      )}
    </>
  );
};

export default ContentGallery;