import { Table, Button } from 'antd';
import { InvoiceInfo } from '../../services/subscription';

interface InvoiceTableProps {
    subscriptions: InvoiceInfo[];
    loading: boolean;
    onCancelSubscription: (subscriptionId: string) => void;
}

const InvoiceTable: React.FC<InvoiceTableProps> = ({ 
    subscriptions, 
    loading, 
    onCancelSubscription 
}) => {
    const columns = [
        {
            title: 'Plan',
            dataIndex: 'plan',
            key: 'plan',
            width: '30%',
        },
        {
            title: 'Status',
            key: 'status',
            width: '15%',
            render: (record: InvoiceInfo) => (
                record.canceled 
                    ? (new Date(record.cancellation_date) < new Date() 
                        ? 'Scheduled for cancellation' 
                        : 'Canceled')
                    : 'Active'
            ),
        },
        {
            title: 'Next Billing Date',
            dataIndex: 'next_billing_date',
            key: 'next_billing_date',
            width: '15%',
            render: (date: string) => {
                const dateObj = new Date(date);
                return dateObj.toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                }).replace(/\//g, '-');
            },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: '15%',
            render: (amount: number) => `${amount.toFixed(2)}`,
        },
        {
            title: 'Action',
            key: 'action',
            width: '15%',
            render: (_: any, record: InvoiceInfo) => (
                <Button
                    danger
                    onClick={() => onCancelSubscription(record.id)}
                    disabled={record.canceled}
                >
                    {record.canceled ? "Already canceled" : "Cancel Subscription" }
                </Button>
            ),
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={subscriptions}
            loading={loading}
            rowKey="id"
            pagination={false}
        />
    );
};

export default InvoiceTable;