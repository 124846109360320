import React from 'react';
import { Tour } from 'antd';
import type { TourStepProps } from 'antd';
import { professor_type, autodidact_type, admin_type, technical_admin_type, marketpalce_admin_type } from '../../services/user';

interface MarketplaceTourProps {
    open: boolean;
    onClose: () => void;
    communityCourseChatRef: React.RefObject<HTMLButtonElement | null>;
    userCourseChatRef: React.RefObject<HTMLButtonElement | null>;
    userCourseEditRef: React.RefObject<HTMLButtonElement | null>;
    searchBarRef: React.RefObject<HTMLDivElement | null>;
    projectsSectionRef: React.RefObject<HTMLDivElement | null>;
    coursesSectionRef: React.RefObject<HTMLDivElement | null>;
    userType: string;
    isFirstCommunityCourse: boolean;
    isFirstUserCourse: boolean;
}

export const MarketplaceTour: React.FC<MarketplaceTourProps> = ({
    open,
    onClose,
    communityCourseChatRef,
    userCourseChatRef,
    userCourseEditRef,
    searchBarRef,
    projectsSectionRef,
    coursesSectionRef,
    userType,
    isFirstCommunityCourse,
    isFirstUserCourse,
}) => {
    // Define base steps that always appear
    const baseSteps: TourStepProps[] = [
        {
            title: 'Search and Filter Courses',
            description: (
                <>
                    <p>Find courses easily using the search bar - it looks through course titles, descriptions, and educator names. 
                    Use the filters to narrow down courses by their last update date or find specific educators.</p>
                    {[
                        professor_type, 
                        autodidact_type, 
                        admin_type, 
                        technical_admin_type, 
                        marketpalce_admin_type
                    ].includes(userType) && (
                        <p>As an educator, you can also create new courses using the "Create Course" button.</p>
                    )}
                </>
            ),
            target: searchBarRef.current as HTMLElement,
            placement: 'bottom' as const,
        }
    ];
    
    // Add project section step if availablea
    if (projectsSectionRef?.current) {
        baseSteps.push({
            title: 'Projects Section',
            description: 'Projects represent a joint undertaking between several users with a common goal. Here you can discover collaborative initiatives and contribute to them.',
            target: projectsSectionRef.current as HTMLElement,
            placement: 'top' as const,
        });
    }
    
    // Add course section step if available
    if (coursesSectionRef?.current) {
        baseSteps.push({
            title: 'Courses Section',
            description: 'Courses provide a place to learn about a topic based on a specific knowledge base. They offer structured learning experiences created by educators and experts.',
            target: coursesSectionRef.current as HTMLElement,
            placement: 'top' as const,
        });
    }
    
    // Add conditional steps for community and user courses
    const conditionalSteps: TourStepProps[] = [];
    
    if (isFirstCommunityCourse && communityCourseChatRef.current) {
        conditionalSteps.push({
            title: 'Community Course',
            description: 'This course is prepared by a community contributor. Start a chat with this course to automatically add it to your Courses menu. You can always remove it later if you wish.',
            target: communityCourseChatRef.current as HTMLElement,
            placement: 'right' as const,
        });
    }
    
    if (isFirstUserCourse && userCourseChatRef.current) {
        conditionalSteps.push({
            title: 'Your Course',
            description: 'This is one of your courses. You can find it both here in the Universe and in your Courses menu. The Chat button lets you interact with the course content.',
            target: userCourseChatRef.current as HTMLElement,
            placement: 'right' as const,
        });
        
        if (userCourseEditRef.current) {
            conditionalSteps.push({
                title: 'Edit Your Course',
                description: 'As this is your course, you can edit its content and structure. Any changes you make will be reflected both in the Universe and your Courses menu.',
                target: userCourseEditRef.current as HTMLElement,
                placement: 'right' as const,
            });
        }
    }
    
    // Combine base and conditional steps, then filter out any with null targets
    const steps = [...baseSteps, ...conditionalSteps].filter(step => step.target != null);

    return (
        <Tour
            open={open}
            onClose={onClose}
            steps={steps}
            scrollIntoViewOptions={{ block: 'nearest' }}
            gap={{ offset: 12 }}
        />
    );
};