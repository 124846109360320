import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Typography, Spin, Alert } from 'antd';
import { AbsoluteCenter } from '../../components/styles';
import { loginWithToken } from '../../services/auth-axios';

const EmailLoginScreen: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleEmailLogin = async () => {
      try {
        // Extract token from URL
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');

        if (!token) {
          setError('Invalid or missing token in URL');
          setLoading(false);
          return;
        }

        // Set token in localStorage
        localStorage.setItem('access_token', token);

        // Login with token
        await loginWithToken();

        // Navigate to password change screen
        navigate('/change-password', { replace: true });
      } catch (error) {
        console.error('Email login failed:', error);
        setError('Authentication failed. Please try again or contact support.');
        setLoading(false);
      }
    };

    handleEmailLogin();
  }, [location, navigate]);

  return (
    <AbsoluteCenter>
      <Card style={{ width: 400, textAlign: 'center' }}>
        <Typography.Title level={3}>Welcome to Infolab</Typography.Title>
        {loading ? (
          <>
            <Spin size="large" />
            <Typography.Paragraph style={{ marginTop: 20 }}>
              Authenticating your login link...
            </Typography.Paragraph>
          </>
        ) : (
          error && <Alert message={error} type="error" showIcon />
        )}
      </Card>
    </AbsoluteCenter>
  );
};

export default EmailLoginScreen;
