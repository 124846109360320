import React, { useState, useEffect } from 'react';
import { 
    TwitterOutlined, LinkedinOutlined, 
    GithubOutlined, GlobalOutlined 
} from '@ant-design/icons';
import { Row, Col } from 'antd';
import { SocialLinks, UserNetworking } from '../../../types/user';
import { CenteredSpace, FormRow, FormSection, InputCol, LabelCol, SectionTitle } from '../../settings/styles';
import { SocialText } from '../styles';

interface CommunitySocialLinksProps {
    social_links: SocialLinks;
    networking: UserNetworking;
}

const CommunitySocialLinksSection: React.FC<CommunitySocialLinksProps> = ({ 
    social_links, 
    networking,
}) => {
    // Add local state for immediate feedback
    const [localNetworking, setLocalNetworking] = useState(networking);

    // Sync local state with props when they change
    useEffect(() => {
        setLocalNetworking(networking);
    }, [networking]);

    return (
        <FormSection>
            <SectionTitle>Social & Network Settings</SectionTitle>
            <Row gutter={24}>
                <Col xs={24} md={12}>
                    <SectionTitle>Social Links</SectionTitle>
                    <CenteredSpace direction="vertical" style={{ width: '100%' }}>
                        <SocialText>
                            <TwitterOutlined className="icon" />
                            <span className="text">
                                {social_links.twitter ? (
                                    <a href={social_links.twitter} target="_blank" rel="noopener noreferrer">
                                        {social_links.twitter}
                                    </a>
                                ) : ''}
                            </span>
                        </SocialText>
                        <SocialText>
                            <LinkedinOutlined className="icon" />
                            <span className="text">
                                {social_links.linkedin ? (
                                    <a href={social_links.linkedin} target="_blank" rel="noopener noreferrer">
                                        {social_links.linkedin}
                                    </a>
                                ) : ''}
                            </span>
                        </SocialText>
                        <SocialText>
                            <GithubOutlined className="icon" />
                            <span className="text">
                                {social_links.github ? (
                                    <a href={social_links.github} target="_blank" rel="noopener noreferrer">
                                        {social_links.github}
                                    </a>
                                ) : ''}
                            </span>
                        </SocialText>
                        <SocialText>
                            <GlobalOutlined className="icon" />
                            <span className="text">
                                {social_links.website ? (
                                    <a href={social_links.website} target="_blank" rel="noopener noreferrer">
                                        {social_links.website}
                                    </a>
                                ) : ''}
                            </span>
                        </SocialText>
                    </CenteredSpace>
                </Col>
                <Col xs={24} md={12}>
                    <SectionTitle>Network Settings</SectionTitle>
                    <CenteredSpace direction="vertical" style={{ width: '100%' }}>
                        <FormRow>
                            <LabelCol span={8}>Profile Visibility</LabelCol>
                            <InputCol span={16}>
                                <span>{localNetworking.visibility.charAt(0).toUpperCase() + localNetworking.visibility.slice(1)}</span>
                            </InputCol>
                        </FormRow>
                        <FormRow>
                            <LabelCol span={8}>Open to Mentoring</LabelCol>
                            <InputCol span={16}>
                                <span>{localNetworking.open_to_mentoring ? 'Yes' : 'No'}</span>
                            </InputCol>
                        </FormRow>
                        <FormRow>
                            <LabelCol span={8}>Seeking Co-founders</LabelCol>
                            <InputCol span={16}>
                                <span>{localNetworking.seeking_cofounders ? 'Yes' : 'No'}</span>
                            </InputCol>
                        </FormRow>
                    </CenteredSpace>
                </Col>
            </Row>
        </FormSection>
    );
};

export default CommunitySocialLinksSection;