import React, { useEffect, useState } from 'react';
import { Button, Card, Typography, Row, Col, Form, Input, InputNumber, Select, message, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { autodidact_message_bundle_options, educator_message_bundle_options } from '../../config';
import { autodidact_type, professor_type, student_type, technical_admin_type } from '../../services/user';
import { authedAxios, logout } from '../../services/auth-axios';
import { setUserDataInLocalStorage } from '../../utils/useLocalStorage';
import { capitalize } from '../../utils/utils';

const { Title, Text } = Typography;

interface UserTypeOption {
    type: string;
    title: string;
    description: string[];
}

const userTypeOptions: UserTypeOption[] = [
    {
        type: student_type,
        title: capitalize(student_type),
        description: [
            "Access your organization's chatbot",
            "Use it without hallucinations",
            "Filter responses and get precise sources"
        ]
    },
    {
        type: autodidact_type,
        title: capitalize(autodidact_type),
        description: [
            `Create a chatbot based on your own sources`,
            "Take up any course made by other users",
            "Chat with precise content & without hallucinations",
            "Create module summaries and test yourself",
            "Connect to like-minded autodidacts",
            "Build AI-powered communities around your interests"
        ]
    },
    {
        type: professor_type,
        title: "Educator",
        description: [
            `Create a chatbot for your own organization`,
            "Enable your team members to learn effectively with AI",
            "Create tasks suitable for AI",
            "Understand your team members' needs using our analytics",
            "Get scheduled reports to improve your team"
        ]
    }
];

const technicalTypeOptions: UserTypeOption[] = [
    {
        type: technical_admin_type,
        title: "Public Access",
        description: [
            "Create a chatbot based on your own content",
            "Provide public access to the bot",
            "Understand what visitor care about",
            "Pay only for the interactions",
            "Control your expenses by capping the interactions"
        ]
    }
];

interface UserTypeSelectionProps {
    userId: string;
    userType: string;
    loginCallback: () => void;
}

const UserTypeSelection: React.FC<UserTypeSelectionProps> = ({ userId, userType, loginCallback }) => {
    const [userOption, setUserOption] = useState<UserTypeOption | null>(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const isTechnical = localStorage.getItem('isTechnicalSignup') === 'true'
    const isKeyAccount = false // localStorage.getItem('isKeyAccount') === 'true'
    const isHuuhUser = window.location.origin.includes("huuh.me");
    
    const isInfolabUserType1 = localStorage.getItem('isInfolabUserType1');
    const isInfolabUserType2 = localStorage.getItem('isInfolabUserType2');
    
    const isHuuhPayAsYouGo = localStorage.getItem('isHuuhPayAsYouGo');
    const isHuuhBundle1 = localStorage.getItem('isHuuhBundle1');
    const isHuuhBundle2 = localStorage.getItem('isHuuhBundle2');

    const navigate = useNavigate();

    const [form] = Form.useForm();
    const numberOfStudents = Form.useWatch('number_of_students', form);
    const isStudentCountTooHigh = numberOfStudents ? numberOfStudents > 100 : false;

    useEffect(() => {
        if (isHuuhUser) {
            setUserOption(userTypeOptions[1]);
            setCurrentStep(1);
            let formValues = {};
            
            if (isHuuhPayAsYouGo === 'true') {
                formValues = { number_of_messages: educator_message_bundle_options[0].number };
            } else if (isHuuhBundle1 === 'true') {
                formValues = { number_of_messages: educator_message_bundle_options[1].number };
            } else if (isHuuhBundle2 === 'true') {
                formValues = { number_of_messages: educator_message_bundle_options[2].number };
            }
            
            if (Object.keys(formValues).length > 0) {
                form.setFieldsValue(formValues);
                // Store the values for auto-submission after component is fully mounted
                localStorage.setItem('autoSubmitValues', JSON.stringify(formValues));
            }
        }
        else if (isInfolabUserType1 === 'true') {
            setUserOption(userTypeOptions[0]);
            setCurrentStep(1);
        }
        else if (isInfolabUserType2 === 'true') {
            setUserOption(userTypeOptions[2]);
            setCurrentStep(1);
        }
        else if (isTechnical) {
            setUserOption(technicalTypeOptions[0]);
            setCurrentStep(1);
        } else if ([student_type, professor_type, autodidact_type].includes(userType)) {
            const foundOption = userTypeOptions.find(option => option.type === userType);
            setUserOption(foundOption!);
            setCurrentStep(1);
        } else if (isKeyAccount) {
            setUserOption(userTypeOptions[2]);
            setCurrentStep(1);
        }
        setIsLoading(false);
    }, []);

    const onTypeSelect = (option: UserTypeOption) => {
        setUserOption(option);
        setCurrentStep(1);
    };

    const handleGoBack = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        } else {
            setUserOption(null);
        }
    };

    const handleSubmit = async (values: any) => {
        if (!userOption) return;

        setIsLoading(true);
        try {
            const payload = {
                type: userOption.type,
                pay_as_you_go: values.number_of_messages === 0,
                is_key_account: isKeyAccount,
                ...values
            };

            if (userOption.type === student_type || (isKeyAccount && userOption.type === professor_type)) {
                const response = await authedAxios.post(
                    `/users/${userId}/update-type`,
                    payload
                );
                message.success(`Successfully registered as ${userOption.title}`);
                localStorage.setItem('signupFormData', JSON.stringify({ payload }));
                setUserDataInLocalStorage(response.data.user);
                loginCallback();
            } else {
                // Redirect to checkout
                localStorage.setItem('signupFormData', JSON.stringify({ payload }));
                navigate('/checkout', { state: payload });
            }
        } catch (error: any) {
            console.error('Error updating user type:', error);
            message.error('Error updating user type');
            logout();
            window.location.href = '/login'; // Navigate to /login
        } finally {
            localStorage.removeItem('isHuuhPayAsYouGo');
            localStorage.removeItem('isHuuhBundle1');
            localStorage.removeItem('isHuuhBundle2');
            localStorage.removeItem('isTechnicalSignup');
            localStorage.removeItem('isKeyAccount');
            localStorage.removeItem('isInfolabUserType1');
            localStorage.removeItem('isInfolabUserType2');
            setIsLoading(false);
        }
    };

    const renderTypeSelection = () => (
        <>
            {isLoading ? (
                <Spin size="large" style={{ display: 'block', margin: '0 auto', marginTop: '50px' }} />
            ) : (
                <>
                    <Title level={3} style={{ textAlign: 'center', marginBottom: '24px' }}>
                        Choose Your Account Type
                    </Title>
                    <Row gutter={[24, 24]} justify="space-between">
                        {userTypeOptions.map((option) => (
                            <Col key={option.title} xs={24} md={8}>
                                <Card
                                    style={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        border: '1px solid #333',
                                    }}
                                    bodyStyle={{
                                        padding: '16px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flex: 1
                                    }}
                                >
                                    <Title level={4} style={{
                                        marginBottom: '16px',
                                        textAlign: 'center'
                                    }}>
                                        {option.title}
                                    </Title>
                                    <ul style={{
                                        paddingLeft: '20px',
                                        margin: 0,
                                        flexGrow: 1,
                                    }}>
                                        {option.description.map((item, index) => (
                                            <li key={index} style={{ marginBottom: '8px' }}><Text>{item}</Text></li>
                                        ))}
                                    </ul>
                                    <Button
                                        type="primary"
                                        onClick={() => onTypeSelect(option)}
                                        style={{
                                            width: '100%',
                                            marginTop: '16px',
                                        }}
                                    >
                                        {"Continue as " + option.title.toLowerCase()}
                                    </Button>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </>
            )}
        </>
    );

    const renderTypeDetails = () => {
        if (!userOption) return null;

        return (
            <>
                {isLoading ? (
                    <Spin size="large" style={{ display: 'block', margin: '0 auto', marginTop: '50px' }} />
                ) : (
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleSubmit}
                        style={{ 
                            width: '100%', 
                            maxWidth: '25vw',
                            minWidth: '20vw',
                            margin: '0 auto'
                        }}
                    >
                        <Title level={3} style={{ textAlign: 'center', marginBottom: '24px' }}>
                            {capitalize(userOption.title)} Account Setup
                        </Title>

                        {userOption.type === student_type && (
                            <Form.Item
                                name="educator_email"
                                label="Educator's Email"
                                rules={[{ required: true, message: 'Please enter your educator\'s email' }]}
                            >
                                <Input 
                                    placeholder="Enter your educator's email" 
                                />
                            </Form.Item>
                        )}

                        {userOption.type === professor_type && (
                            <>
                                <Form.Item
                                    name="number_of_students"
                                    label="Number of Students"
                                    style={{ textAlign: 'center' }}
                                    rules={[
                                        { required: true, message: 'Please enter the number of students' },
                                        {
                                            validator: (_, value) => {
                                                // Check if value is numerical
                                                if (isNaN(Number(value)) || typeof value !== 'number') {
                                                    return Promise.reject('Please enter a valid number');
                                                }
                                                
                                                // Check if value exceeds max
                                                if (value > 100) {
                                                    return Promise.reject(
                                                        'For more than 100 students, please contact us at team@infolab.ai'
                                                    );
                                                }
                                                return Promise.resolve();
                                            }
                                        }
                                    ]}
                                    initialValue={10}
                                >
                                    <InputNumber
                                        min={0}
                                        style={{ 
                                            width: '100%',
                                        }}
                                        placeholder="Number of students (you can start with 0)"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="number_of_messages"
                                    label="Number of Messages per Student per Month"
                                    rules={[{ required: true, message: 'Please select the number of messages' }]}
                                    initialValue={educator_message_bundle_options[0]?.number}
                                >
                                    <Select 
                                        placeholder="Number of messages per student"
                                    >
                                        {educator_message_bundle_options.map((option, index) => (
                                            <Select.Option key={index} value={option.number}>
                                                {option.title}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </>
                        )}
                        {userOption.type === autodidact_type && (
                            <Form.Item
                                name="number_of_messages"
                                label="Number of Messages per Month"
                                rules={[{ required: true, message: 'Please select the number of messages' }]}
                                initialValue={autodidact_message_bundle_options[0]?.number}
                            >
                                <Select 
                                    placeholder="Number of messages"
                                >
                                    {autodidact_message_bundle_options.map((option, index) => (
                                        <Select.Option key={index} value={option.number}>
                                            {option.title}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                        {userOption.type === technical_admin_type && (
                            <Form.Item
                                name="number_of_messages"
                                label="Number of Messages per Month"
                                rules={[{ required: true, message: 'Please select the number of messages' }]}
                                initialValue={autodidact_message_bundle_options[0]?.number}
                            >
                                <Select 
                                    placeholder="Number of messages"
                                >
                                    {autodidact_message_bundle_options.slice(0, 1).map((option, index) => (
                                        <Select.Option key={index} value={option.number}>
                                            {option.title}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}

                        <Form.Item style={{ marginTop: '24px', textAlign: 'center' }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isLoading}
                                disabled={isStudentCountTooHigh}
                                block
                            >
                                {(userOption.type === student_type) ? 'Complete Setup' : 'Continue to Payment'}
                            </Button>
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button onClick={handleGoBack} block>
                                Go Back
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </>
        );
    };

    // Add a new useEffect to handle the auto-submission after component is fully mounted
    useEffect(() => {
        const autoSubmitValuesStr = localStorage.getItem('autoSubmitValues');
        
        if (autoSubmitValuesStr && userOption && currentStep === 1 && !isLoading) {
            try {
                // Use a short timeout to ensure the form is fully ready
                const timer = setTimeout(() => {
                    const autoSubmitValues = JSON.parse(autoSubmitValuesStr);
                    handleSubmit(autoSubmitValues);
                    localStorage.removeItem('autoSubmitValues');
                    localStorage.removeItem('isHuuhPayAsYouGo');
                    localStorage.removeItem('isHuuhBundle1');
                    localStorage.removeItem('isHuuhBundle2');
                    localStorage.removeItem('isTechnicalSignup');
                    localStorage.removeItem('isKeyAccount');
                    localStorage.removeItem('isInfolabUserType1');
                    localStorage.removeItem('isInfolabUserType2');
                }, 500);
                
                return () => clearTimeout(timer);
            } catch (error) {
                console.error('Error auto-submitting form:', error);
                localStorage.removeItem('autoSubmitValues');
                localStorage.removeItem('isHuuhPayAsYouGo');
                localStorage.removeItem('isHuuhBundle1');
                localStorage.removeItem('isHuuhBundle2');
                localStorage.removeItem('isTechnicalSignup');
                localStorage.removeItem('isKeyAccount');
                localStorage.removeItem('isInfolabUserType1');
                localStorage.removeItem('isInfolabUserType2');
            }
        }
    }, [userOption, currentStep, isLoading]);

    return (
        <div style={{ 
            width: '100%',
            maxWidth: '90vw',
            minWidth: '60vw', 
            margin: '0 auto', 
            padding: '24px' }}>
            {currentStep === 0 ? renderTypeSelection() : renderTypeDetails()}
        </div>
    );
};

export default UserTypeSelection;