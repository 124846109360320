import { styled } from "@stitches/react";
import { colorAtla, colorSona } from "../config";
import { NoMarginButton } from "../components/basic/buttons";



export const StyledCard = styled("div", {
    position: "relative",
    width: "100%",
    height: "100%",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    borderRadius: "8px",
    // background: "rgba(255, 255, 255, 0.05)", // TODO this should be set via token
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    justifyContent: "space-between",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    transition: "0.5s",
    margin: "0",
    overflow: "hidden", // Prevent content from overflowing
    '@media (max-width: 768px)': {
        width: '100%',
    }
});

export const CardContent = styled(StyledCard, {
    padding: "20px", // Added padding to CardContent
});

export const InnerCard = styled("div", {
    border: "1px solid #FFFFFF1A", // Changed to a transparent-ish border
    borderRadius: "8px",
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: "30px",
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "100%",
    alignItems: "center",
    padding: "5px 15px",
    margin: "10px 0",
    transition: "0.5s",
})

export const StyledNoMarginButton = styled(NoMarginButton, {
    position: 'absolute',
    top: '0',
    right: '0',
    background: 'transparent !important', // Add !important to override Ant Design's default
    border: "1px solid #FFFFFF1A", // Changed to a transparent-ish border
    borderRadius: "8px",
    padding: '0px 0px',
    margin: '0px',
    height: '22.5px',
    width: '22.5px',
    boxShadow: 'none',
    '&:hover': {
        background: "darkred !important",
        borderColor: "darkred !important",
        color: "white !important",
    },
});

export const SonaAtlaCard = styled(InnerCard, {
    variants: {
        color: {
            sona: {
                borderColor: colorSona,
            },
            atla: {
                borderColor: colorAtla,
            },
        }
    },
    defaultVariants: {
        color: 'blue'
    },
    minHeight: '100px', // Adjust this value based on the actual height of NoMarginButton
});

export const SonaAtlaTitle = styled("h3", {
    fontSize: "20px",
    fontWeight: "bold",
    width: '40%',
})


export const TitleContainer = styled("div", {
    color: "white",
    borderRadius: "8px",
    minHeight: "20px",
    display: "flex",
    wordWrap: "break-word",
})


export const SonaAtlaTitleContainer = styled(TitleContainer, {
    // paddingLeft: "8px",
    // marginLeft: "8px",
    // paddingRight: "8px",
    // marginRight: "8px",
})
export const DescriptionContainer = styled("div", {
    paddingLeft: "8px",
    marginLeft: "8px",
    paddingRight: "8px",
    marginRight: "8px",
})

export const ContentContainer = styled("div", {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    wordBreak: "break-word",
    overflowWrap: "break-word",
    hyphens: "auto",
    overflow: "auto",
})

export const CardTitle = styled("h3", {
    fontSize: "20px",
    fontWeight: "bold",
    margin: "0",
    alignSelf: "flex-end",
    maxWidth: "100%"
})

export const ButtonContainer = styled("div", {
    display: "flex",
    gap: "10px",
    marginTop: "20px",
    '& > button': {
        height: '44px',
        borderRadius: '6px',
        fontSize: '16px',
    }
});