import React, { RefObject } from 'react';
import { Menu, MenuRef } from 'antd';
import type { MenuProps } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import { Week } from '../../services/courses';
import { capitalize } from '../../utils/utils';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';

interface AssignmentMenuProps {
    weeks: Week[];
    onAssignmentSelect: (weekNumber: number) => void;
    assignmentsRef: RefObject<HTMLElement | null>;
}

const AssignmentMenu: React.FC<AssignmentMenuProps> = ({
    weeks,
    onAssignmentSelect,
    assignmentsRef
}) => {
    const user = getUserDataFromLocalStorage();
    const filteredWeeks = weeks.filter(week => week.assignment && week.assignment.trim() !== '');

    const items: MenuProps['items'] = [{
        label: 'Assignments',
        key: 'select-assignments',
        icon: <FormOutlined ref={assignmentsRef} />,
        children: filteredWeeks.length > 0
            ? filteredWeeks.map((week) => ({
                key: `assignment-${week.number}`,
                label: `${capitalize(user.config.module_terminology)} ${week.number} Assignment${week.title ? `: ${week.title}` : ''}`,
                onClick: () => {
                    onAssignmentSelect(week.number);
                }
            }))
            : []
    }];

    return (
        <Menu
            style={{
                overflow: 'visible',
                border: 'none',
                boxShadow: 'none',
                color: 'inherit',
            }}
            mode="inline"
            items={items}
            selectedKeys={[]} // This ensures no items are ever shown as selected
            onSelect={({ key, selectedKeys }) => {
                // Immediately clear any selection that might occur
                return { selectedKeys: [] };
            }}
        />
    );
};

export default AssignmentMenu;