import React from 'react';
import { Container } from '../../styles/chat';

interface ChatContainerProps {
    siderCollapsed: boolean;
    children: React.ReactNode;
}

const ChatContainer: React.FC<ChatContainerProps> = ({ siderCollapsed, children }) => {
    return (
        <Container
            style={{
                width: siderCollapsed ? "100%" : "70%",
                maxWidth: "100%",
                overflowX: "hidden"
            }}
        >
            {children}
        </Container>
    );
};

export default ChatContainer;
