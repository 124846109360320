import { useCallback, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';


const StripeCheckout = () => {
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);
  const [clientSecret, setClientSecret] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const user = getUserDataFromLocalStorage();
  const formData = location.state;


  useEffect(() => {
    if (!formData) {
      message.error('No signup data found. Please start the login process again.');
      navigate('/login');
    }
  }, [formData, navigate]);

  const fetchClientSecret = useCallback(() => {
    if (!formData) return Promise.reject('No form data');

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: user._id,
        email: user.email,
        type: formData.type,
        organization: user.config.organization,
        pay_as_you_go: formData.pay_as_you_go,
        number_of_students: formData.number_of_students,
        number_of_messages: formData.number_of_messages,
        referral_code: formData.referral_code,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);
        return data.clientSecret;
      })
      .catch((error) => {
        console.error('Error fetching client secret:', error);
        message.error('There was an error setting up the checkout. Please try again.');
      });
  }, [formData]);

  const options = { fetchClientSecret };

  if (!formData) {
    return null; // or a loading spinner
  }

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
}

export default StripeCheckout;