import {MenuRef, Tour} from 'antd';
import {TourProps, TourStepProps} from 'antd';
import {useEffect, useState, RefObject} from 'react';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';
import { capitalize } from '../../utils/utils';

// Import the correct type for placement
type PlacementType =
    'left'
    | 'right'
    | 'top'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom';

interface ChatTourProps {
    open: boolean;
    onClose: () => void;
    chatTypeSpecifier: string;
    inputRef: RefObject<HTMLDivElement | null>;
    templateRef: RefObject<HTMLDivElement | null>;
    navigatorRef: RefObject<HTMLDivElement | null>;
    modulesRef: RefObject<HTMLElement | null>;
    assignmentsRef?: RefObject<HTMLElement | null>;
    groupsRef?: RefObject<HTMLElement | null>;
    materialsRef?: RefObject<HTMLElement | null>;
    dateRangeRef?: RefObject<HTMLDivElement | null>;
}

const ChatTour: React.FC<ChatTourProps> = ({
                                               open,
                                               onClose,
                                               chatTypeSpecifier,
                                               inputRef,
                                               templateRef,
                                               navigatorRef,
                                               modulesRef,
                                               assignmentsRef,
                                               groupsRef,
                                               materialsRef,
                                               dateRangeRef
                                           }) => {
    const [steps, setSteps] = useState<TourProps['steps']>([]);
    const user = getUserDataFromLocalStorage();

    useEffect(() => {
        // Base steps that are always present
        const baseSteps: TourStepProps[] = [
            {
                title: 'Chat Input and Templates',
                description: 'Type your messages here. Use the template menu (↑) to create, save, and reuse message templates. Press Shift + Enter for new lines.',
                target: inputRef.current,
                placement: 'top' as PlacementType,
            },
            {
                title: 'Template Menu',
                description: 'Create and manage message templates here. Templates help you save and reuse common messages.',
                target: templateRef.current,
                placement: 'left' as PlacementType,
            },
            {
                title: 'Navigator',
                description: `Use the Navigator to control what content is accessible to the AI. All filters (modules, groups, ${user.config.material_terminology}s) work in combination to refine the available content.`,
                target: navigatorRef.current,
                placement: 'right' as PlacementType,
            },
        ];

        // Steps specific to content chat type
        const contentSteps: TourStepProps[] = chatTypeSpecifier === 'content' ? [
            ...(assignmentsRef?.current ? [{
                title: 'Assignments Menu',
                description: `View and access ${user.config.module_terminology} assignments. Selecting an assignment will automatically add it to the chat conversation.`,
                target: assignmentsRef.current.parentElement,
                placement: 'right' as PlacementType,
            }] : []),
            ...(modulesRef?.current ? [{
                title: `${capitalize(user.config.module_terminology)} Selection`,
                description: `Select specific modules to limit the AI\'s access to course ${user.config.material_terminology}s within those modules.`,
                target: modulesRef.current.parentElement,
                placement: 'right' as PlacementType,
            }] : []),
            ...(groupsRef?.current ? [{
                title: 'Group Filter',
                description: `Course ${user.config.material_terminology}s can be organized into groups by your educator. Select specific groups to limit the AI\'s access to ${user.config.material_terminology}s within those groups.`,
                target: groupsRef.current.parentElement,
                placement: 'right' as PlacementType,
            }] : []),
            ...(materialsRef?.current ? [{
                title: `${capitalize(user.config.material_terminology)}s Filter`,
                description: `Choose specific course ${user.config.material_terminology}s to further refine what content the AI can access. This is useful when you want to focus on particular documents or resources.`,
                target: materialsRef.current.parentElement,
                placement: 'right' as PlacementType,
            }] : []),
        ] : [];

        // Steps specific to analytics chat type
        const analyticsSteps: TourStepProps[] = chatTypeSpecifier === 'analytics' ? [
            ...(dateRangeRef?.current ? [{
                title: 'Date Range Selection',
                description: 'Select a specific time period to analyze. This will limit the AI\'s analytics to data within the selected date range.',
                target: dateRangeRef.current,
                placement: 'right' as PlacementType,
            }] : []),
            ...(modulesRef?.current ? [{
                title: `${capitalize(user.config.module_terminology)} Selection`,
                description: `Select specific modules to limit the AI\'s access to course ${user.config.material_terminology}s within those modules.`,
                target: modulesRef.current.parentElement,
                placement: 'right' as PlacementType,
            }] : []),
        ] : [];

        // Filter out any steps where the target element doesn't exist
        const allSteps = [...baseSteps, ...contentSteps, ...analyticsSteps].filter(
            step => step.target != null
        );

        setSteps(allSteps);
    }, [
        chatTypeSpecifier,
        inputRef.current,
        templateRef.current,
        navigatorRef.current,
        modulesRef.current,
        assignmentsRef?.current,
        groupsRef?.current,
        materialsRef?.current,
        dateRangeRef?.current
    ]);

    return (
        <Tour
            open={open}
            onClose={onClose}
            steps={steps}
            rootClassName="chat-interface-tour"
        />
    );
};

export default ChatTour;