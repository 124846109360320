import { styled } from '@stitches/react';

export const HorizontalScrollContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'auto',
  overflowY: 'hidden',
  whiteSpace: 'nowrap',
  padding: '10px',
  gap: '20px',
  minHeight: '340px',
  maxHeight: '340px',
  width: '100%',
  '&::-webkit-scrollbar': {
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
  // Enable momentum-based scrolling on iOS
  WebkitOverflowScrolling: 'touch',
});

export const CardWrapper = styled('div', {
  display: 'inline-block',
  minWidth: '350px',
  maxWidth: '350px',
  height: '100%',
  flexShrink: 0,
});

export const SectionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '340px',
  maxHeight: '340px',
  padding: '10px 0',  // Add padding to ensure tour highlight encompasses everything
});

export const SectionTitle = styled('div', {
  marginBottom: '15px',
  paddingLeft: '10px',
});
