import { Spin, Typography, Button } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { HorizontalScrollContainer, CardWrapper, SectionContainer, SectionTitle } from '../../components/common/horizontal-scroll-container';
import ProjectCard from '../../components/cards/project-card';
import AutodidactCourseCard from '../../components/cards/autodidact-course-card';
import { CourseOverview, getUserCourseOverview } from '../../services/courses';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';
import SearchAndAddCourse from '../../components/course/SearchAndAddCourse';
import { ScrollableCourseOverviewContent } from '../settings/styles';
import { QuestionCircleOutlined } from "@ant-design/icons";
import { HomeTour } from '../../components/tours/home-tour';
import { admin_type, autodidact_type, marketpalce_admin_type, technical_admin_type } from '../../services/user';
import CommunitiesSection from '../communities/communities-section';

const Home: React.FC = () => {
  const user = getUserDataFromLocalStorage();
  const [courses, setCourses] = useState<CourseOverview[]>([]);
  const [projects, setProjects] = useState<CourseOverview[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [selectedProfessor, setSelectedProfessor] = useState<string | undefined>(undefined);
  const [selectedType, setSelectedType] = useState<string | undefined>(undefined);
  const [tourOpen, setTourOpen] = useState<boolean>(false);

  // Refs for tour targets
  const welcomeAreaRef = useRef<HTMLDivElement>(null);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const projectsSectionRef = useRef<HTMLDivElement>(null);
  const coursesSectionRef = useRef<HTMLDivElement>(null);
  const buildButtonRef = useRef<HTMLButtonElement>(null);
  const chatButtonRef = useRef<HTMLButtonElement>(null);
  const editButtonRef = useRef<HTMLButtonElement>(null);
  const contributeButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const fetchCoursesAndProjects = async () => {
      try {
        const user = getUserDataFromLocalStorage();
        const coursesData = await getUserCourseOverview(user._id, user.course_id_list);

        // Separate courses and projects
        const projectsData = coursesData.filter(course => course.config?.is_project);
        const regularCoursesData = coursesData.filter(course => !course.config?.is_project);

        setProjects(projectsData);
        setCourses(regularCoursesData);
      } catch (error) {
        console.error(`Error fetching courses and projects:`, error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCoursesAndProjects();
  }, []);

  // Filter projects based on search query, last_updated, and professor names
  const filteredProjects = projects.filter(project => {
    const matchesSearchQuery = project.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      project.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      project.last_updated.toLowerCase().includes(searchQuery.toLowerCase()) ||
      project.professors.some(professor => professor.name.toLowerCase().includes(searchQuery.toLowerCase()));

    const matchesDateFilter = selectedDate ? new Date(project.last_updated) >= selectedDate.toDate() : true;
    const matchesProfessorFilter = selectedProfessor ? project.professors.some(professor => professor.name === selectedProfessor) : true;

    return matchesSearchQuery && matchesDateFilter && matchesProfessorFilter;
  });

  // Filter courses based on search query, last_updated, and professor names
  const filteredCourses = courses.filter(course => {
    const matchesSearchQuery = course.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      course.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      course.last_updated.toLowerCase().includes(searchQuery.toLowerCase()) ||
      course.professors.some(professor => professor.name.toLowerCase().includes(searchQuery.toLowerCase()));

    const matchesDateFilter = selectedDate ? new Date(course.last_updated) >= selectedDate.toDate() : true;
    const matchesProfessorFilter = selectedProfessor ? course.professors.some(professor => professor.name === selectedProfessor) : true;

    return matchesSearchQuery && matchesDateFilter && matchesProfessorFilter;
  });

  const handleRemoveItem = (id: string) => {
    setCourses(prevCourses => prevCourses.filter(course => course._id !== id));
    setProjects(prevProjects => prevProjects.filter(project => project._id !== id));
  };

  // Get all professors for the filter
  const allProfessors = [...new Set([...courses, ...projects].flatMap(item => item.professors.map(prof => prof.name)))];

  return (
    <ScrollableCourseOverviewContent>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexShrink: 0,
        width: '100%'
      }}
        ref={welcomeAreaRef}
      >
        <div
          style={{ marginRight: '20px' }}
        >
          <Typography.Title
            level={1}
            style={{ marginTop: '0', fontSize: '40px' }}
          >
            Welcome, {user.name}
          </Typography.Title>
          <Typography.Paragraph>Here's what's happening in your world</Typography.Paragraph>
        </div>

        <div style={{ width: '50%' }}
          ref={searchBarRef}
        >
          <SearchAndAddCourse
            userType={user.type}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            professors={allProfessors}
            setSelectedDate={setSelectedDate}
            setSelectedProfessor={setSelectedProfessor}
            setSelectedType={setSelectedType}
          />
        </div>
      </div>

      {isLoading && <Spin size="large" />}

      {!isLoading && (
        <>
          {/* Projects Section */}
          {(selectedType === undefined || selectedType === 'both' || selectedType === 'project') && (
            <SectionContainer
              style={{
                minHeight: '300px',
                maxHeight: '300px',
              }}>
              <SectionTitle>
                <Typography.Title
                  level={2}
                  style={{ marginTop: '10px', marginBottom: '0' }}
                >
                  Projects
                </Typography.Title>
              </SectionTitle>
              {filteredProjects.length === 0 ? (
                <div style={{ textAlign: 'center', margin: '20px' }}>
                  <Typography.Paragraph>No projects to display. Create a new project using the 'Create' button above.</Typography.Paragraph>
                </div>
              ) : (
                <HorizontalScrollContainer
                  ref={projectsSectionRef}
                  style={{
                    minHeight: '240px',
                    maxHeight: '240px',
                  }}>
                  {filteredProjects.map((project, index) => (
                    <CardWrapper key={`${project._id}-${index}`}>
                      <ProjectCard
                        id={project._id}
                        title={project.name}
                        description={project.description}
                        professors={project.professors}
                        removable={!(project.professors.map(professor => professor._id).includes(user._id))
                          && [admin_type, technical_admin_type, marketpalce_admin_type, autodidact_type].includes(user.type)}
                        onRemove={handleRemoveItem}
                        chatButtonRef={index === 0 ? buildButtonRef : undefined}
                        editButtonRef={index === 0 ? editButtonRef : undefined}
                        contributeButtonRef={index === 0 ? contributeButtonRef : undefined}
                      />
                    </CardWrapper>
                  ))}
                </HorizontalScrollContainer>
              )}
            </SectionContainer>
          )}

          {/* Courses Section */}
          {(selectedType === undefined || selectedType === 'both' || selectedType === 'course') && (
            <SectionContainer
              style={{
                minHeight: '400px',
                maxHeight: '400px',
              }}>
              <SectionTitle>
                <Typography.Title
                  level={2}
                  style={{ marginTop: '10px', marginBottom: '0' }}
                >
                  Courses
                </Typography.Title>
              </SectionTitle>
              {filteredCourses.length === 0 ? (
                <div style={{ textAlign: 'center', margin: '20px' }}>
                  <Typography.Paragraph>No courses to display. Create a new course using the 'Create' button above.</Typography.Paragraph>
                </div>
              ) : (
                <HorizontalScrollContainer ref={coursesSectionRef}>
                  {filteredCourses.map((course, index) => (
                    <CardWrapper key={`${course._id}-${index}`}>
                      <AutodidactCourseCard
                        id={course._id}
                        title={course.name}
                        description={course.description}
                        professors={course.professors}
                        lastUpdated={course.last_updated}
                        removable={!(course.professors.map(professor => professor._id).includes(user._id))
                          && [admin_type, technical_admin_type, marketpalce_admin_type, autodidact_type].includes(user.type)}
                        onRemove={handleRemoveItem}
                        chatButtonRef={index === 0 ? chatButtonRef : undefined}
                        editButtonRef={index === 0 ? editButtonRef : undefined}
                        contributeButtonRef={index === 0 ? contributeButtonRef : undefined}
                      />
                    </CardWrapper>
                  ))}
                </HorizontalScrollContainer>
              )}
            </SectionContainer>
          )}

          {/* Communities Section */}
          {(selectedType === undefined || selectedType === 'both' || selectedType === 'community') && (
            <CommunitiesSection />
          )}
        </>
      )}

      <Button
        icon={<QuestionCircleOutlined />}
        onClick={() => setTourOpen(true)}
        style={{
          marginLeft: '16px', width: '5%', minWidth: '40px', position: 'fixed', bottom: '20px', right: '20px'
        }}
      />
      <HomeTour
        open={tourOpen}
        onClose={() => setTourOpen(false)}
        welcomeAreaRef={welcomeAreaRef}
        searchBarRef={searchBarRef}
        projectsSectionRef={projectsSectionRef}
        coursesSectionRef={coursesSectionRef}
        buildButtonRef={buildButtonRef}
        chatButtonRef={chatButtonRef}
        editButtonRef={editButtonRef}
        contributeButtonRef={contributeButtonRef}
        userType={user.type}
      />
    </ScrollableCourseOverviewContent>
  );
};

export default Home;