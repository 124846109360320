import { getUserDataFromLocalStorage } from "../utils/useLocalStorage";
import { authedAxios } from "./auth-axios";

export async function storeFeedback(nps: number, nextFeature: string, comments: string, userId: string) {
    try {
        const response = await authedAxios.post(`${process.env.REACT_APP_BACKEND_URL}/store-feedback`, {
            userId,
            nps,
            nextFeature,
            comments,
        })
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
}

export async function storeQuickSupport(text: string, userId: string) {
    try {
        const response = await authedAxios.post(`${process.env.REACT_APP_BACKEND_URL}/quick-support`, {
            userId,
            text,
        })
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
}

