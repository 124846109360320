import React, { useState } from "react";
import { styled } from "@stitches/react";
import { Collapse, Tabs, Typography, Button, Table } from "antd";
import { FileAddOutlined, BarsOutlined, FileTextOutlined, UploadOutlined } from "@ant-design/icons";
import { Week, WeekMaterial } from "../../services/courses";
import { capitalize, hexToRGBA } from "../../utils/utils";
import { theme } from "antd";
import ContributionModal from "./contribution-modal";
import { getUserDataFromLocalStorage } from "../../utils/useLocalStorage";
import { CourseFile } from "../../services/files";

const { Panel } = Collapse;

const WeekContainer = styled('div', {
  margin: '1rem 0',
  padding: "1rem 2rem",
  borderRadius: '8px',
});

interface ViewerWeeksProps {
  weeks: Week[];
  courseId: string;
  existingFiles: CourseFile[];
}

export const ViewerWeeks: React.FC<ViewerWeeksProps> = ({ weeks, courseId, existingFiles }) => {
  const { token } = theme.useToken();
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [contributionModalVisible, setContributionModalVisible] = useState(false);
  const [selectedWeekIndex, setSelectedWeekIndex] = useState<number | null>(null);
  const user = getUserDataFromLocalStorage();

  const handleContribute = (weekIndex: number) => {
    setSelectedWeekIndex(weekIndex);
    setContributionModalVisible(true);
  };

  const renderMaterialsTab = (weekIndex: number, week: Week) => (
    <div>
      <Table
        dataSource={week.materials}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: "Pages / Time",
            key: 'span',
            render: (_: any, record: WeekMaterial) => (
              record.spans_fully ? 
                'All' : 
                `${record.span_start} - ${record.span_end}`
            ),
          },
          {
            title: "Group",
            dataIndex: 'group',
            key: 'group',
          },
        ]} 
        pagination={false}
        rowKey={(record) => record.file_id}
      />
    </div>
  );

  const renderTopicsTab = (weekIndex: number, week: Week) => (
    <>
      {week.topics.map((topic, topicIndex) => (
        <div key={topicIndex} style={{ marginBottom: '16px' }}>
          <Typography.Title level={5}>{topic.name}</Typography.Title>
          <Typography.Paragraph>{topic.description}</Typography.Paragraph>
        </div>
      ))}
      {week.topics.length === 0 && (
        <Typography.Paragraph>No topics available for this module.</Typography.Paragraph>
      )}
    </>
  );

  const renderSummaryTab = (weekIndex: number, week: Week) => (
    <div dangerouslySetInnerHTML={{ __html: week.summary || '<p>No summary available for this module.</p>' }} />
  );

  return (
    <div>
      <h2>Modules</h2>
      <Collapse
        activeKey={activeKeys}
        onChange={(keys) => setActiveKeys(keys as string[])}
      >
        {weeks.map((week, weekIndex) => (
          <Panel
            key={weekIndex.toString()}
            header={`${week.title || `${capitalize(user.config.module_terminology)} ${weekIndex + 1}`}`}
            extra={
              <Button 
                type="primary" 
                icon={<UploadOutlined />} 
                onClick={(e) => {
                  e.stopPropagation();
                  handleContribute(weekIndex);
                }}
              >
                Contribute
              </Button>
            }
          >
            <WeekContainer style={{ backgroundColor: hexToRGBA(token.colorBgElevated, 1) }}>
              <h3>Duration</h3>
              <div>
                {week.start_date && week.end_date ? (
                  <Typography.Text>
                    {new Date(week.start_date).toLocaleDateString()} - {new Date(week.end_date).toLocaleDateString()}
                  </Typography.Text>
                ) : (
                  <Typography.Text>No dates specified</Typography.Text>
                )}
              </div>

              <h3>Contents</h3>
              <Tabs
                defaultActiveKey="1"
                items={[
                  {
                    key: '1',
                    label: <span><FileAddOutlined />Materials</span>,
                    children: renderMaterialsTab(weekIndex, week),
                  },
                  {
                    key: '2',
                    label: <span><BarsOutlined />Topics</span>,
                    children: renderTopicsTab(weekIndex, week),
                  },
                  {
                    key: '3',
                    label: <span><FileTextOutlined />Summary</span>,
                    children: renderSummaryTab(weekIndex, week),
                  },
                ]}
              />
            </WeekContainer>
          </Panel>
        ))}
      </Collapse>

      {selectedWeekIndex !== null && (
        <ContributionModal
          visible={contributionModalVisible}
          onClose={() => setContributionModalVisible(false)}
          courseId={courseId}
          weekIndex={selectedWeekIndex}
          weekTitle={weeks[selectedWeekIndex]?.title || `${capitalize(user.config.module_terminology)} ${selectedWeekIndex + 1}`}
          existingFiles={existingFiles}
        />
      )}
    </div>
  );
};