import { Layout } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { portraitViewThreshold } from "../../config";
import React from "react";

interface BaseChatInterfaceProps {
    isDesktopPortraitView: boolean;
    children: ((siderCollapsed: boolean) => ReactNode) | ReactNode;
    siderContent: ReactNode;
    overlayContent?: ReactNode;
}

const BaseChatInterface: React.FC<BaseChatInterfaceProps> = ({
    isDesktopPortraitView,
    children,
    siderContent,
    overlayContent
}) => {
    const [siderCollapsed, setSiderCollapsed] = useState(isDesktopPortraitView);

    // Window resize effect
    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width < portraitViewThreshold) {
                setSiderCollapsed(true);
            } else {
                setSiderCollapsed(false);
            }
        };

        // Initial check
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Render children based on whether it's a function or direct ReactNode
    const renderChildren = () => {
        if (typeof children === 'function') {
            return children(siderCollapsed);
        }
        return children;
    };

    // Clone sider content and pass siderCollapsed prop
    const siderContentWithProps = React.isValidElement(siderContent)
        ? React.cloneElement(siderContent, { 
            siderCollapsed, 
            setSiderCollapsed 
        } as any)
        : siderContent;

    return (
        <Layout style={{ height: '100vh', overflow: 'hidden' }}>
            <Layout.Content
                style={{
                    transition: '0.2s ease-in-out',
                    width: '100%',
                    maxWidth: '100%',
                    overflowX: 'hidden' // Prevent horizontal scrolling
                }}
            >
                {renderChildren()}
                {overlayContent}
            </Layout.Content>
            {siderContentWithProps}
        </Layout>
    );
};

export default BaseChatInterface;
