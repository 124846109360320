import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    LeftOutlined,
    RightOutlined,
    TeamOutlined,
    UserOutlined,
    DoubleRightOutlined,
    GlobalOutlined,
    ProjectOutlined,
    ReadOutlined,
    BankOutlined
} from "@ant-design/icons";
import { Row, Typography, Button, Menu } from "antd";
import { collapsedSiderWidth, siderWidthOpened } from "../../config";
import { StyledMenu, StyledSider } from "../../styles/chat";
import { DiscoverNavigationProps } from "../../types/chat";
import { CourseOverview } from "../../services/courses";
import { newConversation } from "../../services/conversations";
import { getUserDataFromLocalStorage } from "../../utils/useLocalStorage";
import { getCommunityUniverse } from "../../services/communities";
import NavMenuButton from "../common/NavMenuButton";

const { Title } = Typography;

const DiscoverNavigation: React.FC<DiscoverNavigationProps> = ({
    siderCollapsed,
    setSiderCollapsed,
    navigatorRef,
    seekingMentor,
    setSeekingMentor,
    seekingCofounders,
    setSeekingCofounders,
    updateSeekingMentor,
    updateSeekingCofounders,
    seekingCourses,
    setSeekingCourses,
    updateSeekingCourses,
    seekingProjects,
    setSeekingProjects,
    updateSeekingProjects,
    seekingCommunities,
    setSeekingCommunities,
    selectedCommunityIds,
    setSelectedCommunityIds,
    updateSeekingCommunities,
    updateSeekingAll,
}) => {
    const user = getUserDataFromLocalStorage();
    const [isLoadingPopularCourses, setIsLoadingPopularCourses] = useState<boolean>(false);
    const [openKeys, setOpenKeys] = useState<string[]>(['people-menu', 'communities-menu']);
    const [selectedPeopleItems, setSelectedPeopleItems] = useState<string[]>([]);
    const [selectedButtonItems, setSelectedButtonItems] = useState<string[]>([]);
    const [allSelected, setAllSelected] = useState<boolean>(false);
    const [availableCommunities, setAvailableCommunities] = useState<{ _id: string, name: string }[]>([]);
    const [loadingCommunities, setLoadingCommunities] = useState<boolean>(false);
    const [communityMenuItems, setCommunityMenuItems] = useState<any[]>([]);
    const navigate = useNavigate();
    
    // Load available communities and create menu items
    useEffect(() => {
        const loadCommunities = async () => {
            setLoadingCommunities(true);
            try {
                const communities = await getCommunityUniverse(user._id);
                setAvailableCommunities(communities);
                
                // Create menu items for communities
                const communityItems = communities.map(community => ({
                    label: community.name,
                    key: community._id,
                }));
                
                // Add 'All Communities' option if there are multiple communities
                if (communityItems.length >= 2) {
                    communityItems.unshift({
                        label: 'All',
                        key: 'all-communities',
                    });
                }
                
                // Create the menu structure
                const newCommunityMenuItems = [{
                    label: 'Communities',
                    key: 'communities-menu',
                    icon: <BankOutlined />,
                    children: communityItems,
                }];
                
                setCommunityMenuItems(newCommunityMenuItems);
            } catch (error) {
                console.error('Error loading communities:', error);
            } finally {
                setLoadingCommunities(false);
            }
        };
        
        loadCommunities();
    }, [user._id]);
    
    // Function to clear all selections
    const clearAllSelections = () => {
        setAllSelected(false);
        setSeekingMentor(false);
        setSeekingCofounders(false);
        setSeekingCourses(false);
        setSeekingProjects(false);
        setSeekingCommunities(false);
        setSelectedCommunityIds([]);
        setSelectedPeopleItems([]);
        setSelectedButtonItems([]);
    };
    
    // Handler for the All button
    const handleAllClick = () => {
        const newState = !allSelected;
        clearAllSelections();
        setAllSelected(newState);
        setSelectedPeopleItems(newState ? ['all-people'] : []);
        updateSeekingAll(newState);
    };
    
    // Handler for Courses button
    const handleCoursesClick = () => {
        const newState = !selectedButtonItems.includes('seeking-courses');
        clearAllSelections();
        setSeekingCourses(newState);
        setSelectedButtonItems(newState ? ['seeking-courses'] : []);
        updateSeekingCourses(newState);
    };
    
    // Handler for Projects button
    const handleProjectsClick = () => {
        const newState = !selectedButtonItems.includes('seeking-projects');
        clearAllSelections();
        setSeekingProjects(newState);
        setSelectedButtonItems(newState ? ['seeking-projects'] : []);
        updateSeekingProjects(newState);
    };
    
    // Handler for community menu selection
    const handleCommunityMenuClick = (info: { key: string }) => {
        const { key } = info;
        
        // Handle 'All Communities' selection
        if (key === 'all-communities') {
            const allCommunityIds = availableCommunities.map(c => c._id);
            setSelectedCommunityIds(allCommunityIds);
            setSeekingCommunities(true);
            updateSeekingCommunities(true, allCommunityIds);
            return;
        }
        
        // Handle individual community selection/deselection
        let updatedSelectedIds: string[];
        if (selectedCommunityIds.includes(key)) {
            // Deselect this community
            updatedSelectedIds = selectedCommunityIds.filter(id => id !== key);
        } else {
            // Select this community
            updatedSelectedIds = [...selectedCommunityIds, key];
        }
        
        setSelectedCommunityIds(updatedSelectedIds);
        
        // If no communities selected, turn off the seeking flag
        if (updatedSelectedIds.length === 0) {
            setSeekingCommunities(false);
            updateSeekingCommunities(false, []);
        } else {
            setSeekingCommunities(true);
            updateSeekingCommunities(true, updatedSelectedIds);
        }
    };
    
    const handlePeopleMenuClick = (info: { key: string }) => {
        const { key } = info;
        
        if (key === 'all-people') {
            handleAllClick();
        } else if (key === 'seeking-mentor') {
            const newValue = !seekingMentor;
            clearAllSelections();
            setSeekingMentor(newValue);
            updateSeekingMentor(newValue);
            setSelectedPeopleItems(newValue ? ['seeking-mentor'] : []);
        } else if (key === 'seeking-cofounders') {
            const newValue = !seekingCofounders;
            clearAllSelections();
            setSeekingCofounders(newValue);
            updateSeekingCofounders(newValue);
            setSelectedPeopleItems(newValue ? ['seeking-cofounders'] : []);
        }
    };
    
    const handleOpenChange = (keys: string[]) => {
        setOpenKeys(keys);
    };

    const handleCourseClick = async (courseId: string) => {
        try {
            const newChat = await newConversation(user._id, 'content', courseId);
            navigate(`/chat/${newChat._id}`);
        } catch (error) {
            console.error("Failed to create chat for course:", error);
        }
    };

    return (
        <StyledSider
            ref={navigatorRef}
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
            width={siderWidthOpened}
            collapsedWidth={collapsedSiderWidth}
            collapsible
            collapsed={siderCollapsed}
            onCollapse={(value) => setSiderCollapsed(value)}
            trigger={null}>
            <div style={{ flex: 1, overflow: 'auto', overflowX: 'hidden' }}>
                <Row justify="space-between">
                    <div style={{
                        textAlign: 'left',
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        boxShadow: 'none',
                        color: 'inherit',
                        backgroundColor: 'transparent',
                        marginBottom: '0.5rem',
                    }}>
                        <Title
                            level={4}
                            className="!mb-0"
                            style={{ textAlign: 'center', paddingBottom: 10 }}
                        >
                            {siderCollapsed ? "Nav" : "Navigator"}
                        </Title>
                    </div>
                </Row>

                {!siderCollapsed && (
                    <>
                        <div style={{ overflow: 'visible', marginBottom: '10px' }}>
                            <NavMenuButton
                                icon={<GlobalOutlined />}
                                onClick={handleAllClick}
                                selected={allSelected}
                            >
                                All
                            </NavMenuButton>
                        </div>
                        <div style={{ overflow: 'visible' }}>
                            <Menu
                                style={{
                                    overflow: 'visible',
                                    border: 'none',
                                    boxShadow: 'none',
                                    color: 'inherit',
                                }}
                                onClick={handlePeopleMenuClick}
                                selectedKeys={selectedPeopleItems}
                                openKeys={openKeys}
                                onOpenChange={handleOpenChange}
                                mode="inline"
                                items={[
                                    {
                                        label: 'People',
                                        key: 'people-menu',
                                        icon: <TeamOutlined />,
                                        children: [
                                            {
                                                label: `Open to Mentor`,
                                                key: 'seeking-mentor',
                                                icon: <UserOutlined />,
                                                disabled: allSelected
                                            },
                                            {
                                                label: `Seeking Co-Founders`,
                                                key: 'seeking-cofounders',
                                                icon: <DoubleRightOutlined />,
                                                disabled: allSelected
                                            }
                                        ]
                                    }
                                ]}
                            />
                        </div>

                        {/* Communities Menu */}
                        <div style={{ overflow: 'visible', marginTop: '10px' }}>
                            <Menu
                                style={{
                                    overflow: 'visible',
                                    border: 'none',
                                    boxShadow: 'none',
                                    color: 'inherit',
                                }}
                                onClick={handleCommunityMenuClick}
                                selectedKeys={selectedCommunityIds}
                                defaultOpenKeys={['communities-menu']}
                                mode="inline"
                                items={communityMenuItems}
                            />
                        </div>

                        <div style={{ overflow: 'visible', marginTop: '20px' }}>
                            <NavMenuButton
                                icon={<ReadOutlined />}
                                onClick={handleCoursesClick}
                                selected={selectedButtonItems.includes('seeking-courses')}
                                disabled={allSelected}
                                style={{ marginBottom: '10px' }}
                            >
                                Courses
                            </NavMenuButton>
                            <NavMenuButton
                                icon={<ProjectOutlined />}
                                onClick={handleProjectsClick}
                                selected={selectedButtonItems.includes('seeking-projects')}
                                disabled={allSelected}
                            >
                                Projects
                            </NavMenuButton>
                        </div>
                    </>
                )}
            </div>
            <div style={{ marginTop: 'auto' }}>
                <StyledMenu
                    mode="vertical"
                    selectedKeys={[]}
                    items={[
                        {
                            key: "collapse",
                            icon: siderCollapsed ? <LeftOutlined /> : <RightOutlined />,
                            onClick: () => setSiderCollapsed(prev => !prev),
                            label: null,
                            title: "",
                            style: { textAlign: 'center' }
                        },
                    ]}
                />
            </div>
        </StyledSider>
    );
};

export default DiscoverNavigation;