import { styled } from "@stitches/react";

export const MessageBubbleContainer = styled("div", {
    maxWidth: '100%',
    flexShrink: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "10px",
    padding: "10px",
});

export const TextContentContainer = styled("div", {
    borderRadius: '8px',
    padding: '12px',
    width: '100%',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    '& .markdown-body': {
        fontFamily: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        '& > :first-child': {
            marginTop: '0 !important',
            paddingTop: '0 !important',
        },
        '& > :last-child': {
            marginBottom: '0 !important',
            paddingBottom: '0 !important',
        },
    },
});

export const LikeDislikeContainer = styled("div", {
    display: "flex",
    flexDirection: "row",
    '& .anticon': {
        fontSize: '14px',
    },
    '& button': {
        scale: '0.8',
    }
});

export const StyledFlex = styled("div", {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem"
});

export const DiscoverItemsContainer = styled("div", {
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
        height: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: '4px'
    }
});

export const DiscoverItemsWrapper = styled("div", {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    padding: "5px 0",
    minWidth: "min-content"
});

export const DiscoverItemCard = styled("div", {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    borderRadius: "8px",
    cursor: "pointer",
    minWidth: "150px",
    minHeight: "150px",
    maxWidth: "150px",
    maxHeight: "150px",
    overflow: "hidden",
    transition: "background-color 0.2s ease",
    '&:hover': {
        cursor: "pointer"
    }
});

export const DiscoverItemText = styled("div", {
    textAlign: "center",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 5,
    WebkitBoxOrient: "vertical",
    fontSize: "0.9rem"
});