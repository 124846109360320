import { useState, useEffect } from 'react';
import { Content } from 'antd/es/layout/layout';
import { Typography, Card, Button, Modal, Radio, Checkbox, Spin, message } from 'antd';
import InvoiceTable from '../../components/billing/InvoicesTable';
import SubscriptionsTable from '../../components/billing/SubscriptionsTable';
import {
  getInvoiceInfo,
  cancelSubscription,
  switchPlan,
  InvoiceInfo,
  SubscriptionInfo,
  getCustomerPaymentMethods,
  getActiveSubscriptions
} from '../../services/subscription';
import { getUserDataFromLocalStorage, setUserDataInLocalStorage } from '../../utils/useLocalStorage';
import { autodidact_message_bundle_options, educator_message_bundle_options } from '../../config';
import { autodidact_type, getUserBilling } from '../../services/user';

const { Title, Text } = Typography;

const SubscriptionManagement = () => {
  const user = getUserDataFromLocalStorage();
  // Subscription state
  const [subscriptions, setSubscriptions] = useState<InvoiceInfo[]>([]);
  const [loading, setLoading] = useState(true);
  
  // Active subscriptions state
  const [activeSubscriptions, setActiveSubscriptions] = useState<SubscriptionInfo[]>([]);
  const [loadingActiveSubscriptions, setLoadingActiveSubscriptions] = useState(true);

  // Plan switching state
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [switchingPlan, setSwitchingPlan] = useState(false);
  const [selectedPlanType, setSelectedPlanType] = useState<'pay_as_you_go' | 'monthly'>('monthly');
  const [messageBundle, setMessageBundle] = useState<number>(
    autodidact_message_bundle_options.filter(option => option.number > 0)[0]?.number || 250
  );

  // Payment method state
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>(null);
  const [setAsDefault, setSetAsDefault] = useState<boolean>(true);
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState<boolean>(false);

  const [isPayAsYouGo, setIsPayAsYouGo] = useState<boolean>(user?.billing?.pay_as_you_go || false);
  const [allSubscriptionsCanceled, setAllSubscriptionsCanceled] = useState<boolean>(false);

  useEffect(() => {
    fetchSubscriptions();
    fetchActiveSubscriptions();
    if (user.type === autodidact_type) {
      setMessageBundle(autodidact_message_bundle_options.filter(option => option.number > 0)[0]?.number || 250);
    } else {
      setMessageBundle(educator_message_bundle_options.filter(option => option.number > 0)[0]?.number || 250);
    }
  }, []);

  const updateUserBilling = async (userId: string) => {
    try {
      const billing = await getUserBilling(userId);
      const user = getUserDataFromLocalStorage();
      user.billing = billing;
      setIsPayAsYouGo(billing.pay_as_you_go);
      setUserDataInLocalStorage(user);
    } catch (error) {
      console.error('Error fetching user billing:', error);
      message.error('Failed to load billing information');
    }
  };

  const fetchSubscriptions = async () => {
    setLoading(true);
    try {
      const data = await getInvoiceInfo(user._id);
      setSubscriptions(data);
      setAllSubscriptionsCanceled(data.length === 0 || data.every(sub => sub.canceled))
      await updateUserBilling(user._id);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
      message.error('Failed to load subscription information');
    } finally {
      setLoading(false);
    }
  };
  
  const fetchActiveSubscriptions = async () => {
    setLoadingActiveSubscriptions(true);
    try {
      const data = await getActiveSubscriptions(user._id);
      setActiveSubscriptions(data);
    } catch (error) {
      console.error('Error fetching active subscriptions:', error);
      message.error('Failed to load active subscription information');
    } finally {
      setLoadingActiveSubscriptions(false);
    }
  };

  const handleCancelSubscription = async (subscriptionId: string) => {
    try {
      await cancelSubscription(user._id, subscriptionId);
      message.success('Subscription cancelled successfully');
      fetchSubscriptions(); // Refresh the subscription list
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      message.error('Failed to cancel subscription');
    }
  };

  const fetchPaymentMethods = async () => {
    setLoadingPaymentMethods(true);
    try {
      const methods = await getCustomerPaymentMethods(user._id);
      setPaymentMethods(methods);
      // Preselect the first payment method if available
      if (methods.length > 0) {
        setSelectedPaymentMethod(methods[0].id);
      }
    } catch (error) {
      console.error('Error fetching payment methods:', error);
      message.error('Failed to load payment methods');
    } finally {
      setLoadingPaymentMethods(false);
    }
  };

  const getButtonText = () => {
    if (loading) {
      return "Loading...";
    } else if (allSubscriptionsCanceled) {
      return "Restart or Create a new Subscription";
    }
    return `Switch to ${isPayAsYouGo ? 'Monthly Plan' : 'Pay As You Go'}`;
  };

  const showSwitchModal = () => {
    // Set initial plan type based on current state
    if (allSubscriptionsCanceled || activeSubscriptions.length === 0) {
      // If all subscriptions canceled or no active subscriptions, default to pay-as-you-go
      setSelectedPlanType('pay_as_you_go');
    } else {
      // Otherwise, default to the current plan type
      setSelectedPlanType(isPayAsYouGo ? 'pay_as_you_go' : 'monthly');
    }

    // Fetch payment methods if current plan is pay-as-you-go or no active subscriptions
    fetchPaymentMethods();

    setIsModalVisible(true);
  };

  const handleSwitchPlan = async () => {
    setSwitchingPlan(true);
    try {
      await switchPlan(user._id, {
        new_plan_type: selectedPlanType,
        message_bundle: selectedPlanType === 'monthly' ? messageBundle : 0,
        payment_method_id: selectedPlanType === 'monthly' ? selectedPaymentMethod : undefined,
        set_as_default: selectedPlanType === 'monthly' ? setAsDefault : undefined
      });
      message.success('Your plan has been updated');
      setIsModalVisible(false);
      fetchSubscriptions(); // Refresh the subscriptions
      fetchActiveSubscriptions(); // Refresh the active subscriptions
    } catch (error) {
      console.error('Error switching plans:', error);
      message.error('Failed to switch plans. Please try again later.');
    } finally {
      setSwitchingPlan(false);
    }
  };

  return (
    <Content style={{ padding: "2rem" }}>
      <Title level={1}>Active Subscriptions</Title>
      <Card style={{ marginTop: '1rem', marginBottom: '2rem' }}>
        <SubscriptionsTable
          subscriptions={activeSubscriptions}
          loading={loadingActiveSubscriptions}
          onSwitchBundle={showSwitchModal}
        />
        {user.type === autodidact_type && activeSubscriptions.length === 0 && !loadingActiveSubscriptions && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Button
              type="primary"
              onClick={showSwitchModal}
              disabled={loadingActiveSubscriptions}
            >
              Create Subscription
            </Button>
          </div>
        )}
      </Card>
      
      <Title level={1}>Upcoming Invoices</Title>
      <Card style={{ marginTop: '1rem' }}>
        <InvoiceTable
          subscriptions={subscriptions}
          loading={loading}
          onCancelSubscription={handleCancelSubscription}
        />
        {user.type === autodidact_type && activeSubscriptions.length === 0 && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Button
              type="primary"
              onClick={showSwitchModal}
              disabled={loading}
            >
              {getButtonText()}
            </Button>
          </div>
        )}
      </Card>

      <Modal
        title={(allSubscriptionsCanceled || activeSubscriptions.length === 0) ? "Select Subscription Plan" : "Switch Plan"}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSwitchPlan}
        confirmLoading={switchingPlan}
        okText={"Confirm"}
      >
        <div>
          {(allSubscriptionsCanceled || activeSubscriptions.length === 0) && (
            <div style={{ marginBottom: 16 }}>
              <Text>Please select your preferred subscription plan:</Text>
              <Radio.Group
                value={selectedPlanType}
                onChange={(e) => setSelectedPlanType(e.target.value)}
                style={{ display: 'flex', flexDirection: 'column', marginTop: 8, gap: 8 }}
              >
                <Radio value="pay_as_you_go">Pay As You Go</Radio>
                <Radio value="monthly">Monthly Bundle</Radio>
              </Radio.Group>
            </div>
          )}

          {!allSubscriptionsCanceled && activeSubscriptions.length > 0 && (
            <div>
              <Text strong>Current Plan: </Text>
              <Text>{isPayAsYouGo ? 'Pay As You Go' : 'Monthly Bundle'}</Text>
              
              <div style={{ marginTop: 16 }}>
                <Text>Select your new subscription plan:</Text>
                <Radio.Group
                  value={selectedPlanType}
                  onChange={(e) => setSelectedPlanType(e.target.value)}
                  style={{ display: 'flex', flexDirection: 'column', marginTop: 8, gap: 8 }}
                >
                  <Radio value="pay_as_you_go">Pay As You Go</Radio>
                  <Radio value="monthly">Monthly Bundle</Radio>
                </Radio.Group>
              </div>
            </div>
          )}

          {!allSubscriptionsCanceled && activeSubscriptions.length > 0 && selectedPlanType === 'pay_as_you_go' && !isPayAsYouGo && (
            <Text style={{ display: 'block', marginTop: 16 }}>
              Your current subscription will be canceled at the end of the billing period. After that, you will be on a pay-as-you-go basis starting at 10 Cents per message.
            </Text>
          )}

          {!allSubscriptionsCanceled && activeSubscriptions.length > 0 && selectedPlanType === 'monthly' && isPayAsYouGo && (
            <Text style={{ display: 'block', marginTop: 16 }}>
              Your new monthly plan will be active immediately. You will be billed on a monthly basis.
            </Text>
          )}
            
          {!allSubscriptionsCanceled && activeSubscriptions.length > 0 && selectedPlanType === 'monthly' && !isPayAsYouGo && (
            <Text style={{ display: 'block', marginTop: 16 }}>
              You are switching between monthly bundles. Your current subscription will be canceled, and the new one will be active immediately.
            </Text>
          )}

          {selectedPlanType === 'monthly' && (
            <div style={{ marginTop: 16 }}>
              <Text>Select a message bundle:</Text>
              <Radio.Group
                value={messageBundle}
                onChange={e => setMessageBundle(e.target.value)}
                style={{ marginTop: 8, display: 'flex', flexDirection: 'column', gap: 8 }}
              >
                {autodidact_message_bundle_options.filter(option => option.number > 0).map(option => (
                  <Radio key={option.number} value={option.number}>
                    {option.title} messages
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          )}

          {selectedPlanType === 'monthly' && (
            <div style={{ marginTop: 16 }}>
              <Text strong>Select a Payment Method</Text>
              {loadingPaymentMethods ? (
                <Spin size="small" style={{ marginLeft: 8 }} />
              ) : paymentMethods.length > 0 ? (
                <>
                  <Radio.Group
                    value={selectedPaymentMethod}
                    onChange={e => setSelectedPaymentMethod(e.target.value)}
                    style={{ marginTop: 8, display: 'flex', flexDirection: 'column', gap: 8 }}
                  >
                    {paymentMethods.map(method => (
                      <Radio key={method.id} value={method.id}>
                        {method.card ? (
                          `${method.card.brand.toUpperCase()} ending in ${method.card.last4} (exp: ${method.card.exp_month}/${method.card.exp_year})`
                        ) : (
                          `Payment method (${method.type})`
                        )}
                      </Radio>
                    ))}
                  </Radio.Group>
                  <Checkbox
                    checked={setAsDefault}
                    onChange={e => setSetAsDefault(e.target.checked)}
                    style={{ marginTop: 12 }}
                  >
                    Set as default payment method
                  </Checkbox>
                </>
              ) : (
                <Text type="warning" style={{ display: 'block', marginTop: 8 }}>
                  No payment methods available. Please add a payment method first.
                </Text>
              )}
            </div>
          )}
        </div>
      </Modal>
    </Content>
  );
};

export default SubscriptionManagement;