import React, { useState } from "react";
import { Form, Input, Modal, message } from "antd";
import { setTelegramWebhook } from "../../services/courses";

interface TelegramBotSetupModalProps {
  visible: boolean;
  onClose: () => void;
  courseId: string;
}

const TelegramBotSetupModal: React.FC<TelegramBotSetupModalProps> = ({
  visible,
  onClose,
  courseId
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      
      const response = await setTelegramWebhook(values.botToken, courseId);
      
      if (response === true) {
        message.success("Telegram webhook setup successfully!");
        form.resetFields();
        onClose();
      } else {
        message.error("Failed to set up Telegram webhook.");
      }
    } catch (error: any) {
      message.error("Failed to set up Telegram webhook.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Set Up Telegram Bot"
      open={visible}
      onCancel={onClose}
      onOk={handleSubmit}
      okText="Set Up Webhook"
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="botToken"
          label="Telegram Bot Token"
          rules={[
            { required: true, message: "Please enter your Telegram bot token" },
            { 
              pattern: /^\d+:[A-Za-z0-9_-]+$/, 
              message: "Invalid bot token format. It should look like '123456789:AbCdEfGhIjKlMnOpQrStUvWxYz'" 
            }
          ]}
        >
          <Input placeholder="Enter your Telegram bot token" />
        </Form.Item>
        <p>
          You need to create a bot via BotFather on Telegram first.
          Once created, paste the bot token here to connect it with this course.
        </p>
      </Form>
    </Modal>
  );
};

export default TelegramBotSetupModal;
