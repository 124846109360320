import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { Container, TopImages } from '../../components/session-styles';
import { AbsoluteCenter } from '../../components/styles';
import { authedAxios } from '../../services/auth-axios';

interface ReturnProps {
    loginCallback: () => void;
    isDarkMode: boolean;
}

const Return: React.FC<ReturnProps> = ({ loginCallback, isDarkMode }) => {
    const [status, setStatus] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        fetch(`${process.env.REACT_APP_BACKEND_URL}/session-status?session_id=${sessionId}`)
            .then((res) => res.json())
            .then(async (data) => {
                setStatus(data.status);

                if (data.status === 'complete') {
                    // Retrieve stored form data
                    if (data.checkout_data) {
                        // Complete the signup process
                        const payload = {
                            payment_session_id: sessionId,
                            stripe_customer_id: data.stripe_customer_id,
                            paid: data.paid,
                            ...data.checkout_data
                        };
                        try {
                            const response = await authedAxios.post(
                                `/users/${data.checkout_data.user_id}/update-type`,
                                payload
                            )
                            if (response.data.updated) {
                                localStorage.setItem('user', JSON.stringify(response.data.user));
                                localStorage.removeItem('signupFormData');  // Clean up stored form data
                                message.success('Signup successful!');
                                loginCallback();
                            } else {
                                message.error('Failed to update user. Please try again.')
                            }
                        } catch (error: any) {
                            console.error('Error during signup:', error);
                            message.error('There was an error completing your signup. Please contact support.');
                        }
                    } else {
                        console.error('No stored form data found');
                        message.error('There was an error completing your signup. Please try again.');
                    }
                }
            })
            .catch((error) => {
                console.error('Error fetching session status:', error);
                message.error('There was an error processing your payment. Please contact support.');
            });
    }, [navigate]);

    if (status === 'open') {
        return (
            <Navigate to="/checkout" />
        )
    }
    if (status === 'complete') {
        return (
            <>
                <TopImages />
                <AbsoluteCenter>
                    <Container>
                        <section id="success" style={{ color: isDarkMode ? 'white' : 'black' }}>
                            <p>
                                We appreciate your business! You will be forwarded to your dashboard shortly.
                                If you have any questions, please email <a href="mailto:team@infolab.ai">team@infolab.ai</a>.
                            </p>
                        </section>
                    </Container>
                </AbsoluteCenter>
            </>
        );
    };

    return (
        <>
            <TopImages />
            <AbsoluteCenter>
                <Container>
                    Processing your payment...
                </Container>
            </AbsoluteCenter>
        </>
    );
}

export default Return;