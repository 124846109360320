import React from 'react';
import {
    Input, Col, Button, Space,
    Avatar, Tooltip, Typography, Upload, Radio, Switch, Select
} from 'antd';
import {
    UploadOutlined, UserOutlined,
    ShareAltOutlined
} from '@ant-design/icons';
import { FormSection, FormRow, LabelCol, InputCol, SectionTitle } from '../styles';

const { Text } = Typography;

interface ProfileOverviewSectionProps {
    newUserName: string;
    setNewUserName: (name: string) => void;
    location: string;
    setLocation: (location: string) => void;
    profilePicture?: string;
    tldr: string;
    bio: string;
    userId: string;
    handleProfilePictureUpload: (file: File) => Promise<void>;
    handleGenerateTldr: () => Promise<void>;
    handleShareProfile: () => void;
}

const ProfileOverviewSection: React.FC<ProfileOverviewSectionProps> = ({
    newUserName,
    setNewUserName,
    location,
    setLocation,
    profilePicture,
    tldr,
    bio,
    handleProfilePictureUpload,
    handleGenerateTldr,
    handleShareProfile,
}) => {
    return (
        <FormSection>
            <SectionTitle>Profile Overview</SectionTitle>
            <FormRow align="middle" justify="center" gutter={[24, 24]}>
                <Col xs={24} sm={6}>
                    <Space direction="vertical" align="center" style={{width: '100%'}}>
                        <Avatar
                            size={100}
                            icon={<UserOutlined/>}
                            src={profilePicture}
                            style={{border: '2px solid #f0f0f0'}}
                        />
                        <Upload
                            accept="image/*"
                            showUploadList={false}
                            beforeUpload={(file) => {
                                handleProfilePictureUpload(file);
                                return false;
                            }}
                        >
                            <Button icon={<UploadOutlined/>}>Change Photo</Button>
                        </Upload>
                    </Space>
                </Col>
                <Col xs={24} sm={18}>
                    <Space direction="vertical" style={{width: '100%'}}>
                        <FormRow>
                            <LabelCol span={6}>Name</LabelCol>
                            <InputCol span={18}>
                                <Input
                                    value={newUserName}
                                    onChange={(e) => setNewUserName(e.target.value)}
                                    style={{maxWidth: 300}}
                                />
                            </InputCol>
                        </FormRow>
                        <FormRow>
                            <LabelCol span={6}>Location</LabelCol>
                            <InputCol span={18}>
                                <Input
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                    placeholder="City, Country"
                                    style={{maxWidth: 300}}
                                />
                            </InputCol>
                        </FormRow>
                        <FormRow>
                            <LabelCol span={6}>Tldr</LabelCol>
                            <InputCol span={18}>
                                <Text type="secondary" style={{flex: 1}}>
                                    {tldr ? tldr : 'No AI summary generated yet'}
                                </Text>
                                <Tooltip title={!bio.trim() ? "Please add some content in About Me first" : ""}>
                                    <Button
                                        type="link"
                                        onClick={handleGenerateTldr}
                                        disabled={!bio.trim()}
                                    >
                                        {tldr ? 'Regenerate' : 'Generate'}
                                    </Button>
                                </Tooltip>
                            </InputCol>
                        </FormRow>
                        <FormRow>
                            <LabelCol span={6}></LabelCol>
                            <InputCol span={18}>
                                <Button
                                    icon={<ShareAltOutlined/>}
                                    onClick={handleShareProfile}
                                >
                                    Share Profile
                                </Button>
                            </InputCol>
                        </FormRow>
                    </Space>
                </Col>
            </FormRow>
        </FormSection>
    );
};

export default ProfileOverviewSection;