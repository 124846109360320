import React, { useState } from 'react';
import { Typography, Card, Button, Input, Form, List, Space, Empty } from 'antd';
import { PlusOutlined, DeleteOutlined, LinkOutlined } from '@ant-design/icons';
import { styled } from '@stitches/react';
import { PortfolioItem } from '../../../types/user';
import { v4 as uuidv4 } from 'uuid';

const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;

const Container = styled('div', {
  width: '100%',
  textAlign: 'left',
});

const StyledCard = styled(Card, {
  marginBottom: '1rem',
});

const AddButton = styled(Button, {
  marginBottom: '16px',
});

const UrlItem = styled('div', {
  marginBottom: '8px',
});

const PortfolioItemCard = styled(Card, {
  marginBottom: '16px',
});

const EmptyContainer = styled('div', {
  margin: '20px 0',
});

interface PortfolioStepProps {
  portfolio: PortfolioItem[];
  onPortfolioChange: (portfolio: PortfolioItem[]) => void;
}

const PortfolioStep: React.FC<PortfolioStepProps> = ({ portfolio, onPortfolioChange }) => {
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [form] = Form.useForm();

  const handleAddItem = () => {
    setIsAddingItem(true);
  };

  const handleCancelAdd = () => {
    setIsAddingItem(false);
    form.resetFields();
  };

  const handleSaveItem = () => {
    form.validateFields()
      .then(values => {
        const newItem: PortfolioItem = {
          id: uuidv4(),
          title: values.title,
          description: values.description,
          urls: values.urls || []
        };
        onPortfolioChange([...portfolio, newItem]);
        setIsAddingItem(false);
        form.resetFields();
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleDeleteItem = (id: string) => {
    onPortfolioChange(portfolio.filter(item => item.id !== id));
  };

  const handleAddUrl = () => {
    const urls = form.getFieldValue('urls') || [];
    form.setFieldsValue({
      urls: [
        ...urls,
        {
          url: '',
          explanation: '',
        },
      ],
    });
  };

  const handleRemoveUrl = (index: number) => {
    const urls = form.getFieldValue('urls') || [];
    form.setFieldsValue({
      urls: urls.filter((_: any, i: number) => i !== index),
    });
  };

  return (
    <Container>
      <Title level={3} style={{ 
        textAlign: 'center' 
        }}>
          Portfolio Items
          </Title>
      <Paragraph 
      >
        Showcase your projects, achievements, or work you're proud of. This is optional but helps
        others understand your skills and experience.
      </Paragraph>

      {portfolio.length > 0 ? (
        <List
          dataSource={portfolio}
          renderItem={(item) => (
            <PortfolioItemCard
              title={
                <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Text strong>{item.title}</Text>
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    type="text"
                    onClick={() => handleDeleteItem(item.id)}
                  />
                </Space>
              }
            >
              <Paragraph>{item.description}</Paragraph>
              
              {item.urls && item.urls.length > 0 && (
                <>
                  <Text strong>Links:</Text>
                  <List
                    dataSource={item.urls}
                    renderItem={(urlItem) => (
                      <List.Item>
                        <Space direction="vertical" style={{ width: '100%' }}>
                          <a href={urlItem.url} target="_blank" rel="noopener noreferrer">
                            <LinkOutlined /> {urlItem.url}
                          </a>
                          {urlItem.explanation && (
                            <Text type="secondary">{urlItem.explanation}</Text>
                          )}
                        </Space>
                      </List.Item>
                    )}
                  />
                </>
              )}
            </PortfolioItemCard>
          )}
        />
      ) : (
        <EmptyContainer>
          <Empty description="No portfolio items yet" />
        </EmptyContainer>
      )}

      {isAddingItem ? (
        <StyledCard title={<span 
        >Add Portfolio Item</span>}>
          <Form
            form={form}
            layout="vertical"
            name="portfolio_form"
          >
            <Form.Item
              name="title"
              label={<span 
                >Title</span>}
              rules={[{ required: true, message: 'Please enter a title' }]}
            >
              <Input placeholder="Project or work title" 
              />
            </Form.Item>
            
            <Form.Item
              name="description"
              label={<span 
                // style={{ color: 'white' }}
                >Description</span>}
              rules={[{ required: true, message: 'Please enter a description' }]}
            >
              <TextArea rows={4} placeholder="Describe your project, skills used, and outcomes" 
              />
            </Form.Item>
            
            <Form.List name="urls">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, 'url']}
                        rules={[{ required: true, message: 'URL is required' }]}
                      >
                        <Input placeholder="URL (e.g., https://github.com/...)" 
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'explanation']}
                      >
                        <Input placeholder="Link description (optional)" 
                        />
                      </Form.Item>
                      <DeleteOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} 
                    >
                      Add Link
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            
            <Form.Item>
              <Space>
                <Button type="primary" onClick={handleSaveItem} 
                >
                  Save Item
                </Button>
                <Button onClick={handleCancelAdd} 
                >
                  Cancel
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </StyledCard>
      ) : (
        <AddButton type="dashed" icon={<PlusOutlined />} onClick={handleAddItem} 
        >
          Add Portfolio Item
        </AddButton>
      )}
    </Container>
  );
};

export default PortfolioStep;
