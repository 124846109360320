import { styled } from "@stitches/react";
import { Button } from "antd";


export const NoMarginButton = styled(Button, {
    alignItems: 'center',
    justifyContent: 'center',
    '& .anticon': { // Target Ant Design icons specifically
        // display: 'flex',
        alignItems: 'center',
    },
});

export const MarginButton = styled(NoMarginButton, {
    marginRight: '2px',
    marginLeft: '2px',
    marginTop: '2px',
    marginBottom: '2px',
});

export const FlexMarginButton = styled(MarginButton, {
    display: 'flex',
    alignItems: 'center', // This centers items vertically
    justifyContent: 'center', // This centers items horizontally
    margin: '2px',
});

export const SaveButton = styled(Button, {
    position: "fixed",
    right: "32px",
    top: "32px",
    zIndex: 3
})