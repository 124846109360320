import React, { useState, useEffect } from 'react';
import { Typography, Input, Card } from 'antd';
import { styled } from '@stitches/react';

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

const Container = styled('div', {
  width: '100%',
  textAlign: 'left',
});

const StyledCard = styled(Card, {
  marginBottom: '1rem',
});

const HelpText = styled(Paragraph, {
  marginTop: '8px',
});

// Bio prompts to inspire users
const BIO_PROMPTS = [
  "What's your next big idea?",
  "What skill are you mastering?",
  "What drives you to create?",
  "What's the coolest thing you've learned?",
  "What are you passionate about learning?",
  "What problems are you interested in solving?",
  "What's your learning style?",
  "What are your career aspirations?"
];

interface BioStepProps {
  bio: string;
  onChange: (bio: string) => void;
}

const BioStep: React.FC<BioStepProps> = ({ bio, onChange }) => {
  const [currentPrompt, setCurrentPrompt] = useState(0);

  // Rotate prompts every few seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPrompt((prev) => (prev + 1) % BIO_PROMPTS.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <Title level={3} style={{ 
        textAlign: 'center' 
        }}>
          Tell us about yourself
          </Title>
      <Paragraph 
      >
        Share a bit about yourself, your interests, and what you're looking to learn or achieve.
        This helps us personalize your experience and connect you with like-minded learners.
      </Paragraph>

      <StyledCard title={<span 
      >Your Bio</span>}>
        <TextArea
          value={bio}
          onChange={(e) => onChange(e.target.value)}
          placeholder={BIO_PROMPTS[currentPrompt]}
          rows={4}
          showCount
          maxLength={500}
        />
        <HelpText 
        >
          What motivates you? What are you hoping to learn? Feel free to share your background,
          interests, or goals.
        </HelpText>
      </StyledCard>
    </Container>
  );
};

export default BioStep;
