import { SettingOutlined, UserOutlined, BarChartOutlined, SignatureOutlined, MessageFilled, CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { Avatar, Popover, theme } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { removeCourse, UserOverview } from '../../services/courses';
import { newConversation } from '../../services/conversations';
import { getUserDataFromLocalStorage, setUserDataInLocalStorage } from '../../utils/useLocalStorage';
import { NoMarginButton } from '../basic/buttons';
import { admin_type, autodidact_type, marketpalce_admin_type, professor_type, technical_admin_type } from '../../services/user';
import { CardContent, ContentContainer, DescriptionContainer, InnerCard, SonaAtlaCard, SonaAtlaTitle, StyledCard, StyledNoMarginButton, SonaAtlaTitleContainer } from '../../styles/course-card';
import AnimatedTitle from './animated-title';

const maxLengthDescription = 200; //chars

interface CourseCardProps {
    id: string;
    title: string;
    description: string;
    professors: UserOverview[];
    lastUpdated: string;
    removable?: boolean;
    onRemove?: (id: string) => void;
    chatButtonRef?: React.RefObject<HTMLButtonElement | null> | undefined;
    editButtonRef?: React.RefObject<HTMLButtonElement | null> | undefined;
    interactButtonRef?: React.RefObject<HTMLButtonElement | null> | undefined;
    analyzeButtonRef?: React.RefObject<HTMLButtonElement | null> | undefined;
}

const CourseCard: React.FC<CourseCardProps> = ({
    id,
    title,
    description,
    professors,
    lastUpdated,
    removable,
    onRemove,
    chatButtonRef,
    editButtonRef,
    interactButtonRef,
    analyzeButtonRef
}) => {
    const { token } = theme.useToken();
    const user = getUserDataFromLocalStorage();
    const navigate = useNavigate()
    const date = new Date(lastUpdated);
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;

    const handleNewConversation = () => {
        newConversation(user._id, "content", id).then(chat => {
            if (!user.course_id_list.includes(id)) {
                const updatedUser = {
                    ...user,
                    course_id_list: [...(user.course_id_list || []), id]
                };
                setUserDataInLocalStorage(updatedUser);
            }
            navigate("/chat/" + chat._id);
        });
    }

    const handleRemoveCourse = () => {
        removeCourse(id, user._id).then(() => {
            const updatedUser = { ...user, course_id_list: user.course_id_list.filter((courseId: string) => courseId !== id) };
            setUserDataInLocalStorage(updatedUser);
            if (onRemove) onRemove(id);
        }).catch((error: any) => {
            console.error('Failed to remove course:', error);
        });
    };

    return (
        <StyledCard
            style={{ 
                minHeight: '575px', 
                maxHeight: '575px',
                background: token.colorBgElevated
            }}
        >
            <CardContent>
                <div style={{ position: 'relative' }}>
                    <SonaAtlaTitleContainer>
                        <AnimatedTitle title={title} color={token.colorInfoText} />
                    </SonaAtlaTitleContainer>
                </div>
                <ContentContainer
                    style={{ minHeight: '480px', maxHeight: '480px' }}
                >
                    <DescriptionContainer style={{ color: token.colorTextSecondary }}>
                        <p>{description.length > maxLengthDescription ? description.slice(0, maxLengthDescription) + "..." : description}</p>
                    </DescriptionContainer>
                    <div>
                        <InnerCard>
                            <p style={{ color: token.colorTextSecondary }}>Professors</p>
                            <Avatar.Group>
                                {professors.map((professor) => (
                                    <Popover
                                        key={professor._id}
                                        placement="right"
                                        title={"Prof. " + professor.name}
                                    >
                                        <Avatar size="large" src={professor.photo_url} icon={<UserOutlined />} />
                                    </Popover>
                                ))}
                            </Avatar.Group>
                        </InnerCard>
                        <InnerCard>
                            <p style={{ color: token.colorTextSecondary }}>Last Update</p>
                            <p style={{ color: token.colorTextSecondary }}>{formattedDate}</p>
                        </InnerCard>
                        <SonaAtlaCard 
                            color="sona" 
                        >
                            <SonaAtlaTitle style={{ color: token.colorText }}>
                                Sona
                            </SonaAtlaTitle>
                            <div style={{ textAlign: "right", width: '50%' }}>
                                <NoMarginButton
                                    ref={chatButtonRef}
                                    onClick={handleNewConversation}
                                    size="large"
                                    type="primary"
                                    icon={<MessageFilled />}
                                    style={{ width: "100%", marginTop: '2px', marginBottom: '2px' }}>
                                    {"Chat"}
                                </NoMarginButton>
                                {user.course_id_list.includes(id) ? (
                                    [professor_type, autodidact_type, admin_type, technical_admin_type, marketpalce_admin_type].includes(user.type)
                                        && professors.map((professor) => professor._id).includes(user._id)
                                        ? <NoMarginButton
                                            ref={editButtonRef}
                                            onClick={() => navigate(`/courses/${id}`)}
                                            size="large"
                                            icon={<SettingOutlined />}
                                            style={{ width: "100%", marginTop: '2px', marginBottom: '2px' }}>
                                            {"Edit"}
                                        </NoMarginButton>
                                        : <NoMarginButton
                                            onClick={() => navigate(`/courses/contribute/${id}`)}
                                            size="large"
                                            icon={<UploadOutlined />}
                                            style={{ width: "100%", marginTop: '2px', marginBottom: '2px' }}>
                                            {"Contribute"}
                                        </NoMarginButton>
                                ) : null}
                            </div>
                        </SonaAtlaCard>
                        {(
                            [professor_type, admin_type, technical_admin_type, marketpalce_admin_type].includes(user.type)
                            && professors.map((professor) => professor._id).includes(user._id)
                        ) && (
                                <SonaAtlaCard
                                    color="atla"
                                >
                                    <SonaAtlaTitle style={{ color: token.colorText }}>
                                        Atla
                                    </SonaAtlaTitle>
                                    <div style={{ textAlign: "right", width: '50%' }}>
                                        <NoMarginButton
                                            ref={interactButtonRef}
                                            onClick={() => { newConversation(user._id, "analytics", id).then((chat) => navigate(`/analytics-copilot/${chat._id}`)) }}
                                            size="large"
                                            type="primary"
                                            icon={<SignatureOutlined />}
                                            style={{ width: "100%", marginTop: '2px', marginBottom: '2px' }}>
                                            {"Interact"}
                                        </NoMarginButton>
                                        <NoMarginButton
                                            ref={analyzeButtonRef}
                                            onClick={() => navigate(`/analytics/${id}`)}
                                            size="large"
                                            icon={<BarChartOutlined />}
                                            style={{ width: "100%", marginTop: '2px', marginBottom: '2px' }}>
                                            {"Analyze"}
                                        </NoMarginButton>
                                    </div>
                                </SonaAtlaCard>
                            )}
                    </div>
                </ContentContainer >
            </CardContent>
            {removable && (
                <StyledNoMarginButton
                    onClick={handleRemoveCourse}
                >
                    <CloseOutlined
                        style={{
                            fontSize: '1.2rem',
                        }}
                    />
                </StyledNoMarginButton>
            )}
        </StyledCard>
    );
};

export default CourseCard;