import React from 'react';
import { Tour } from 'antd';
import type { TourStepProps } from 'antd';
import { professor_type, autodidact_type, admin_type, technical_admin_type, marketpalce_admin_type } from '../../services/user';

interface HomeTourProps {
    open: boolean;
    onClose: () => void;
    welcomeAreaRef: React.RefObject<HTMLDivElement | null>;
    searchBarRef: React.RefObject<HTMLDivElement | null>;
    projectsSectionRef: React.RefObject<HTMLDivElement | null>;
    coursesSectionRef: React.RefObject<HTMLDivElement | null>;
    buildButtonRef?: React.RefObject<HTMLButtonElement | null>;
    editButtonRef?: React.RefObject<HTMLButtonElement | null>;
    contributeButtonRef?: React.RefObject<HTMLButtonElement | null>;
    chatButtonRef?: React.RefObject<HTMLButtonElement | null>;
    userType: string;
}

export const HomeTour: React.FC<HomeTourProps> = ({
    open,
    onClose,
    welcomeAreaRef,
    searchBarRef,
    projectsSectionRef,
    coursesSectionRef,
    buildButtonRef,
    editButtonRef,
    contributeButtonRef,
    chatButtonRef,
    userType,
}) => {
    // Define base steps that always appear
    const steps: TourStepProps[] = [];

    // Add welcome area step if available
    if (welcomeAreaRef?.current) {
        steps.push({
            title: 'Welcome Area',
            description: 'Get a quick overview of your personal dashboard. Here you can see a summary of your activities and a personalized welcome message.',
            target: welcomeAreaRef.current as HTMLElement,
            placement: 'bottom' as const,
        });
    }

    // Add search bar step if available
    if (searchBarRef?.current) {
        steps.push({
            title: 'Search and Filter',
            description: (
                <>
                    <p>Find your projects and courses easily using the search bar - it searches through titles, descriptions, and educator names. 
                    Use the filters to narrow down results by their last update date or find content from specific educators.</p>
                    {[
                        professor_type, 
                        autodidact_type, 
                        admin_type, 
                        technical_admin_type, 
                        marketpalce_admin_type
                    ].includes(userType) && (
                        <p>As an educator, you can also create new projects and courses using the "Create" button.</p>
                    )}
                </>
            ),
            target: searchBarRef.current as HTMLElement,
            placement: 'bottom' as const,
        });
    }
    
    // Add project section step if available
    if (projectsSectionRef?.current) {
        steps.push({
            title: 'Projects Section',
            description: 'Projects represent joint undertakings between several users with a common goal. Here you can see all the projects you participate in and quickly access them.',
            target: projectsSectionRef.current as HTMLElement,
            placement: 'top' as const,
        });
    }
    
    // Add course section step if available
    if (coursesSectionRef?.current) {
        steps.push({
            title: 'Courses Section',
            description: 'Courses provide a place to learn about a topic based on a specific knowledge base. This section shows all the courses you have access to, including those you created and those shared with you.',
            target: coursesSectionRef.current as HTMLElement,
            placement: 'top' as const,
        });
    }

    // Add button-specific steps
    if (buildButtonRef?.current) {
        steps.push({
            title: 'Build Button',
            description: 'Use this button to start or continue building a project. It opens a chat interface where you can collaborate with AI assistants and other project members to make progress on your project goals.',
            target: buildButtonRef.current as HTMLElement,
            placement: 'right' as const,
        });
    }

    if (chatButtonRef?.current) {
        steps.push({
            title: 'Chat Button',
            description: 'Start a conversation about your course content. Ask questions, get explanations, or discuss any topic related to the course with intelligent AI assistants.',
            target: chatButtonRef.current as HTMLElement,
            placement: 'right' as const,
        });
    }
    
    if (editButtonRef?.current) {
        steps.push({
            title: 'Edit Button',
            description: 'As a project or course owner, you can use this button to modify content, structure, and settings. Update information, add new content, or adjust the learning experience.',
            target: editButtonRef.current as HTMLElement,
            placement: 'right' as const,
        });
    }
    
    if (contributeButtonRef?.current) {
        steps.push({
            title: 'Contribute Button',
            description: 'Even if you\'re not the owner, you can contribute to projects and courses. Share your knowledge, suggest improvements, or add valuable resources to enhance the content.',
            target: contributeButtonRef.current as HTMLElement,
            placement: 'right' as const,
        });
    }
    
    // Filter out any steps with null targets
    const validSteps = steps.filter(step => step.target != null);

    return (
        <Tour
            open={open}
            onClose={onClose}
            steps={validSteps}
        />
    );
};
