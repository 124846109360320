import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Tooltip, Typography } from "antd";
import { Course } from "../../services/courses";
import { CourseDescription, HeaderContainer } from "../../styles/chat";

const { Title } = Typography;

interface CourseHeaderProps {
    course: Course;
    onTourOpen: () => void;
}

const CourseHeader: React.FC<CourseHeaderProps> = ({ course, onTourOpen }) => {
    return (
        <HeaderContainer>
            <div style={{ width: '90%' }}>
                <Title
                    level={2}
                    style={{
                        margin: 0,
                        fontSize: '24px'
                    }}
                >
                    {course.name}
                </Title>
                <Tooltip title={course.description}>
                    <CourseDescription>
                        {course.description}
                    </CourseDescription>
                </Tooltip>
            </div>
            <div style={{
                width: '10%',
                display: 'flex',
                justifyContent: 'center',
                flexWrap: "nowrap",
                maxWidth: "100%",
                flexDirection: "row",
                alignItems: "flex-start",
                padding: "0px 0px",
            }}>
                <Button
                    icon={<QuestionCircleOutlined />}
                    style={{
                        flexShrink: 0,
                        height: '100%',
                        margin: 5,
                        width: '100%',
                    }}
                    onClick={onTourOpen}
                />
            </div>
        </HeaderContainer>
    );
};

export default CourseHeader;
