import { PlusOutlined, FolderOutlined, ProjectOutlined, TeamOutlined } from '@ant-design/icons';
import { styled } from '@stitches/react';
import { Button, Form, Input, Modal, message, Radio, Switch, Typography } from 'antd';
import { FormikErrors, useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createCourse } from '../../services/courses';
import { addCourseToCommunity } from '../../services/communities';
import { createCommunity } from '../../services/communities';
import { getUserDataFromLocalStorage, setUserDataInLocalStorage } from '../../utils/useLocalStorage';
import { capitalize } from '../../utils/utils';

const CardButton = styled(Button, {
    minWidth: "5vw",
    maxWidth: "10vw",
    height: "40px",
    padding: "0 20px",
    border: "1px solid gray",
    borderRadius: "8px",
    background: "transparent",
    color: "gray",
    gridColumn: "1 / -1"
})

interface FormValues {
    name: string;
    description: string;
    courseType: 'course' | 'project' | 'community';
    isPrivate?: boolean;
}

interface AddCourseCardProps {
    communityContext?: {
        communityID: string;
        communityName: string;
    } | null;
}

const AddCourseCard: React.FC<AddCourseCardProps> = ({ communityContext = null }) => {
    const user = getUserDataFromLocalStorage();
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    // Initialize form with communityContext values if available
    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            courseType: 'course' as 'course' | 'project' | 'community',
            isPrivate: false
        },
        validate: (values) => {
            let errors: FormikErrors<FormValues> = {};
            if (!values.name) {
                errors.name = `Please enter a item name.`;
            }
            if (!values.description) {
                errors.description = `Please enter a item description.`;
            }
            return errors;
        },
        onSubmit: (values) => {
            setIsLoading(true);
            // Perform creation logic by sending data to the backend
            if (values.courseType === 'community') {
                createCommunity(values.name, values.description, values.isPrivate)
                    .then((community) => {
                        message.success(`Community created successfully!`);
                        navigate("/communities/" + community._id);
                    })
                    .catch((err) => {
                        message.error(`Error creating community`);
                        console.error(err);
                    })
                    .finally(() => {
                        setIsLoading(false);
                        setModalVisible(false);
                    });
            } else {
                const isProject = values.courseType === 'project';
                createCourse(values.name, values.description, user._id, isProject)
                    .then((course) => {
                        message.success(`${capitalize(user.config.course_terminology)} created successfully!`);
                        user.course_id_list = [...user.course_id_list, course._id];
                        setUserDataInLocalStorage(user);
                        
                        // If communityContext exists, add the course to the community
                        if (communityContext?.communityID) {
                            return addCourseToCommunity(communityContext.communityID, course._id, course.config.is_project)
                                .then(() => {
                                    message.success(`Added to ${communityContext.communityName} community`);
                                    return course;
                                })
                                .catch(err => {
                                    console.error('Error adding to community:', err);
                                    message.warning(`Created successfully but failed to add to community`);
                                    return course;
                                });
                        }
                        return course;
                    })
                    .then((course) => {
                        navigate("/courses/" + course._id);
                    })
                    .catch((err) => {
                        message.error(`Error creating item`);
                        console.error(err);
                    })
                    .finally(() => {
                        setIsLoading(false);
                        setModalVisible(false);
                    });
            }

        },
        validateOnChange: false,  // Prevent automatic validation on change
        validateOnBlur: false
    });

    return (
        <>
            <CardButton
                icon={<PlusOutlined />}
                onClick={() => setModalVisible(true)}
                style={{ fontSize: "1.1rem", background: "rgba(255,255,255,0.1)" }}
            >
                {'Create'}
            </CardButton>
            <Modal title={communityContext ? `Create new Item in ${communityContext.communityName}` : "Create new Item"} open={modalVisible} onCancel={() => setModalVisible(false)} footer={null}>
                <Form onSubmitCapture={formik.handleSubmit} style={{ width: "100%" }}>
                    <Form.Item
                        help={formik.errors.name}
                        validateStatus={formik.errors.name ? "error" : undefined}
                    >
                        <Input
                            type="text"
                            id="name"
                            placeholder={`Enter ${user.config.course_terminology.toLowerCase()} name`}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Form.Item>

                    <Form.Item
                        help={formik.errors.description}
                        validateStatus={formik.errors.description ? "error" : undefined}
                    >
                        <Input
                            type="text"
                            id="description"
                            placeholder={`Enter ${user.config.course_terminology.toLowerCase()} description`}
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Form.Item>

                    <Form.Item style={{ marginBottom: '20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', flexWrap: 'wrap' }}>
                            <Button
                                type={formik.values.courseType === 'course' ? 'primary' : 'default'}
                                size="large"
                                style={{
                                    width: '140px',
                                    height: '100px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '8px',
                                    fontSize: '16px',
                                    fontWeight: formik.values.courseType === 'course' ? 'bold' : 'normal'
                                }}
                                onClick={() => formik.setFieldValue('courseType', 'course')}
                            >
                                <FolderOutlined style={{ fontSize: '24px', marginBottom: '10px' }} />
                                {capitalize(user.config.course_terminology)}
                            </Button>
                            <Button
                                type={formik.values.courseType === 'project' ? 'primary' : 'default'}
                                size="large"
                                style={{
                                    width: '140px',
                                    height: '100px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '8px',
                                    fontSize: '16px',
                                    fontWeight: formik.values.courseType === 'project' ? 'bold' : 'normal'
                                }}
                                onClick={() => formik.setFieldValue('courseType', 'project')}
                            >
                                <ProjectOutlined style={{ fontSize: '24px', marginBottom: '10px' }} />
                                Project
                            </Button>
                            {!communityContext && (
                                <Button
                                    type={formik.values.courseType === 'community' ? 'primary' : 'default'}
                                    size="large"
                                    style={{
                                        width: '140px',
                                        height: '100px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '8px',
                                        fontSize: '16px',
                                        fontWeight: formik.values.courseType === 'community' ? 'bold' : 'normal'
                                    }}
                                    onClick={() => formik.setFieldValue('courseType', 'community')}
                                >
                                    <TeamOutlined style={{ fontSize: '24px', marginBottom: '10px' }} />
                                    Community
                                </Button>
                            )
                        }
                        </div>
                    </Form.Item>

                    <p style={{ color: "gray" }}><i>{`You will be able to add ${formik.values.courseType === 'course' ? user.config.course_terminology.toLowerCase() : formik.values.courseType === 'project' ? 'project' : 'community'} ${formik.values.courseType !== 'community' ? user.config.material_terminology + 's' : 'content'} later!`}</i></p>

                    {formik.values.courseType === 'community' && (
                        <Form.Item>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <Switch 
                                    checked={formik.values.isPrivate} 
                                    onChange={(checked) => formik.setFieldValue('isPrivate', checked)}
                                    style={{ marginRight: '10px' }}
                                />
                                <Typography.Text>
                                    Private Community
                                </Typography.Text>
                            </div>
                            {formik.values.isPrivate && (
                                <div style={{ marginLeft: '30px', marginBottom: '10px' }}>
                                    <Typography.Text type="secondary">
                                        Members must be approved to join private communities
                                    </Typography.Text>
                                </div>
                            )}
                        </Form.Item>
                    )}

                    <Button loading={isLoading} type="primary" htmlType="submit" size="large" style={{ width: "100%" }}>Create</Button>
                </Form>
            </Modal>
        </>
    );
};

export default AddCourseCard;
