import React, { useState } from 'react';
import { Input, Button, Dropdown, DatePicker, Select, theme } from 'antd';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { admin_type, autodidact_type, marketpalce_admin_type, professor_type, technical_admin_type } from '../../services/user';
import CommunityAddItemButton from './CommunityAddItemButton';

interface CommunitySearchBarProps {
    userType: string;
    searchQuery: string;
    setSearchQuery: (query: string) => void;
    professors: string[];
    setSelectedDate: (date: any) => void;
    setSelectedProfessor: (professor: string | undefined) => void;
    setSelectedType?: (type: string | undefined) => void;
}

const CommunitySearchBar: React.FC<CommunitySearchBarProps> = ({
    userType,
    searchQuery,
    setSearchQuery,
    professors,
    setSelectedDate,
    setSelectedProfessor,
    setSelectedType
}) => {
    const { token } = theme.useToken();
    const [selectedDateState, setSelectedDateState] = useState<any>(null);
    const [selectedProfessor, setSelectedProfessorState] = useState<string | undefined>(undefined);
    const [selectedType, setSelectedTypeState] = useState<string | undefined>(undefined);

    const handleDateChange = (date: any) => {
        setSelectedDateState(date);
        setSelectedDate(date);
    };

    const handleProfessorChange = (value: string) => {
        setSelectedProfessorState(value);
        setSelectedProfessor(value);
    };

    const handleTypeChange = (value: string | undefined) => {
        // If 'both' is selected, treat it like clearing the selection
        const effectiveValue = value === 'both' ? undefined : value;

        setSelectedTypeState(effectiveValue);
        if (setSelectedType) {
            setSelectedType(effectiveValue);
        }
    };

    const filterMenu = (
        <div style={{
            padding: '1rem',
            backgroundColor: token.colorBgContainer,
            border: 'gray',
            borderRadius: '4px',
            width: '10vw',
        }}>
            <DatePicker
                onChange={handleDateChange}
                placeholder="Select Last Updated Date"
                value={selectedDateState}
                style={{ marginBottom: '1rem', width: '100%' }}
            />
            <Select
                placeholder="Select Professor"
                onChange={handleProfessorChange}
                value={selectedProfessor}
                style={{ width: '100%', marginBottom: '1rem' }}
            >
                {professors.map(professor => (
                    <Select.Option key={professor} value={professor}>
                        {professor}
                    </Select.Option>
                ))}
            </Select>
            {setSelectedType && (
                <Select
                    placeholder="Select Type"
                    onChange={handleTypeChange}
                    value={selectedType}
                    style={{ width: '100%', marginBottom: '1rem' }}
                    allowClear
                >
                    <Select.Option key="both" value="both">All</Select.Option>
                    <Select.Option key="course" value="course">Courses</Select.Option>
                    <Select.Option key="project" value="project">Projects</Select.Option>
                </Select>
            )}
            <Button
                onClick={() => {
                    setSelectedDateState(null);
                    setSelectedDate(null);
                    setSelectedProfessorState(undefined);
                    setSelectedProfessor(undefined);
                    if (setSelectedType) {
                        setSelectedTypeState(undefined);
                        setSelectedType(undefined);
                    }
                    setSearchQuery('');
                }}
                style={{ width: '100%' }}
            >
                Reset Filters
            </Button>
        </div>
    );

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%'
        }}>
            <Input
                placeholder="Search courses and projects..."
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                style={{ marginRight: '1rem', flex: 1, height: '40px', fontSize: '1rem' }}
                prefix={<SearchOutlined style={{color: 'gray'}}/>}
            />
            <Dropdown
                overlay={filterMenu}
                trigger={['hover']}
            >
                <Button
                    style={{ marginRight: '1rem', height: '40px', fontSize: '1rem', color: 'gray' }}
                    icon={<FilterOutlined style={{ color: 'gray' }} />}
                >
                    Filters
                </Button>
            </Dropdown>
            {([professor_type, autodidact_type, admin_type, technical_admin_type, marketpalce_admin_type].includes(userType)) &&
                <CommunityAddItemButton userType={userType} />
            }
        </div>
    );
};

export default CommunitySearchBar;