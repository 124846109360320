import React from 'react';
import { Container } from '../../components/session-styles';
import { AbsoluteCenter } from '../../components/styles';
import UserTypeSelection from '../../components/signup/user-type-selection';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';
import { LoginProps } from '../login/login';
import { SunFilled, SunOutlined } from '@ant-design/icons';
import { logout } from '../../services/auth-axios';


const UserTypeSelectionScreen: React.FC<LoginProps> = ({ loginCallback, isDarkMode, toggleTheme }) => {
    const user = getUserDataFromLocalStorage();

    const handleLogout = () => {
        logout(); // Call the logout function
        window.location.href = '/login'; // Navigate to /login
    };
    
    return (
        <AbsoluteCenter>
            <Container>
                <UserTypeSelection userId={user!._id} userType={user!.type} loginCallback={loginCallback} />
                <div className="absolute bottom-4 right-4 flex items-center">
                    <button
                        onClick={toggleTheme}
                        className={`p-2 rounded-full ${isDarkMode ? 'bg-black hover:bg-gray-800' : 'bg-white hover:bg-gray-200'
                            } transition-colors border-none`}
                        aria-label="Toggle theme"
                    >
                        {isDarkMode ? (
                            <SunOutlined style={{ fontSize: '24px', color: 'white' }} />
                        ) : (
                            <SunFilled style={{ fontSize: '24px', color: 'black' }} />
                        )}
                        <p className={`${isDarkMode ? 'text-white' : 'text-black'}`}>Switch theme</p>
                    </button>
                </div>
                <div className="absolute bottom-4 left-4 flex items-center">
                    <button
                        onClick={handleLogout}
                        className={`p-2 rounded-full ${isDarkMode ? 'bg-black hover:bg-gray-800' : 'bg-white hover:bg-gray-200'
                        } transition-colors border-none`}
                        aria-label="Logout"
                    >
                        <p className={`${isDarkMode ? 'text-white' : 'text-black'}`}>Logout</p>
                    </button>
                </div>
            </Container>
        </AbsoluteCenter>
    );
};

export default UserTypeSelectionScreen;