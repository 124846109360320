import { theme, ThemeConfig } from "antd";
import { hexToRGBA } from "./utils/utils";
import "@fontsource/inter";

export const portraitViewThreshold = 1024;
export const siderWidthOpened = "16vw";

export const autodidact_message_bundle_options = [
    { title: 'Explorer: pay as you go (10 Cents per message', number: 0 },
    { title: 'Builder: 250 (~6 Cents per message)', number: 250 },
    { title: 'Creator: 500 (~4.5 Cents per message)', number: 500 }
];
export const educator_message_bundle_options = [
    { title: 'Pay as you go (10 Cents per message)', number: 0 },
    { title: '250 (~6 Cents per message)', number: 250 },
    { title: '500 (~4.5 Cents per message)', number: 500 }
];

export const chatFormatContent = 'content'
export const chatFormatAnalytics = 'analytics'
export const chatFormatDiscover = 'discover'

export const sona_logo_path = '/sona_logo_192x192.png' 
export const atla_logo_path = '/atla_logo_192x192.png'
export const discover_logo_path = '/discover_logo_192x192.png'

export const page_threshold = 50;  // Maximum number of pages before showing overlay
export const duration_threshold = 600;  // Maximum duration in seconds
    
export const collapsedSiderWidth = 130; // Chat interface configuration

export const redirectUrlKey = 'redirectAfterLogin';

export const sourceNotDownloadableKey = 'source_not_downloadable'

export const supportedFormats = [
    '.pdf', // pdf
    '.mp3', '.wav', '.mp2', '.mp1', '.m4a', '.ogg', '.flac', '.wma', '.aiff', // audio
    '.mp4', // video
    '.docx', // ms document
    '.txt', // text
    '.md', '.markdown', // markdown
    '.pptx', '.ppt', // ms presentation
    '.odt', // open document text
    '.vtt', // webvtt
];

export const noPageFormats = [
    '.txt', // text
    '.md', '.markdown', // markdown
];

export const audioFormats = [
    '.wav', '.mp3', '.m4a', '.mp2', '.mp1', '.ogg', '.flac', '.wma', '.aiff', // audio
    '.mp4', // video,
    '.vtt', // webvtt
];

export const dark_theme_token = {
    colorText: '#ffffff',
    colorTextSecondary: 'rgba(250, 250, 250, 0.4)',

    colorBgContainer: '#050505',
    colorBgElevated: '#1a1b1e',
    colorBgSpotlight: '#002654',

    colorLink: '#1890ff',

    colorPrimary: '#1890ff',
    colorPrimaryActive: '#1890ff',
    colorPrimaryHover: '#FAC748',
    colorPrimaryBgHover: '#FAC748',

    colorPrimaryBorder: '#272727',
    colorPrimaryBorderHover: '#303030',

    colorPrimaryText: '#ffffff',
    colorPrimaryTextActive: '#e6f7ff',
    colorPrimaryTextHover: '#bae7ff',

    colorInfoText: '#FAFAFA',
    colorTextPlaceholder: hexToRGBA('#FAFAFA', 0.4),

    fontFamily: "Inter"
};
export const default_theme_token = {
    colorText: '#000000',
    colorTextSecondary: 'rgba(0, 0, 0, 0.7)',

    colorBgContainer: '#F3F3F3',
    colorBgElevated: '#F0F0F0',
    colorBgSpotlight: '#002654',

    colorLink: '#002654',

    colorPrimary: '#1890ff',
    colorPrimaryActive: '#1890ff',
    colorPrimaryHover: '#002654',
    colorPrimaryBgHover: '#1890ff',

    colorPrimaryBorder: '#002654',
    colorPrimaryBorderHover: '#FAFAFA',

    colorPrimaryText: '#000000',
    colorPrimaryTextActive: '#141414',
    colorPrimaryTextHover: '#141414',

    colorInfoText: '#141414',
    colorTextPlaceholder: hexToRGBA('#141414', 0.4),

    fontFamily: "Inter"
};

export const dark_theme_components = {
    Layout: {
        triggerBg: '#050505',
        triggerColor: '#FD5050',
        bodyBg: '#050505',
        siderBg: '#050505',
    },
    Menu: {
        colorItemBgHover: hexToRGBA('#ffffff', 0.2),
        colorItemBgSelected: hexToRGBA("#FAC748", 0.2),
        colorItemTextSelected: "#ffffff",
    },
    Divider: {
        orientation: "right",
        orientationMargin: 50,
    }
};

export const default_theme_components = {
    Layout: {
        triggerBg: hexToRGBA('#F3F3F3', 0.5),
        triggerColor: '#000000',
        bodyBg: hexToRGBA('#F3F3F3', 0.5),
        siderBg: '#F3F3F3',
    },
    Menu: {
        colorItemBgHover: hexToRGBA('#FAC748', 0.5),
        colorItemBgSelected: '#FAC748',
        colorItemTextSelected: "#002654",
    },
    Divider: {
        orientation: "right",
        orientationMargin: 50,
        colorSplit: '#002654'
    }
};

export const dark_theme_config: ThemeConfig = {
    algorithm: theme.darkAlgorithm,
    token: dark_theme_token,
    components: dark_theme_components,
};

export const default_theme_config: ThemeConfig = {
    algorithm: theme.defaultAlgorithm,
    token: default_theme_token,
    components: default_theme_components,
};

export const colorSona: string = '#F08094';
export const colorAtla: string = '#61A8EB';