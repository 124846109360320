import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from '../../components/login-form';
import { Container, LoginFormContainer, Subtitle, Title, TopImages } from '../../components/session-styles';
import { AbsoluteCenter } from '../../components/styles';
import { SunOutlined, SunFilled } from '@ant-design/icons';

export interface LoginProps {
  loginCallback: () => void;
  isDarkMode: boolean;
  toggleTheme: () => void;
}

const Login: React.FC<LoginProps> = ({ loginCallback, isDarkMode, toggleTheme }) => {
  return (<>
    <TopImages />
    <AbsoluteCenter>
      <Container>
        <LoginFormContainer>
          <Title>Log in to your account</Title>
          <Subtitle>Enter your email below to log in to your account</Subtitle>
          <LoginForm loginCallback={loginCallback} />

          <p>
            Don't have an account yet?{' '}
            <Link to="/signup" style={{ color: isDarkMode ? 'white' : 'black' }}>
              Register here
            </Link>
          </p>
        </LoginFormContainer>

        <div className="absolute bottom-4 right-4 flex items-center">
          <button
            onClick={toggleTheme}
            className={`p-2 rounded-full ${isDarkMode ? 'bg-black hover:bg-gray-800' : 'bg-white hover:bg-gray-200'
              } transition-colors border-none`}
            aria-label="Toggle theme"
          >
            {isDarkMode ? (
              <SunOutlined style={{ fontSize: '24px', color: 'white' }} />
            ) : (
              <SunFilled style={{ fontSize: '24px', color: 'black' }} />
            )}
            <p className={`${isDarkMode ? 'text-white' : 'text-black'}`}>Switch theme</p>
          </button>
        </div>
      </Container>
    </AbsoluteCenter >
  </>)
};

export default Login;
