import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AddCourseCard from '../course/add-course-card';
import { getCommunity } from '../../services/communities';

interface CommunityAddItemButtonProps {
  userType: string;
}

const CommunityAddItemButton: React.FC<CommunityAddItemButtonProps> = ({ userType }) => {
  const { communityID } = useParams();
  const [communityContext, setCommunityContext] = React.useState<any>(null);

  // Fetch community info to provide context
  useEffect(() => {
    if (communityID) {
      getCommunity(communityID)
        .then(community => {
          setCommunityContext({
            communityID: community._id,
            communityName: community.name
          });
        })
        .catch(err => {
          console.error('Error fetching community details:', err);
        });
    }
  }, [communityID]);

  // Return default AddCourseCard with community context
  return <AddCourseCard communityContext={communityContext} />;
};

export default CommunityAddItemButton;