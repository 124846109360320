import { getUserDataFromLocalStorage } from "../utils/useLocalStorage";
import { authedAxios } from "./auth-axios";

export interface FileContribution {
    _id: string;
    file_name: string;
    course_id: string;
    created_at: string;
    contributor_id: string;
    contributor_name: string;
    week_index: number;
    week_title: string;
    span_start?: number;
    span_end?: number;
    spans_fully: boolean;
    status: 'pending' | 'accepted' | 'rejected';
}


export interface CourseFile {
    _id: string;
    file_name: string;
    course_id: string;
    created_at: string;
    last_updated: string;
    user_id: string;
    number_of_pages: number;
    start_page: number;
    end_page: number;
    downloadable: boolean;
    status: string;
    contribution_specifications?: ContributionSpecification;
    specifications?: FileSpecifications;
}


export interface FileSpecifications {
    startPage?: number;
    endPage?: number;
}

export interface ContributionSpecification {
    contributor_id: string;
    contributor_name: string;
    week_index: number;
    week_title: string;
}

export const setFilesDownloadable = async (file_ids: string[], downloadable: boolean): Promise<void> => {
    try {
        // Always send file_ids as an array in the request body
        const response = await authedAxios.post(`/files/downloadable/${downloadable}`, {
            file_ids
        });
        
        if (response.status !== 200) {
            throw new Error('Failed to set file(s) downloadable');
        }
        return response.data;
    } catch (error) {
        console.error('Error setting file(s) downloadable:', error);
        throw error;
    }
}

export const deleteCourseFile = async (file_id: string, course_id: string): Promise<void> => {
    try {
        const response = await authedAxios.delete(`/files`, {
            params: { file_id, course_id }
        });
        return response.data;
    } catch (error) {
        console.error('Error deleting file:', error);
        throw error;
    }
}

export const loadCourseFiles = async (course_id: string): Promise<CourseFile[]> => {
    try {
        const response = await authedAxios.get(`/files/${course_id}`);
        return response.data.files;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
}

export const getCourseContributions = async (course_id: string): Promise<CourseFile[]> => {
    try {
        const response = await authedAxios.get(`/files/contributions/${course_id}`);
        return response.data.files;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
}

export const submitFileContribution = async (formData: FormData): Promise<void> => {
    try {
        const response = await authedAxios.post('/files/contributions', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error submitting file contribution:', error);
        throw error;
    }
};

export const acceptFileContribution = async (contribution_id: string, course_id: string): Promise<void> => {
    try {
        const response = await authedAxios.post(`/files/contributions/${contribution_id}/accept`, {
            course_id: course_id,
        });
        return response.data;
    } catch (error) {
        console.error('Error accepting contribution:', error);
        throw error;
    }
};

export const rejectFileContribution = async (contribution_id: string, course_id: string): Promise<void> => {
    try {
        const response = await authedAxios.post(`/files/contributions/${contribution_id}/reject`, {
            course_id: course_id,
        });
        return response.data;
    } catch (error) {
        console.error('Error rejecting contribution:', error);
        throw error;
    }
};