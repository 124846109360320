import { Modal, Input, Form, message } from 'antd';
import { useState } from 'react';
import { authedAxios } from '../../services/auth-axios';

interface FeedbackModalProps {
    isOpen: boolean;
    onClose: () => void;
    originalMessage: string;  // The message that the user is providing feedback on
    userId: string;  // If you want to associate feedback with specific messages
    conversationId: string;  // If you want to associate feedback with specific conversations
}

export const FeedbackModal: React.FC<FeedbackModalProps> = ({ isOpen, onClose, originalMessage, userId, conversationId }) => {
    const [form] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);
            const values = await form.validateFields();

            const response = await authedAxios.post('/store-feedback', {
                nps: 0, // Dummy value as requested
                nextFeature: 'none', // Dummy value as requested
                originalMessage: originalMessage,
                comments: values.comments,
                userId: userId,
                conversationId: conversationId, 
            });

            if (response.status !== 200) {
                throw new Error('Failed to submit feedback');
            }

            message.success('Thank you for your feedback!');
            form.resetFields();
            onClose();
        } catch (error) {
            message.error('Failed to submit feedback. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Modal
            title="Send Feedback"
            open={isOpen}
            onCancel={onClose}
            onOk={handleSubmit}
            confirmLoading={isSubmitting}
        >
            <Form form={form}>
                <Form.Item
                    name="comments"
                    rules={[{ required: true, message: 'Please enter your feedback' }]}
                >
                    <Input.TextArea
                        rows={4}
                        placeholder="Please share your thoughts about this response..."
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}; 