import {
    FolderOpenOutlined,
    LeftOutlined,
    RightOutlined,
    FieldTimeOutlined
} from "@ant-design/icons";
import { Collapse, DatePicker, Menu, Row, Typography } from "antd";
import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { capitalize } from "../../utils/utils";
import { collapsedSiderWidth, siderWidthOpened } from "../../config";
import { StyledMenu, StyledSider } from "../../styles/chat";
import { AnalyticsNavigationProps, MenuItem } from "../../types/chat";
import { getUserDataFromLocalStorage } from "../../utils/useLocalStorage";

const { RangePicker } = DatePicker;
const { Title } = Typography;

const AnalyticsNavigation: React.FC<AnalyticsNavigationProps> = ({
    course,
    conversation,
    siderCollapsed,
    setSiderCollapsed,
    selectedWeeks,
    setSelectedWeeks,
    dateRange,
    setDateRange,
    onWeekSelectionClick,
    handleDateRangeChange,
    updateRelevantWeekNumbers,
    modulesRef,
    dateRangeRef,
    navigatorRef,
    isLoadingMessages
}) => {
    const user = getUserDataFromLocalStorage();
    const [weekMenuItems, setWeekMenuItems] = useState<MenuItem[]>([]);

    useEffect(() => {
        if (course && course.weeks) {
            const currentDate = new Date();
            const relevantWeekItems = course.weeks
                .map((week) => ({
                    label: week.title?.length > 0 
                        ? week.title 
                        : `${capitalize(user.config?.module_terminology)} ${week.number}`,
                    key: week.number,
                }));

            // Prepend "Select All" to the top if there are at least two weeks
            if (relevantWeekItems.length >= 2) {
                relevantWeekItems.unshift({
                    label: 'All',
                    key: 0, // Key for "Select All"
                });
            }

            let newWeekMenuItems = [{
                label: `${capitalize(user.config?.module_terminology)}s`,
                key: 'select-modules',
                children: [
                    ...relevantWeekItems,
                ],
                icon: <FolderOpenOutlined ref={modulesRef} />,
            }];

            if (relevantWeekItems.length > 0 && selectedWeeks.length === 0) {
                const indexToSelect = Math.min(1, relevantWeekItems.length - 1);
                let initialSelectedWeeks = [relevantWeekItems[indexToSelect].key as number];
                setSelectedWeeks(initialSelectedWeeks);
                updateRelevantWeekNumbers(initialSelectedWeeks);
            }
            
            setWeekMenuItems(newWeekMenuItems);
        }
    }, [course]);

    return (
        <StyledSider
            ref={navigatorRef}
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
            width={siderWidthOpened}
            collapsedWidth={collapsedSiderWidth}
            collapsible
            collapsed={siderCollapsed}
            onCollapse={(value) => setSiderCollapsed(value)}
            trigger={null}>
            <div style={{ flex: 1, overflow: 'auto', overflowX: 'hidden' }}>
                <Row justify="space-between">
                    <div style={{
                        textAlign: 'left',
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        boxShadow: 'none',
                        color: 'inherit',
                        backgroundColor: 'transparent',
                        marginBottom: '0.5rem',
                    }}>
                        <Title
                            level={4}
                            className="!mb-0"
                            style={{ textAlign: 'center', paddingBottom: 10 }}
                        >
                            {siderCollapsed ? "Nav" : "Navigator"}
                        </Title>
                    </div>
                </Row>
                
                <Collapse
                    ref={dateRangeRef}
                    style={{
                        overflow: 'visible',
                        border: 'none',
                        boxShadow: 'none',
                        color: 'inherit',
                    }}
                    ghost
                    defaultActiveKey={['1']}
                    expandIcon={() => <FieldTimeOutlined style={{ marginLeft: 10 }} />}
                >
                    <Collapse.Panel header="Date Range" key="1">
                        <RangePicker
                            data-testid="date-range-picker"
                            value={dateRange}
                            onChange={(dates: any) => handleDateRangeChange(dates as [Dayjs, Dayjs] | null)}
                        />
                    </Collapse.Panel>
                </Collapse>
                
                {course && course.weeks.length > 0 &&
                    <div style={{ overflow: 'visible' }}>
                        <Menu
                            style={{
                                overflow: 'visible',
                                border: 'none',
                                boxShadow: 'none',
                                color: 'inherit',
                            }}
                            onClick={onWeekSelectionClick}
                            selectedKeys={selectedWeeks.map(week => week.toString())}
                            defaultOpenKeys={['select-modules']}
                            mode="inline"
                            items={weekMenuItems}
                        />
                    </div>
                }
            </div>
            <div style={{ marginTop: 'auto' }}>
                <StyledMenu
                mode="vertical"
                selectedKeys={[]}
                items={[
                    {
                        key: "collapse",
                        icon: siderCollapsed ? <LeftOutlined /> : <RightOutlined />,
                        onClick: () => setSiderCollapsed(prev => !prev),
                        label: null,
                        title: "",
                        style: { textAlign: 'center' }
                    },
                ]}
            />
            </div>
        </StyledSider>
    );
};

export default AnalyticsNavigation;
