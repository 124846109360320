import React from 'react';
import { Button, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Community } from '../../services/communities';

interface CommunityActionsProps {
  isLeader: boolean;
  isMember: boolean;
  community: Community;
  onDelete: () => void;
  onJoin: () => void;
  onLeave: () => void;
}

export const CommunityActions: React.FC<CommunityActionsProps> = ({
  isLeader,
  isMember,
  community,
  onDelete,
  onJoin,
  onLeave,
}) => {
  return (
    <div style={{ marginTop: '20px' }}>
      {isLeader ? (
        <Space>
          <Button 
            danger 
            icon={<DeleteOutlined />} 
            onClick={onDelete}
          >
            Delete
          </Button>
        </Space>
      ) : (
        <>
          {!isMember ? (
            <Button 
              type="primary" 
              onClick={onJoin}
            >
              {community.config?.private ? 'Apply to Join' : 'Join Community'}
            </Button>
          ) : (
            <Button 
              danger 
              onClick={onLeave}
            >
              Leave Community
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default CommunityActions;