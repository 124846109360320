import { Typography, theme, Button, Badge, Tag, Space, Modal, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import { CloseOutlined, TeamOutlined, LockOutlined, BuildOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';
import { ButtonContainer, CardContent, ContentContainer, StyledCard, StyledNoMarginButton, TitleContainer } from '../../styles/course-card';
import AnimatedTitle from './animated-title';
import { addMemberToCommunity, removeMemberFromCommunity, submitCommunityApplication } from '../../services/communities';

const maxLengthDescription = 100; //chars

interface CommunityCardProps {
    id: string;
    title: string;
    description: string;
    leaders: { _id: string; name: string; }[];
    memberCount: number;
    projectCount: number;
    courseCount: number;
    isPrivate: boolean;
    removable?: boolean;
    onRemove?: (id: string) => void;
    userIsMember?: boolean;
    userIsLeader?: boolean;
    userHasApplied?: boolean;
    onRefresh?: () => void;
}

const { TextArea } = Input;

const CommunityCard: React.FC<CommunityCardProps> = ({
    id,
    title,
    description,
    leaders,
    memberCount,
    projectCount,
    courseCount,
    isPrivate,
    removable,
    onRemove,
    userIsMember = false,
    userIsLeader = false,
    userHasApplied = false,
    onRefresh
}) => {
    const { token } = theme.useToken();
    const navigate = useNavigate();
    const user = getUserDataFromLocalStorage();
    const [isApplicationModalVisible, setIsApplicationModalVisible] = useState(false);
    const [applicationReason, setApplicationReason] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();

    const handleRemoveCommunity = () => {
        if (onRemove) onRemove(id);
    };

    const handleJoinCommunity = async () => {
        if (isPrivate) {
            setIsApplicationModalVisible(true);
        } else {
            try {
                await addMemberToCommunity(id, user._id);
                if (onRefresh) onRefresh();
            } catch (error) {
                console.error('Failed to join community:', error);
            }
        }
    };
    
    const handleApplicationSubmit = async () => {
        try {
            setSubmitting(true);
            await form.validateFields();
            
            const success = await submitCommunityApplication(id, user._id, applicationReason);
            
            if (success) {
                message.success('Application submitted successfully');
                setIsApplicationModalVisible(false);
                setApplicationReason('');
                form.resetFields();
                if (onRefresh) onRefresh();
            } else {
                message.error('Failed to submit application');
            }
        } catch (error) {
            console.error('Failed to submit application:', error);
        } finally {
            setSubmitting(false);
        }
    };
    
    const handleApplicationCancel = () => {
        setIsApplicationModalVisible(false);
        setApplicationReason('');
        form.resetFields();
    };

    const handleBuildClick = () => {
        navigate(`/communities/${id}`);
    };

    const handleViewClick = () => {
        navigate(`/communities/${id}`);
    };

    return (
        <StyledCard
            style={{
                minHeight: '280px',
                maxHeight: '280px',
                background: token.colorBgElevated
            }}
        >
            <CardContent>
                <div style={{ position: 'relative' }}>
                    <TitleContainer>
                        <AnimatedTitle title={title} color={token.colorInfoText} />
                        {isPrivate && <LockOutlined style={{ color: token.colorTextSecondary }} />}
                    </TitleContainer>
                </div>
                <ContentContainer
                    style={{ minHeight: '195px', maxHeight: '195px' }}
                >
                    <Typography.Paragraph style={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        color: token.colorTextSecondary
                    }}>
                        {description.length > maxLengthDescription
                            ? description.slice(0, maxLengthDescription).split(' ').slice(0, -1).join(' ') + "..."
                            : description}
                    </Typography.Paragraph>

                    <div>
                        <Space
                            style={{ marginBottom: '10px' }}
                        >
                            <Tag icon={<TeamOutlined />} color="processing">
                                {memberCount || 0} members
                            </Tag>
                            <Tag color="default">
                                {projectCount || 0} projects
                            </Tag>
                            <Tag color="default">
                                {courseCount || 0} courses
                            </Tag>
                        </Space>

                        <p style={{
                            color: token.colorTextSecondary,
                            fontSize: '12px',
                            marginBottom: '10px'
                        }}>
                            Led by: {leaders.slice(0, 2).map(leader => leader.name).join(', ')}
                            {leaders.length > 2 && `... and ${leaders.length - 2} more`}
                        </p>

                        <ButtonContainer>
                            {userIsLeader ? (
                                <Button
                                    type="primary"
                                    style={{ flex: 1 }}
                                    icon={<BuildOutlined />}
                                    onClick={handleBuildClick}
                                >
                                    Manage
                                </Button>
                            ) : userIsMember ? (
                                <Button
                                    type="primary"
                                    style={{ flex: 1 }}
                                    icon={<EyeOutlined />}
                                    onClick={handleViewClick}
                                >
                                    View
                                </Button>
                            ) : isPrivate ? (
                                userHasApplied ? (
                                    <Button
                                        type="default"
                                        style={{ flex: 1 }}
                                        disabled
                                    >
                                        Application Pending
                                    </Button>
                                ) : (
                                    <Button
                                        type="primary"
                                        style={{ flex: 1 }}
                                        onClick={handleJoinCommunity}
                                    >
                                        Apply to Join
                                    </Button>
                                )
                            ) : (
                                <Button
                                    type="primary"
                                    style={{ flex: 1 }}
                                    onClick={handleJoinCommunity}
                                >
                                    Join Community
                                </Button>
                            )}
                        </ButtonContainer>
                    </div>
                </ContentContainer>
            </CardContent>
            {removable && (
                <StyledNoMarginButton
                    onClick={handleRemoveCommunity}
                >
                    <CloseOutlined
                        style={{
                            fontSize: '1.2rem',
                        }}
                    />
                </StyledNoMarginButton>
            )}
            
            {/* Application Modal */}
            <Modal
                title="Apply to Join Community"
                open={isApplicationModalVisible}
                onCancel={handleApplicationCancel}
                footer={[
                    <Button key="cancel" onClick={handleApplicationCancel}>
                        Cancel
                    </Button>,
                    <Button 
                        key="submit" 
                        type="primary" 
                        onClick={handleApplicationSubmit}
                        loading={submitting}
                    >
                        Submit Application
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Form.Item
                        name="reason"
                        label="Why do you want to join this community?"
                        rules={[{ required: true, message: 'Please tell us why you want to join' }]}
                    >
                        <TextArea 
                            rows={4} 
                            placeholder="Explain why you would like to join this community..."
                            value={applicationReason}
                            onChange={(e) => setApplicationReason(e.target.value)}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </StyledCard>
    );
};

export default CommunityCard;