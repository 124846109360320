import axios from 'axios';
import { authedAxios } from './auth-axios';
import { BackendTemplate } from './templates';
import { Billing, UserProfile, UserUpdateDTO } from '../types/user';

// User types
export const student_type = 'student';
export const professor_type = 'professor';
export const autodidact_type = 'autodidact';
export const admin_type = 'admin';
export const technical_type = 'technical';
export const technical_admin_type = 'technical_admin';
export const marketpalce_admin_type = 'marketplace_admin';

// Onboarding status
export const isNewAutodidact = (userData: any): boolean => {
    // Check if user is autodidact and hasn't completed onboarding (created_at === last_updated)
    return userData &&
        userData.type === autodidact_type &&
        userData.created_at === userData.last_updated;
};

// User type fetch
export async function fetchUserType(user_id: string): Promise<string> {
    try {
        const response = await authedAxios.get(`/users/${user_id}/type`);
        return response.data.type;
    } catch (error) {
        console.error('Error fetching user type:', error);
        throw new Error('Failed to fetch user type');
    }
}

// Usage management
type CreditUsage = {
    max_sent_messages: number;
    current_sent_messages: number;
}

export async function getUsage(user_id: string): Promise<CreditUsage> {
    try {
        const response = await authedAxios.get(`/users/${user_id}/usage`);
        return response.data as CreditUsage;
    } catch (error) {
        console.error('Error fetching user usage:', error);
        throw error;
    }
}

// Student usage
type TotalStudentUsage = {
    total_student_usage: number;
}

export const getTotalStudentUsage = async (user_id: string, assigned_student_id_list: string[]): Promise<TotalStudentUsage> => {
    try {
        if (assigned_student_id_list.length === 0) {
            return { total_student_usage: 0 };
        }

        const queryParams = new URLSearchParams();
        assigned_student_id_list.forEach(id => queryParams.append('assigned_student_id_list', id));
        const response = await authedAxios.get(`/users/${user_id}/student-usage?${queryParams.toString()}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching usage:', error);
        throw error;
    }
};

export const getUserBilling = async (user_id: string): Promise<Billing> => {
    try {
        const response = await authedAxios.get(`/users/${user_id}/billing`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
};

// Authentication
export const verifyToken = async (): Promise<boolean> => {
    try {
        const token = localStorage.getItem('access_token');
        if (!token) return false;

        await authedAxios.get('/verify-token');
        return true;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
            return false;
        }
        console.error('Token verification failed', error);
        return false;
    }
};

export const updatePassword = async (user_id: string, { old_password, new_password }: {
    old_password: string;
    new_password: string
}): Promise<boolean> => {
    try {
        const response = await authedAxios.put(`/users/${user_id}/update-password`, {
            old_password,
            new_password,
        });
        return response.status === 200;
    } catch (error) {
        console.error('Error updating password:', error);
        throw error;
    }
};

export const updateInitialPassword = async (user_id: string, new_password: string): Promise<boolean> => {
    try {
        const response = await authedAxios.put(`/users/${user_id}/update-initial-password`, {
            new_password,
        });
        return response.status === 200;
    } catch (error) {
        console.error('Error updating password from magic link:', error);
        throw error;
    }
};

export const updateUserProfile = async (user_id: string, profileData: UserUpdateDTO): Promise<boolean> => {
    try {
        const response = await authedAxios.put(`/users/${user_id}/update-profile`, profileData);
        return response.status === 200;
    } catch (error) {
        console.error('Error updating user profile:', error);
        throw error;
    }
};

export const uploadProfilePicture = async (user_id: string, file: File): Promise<string> => {
    try {
        const formData = new FormData();
        formData.append('profile_picture', file);
        const response = await authedAxios.post(`/users/${user_id}/upload-profile-picture`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data.url;
    } catch (error) {
        console.error('Error uploading profile picture:', error);
        throw error;
    }
};

export const generateTldr = async (user_id: string): Promise<string> => {
    try {
        const response = await authedAxios.post(`/users/${user_id}/generate-summary`);
        return response.data.summary;
    } catch (error) {
        console.error('Error generating tldr summary:', error);
        throw error;
    }
};

export const getUserProfile = async (user_id: string): Promise<UserProfile> => {
    try {
        const response = await authedAxios.get(`/users/${user_id}/profile`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
};

// Configuration and templates
export const updateUserTemplates = async (userId: string, templates: BackendTemplate[]): Promise<boolean> => {
    try {
        const response = await authedAxios.put(`/users/${userId}/update_user_templates`, {
            templates: templates
        });
        return response.status === 200;
    } catch (error) {
        console.error('Error updating user templates:', error);
        return false;
    }
};

export const update_user_config = async (
    user_id: string,
    course_ids: string[],
    organization: string,
    userNumberCap: number,
    userMessageCap: number,
    orgMessageCap: number,
    courseTerminology: string,
    moduleTerminology: string,
    sourcesVisible: boolean,
    filterBySource: boolean,
    filterByGroup: boolean,
): Promise<boolean> => {
    try {
        const response = await authedAxios.post(`/users/${user_id}/update-user-config`, {
            auto_enroll_students_to_courses: course_ids,
            organization: organization,
            userNumberCap: userNumberCap,
            userMessageCap: userMessageCap,
            orgMessageCap: orgMessageCap,
            courseTerminology: courseTerminology,
            moduleTerminology: moduleTerminology,
            sourcesVisible: sourcesVisible,
            filterBySource: filterBySource,
            filterByGroup: filterByGroup,
        });
        return response.status === 200;
    } catch (error) {
        console.error('Error updating user config:', error);
        throw error;
    }
};


export const fetchAssignedStudents = async (
    user_id: string,
    assigned_student_id_list: string[]
) => {
    try {
        // If list is empty, return empty result immediately
        if (assigned_student_id_list.length === 0) {
            return { assigned_students: [] };
        }

        const queryParams = new URLSearchParams();
        assigned_student_id_list.forEach(id => queryParams.append('assigned_student_id_list', id));
        const response = await authedAxios.get(`/users/${user_id}/assigned_students?${queryParams.toString()}`);
        return response.data;
    } catch (error) {
        console.error('Error updating assigned students:', error);
        throw error;
    }
};

type AssignedStudent = {
    name: string
    email: string
    last_updated: string
}

type AssignedStudents = {
    assigned_students: AssignedStudent[]
}

export const removeSelectedStudents = async (
    user_id: string,
    assigned_student_id_list: string[],
    student_ids_selected_for_removal: string[],
    course_id_list: string[]
): Promise<AssignedStudents> => {
    try {
        const response = await authedAxios.post(`/users/${user_id}/remove`, {
            assigned_student_id_list: assigned_student_id_list,
            student_ids_selected_for_removal: student_ids_selected_for_removal,
            course_id_list: course_id_list
        });
        return response.data;
    } catch (error) {
        console.error('Error removing students:', error);
        throw error;
    }
}

export const update_last_updated = async (
    user_id: string
): Promise<boolean> => {
    try {
        const response = await authedAxios.get(`users/${user_id}/update_user_last_updated`);
        return response.status === 200;
    } catch (error) {
        console.error('Error updateding last_updated: ', error);
        throw error
    }
}

export const deleteUser = async (userId: string) => {
    const response = await authedAxios.delete(`users/${userId}`);
    return response.status === 200;
};