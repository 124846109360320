import { useEffect, useState } from 'react';
import { Input, message, Modal } from 'antd';
import { getUserDataFromLocalStorage, setUserDataInLocalStorage } from '../../utils/useLocalStorage';
import {
    getUsage,
    updateUserProfile,
    generateTldr, updatePassword,
    uploadProfilePicture,
    deleteUser,
    student_type
} from '../../services/user';
import { FlexMarginButton, SaveButton } from '../../components/basic/buttons';
import { Container, FormSection, ScrollableContent, SectionTitle } from './styles';
import SocialLinksSection from './components/SocialLinksSection';
import PortfolioSection from './components/PortfolioSection';
import ProfileOverviewSection from './components/ProfileOverviewSection';
import MessageUsageSection from './components/MessageUsageSection';
import AccountSettingsSection from './components/AccountSettingsSection';
import PasswordChangeModal from './components/PasswordChangeModal';
import { PortfolioItem, UserNetworking } from "../../types/user";
import { logout } from '../../services/auth-axios';
import { SaveOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const BIO_PROMPTS = [
    "What's your next big idea?",
    "What skill are you mastering?",
    "What drives you to create?",
    "What's the coolest thing you've learned?"
];

const UserSettingsPage = () => {
    const [savingLoading, setSavingLoading] = useState<boolean>(false);
    const [maxSentMessages, setMaxSentMessages] = useState<number>(0);
    const [currentSentMessages, setCurrentSentMessages] = useState<number>(0);
    const [newMaxSentMessages, setNewMaxSentMessages] = useState<number>(0);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false); // New state for delete modal

    const user = getUserDataFromLocalStorage();
    const [newUserName, setNewUserName] = useState(user.name);
    const [newUserEmail, setNewUserEmail] = useState(user.email);
    const [bio, setBio] = useState(user.bio);
    const [location, setLocation] = useState(user.location);
    const [profilePicture, setProfilePicture] = useState<string>(user.photo_url);
    const [tldr, setTldr] = useState<string>(user.tldr);
    const [currentPrompt, setCurrentPrompt] = useState(0);
    const [portfolio, setPortfolio] = useState<PortfolioItem[]>(user.portfolio);
    const [social_links, setSocialLinks] = useState(user.social_links || {
        twitter: '',
        linkedin: '',
        github: '',
        website: ''
    });
    const [networking, setNetworking] = useState(user.networking || {
        visibility: 'public',
        open_to_mentoring: false,
        seeking_cofounders: false,
        connections: []
    });

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');


    const referralLink = `${window.location.origin}/signup?referral_code=${user.referrals.code}`;
    const profileLink = `${window.location.origin}/communities/users/${user._id}`;

    useEffect(() => {
        getUsage(user._id).then(data => {
            setCurrentSentMessages(data.current_sent_messages);
            setMaxSentMessages(data.max_sent_messages);
            setNewMaxSentMessages(data.max_sent_messages);
        });
    }, []);

    useEffect(() => {
        const user = getUserDataFromLocalStorage();
        getUsage(user._id).then(data => {
            setCurrentSentMessages(data.current_sent_messages);
            setMaxSentMessages(data.max_sent_messages);
        });
        setHasChanges(
            newUserName !== user.name ||
            newUserEmail !== user.email ||
            bio !== user.bio ||
            location !== user.location ||
            JSON.stringify(social_links) !== JSON.stringify(user.social_links || {}) ||
            JSON.stringify(portfolio) !== JSON.stringify(user.portfolio || []) ||
            (newMaxSentMessages !== maxSentMessages && newMaxSentMessages >= currentSentMessages) ||
            JSON.stringify(networking) !== JSON.stringify(user.networking || {})
        );
    }, [newUserName, newUserEmail, bio, location, social_links, portfolio, newMaxSentMessages, maxSentMessages, networking]);

    const handleProfilePictureUpload = async (file: File) => {
        try {
            const url = await uploadProfilePicture(user._id, file);
            setProfilePicture(url);
            message.success('Profile picture updated successfully!');
        } catch (error) {
            message.error('Failed to upload profile picture');
        }
    };

    const handleGenerateTldr = async () => {
        try {
            const summary = await generateTldr(user._id);
            setTldr(summary);
            message.success('AI summary generated successfully!');
        } catch (error) {
            message.error('Failed to generate AI summary');
        }
    };

    const handleShareProfile = () => {
        navigator.clipboard.writeText(profileLink)
            .then(() => message.success('Profile link copied!'))
            .catch(() => message.error('Failed to copy profile link'));
    };

    const handleSettingsUpdate = (updates: Partial<UserNetworking>) => {
        setNetworking((prev: any) => ({
            ...prev,
            ...updates
        }));
    };

    const saveChanges = async () => {
        setSavingLoading(true);
        try {
            await updateUserProfile(user._id, {
                name: newUserName,
                email: newUserEmail,
                location,
                bio,
                social_links: social_links,
                portfolio,
                usage: {
                    ...user.usage,
                    max_sent_messages: newMaxSentMessages
                },
                networking
            });

            const updatedUser = {
                ...user,
                name: newUserName,
                email: newUserEmail,
                bio,
                location,
                social_links,
                portfolio,
                usage: {
                    ...user.usage,
                    max_sent_messages: newMaxSentMessages
                },
                networking
            };
            setUserDataInLocalStorage(updatedUser);
            setMaxSentMessages(newMaxSentMessages);

            message.success("Profile updated successfully!");
            setHasChanges(false);
        } catch (error) {
            message.error("Failed to update profile. Please try again.");
        } finally {
            setSavingLoading(false);
        }
    };

    const handleClosePasswordModal = () => {
        setIsModalVisible(false);
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
    };
    const handleDeleteUser = async () => {
        try {
            const success = await deleteUser(user._id);
            if (success) {
                message.success('User deleted successfully!');
                logout();
                window.location.href = '/login'; // Navigate to /login
            } else {
                message.error('User could not be deleted. Please try again or contact support.');
            }
        } catch (error) {
            message.error('Failed to delete user. Please try again.');
        }
    };

    const showDeleteConfirm = () => {
        setIsDeleteModalVisible(true);
    };

    const handleCancelDelete = () => {
        setIsDeleteModalVisible(false);
    };


    return (
        <ScrollableContent>
            <Container>
                <ProfileOverviewSection
                    newUserName={newUserName}
                    setNewUserName={setNewUserName}
                    location={location}
                    setLocation={setLocation}
                    profilePicture={profilePicture}
                    tldr={tldr}
                    bio={bio}
                    userId={user._id}
                    handleProfilePictureUpload={handleProfilePictureUpload}
                    handleGenerateTldr={handleGenerateTldr}
                    handleShareProfile={handleShareProfile}
                />

                <SocialLinksSection
                    social_links={social_links}
                    networking={networking}
                    onChange={setSocialLinks}
                    onNetworkingUpdate={handleSettingsUpdate}
                />

                <FormSection>
                    <SectionTitle>About Me</SectionTitle>
                    <TextArea
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                        placeholder={BIO_PROMPTS[currentPrompt]}
                        rows={4}
                        style={{ marginBottom: '16px' }}
                    />
                </FormSection>

                <PortfolioSection
                    portfolio={portfolio}
                    onPortfolioChange={setPortfolio}
                />

                <MessageUsageSection
                    canIncreaseCap={user.billing.pay_as_you_go && user.type !== student_type}
                    currentSentMessages={currentSentMessages}
                    maxSentMessages={maxSentMessages}
                    onMaxMessagesChange={setNewMaxSentMessages}
                />

                <AccountSettingsSection
                    newUserEmail={newUserEmail}
                    setNewUserEmail={setNewUserEmail}
                    onPasswordChange={() => setIsModalVisible(true)}
                    referralLink={referralLink}
                    referralCount={user.referrals.count}
                />

                <PasswordChangeModal
                    isVisible={isModalVisible}
                    onClose={handleClosePasswordModal}
                    oldPassword={oldPassword}
                    setOldPassword={setOldPassword}
                    newPassword={newPassword}
                    setNewPassword={setNewPassword}
                    confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword}
                    userId={user._id}
                    updatePassword={updatePassword}
                />

                <FlexMarginButton
                    type="text"
                    danger
                    onClick={showDeleteConfirm}
                >
                    Delete User
                </FlexMarginButton>

                <Modal
                    title="Confirm Deletion"
                    open={isDeleteModalVisible}
                    onOk={handleDeleteUser}
                    onCancel={handleCancelDelete}
                    okText="Delete"
                    cancelText="Cancel"
                >
                    <p>Are you sure you want to delete your account? This action cannot be undone.</p>
                </Modal>

                {hasChanges && (
                    <SaveButton
                        icon={<SaveOutlined />}
                        loading={savingLoading}
                        type="primary"
                        size="large"
                        onClick={saveChanges}
                    >
                        Save
                    </SaveButton>
                )}
            </Container>
        </ScrollableContent>
    );
};

export default UserSettingsPage;