// api.ts
import { UploadFile } from "antd";
import { authedAxios } from "./auth-axios";
import { getUserDataFromLocalStorage } from "../utils/useLocalStorage";
import { Template } from "./templates";

export interface Professor {
    _id: string;
    name: string;
    photo_url: string;
    assigned_student_id_list: UserOverview[];
}

export interface UserOverview {
    _id: string;
    name: string;
    photo_url: string;
}

export type WeekMaterial = {
    name: string
    file_id: string
    spans_fully: boolean
    span_start: number | undefined
    span_end: number | undefined
    file_type: 'book' | 'slides' | 'audio'
    downloadable: boolean
    group: string
}

export type Topic = {
    name: string
    description: string
}

export type Week = {
    number: number
    title: string
    materials: WeekMaterial[]
    topics: Topic[]
    assignment: string
    start_date: string
    end_date: string
    summary: string
    prompt_suggestions?: string[]
}

export type CourseConfig = {
    is_project: boolean
}

export interface Course {
    _id: string;
    last_updated: string;
    created_at: string;
    name: string;
    description: string;
    professors: Professor[];
    enrolled_students: UserOverview[];
    assigned_students: UserOverview[];
    weeks: Week[]
    templates: Template[]
    public: boolean
    config: CourseConfig
    language: string
}

export interface CourseOverview {
    _id: string;
    last_updated: string;
    name: string;
    description: string;
    professors: UserOverview[];
    config?: CourseConfig;
}

export const createCourse = async (name: string, description: string, professor_id: string, is_project: boolean = false): Promise<Course> => {
    try {
        const response = await authedAxios.post(`/courses`, { name, description, professor_id, is_project });
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
};

export const getCourse = async (id: string): Promise<Course> => {
    try {
        const response = await authedAxios.get(`/courses/${id}`);
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
};

export const getUserCourses = async (user_id: string, course_id_list: string[]): Promise<Course[]> => {
    if (course_id_list.length === 0) {
        return [];
    }
    try {
        const queryParams = new URLSearchParams();
        course_id_list.forEach(id => queryParams.append('course_id_list', id));
        const response = await authedAxios.get(`/courses/${user_id}/get_user_courses?${queryParams.toString()}`);
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
};

export const getUserCourseOverview = async (user_id: string, course_id_list: string[]): Promise<CourseOverview[]> => {
    if (course_id_list.length === 0) {
        return [];
    }
    try {
        const queryParams = new URLSearchParams();
        course_id_list.forEach(id => queryParams.append('course_id_list', id));
        const response = await authedAxios.get(`/courses/${user_id}/get_user_course_overview?${queryParams.toString()}`);
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
};


export const getCourseUniverse = async (user_id: string): Promise<CourseOverview[]> => {
    try {
        const response = await authedAxios.get(`/courses/${user_id}/get_course_universe`);
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
};

export const updateCourse = async (course: Course): Promise<Boolean> => {
    try {
        const response = await authedAxios.post(`/courses/${course._id}`, {
            ...course
        });
        return response.status === 200;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
}

export const deleteCourse = async (course_id: string): Promise<boolean> => {
    try {
        const response = await authedAxios.delete(`/courses/${course_id}`);
        return response.data.deleted === true;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
};

export const removeCourse = async (course_id: string, user_id: string): Promise<boolean> => {
    try {
        const response = await authedAxios.post(`/courses/${course_id}/remove_from_user_courses`,
            { user_id }
        );
        return response.status === 200;
    } catch (error) {
        console.error('Error removing course:', error);
        throw error;
    }
};

export const uploadCourseBanner = async (courseId: string, bannerImage: File): Promise<string> => {
    try {
        // Validate file type
        if (!bannerImage.type.match(/image\/(jpeg|jpg|png)/)) {
            throw new Error('Only JPEG, JPG, and PNG formats are allowed');
        }

        const formData = new FormData();
        formData.append('banner_image', bannerImage);
        
        const response = await authedAxios.post(`/courses/${courseId}/upload-banner`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        
        return response.data.url;
    } catch (error) {
        console.error(`Error uploading course banner:`, error);
        throw error;
    }
};
export const summarizeWeek = async (id: string, week_number: number): Promise<Boolean> => {
    try {
        const response = await authedAxios.post(`/courses/${id}/summarize-week`, { week_number: week_number });
        return response.status === 200;
    } catch (error) {
        console.error('Error fetching week summary:', error);
        throw error;
    }
};


export const fillOutAssignmentWorkflowStep = async (
    courseId: string,
    stepIndex: number,
    stepInput: string,
    currentState: string,
    previousSteps: string[],
    goalOfStep: string,
    summary: string,
    onChunkReceived: (chunk: string) => void) => {
    try {
        const token = localStorage.getItem('access_token');
        if (!token) {
            throw new Error("No access token available");
        }
        const textDecoder = new TextDecoder();
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/courses/${courseId}/assignment-workflow/${stepIndex}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'text/event-stream',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    step_input: stepInput,
                    current_state: currentState,
                    previous_steps: previousSteps,
                    goal_of_step: goalOfStep,
                    summary: summary
                })
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body?.getReader();
        if (!reader) {
            throw new Error('Response body is not readable');
        }

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            const chunk = textDecoder.decode(value, { stream: true });
            onChunkReceived(chunk);
        }
    } catch (error) {
        throw new Error('Failed to fill out with Atla. Please try again.');
    }
};

export const finalizeAssignment = async (
    courseId: string,
    stepSpecifiers: string[],
    summary: string,
    onChunkReceived: (chunk: string) => void) => {
    try {
        const token = localStorage.getItem('access_token');
        if (!token) {
            throw new Error("No access token available");
        }
        const textDecoder = new TextDecoder();
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/courses/${courseId}/finalize-assignment`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'text/event-stream',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    summary: summary,
                    step_specifiers: stepSpecifiers,
                })
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body?.getReader();
        if (!reader) {
            throw new Error('Response body is not readable');
        }

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            const chunk = textDecoder.decode(value, { stream: true });
            onChunkReceived(chunk);
        }
    } catch (error) {
        throw new Error('Failed to generate assignment. Please try again.');
    }
};

export const setTelegramWebhook = async (botToken: string, courseId: string): Promise<boolean> => {
    try {
        const response = await authedAxios.get(`/public/set_tg_webhook/${botToken}/${courseId}`);
        return response.status === 200;
    } catch (error) {
        console.error('Error setting Telegram webhook:', error);
        throw error;
    }
};

export const deleteTelegramWebhook = async (courseId: string): Promise<any> => {
    try {
        const response = await authedAxios.get(`/public/delete_tg_webhook/${courseId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting Telegram webhook:', error);
        throw error;
    }
};

export const getTelegramWebhookInfo = async (courseId: string): Promise<any> => {
    try {
        const response = await authedAxios.get(`/public/get_tg_webhook_info/${courseId}`);
        return response.data;
    } catch (error) {
        console.error('Error getting Telegram webhook info:', error);
        throw error;
    }
};

export async function extractAudioAndConvertToAudioFile(videoFile: UploadFile): Promise<UploadFile> {
    // Extract audio from the video file
    const audioBuffer = await extractAudioFromVideo(videoFile);

    // Float32Array samples
    const [left, right] = [audioBuffer.getChannelData(0), audioBuffer.getChannelData(1)]

    // interleaved
    const interleaved = new Float32Array(left.length + right.length)
    for (let src = 0, dst = 0; src < left.length; src++, dst += 2) {
        interleaved[dst] = left[src]
        interleaved[dst + 1] = right[src]
    }

    // get WAV file bytes and audio params of your audio source
    const wavBytes = getWavBytes(interleaved.buffer, {
        isFloat: true,       // floating point or 16-bit integer
        numChannels: 2,
        sampleRate: 48000,
    })
    const audioBlob = new Blob([wavBytes], { type: 'audio/wav' })

    // // Create a File object from the Blob
    const audioFile = blobToFile(audioBlob, videoFile.name.replace(/\.[^/.]+$/, ".wav"));

    return audioFile;
}

async function extractAudioFromVideo(videoFile: UploadFile): Promise<AudioBuffer> {
    return new Promise<AudioBuffer | PromiseLike<AudioBuffer>>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async (event) => {
            try {
                const audioContext = new (window.AudioContext)();
                const audioBuffer = await audioContext.decodeAudioData(event.target?.result as ArrayBuffer);
                resolve(audioBuffer);
            } catch (error) {
                reject(error);
            }
        };
        reader.onerror = reject;

        // videoFile extends File and thus Blob
        // @ts-ignore
        reader.readAsArrayBuffer(videoFile);
    });
}

function blobToFile(blob: Blob, fileName: string): UploadFile {
    return Object.assign(new File([blob], fileName, { type: blob.type }));
}

// Returns Uint8Array of WAV bytes
function getWavBytes(buffer: ArrayBuffer, options: { isFloat: Boolean; numChannels?: number; sampleRate?: number; }) {
    const type = options.isFloat ? Float32Array : Uint16Array
    const numFrames = buffer.byteLength / type.BYTES_PER_ELEMENT

    const headerBytes = getWavHeader(Object.assign({}, options, { numFrames }))
    const wavBytes = new Uint8Array(headerBytes.length + buffer.byteLength);

    // prepend header, then add pcmBytes
    wavBytes.set(headerBytes, 0)
    wavBytes.set(new Uint8Array(buffer), headerBytes.length)

    return wavBytes
}

// adapted from https://gist.github.com/also/900023
// returns Uint8Array of WAV header bytes
function getWavHeader(options: { isFloat: Boolean; numChannels?: number | undefined; sampleRate?: number | undefined; } & { numFrames: number; }) {
    const numFrames = options.numFrames
    const numChannels = options.numChannels || 2
    const sampleRate = options.sampleRate || 44100
    const bytesPerSample = options.isFloat ? 4 : 2
    const format = options.isFloat ? 3 : 1

    const blockAlign = numChannels * bytesPerSample
    const byteRate = sampleRate * blockAlign
    const dataSize = numFrames * blockAlign

    const buffer = new ArrayBuffer(44)
    const dv = new DataView(buffer)

    let p = 0

    function writeString(s: string) {
        for (let i = 0; i < s.length; i++) {
            dv.setUint8(p + i, s.charCodeAt(i))
        }
        p += s.length
    }

    function writeUint32(d: number) {
        dv.setUint32(p, d, true)
        p += 4
    }

    function writeUint16(d: number) {
        dv.setUint16(p, d, true)
        p += 2
    }

    writeString('RIFF')              // ChunkID
    writeUint32(dataSize + 36)       // ChunkSize
    writeString('WAVE')              // Format
    writeString('fmt ')              // Subchunk1ID
    writeUint32(16)                  // Subchunk1Size
    writeUint16(format)              // AudioFormat https://i.sstatic.net/BuSmb.png
    writeUint16(numChannels)         // NumChannels
    writeUint32(sampleRate)          // SampleRate
    writeUint32(byteRate)            // ByteRate
    writeUint16(blockAlign)          // BlockAlign
    writeUint16(bytesPerSample * 8)  // BitsPerSample
    writeString('data')              // Subchunk2ID
    writeUint32(dataSize)            // Subchunk2Size

    return new Uint8Array(buffer)
}