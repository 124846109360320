import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Spin, Result, Button } from 'antd';
import { newConversation } from '../../services/conversations';
import { getUserDataFromLocalStorage, setUserDataInLocalStorage } from '../../utils/useLocalStorage';
import { AbsoluteCenter } from '../../components/styles';

const JoinCourse: React.FC = () => {
  const { courseId } = useParams<{ courseId: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  useEffect(() => {
    const handleJoinCourse = async () => {
      if (!courseId) {
        setError('Invalid course ID');
        setLoading(false);
        return;
      }

      try {
        const user = getUserDataFromLocalStorage();
        
        // Replicated logic from handleNewConversation function in course-card.tsx
        // Create a new conversation with the course
        const chat = await newConversation(user._id, "content", courseId);
        
        // Update the user's course_id_list if needed
        if (!user.course_id_list.includes(courseId)) {
          const updatedUser = {
            ...user,
            course_id_list: [...(user.course_id_list || []), courseId]
          };
          setUserDataInLocalStorage(updatedUser);
        }
        
        // Navigate to the new chat
        navigate(`/chat/${chat._id}`);
      } catch (err) {
        console.error('Failed to join course:', err);
        setError('Failed to join the course. Please try again later.');
        setLoading(false);
      }
    };

    handleJoinCourse();
  }, [courseId, navigate]);

  if (loading) {
    return (
      <AbsoluteCenter>
        <Spin size="large" tip="Joining the course..." />
      </AbsoluteCenter>
    );
  }

  if (error) {
    return (
      <Result
        status="error"
        title="Failed to join the course"
        subTitle={error}
        extra={[
          <Button type="primary" key="back" onClick={() => navigate('/home')}>
            Go to Courses
          </Button>,
        ]}
      />
    );
  }

  return null;
};

export default JoinCourse;
