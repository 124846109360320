import { getUserDataFromLocalStorage } from "../utils/useLocalStorage";
import { authedAxios } from "./auth-axios";

export interface SatisfactionTableDataType {
    key: string;
    userName: string;
    satisfaction: number | string;
}

export interface SatisfactionPlotResponse {
    user_id: string;
    user_name: string;
    satisfaction: number | null;
}

export interface MotivationTableDataType {
    key: string;
    userName: string;
    motivation: number | string;
}

export interface MotivationPlotResponse {
    user_id: string;
    user_name: string;
    motivation: number;
    daily_message_count: number
    lexical_diversity: number
    average_reengagement_speed: number
}

export interface RagCoverageTableDataType {
    key: string;
    userName: string;
    coverage: number | string;
}

export interface RagCoveragePlotResponse {
    user_id: string;
    user_name: string;
    coverage: number | null;
}

export interface AvgMessagePerDayTableDataType {
    key: string;
    userName: string;
    avg_messages_per_day: number | string;
}

export interface AvgMessagePerDatePlotResponse {
    user_id: string;
    user_name: string;
    avg_messages_per_day: number | null;
}

export interface MessageStatisticsPlotResponse {
    user_id: string;
    user_name: string;
    date: string;
    template_statistic: Record<string, any>;
    module_statistic: Record<string, any>;
    group_statistic: Record<string, any>;
    material_statistic: Record<string, any>;
}

export interface MessageStatisticsTableDataType {
    key: string;
    userName: string;
    recordCount: number | string;
}

export interface SourceCountTableDataType {
    key: string;
    userName: string;
    source_counts: Record<string, number>;
}

export interface SourceCountPlotResponse {
    user_id: string;
    user_name: string;
    source_counts: Record<string, number>;
}

export type QueryPCADataPoint = {
    text: string,
    embedding: number[]
}

export type QueryData = {
    queries: QueryPCADataPoint[];
    topics: QueryPCADataPoint[];
}

export type QueryPCAData = {
    queriesPCA: number[][];
    topicsPCA: number[][];
}

export type StudentCourseQueryCount = {
    student_id: string,
    student_name: string,
    query_count: number
}

export const fetchSatisfactionData = async (
    courseID: string,
    timeframe: string | string[],
    enrolled_student_ids: string[]
): Promise<SatisfactionPlotResponse[]> => {
    try {
        const response = await authedAxios.post<SatisfactionPlotResponse[]>(`/analytics/${courseID}/data/satisfaction`, {
            timeframe: timeframe,
            enrolled_student_ids: enrolled_student_ids
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching satisfaction data:", error);
        throw error;
    }
};

export const fetchMotivationData = async (
    courseID: string,
    timeframe: string | string[],
    enrolled_student_ids: string[]
): Promise<MotivationPlotResponse[]> => {
    try {
        const response = await authedAxios.post<MotivationPlotResponse[]>(`/analytics/${courseID}/data/motivation`, {
            timeframe: timeframe,
            enrolled_student_ids: enrolled_student_ids
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching motivation data:", error);
        throw error;
    }
};

export const fetchCoverageData = async (
    courseID: string,
    timeframe: string | string[],
    relevant_weeks: string[],
    enrolled_student_ids: string[]
): Promise<RagCoveragePlotResponse[]> => {
    try {
        const response = await authedAxios.post<RagCoveragePlotResponse[]>(`/analytics/${courseID}/data/ragcoverage`, {
            timeframe: timeframe,
            relevant_weeks: relevant_weeks,
            enrolled_student_ids: enrolled_student_ids
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching coverage data:", error);
        throw error;
    }
};

export const fetchAvgMessagePerDate = async (
    courseID: string,
    timeframe: string | string[],
    enrolled_student_ids: string[]
): Promise<AvgMessagePerDatePlotResponse[]> => {
    try {
        const response = await authedAxios.post<AvgMessagePerDatePlotResponse[]>(`/analytics/${courseID}/data/daily_message_count`, {
            timeframe: timeframe,
            enrolled_student_ids: enrolled_student_ids
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching average messages per day data:", error);
        throw error;
    }
};

export const fetchMessageStatistics = async (
    courseID: string,
    timeframe: string | string[],
    enrolled_student_ids?: string[]
): Promise<MessageStatisticsPlotResponse[]> => {
    try {
        const response = await authedAxios.post<MessageStatisticsPlotResponse[]>(
            `/analytics/${courseID}/data/message_statistics`,
            {
                timeframe: timeframe,
                enrolled_student_ids: enrolled_student_ids
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error fetching message statistics:", error);
        throw error;
    }
};

export const fetchSourceCountData = async (
    courseID: string,
    timeframe: string | string[],
    enrolled_student_ids: string[]
): Promise<SourceCountPlotResponse[]> => {
    try {
        const response = await authedAxios.post<SourceCountPlotResponse[]>(`/analytics/${courseID}/data/source_counts`, {
            timeframe: timeframe,
            enrolled_student_ids: enrolled_student_ids
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching source count data:", error);
        throw error;
    }
};

export const getQueryData = async (
    id: string,
    timeframe: string | string[]
): Promise<QueryData> => {
    try {
        const response = await authedAxios.post(`/analytics/${id}/data/query-pca-data`, {
            timeframe: timeframe
        });
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}s analytics:`, error);
        throw error;
    }
}