import { message } from "antd";
import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { 
    Community,
    getCommunity,
    updateCommunity,
    deleteCommunity
} from "../services/communities";
import { getUserDataFromLocalStorage } from "../utils/useLocalStorage";

export const useCommunity = () => {
    const navigate = useNavigate();
    const { communityID } = useParams();

    const [savedCommunity, setSavedCommunity] = useState<Community | undefined>(undefined);
    const [tempCommunity, setTempCommunity] = useState<Community | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [savingLoading, setSavingLoading] = useState<boolean>(false);
    const [isLeader, setIsLeader] = useState<boolean>(false);
    const [isMember, setIsMember] = useState<boolean>(false);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    
    const user = getUserDataFromLocalStorage();
    
    const loadCommunity = useCallback(async () => {
        setLoading(true);
        try {
            const data = await getCommunity(communityID!);
            setSavedCommunity(data);
            setTempCommunity(structuredClone(data));
        } catch (error) {
            console.error("Error loading community:", error);
            message.error("Failed to load community details");
        } finally {
            setLoading(false);
        }
    }, [communityID]);

    useEffect(() => {
        if (communityID) {
            loadCommunity();
        }
    }, [communityID, loadCommunity]);

    useEffect(() => {
        if (savedCommunity && user) {
            // Check if current user is a leader
            setIsLeader(savedCommunity.leaders.some(leader => leader._id === user._id));
            
            // Check if current user is a member
            setIsMember(savedCommunity.members.some(member => member._id === user._id));
        }
    }, [savedCommunity, user]);

    // Compare the temporary and saved community to detect changes
    useEffect(() => {
        if (tempCommunity && savedCommunity) {
            const tempJson = JSON.stringify(tempCommunity);
            const savedJson = JSON.stringify(savedCommunity);
            setHasChanges(tempJson !== savedJson);
        } else {
            setHasChanges(false);
        }
    }, [tempCommunity, savedCommunity]);

    const saveCommunityChanges = async () => {
        if (!tempCommunity || !hasChanges) return Promise.resolve(false);
        
        setSavingLoading(true);
        try {
            const success = await updateCommunity(tempCommunity);
            if (success) {
                message.success("Community updated successfully");
                setSavedCommunity(structuredClone(tempCommunity));
                setHasChanges(false);
                return true;
            } else {
                message.error("Failed to update community");
                return false;
            }
        } catch (error) {
            console.error("Error updating community:", error);
            message.error("Failed to update community");
            return false;
        } finally {
            setSavingLoading(false);
        }
    };

    const deleteCurrentCommunity = async () => {
        try {
            const success = await deleteCommunity(communityID!);
            if (success) {
                message.success("Community deleted successfully");
                navigate("/home");
                return true;
            } else {
                message.error("Failed to delete community");
                return false;
            }
        } catch (error) {
            console.error("Error deleting community:", error);
            message.error("Failed to delete community");
            return false;
        }
    };

    return {
        savedCommunity,
        tempCommunity,
        setTempCommunity,
        setSavedCommunity,
        loading,
        savingLoading,
        isLeader,
        isMember,
        hasChanges,
        saveCommunityChanges,
        deleteCurrentCommunity,
        refreshCommunity: loadCommunity
    };
};
