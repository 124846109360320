import React, { useEffect, useState } from "react";
import { Avatar, Typography, Divider, Spin } from "antd";
import { useParams } from "react-router-dom";
import { getCourse } from "../../services/courses";
import { Course } from "../../services/courses";
import { ViewerWeeks } from "../../components/course/viewer-weeks";
import { getRandomDimColor } from "../../utils/utils";
import { ScrollableContent } from "../settings/styles";
import { useCourse } from "../../hooks/useCourse";
import { CourseFile } from "../../services/files";

const CourseViewer: React.FC = () => {
  const { courseID } = useParams();
  const { 
    tempCourse: course,
    courseFiles,
    pendingCourseFiles,
    fetchCourseFiles,
    fetchedFiles,
  } = useCourse();
  const [loading, setLoading] = useState(true);
  const [existingFiles, setExistingFiles] = useState<CourseFile[]>([]);

  useEffect(() => {
    const loadCourseFiles = async () => {
      if (course) {
        await fetchCourseFiles(courseID!);
      }
    };
    loadCourseFiles();
  }, [course, courseID]);

  useEffect(() => {
    if (courseFiles.length > 0 || pendingCourseFiles.length > 0) {
      setExistingFiles(courseFiles.concat(pendingCourseFiles));
      setLoading(false);
    }
  }, [fetchedFiles]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!course) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <Typography.Title level={3}>Course not found</Typography.Title>
        <Typography.Paragraph>The requested course could not be loaded.</Typography.Paragraph>
      </div>
    );
  }

  return (
    <ScrollableContent style={{ padding: "2rem" }}>
      <Typography.Title level={1}>{course.name}</Typography.Title>
      <Avatar.Group>
        {course.professors.map((professor) => (
          <Avatar 
            key={professor._id} 
            size="large" 
            src={professor.photo_url}
            style={{ background: getRandomDimColor() }}
          >
            {professor.name[0]}
          </Avatar>
        ))}
      </Avatar.Group>
      <Typography.Paragraph>{course.description}</Typography.Paragraph>
      
      <Divider orientation="right" orientationMargin={50} />
      
      <ViewerWeeks weeks={course.weeks} courseId={course._id} existingFiles={existingFiles} />
    </ScrollableContent>
  );
};

export default CourseViewer;