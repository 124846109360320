import React from 'react';
import { Typography, Input, Card, Row, Col } from 'antd';
import { 
  TwitterOutlined, 
  LinkedinOutlined, 
  GithubOutlined, 
  GlobalOutlined,
  EnvironmentOutlined
} from '@ant-design/icons';
import { styled } from '@stitches/react';
import { SocialLinks } from '../../../types/user';

const { Title, Paragraph } = Typography;

const Container = styled('div', {
  width: '100%',
  textAlign: 'left',
});

const StyledCard = styled(Card, {
  marginBottom: '1rem',
});

const InputWrapper = styled('div', {
  marginBottom: '16px',
});

const IconWrapper = styled('span', {
  marginRight: '8px',
});

const OptionalText = styled('span', {
  fontSize: '12px',
  marginLeft: '8px',
});

interface SocialLocationStepProps {
  location: string;
  onLocationChange: (location: string) => void;
  socialLinks: SocialLinks;
  onSocialLinksChange: (socialLinks: SocialLinks) => void;
}

const SocialLocationStep: React.FC<SocialLocationStepProps> = ({
  location,
  onLocationChange,
  socialLinks,
  onSocialLinksChange,
}) => {
  const handleSocialLinkChange = (platform: keyof SocialLinks, value: string) => {
    onSocialLinksChange({
      ...socialLinks,
      [platform]: value,
    });
  };

  return (
    <Container>
      <Title level={3} 
      >Your Location & Social Links</Title>
      <Paragraph 
      >
        Share where you're based and your social media profiles to connect with other learners.
        All fields on this page are optional.
      </Paragraph>

      <StyledCard title={<span 
      >Location</span>}>
        <InputWrapper>
          <Input
            prefix={<IconWrapper><EnvironmentOutlined 
              /></IconWrapper>}
            placeholder="Your location (city, country)"
            value={location}
            onChange={(e) => onLocationChange(e.target.value)}
            suffix={<OptionalText>Optional</OptionalText>}
          />
        </InputWrapper>
      </StyledCard>

      <StyledCard title={<span style={{ color: 'white' }}>Social Media</span>}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <InputWrapper>
              <Input
                prefix={<IconWrapper><TwitterOutlined 
                  /></IconWrapper>}
                placeholder="X/Twitter profile URL"
                value={socialLinks.twitter}
                onChange={(e) => handleSocialLinkChange('twitter', e.target.value)}
                suffix={<OptionalText>Optional</OptionalText>}
              />
            </InputWrapper>
          </Col>
          <Col span={24}>
            <InputWrapper>
              <Input
                prefix={<IconWrapper><LinkedinOutlined 
                  /></IconWrapper>}
                placeholder="LinkedIn profile URL"
                value={socialLinks.linkedin}
                onChange={(e) => handleSocialLinkChange('linkedin', e.target.value)}
                suffix={<OptionalText>Optional</OptionalText>}
              />
            </InputWrapper>
          </Col>
          <Col span={24}>
            <InputWrapper>
              <Input
                prefix={<IconWrapper><GithubOutlined 
                  /></IconWrapper>}
                placeholder="GitHub profile URL"
                value={socialLinks.github}
                onChange={(e) => handleSocialLinkChange('github', e.target.value)}
                suffix={<OptionalText>Optional</OptionalText>}
              />
            </InputWrapper>
          </Col>
          <Col span={24}>
            <InputWrapper>
              <Input
                prefix={<IconWrapper><GlobalOutlined 
                  /></IconWrapper>}
                placeholder="Personal website URL"
                value={socialLinks.website}
                onChange={(e) => handleSocialLinkChange('website', e.target.value)}
                suffix={<OptionalText>Optional</OptionalText>}
              />
            </InputWrapper>
          </Col>
        </Row>
      </StyledCard>
    </Container>
  );
};

export default SocialLocationStep;
