import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Typography, Space } from 'antd';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { SignupProps } from '../../screens/signup/signup';
import { Container, SignupFormContainer, TopImages } from '../session-styles';
import { AbsoluteCenter } from '../styles';
import { SunFilled, SunOutlined } from '@ant-design/icons';

const { Title } = Typography;

export interface SignupFormProps {
    loginCallback: () => void;
    referralCode?: string | null;
    isTechnical?: boolean | null;
    isKeyAccount: boolean | null;
}

const SignupForm: React.FC<SignupFormProps> = ({ loginCallback, referralCode, isTechnical, isKeyAccount }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [emailExists, setEmailExists] = useState(false);
    const isHuuhUser = window.location.origin.includes("huuh.me");

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
        },
        validate: (values) => {
            let errors: Record<string, string> = {};
            if (!values.name) {
                errors.name = 'Please enter your name.';
            }
            if (!values.email) {
                errors.email = 'Please enter your email.';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = 'Invalid email address.';
            }
            if (!values.password) {
                errors.password = 'Please enter your password.';
            } else if (values.password.length < 8) {
                errors.password = 'Password must be at least 8 characters.';
            }
            if (values.password !== values.confirmPassword) {
                errors.confirmPassword = 'Passwords do not match.';
            }
            if (emailExists) {
                errors.email = 'This email is already in use.';
            }
            return errors;
        },
        onSubmit: (values) => {
            if (emailExists) {
                message.error('This email is already in use. Please use a different email.');
                return;
            }
            setIsLoading(true);
            
            // Register user with 'unregistered' type
            fetch(`${process.env.REACT_APP_BACKEND_URL}/v2/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: values.name,
                    email: values.email,
                    username: values.email,
                    password: values.password,
                    type: 'unregistered',
                    is_huuh_user: isHuuhUser,
                    referral_code: referralCode || '',
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Signup failed. Please check your details and try again.");
                    }
                    return response.json();
                })
                .then((data) => {
                    localStorage.setItem('user', JSON.stringify(data.user));
                    localStorage.setItem('access_token', data.access_token);
                    if (isTechnical) {
                        localStorage.setItem('isTechnicalSignup', 'true')
                    }
                    if (isKeyAccount) {
                        localStorage.setItem('isKeyAccount', 'true')
                    }
                    loginCallback();
                })
                .catch((error) => {
                    message.error(error.message || 'Signup Failed!');
                }).finally(() => {
                    setIsLoading(false);
                });
        },
        validateOnChange: false,
        validateOnBlur: false
    });

    useEffect(() => {
        const fields = ['name', 'email', 'password', 'confirmPassword', 'educator_email'];
        fields.forEach(field => {
            const input = document.querySelector(`input[name="${field}"]`) as HTMLInputElement;
            if (input) {
                input.addEventListener('blur', () => {
                    formik.setFieldValue(field, input.value);
                });
            }
        });
    }, []);

    const checkEmailExists = async (email: string) => {
        if (email.includes('@') && email.includes('.')) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/email_exists/${email}`);
                const data = await response.json();
                setEmailExists(data.exists);
            } catch (error) {
                console.error('Error checking email:', error);
            }
        } else {
            setEmailExists(false);
        }
    };

    useEffect(() => {
        if (formik.values.email) {
            checkEmailExists(formik.values.email);
        }
    }, [formik.values.email]);

    return (
        <Form
            onFinish={formik.handleSubmit}
            layout="vertical"
            style={{
                maxHeight: '80%',
                width: '100%',
                maxWidth: '400px',
                margin: '0 auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <Space
                direction="vertical"
                size="small"
                style={{
                    width: '100%',
                }}
            >
                        <Form.Item
                            name="name"
                            validateStatus={formik.errors.name ? 'error' : ''}
                            help={formik.errors.name}
                            style={{ marginBottom: '16px' }}
                        >
                            <Input
                                size="large"
                                placeholder='Enter your name'
                                {...formik.getFieldProps('name')}
                                onBlur={(e) => {
                                    formik.handleBlur(e);
                                    formik.setFieldValue('name', e.target.value);
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            validateStatus={(formik.errors.email || emailExists) ? 'error' : ''}
                            help={formik.errors.email || (emailExists ? 'This email is already in use.' : '')}
                            style={{ marginBottom: '16px' }}
                        >
                            <Input
                                size="large"
                                placeholder='Enter your email'
                                {...formik.getFieldProps('email')}
                                onChange={(e) => formik.setFieldValue('email', e.target.value)}
                                onBlur={(e) => {
                                    formik.handleBlur(e);
                                    checkEmailExists(e.target.value);
                                }}
                                style={{
                                    borderColor: emailExists ? '#ff4d4f' : undefined,
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            validateStatus={formik.errors.password ? 'error' : ''}
                            help={formik.errors.password}
                            style={{ marginBottom: '16px' }}
                        >
                            <Input.Password
                                size="large"
                                placeholder='Enter your password'
                                {...formik.getFieldProps('password')}
                                onBlur={(e) => {
                                    formik.handleBlur(e);
                                    formik.setFieldValue('password', e.target.value);
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="confirmPassword"
                            validateStatus={formik.errors.confirmPassword ? 'error' : ''}
                            help={formik.errors.confirmPassword}
                            style={{ marginBottom: '16px' }}
                        >
                            <Input.Password
                                size="large"
                                placeholder='Confirm your password'
                                {...formik.getFieldProps('confirmPassword')}
                                onBlur={(e) => {
                                    formik.handleBlur(e);
                                    formik.setFieldValue('confirmPassword', e.target.value);
                                }}
                            />
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '8px' }}>
                            <Button
                                loading={isLoading}
                                type="primary"
                                htmlType="submit"
                                size="large"
                                style={{ width: "100%" }}
                                disabled={emailExists}
                            >
                                Create Account
                            </Button>
                        </Form.Item>
                    </Space>
        </Form>
    );
};

const Signup: React.FC<SignupProps> = ({ loginCallback, isDarkMode, toggleTheme, referralCode, isTechnical, isKeyAccount }) => {
    console.log("SignupScreen is rendering");

    return (
        <>
            <TopImages />
            <AbsoluteCenter>
                <Container>
                    <SignupFormContainer>
                        <Title
                            style={{ textAlign: 'center' }}
                        >Create an account
                        </Title>
                        <SignupForm loginCallback={loginCallback} referralCode={referralCode} isTechnical={isTechnical} isKeyAccount={isKeyAccount} />
                        <p className="mt-4 text-center">
                            Already have an account?{' '}
                            <Link to="/login" style={{ color: isDarkMode ? 'white' : 'black' }}>
                                Go to Login
                            </Link>
                        </p>
                    </SignupFormContainer>
                    <div className="absolute bottom-4 right-4 flex items-center">
                        <button
                            onClick={toggleTheme}
                            className={`p-2 rounded-full ${isDarkMode ? 'bg-black hover:bg-gray-800' : 'bg-white hover:bg-gray-200'
                                } transition-colors border-none`}
                            aria-label="Toggle theme"
                        >
                            {isDarkMode ? (
                                <SunOutlined style={{ fontSize: '24px', color: 'white' }} />
                            ) : (
                                <SunFilled style={{ fontSize: '24px', color: 'black' }} />
                            )}
                            <p className={`${isDarkMode ? 'text-white' : 'text-black'}`}>Switch theme</p>
                        </button>
                    </div>
                </Container>
            </AbsoluteCenter>
        </>
    );
};

export default Signup;