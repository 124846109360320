import React, { useState, useEffect } from 'react';
import { Modal, Table, Button, Tooltip, Typography, message, Space, Tabs, Popconfirm } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UserOutlined, CrownOutlined } from '@ant-design/icons';
import { 
  CommunityApplication, 
  approveCommunityApplication, 
  rejectCommunityApplication, 
  getCommunityApplications,
  removeMemberFromCommunity,
  addLeaderToCommunity,
  removeLeaderFromCommunity,
  Community
} from '../../services/communities';
import { UserOverview } from '../../services/courses';

const { Text } = Typography;
const { TabPane } = Tabs;

interface UserManagementModalProps {
  visible: boolean;
  onClose: () => void;
  communityId: string;
  onApplicationsChange: () => void;
  community: Community;
  onMemberUpdate?: (updatedCommunity: Community) => void;
}

const truncateText = (text: string, maxChars: number = 100): string => {
  if (!text) return '';
  
  if (text.length <= maxChars) {
    return text;
  }
  
  const words = text.split(' ');
  let result = words[0];
  
  for (let i = 1; i < words.length; i++) {
    if ((result + ' ' + words[i]).length <= maxChars) {
      result += ' ' + words[i];
    } else {
      result += '...';
      break;
    }
  }
  
  return result;
};

const UserManagementModal: React.FC<UserManagementModalProps> = ({ 
  visible, 
  onClose, 
  communityId,
  onApplicationsChange,
  community,
  onMemberUpdate
}) => {
  const navigate = useNavigate();
  const [applications, setApplications] = useState<CommunityApplication[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [processingIds, setProcessingIds] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<string>('members');
  // Local state to track members and leaders for UI updates
  const [localMembers, setLocalMembers] = useState<any[]>([]);
  const [localLeaders, setLocalLeaders] = useState<any[]>([]);
  
  useEffect(() => {
    if (visible) {
      fetchApplications();
      // Update local state when the community prop changes
      setLocalMembers([...community.members]);
      setLocalLeaders([...community.leaders]);
    }
  }, [visible, communityId, community]);
  
  const fetchApplications = async () => {
    setLoading(true);
    try {
      const data = await getCommunityApplications(communityId);
      // Filter to only show pending applications
      setApplications(data.filter(app => app.status === 'pending'));
    } catch (error) {
      console.error('Error fetching applications:', error);
      message.error('Failed to load applications');
    } finally {
      setLoading(false);
    }
  };
  
  const handleViewProfile = (userId: string) => {
    navigate(`/communities/users/${userId}`);
  };
  
  const handleApprove = async (applicationId: string) => {
    setProcessingIds(prev => [...prev, applicationId]);
    try {
      const success = await approveCommunityApplication(applicationId);
      if (success) {
        message.success('Application approved successfully');
        
        // Find the application in the list to get the user info
        const approvedApplication = applications.find(app => app._id === applicationId);
        
        if (approvedApplication && approvedApplication.user) {
          // Add the new member to local state
          const updatedMembers = [...localMembers, approvedApplication.user];
          setLocalMembers(updatedMembers);
          
          // Update parent component with new community data
          if (onMemberUpdate) {
            const updatedCommunity = {
              ...community,
              members: updatedMembers
            };
            onMemberUpdate(updatedCommunity);
          }
        }
        
        // Remove from the list of applications
        setApplications(prev => prev.filter(app => app._id !== applicationId));
        
        // Call the parent's refresh function
        onApplicationsChange();
      } else {
        message.error('Failed to approve application');
      }
    } catch (error) {
      console.error('Error approving application:', error);
      message.error('Failed to approve application');
    } finally {
      setProcessingIds(prev => prev.filter(id => id !== applicationId));
    }
  };
  
  const handleReject = async (applicationId: string) => {
    setProcessingIds(prev => [...prev, applicationId]);
    try {
      const success = await rejectCommunityApplication(applicationId);
      if (success) {
        message.success('Application rejected');
        // Remove from the list
        setApplications(prev => prev.filter(app => app._id !== applicationId));
        onApplicationsChange();
      } else {
        message.error('Failed to reject application');
      }
    } catch (error) {
      console.error('Error rejecting application:', error);
      message.error('Failed to reject application');
    } finally {
      setProcessingIds(prev => prev.filter(id => id !== applicationId));
    }
  };
  
  const applicationColumns = [
    {
      title: 'Applicant',
      dataIndex: ['user', 'name'],
      key: 'name',
      width: '25%',
    },
    {
      title: 'Reason',
      key: 'reason',
      width: '45%',
      render: (text: string, record: CommunityApplication) => (
        <Tooltip title={record.reason} placement="topLeft" overlayStyle={{ maxWidth: '500px' }}>
          <Text>{truncateText(record.reason)}</Text>
        </Tooltip>
      ),
    },
    {
      title: 'Date Applied',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '15%',
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '15%',
      render: (text: string, record: CommunityApplication) => (
        <Space size="small">
          <Button 
            type="link" 
            onClick={() => handleViewProfile(record.user._id)}
          >
            View Profile
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => handleApprove(record._id)}
            loading={processingIds.includes(record._id)}
          >
            Approve
          </Button>
          <Button
            danger
            size="small"
            onClick={() => handleReject(record._id)}
            loading={processingIds.includes(record._id)}
          >
            Reject
          </Button>
        </Space>
      ),
    },
  ];
  
  // Handle member actions
  const handleRemoveMember = async (userId: string) => {
    setProcessingIds(prev => [...prev, userId]);
    try {
      const success = await removeMemberFromCommunity(communityId, userId);
      if (success) {
        message.success('Member removed successfully');
        
        // Update local state
        const updatedMembers = localMembers.filter(member => member._id !== userId);
        setLocalMembers(updatedMembers);
        
        // Update parent component with new community data
        if (onMemberUpdate) {
          const updatedCommunity = {
            ...community,
            members: updatedMembers
          };
          onMemberUpdate(updatedCommunity);
        }
        
        // Call applications change in parent
        onApplicationsChange();
      } else {
        message.error('Failed to remove member');
      }
    } catch (error) {
      console.error('Error removing member:', error);
      message.error('Failed to remove member');
    } finally {
      setProcessingIds(prev => prev.filter(id => id !== userId));
    }
  };

  const handlePromoteToLeader = async (userId: string) => {
    setProcessingIds(prev => [...prev, userId]);
    try {
      const success = await addLeaderToCommunity(communityId, userId);
      if (success) {
        message.success('Member promoted to leader successfully');
        
        // Find the member to promote
        const memberToPromote = localMembers.find(member => member._id === userId);
        if (memberToPromote) {
          // Update local state
          const updatedLeaders = [...localLeaders, memberToPromote];
          setLocalLeaders(updatedLeaders);
          
          // Update parent component with new community data
          if (onMemberUpdate) {
            const updatedCommunity = {
              ...community,
              leaders: updatedLeaders
            };
            onMemberUpdate(updatedCommunity);
          }
        }
        
        // Call applications change in parent
        onApplicationsChange();
      } else {
        message.error('Failed to promote member');
      }
    } catch (error) {
      console.error('Error promoting member:', error);
      message.error('Failed to promote member');
    } finally {
      setProcessingIds(prev => prev.filter(id => id !== userId));
    }
  };

  const handleDemoteLeader = async (userId: string) => {
    setProcessingIds(prev => [...prev, userId]);
    try {
      // Check if this is the last leader
      if (localLeaders.length <= 1) {
        message.error('Cannot remove the last leader from the community');
        return;
      }
      
      const success = await removeLeaderFromCommunity(communityId, userId);
      if (success) {
        message.success('Leader demoted to member successfully');
        
        // Update local state
        const updatedLeaders = localLeaders.filter(leader => leader._id !== userId);
        setLocalLeaders(updatedLeaders);
        
        // Update parent component with new community data
        if (onMemberUpdate) {
          const updatedCommunity = {
            ...community,
            leaders: updatedLeaders
          };
          onMemberUpdate(updatedCommunity);
        }
        
        // Call applications change in parent
        onApplicationsChange();
      } else {
        message.error('Failed to demote leader');
      }
    } catch (error) {
      console.error('Error demoting leader:', error);
      message.error('Failed to demote leader');
    } finally {
      setProcessingIds(prev => prev.filter(id => id !== userId));
    }
  };

  // Columns for members table
  const memberColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: 'Role',
      key: 'role',
      width: '20%',
      render: (text: string, record: any) => {
        const isLeader = localLeaders.some(leader => leader._id === record._id);
        return isLeader ? (
          <span>
            <CrownOutlined style={{ color: '#faad14', marginRight: 8 }} />
            Leader
          </span>
        ) : (
          <span>
            <UserOutlined style={{ marginRight: 8 }} />
            Member
          </span>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '40%',
      render: (text: string, record: any) => {
        const isLeader = community.leaders.some(leader => leader._id === record._id);
        const isProcessing = processingIds.includes(record._id);
        
        return (
          <Space size="small">
            <Button 
              type="link" 
              onClick={() => handleViewProfile(record._id)}
            >
              View Profile
            </Button>
            
            {isLeader ? (
              // Leader actions - can be demoted
              <Popconfirm
                title="Are you sure you want to demote this leader to a regular member?"
                onConfirm={() => handleDemoteLeader(record._id)}
                okText="Yes"
                cancelText="No"
                disabled={localLeaders.length <= 1 || isProcessing}
              >
                <Button
                  danger
                  size="small"
                  loading={isProcessing}
                  disabled={localLeaders.length <= 1}
                >
                  Demote to Member
                </Button>
              </Popconfirm>
            ) : (
              // Member actions - can be promoted or removed
              <>
                <Button
                  type="primary"
                  size="small"
                  icon={<CrownOutlined />}
                  onClick={() => handlePromoteToLeader(record._id)}
                  loading={isProcessing}
                >
                  Make Leader
                </Button>
                <Popconfirm
                  title="Are you sure you want to remove this member?"
                  onConfirm={() => handleRemoveMember(record._id)}
                  okText="Yes"
                  cancelText="No"
                  disabled={isProcessing}
                >
                  <Button
                    danger
                    size="small"
                    loading={isProcessing}
                  >
                    Remove
                  </Button>
                </Popconfirm>
              </>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <Modal
      title="Community Management"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={900}
    >
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab="Members" key="members">
          <Table
            columns={memberColumns}
            dataSource={localMembers}
            rowKey="_id"
            pagination={{ pageSize: 5 }}
          />
        </TabPane>
        <TabPane tab="Applications" key="applications">
          {applications.length === 0 && !loading ? (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <Text>No pending applications</Text>
            </div>
          ) : (
            <Table
              columns={applicationColumns}
              dataSource={applications}
              rowKey="_id"
              loading={loading}
              pagination={{ pageSize: 5 }}
            />
          )}
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default UserManagementModal;