import React from 'react';
import {
    Col, Button, Space, Avatar, Typography, message
} from 'antd';
import {
    UserOutlined, ShareAltOutlined
} from '@ant-design/icons';
import { FormRow, FormSection, InputCol, LabelCol, SectionTitle } from '../../settings/styles';

const { Text } = Typography;

interface CommunityProfileOverviewSectionProps {
    newUserName: string;
    location: string;
    profilePicture?: string;
    tldr: string;
}

const CommunityProfileOverviewSection: React.FC<CommunityProfileOverviewSectionProps> = ({
    newUserName,
    location,
    profilePicture,
    tldr,
}) => {
    const handleShareProfile = () => {
        const profileLink = window.location.href;
        navigator.clipboard.writeText(profileLink)
            .then(() => message.success('Profile link copied!'))
            .catch(() => message.error('Failed to copy profile link'));
    };

    return (
        <FormSection>
            <SectionTitle>Profile Overview</SectionTitle>
            <FormRow align="middle" justify="center" gutter={[24, 24]}>
                <Col xs={24} sm={6}>
                    <Space direction="vertical" align="center" style={{ width: '100%' }}>
                        <Avatar
                            size={100}
                            icon={<UserOutlined />}
                            src={profilePicture}
                            style={{ border: '2px solid #f0f0f0' }}
                        />
                    </Space>
                </Col>
                <Col xs={24} sm={18}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <FormRow>
                            <LabelCol span={6}>Name</LabelCol>
                            <InputCol span={18}>
                                <Text
                                    style={{ maxWidth: 300 }}
                                >
                                    {newUserName}
                                </Text>
                            </InputCol>
                        </FormRow>
                        <FormRow>
                            <LabelCol span={6}>Location</LabelCol>
                            <InputCol span={18}>
                                <Text
                                    style={{ maxWidth: 300 }}
                                >
                                    {location}
                                </Text>
                            </InputCol>
                        </FormRow>
                        <FormRow>
                            <LabelCol span={6}>Tldr</LabelCol>
                            <InputCol span={18}>
                                <Text type="secondary" style={{ flex: 1 }}>
                                    {tldr ? tldr : 'No AI summary generated yet'}
                                </Text>
                            </InputCol>
                        </FormRow>
                        <FormRow>
                            <LabelCol span={6}></LabelCol>
                            <InputCol span={18}>
                                <Button
                                    icon={<ShareAltOutlined />}
                                    onClick={() => handleShareProfile()}
                                >
                                    Share Profile
                                </Button>
                            </InputCol>
                        </FormRow>
                    </Space>
                </Col>
            </FormRow>
        </FormSection>
    );
};

export default CommunityProfileOverviewSection;