/**
 * Utility functions for handling redirect URLs after login
 */

import { redirectUrlKey } from "../config";

/**
 * Saves the current URL path for redirection after login
 * @param path - The path to save (without domain)
 */
export const saveRedirectUrl = (path: string): void => {
  // Don't save login-related paths
  const publicPaths = ['/login', '/signup', '/user-type-selection', '/checkout', '/return', '/health'];
  if (publicPaths.some(publicPath => path.startsWith(publicPath))) {
    return;
  }

  // Don't save empty or root paths
  if (!path || path === '/' || path === '/index.html') {
    return;
  }

  // Don't overwrite existing redirect URL if we're already being redirected
  if (!localStorage.getItem(redirectUrlKey)) {
    localStorage.setItem(redirectUrlKey, path);
  } else {
    localStorage.removeItem(redirectUrlKey);
    localStorage.setItem(redirectUrlKey, path);
  }
};

/**
 * Retrieves the saved redirect URL and clears it from storage
 * @returns The saved redirect URL or null if none exists
 */
export const getAndClearRedirectUrl = (): string | null => {
  const redirectUrl = localStorage.getItem(redirectUrlKey);
  localStorage.removeItem(redirectUrlKey);
  return redirectUrl;
};