import { styled } from "@stitches/react";


export const SocialText = styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '30vh',
    padding: '4px 0',
    
    '& .icon': {
        fontSize: '16px',
    },
    
    '& .text': {
        overflow: 'hidden',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        marginLeft: '20px',
    },

    '& a': {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        }
    }
});