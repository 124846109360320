import { CopyOutlined, DislikeOutlined, LikeOutlined, MessageOutlined, FileSearchOutlined } from "@ant-design/icons";
import { Avatar, Button, message, theme } from "antd";
import 'katex/dist/katex.min.css';
import { memo, useEffect, useState } from "react";
import { dislikeMessage, likeMessage } from "../../services/conversations";
import { getUserDataFromLocalStorage } from "../../utils/useLocalStorage";
import { hexToRGBA, preprocessLatex } from "../../utils/utils";
import {
    atla_logo_path,
    chatFormatAnalytics,
    chatFormatContent,
    chatFormatDiscover,
    discover_logo_path,
    sona_logo_path
} from "../../config";
import { SourceItem } from "./SourceButton";
import { parseSourceButtons } from "../../utils/sourceParser";
import DiscoverItems, { DiscoverItem } from "./DiscoverItem";
import Markdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import CodeBlock from './CodeBlock';
import { LikeDislikeContainer, MessageBubbleContainer, StyledFlex, TextContentContainer } from "../../styles/message";
import { MessageActionsContainer, SourcesButton } from '../../styles/chat';
import { FeedbackModal } from "./FeedbackModal";
import SourcesModal from "./SourcesModal";



interface CodeSection {
    language: string;
    code: string;
}

const parseCodeSections = (text: string): { textParts: string[], codeSections: CodeSection[] } => {
    const regex = /```(\w+)?\n([\s\S]*?)```/g;
    const textParts: string[] = [];
    const codeSections: CodeSection[] = [];
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(text)) !== null) {
        // Add text before code block
        if (match.index > lastIndex) {
            textParts.push(text.slice(lastIndex, match.index));
        }

        // Add code section
        codeSections.push({
            language: match[1] || 'text',
            code: match[2].trim()
        });

        lastIndex = match.index + match[0].length;
    }

    // Add remaining text
    if (lastIndex < text.length) {
        textParts.push(text.slice(lastIndex));
    }

    return { textParts, codeSections };
};

// Function to parse discover items from text
const parseDiscoverItems = (text: string): { mainText: string, discoverItems: DiscoverItem[] } => {
    const discoverItems: DiscoverItem[] = [];

    // Replace all discover item tags with an empty string and collect the items
    const mainText = text.replace(/<discover_item>([\s\S]*?)<\/discover_item>/g, (match, content) => {
        const [itemId, itemType, itemText] = content.split(':').map((part: string) => part.trim());
        if (itemId && itemType && itemText) {
            discoverItems.push({
                itemId,
                itemType,
                itemText
            });
        }
        return ''; // Remove the tag from the main text
    });

    return { mainText, discoverItems };
};

// Helper function to get bot name based on chat type
const getBotName = (type: string): string => {
    switch (type) {
        case chatFormatContent:
            return "Sona";
        case chatFormatAnalytics:
            return "Atla";
        case chatFormatDiscover:
            return "Discover";
        default:
            return "Assistant";
    }
};

// Helper function to get bot avatar path based on chat type
const getBotAvatar = (type: string): string => {
    switch (type) {
        case chatFormatContent:
            return sona_logo_path;
        case chatFormatAnalytics:
            return atla_logo_path;
        case chatFormatDiscover:
            return discover_logo_path;
        default:
            return sona_logo_path;
    }
};

const StreamingContent = memo(({ 
text,
sourceItems,
discoverItems = [],
conversationId,
messageId,
detectSourceButtons = true,
detectDiscoverItems = true,
detectCodeSegments = true,
setIsSourcesModalOpen
}: {
    text: string,
sourceItems: SourceItem[],
discoverItems?: DiscoverItem[],
conversationId: string,
messageId: string,
detectSourceButtons?: boolean,
detectDiscoverItems?: boolean,
detectCodeSegments?: boolean,
setIsSourcesModalOpen: (open: boolean) => void
}) => {
    // Only parse code sections if they're enabled
    const { token } = theme.useToken();
    const { textParts, codeSections } = detectCodeSegments
        ? parseCodeSections(text)
        : { textParts: [text], codeSections: [] };

    let codeBlockIndex = 0;

    return (
        <TextContentContainer
            style={{
                backgroundColor: hexToRGBA(token.colorBgElevated, 0.9),
                maxWidth: '100%'
            }}
        >
            {textParts.map((part, index) => (
                <div key={index}>
                    {part && (
                        <Markdown
                            remarkPlugins={[]}
                            rehypePlugins={[rehypeKatex]}
                        >
                            {part}
                        </Markdown>
                    )}
                    {detectCodeSegments && index < codeSections.length && (
                        <CodeBlock
                            language={codeSections[index].language}
                            code={codeSections[index].code}
                            enableExecution={true}
                            conversationId={conversationId}
                            messageId={messageId}
                            blockIndex={codeBlockIndex++}
                        />
                    )}
                </div>
            ))}

            {detectDiscoverItems && discoverItems.length > 0 && (
                <DiscoverItems items={discoverItems} />
            )}

            {/* Sources button - moved from actions to content area */}
            {detectSourceButtons && sourceItems.length > 0 && (
                <div style={{ marginTop: '12px', marginBottom: '8px' }}>
                    <SourcesButton
                        data-testid="sources-button"
                        type="text"
                        icon={<FileSearchOutlined style={{ fontSize: '16px' }} />}
                        onClick={() => setIsSourcesModalOpen(true)}
                    >
                        Source{sourceItems.length > 1 ? 's' : ''} ({sourceItems.length})
                    </SourcesButton>
                </div>
            )}
        </TextContentContainer>
    );
});

export const MessageBubble = ({
    conversationId,
    msgId,
    user,
    text,
    sources,
    type,
    isLoading,
    reaction,
}: {
    conversationId: string,
    msgId: string,
    user: "user" | "bot" | "system",
    text: string,
    sources: string[],
    type: string,
    isLoading: boolean,
    reaction?: "liked" | "disliked",
}) => {
    const { token } = theme.useToken();
    const userData = getUserDataFromLocalStorage();
    const [mainText, setMainText] = useState<string>('');
    const [sourceItems, setSourceItems] = useState<SourceItem[]>([]);
    const [discoverItems, setDiscoverItems] = useState<DiscoverItem[]>([]);
    const [likeLoading, setLikeLoading] = useState<boolean>(false);
    const [dislikeLoading, setDislikeLoading] = useState<boolean>(false);
    const [liked, setLiked] = useState<boolean>(false);
    const [disliked, setDisliked] = useState<boolean>(false);
    const [detectSourceButtons, setDetectSourceButtons] = useState(type === chatFormatContent);
    const [detectDiscoverItems, setDetectDiscoverItems] = useState(type === chatFormatDiscover);
    const [detectCodeSegments, setDetectCodeSegments] = useState(type === chatFormatContent);
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
    const [isSourcesModalOpen, setIsSourcesModalOpen] = useState(false);

    useEffect(() => {
        let processedText = text;
        let extractedDiscoverItems: DiscoverItem[] = [];

        // Process discover items if detection is enabled
        if (detectDiscoverItems) {
            const discoverItemsResult = parseDiscoverItems(processedText);
            processedText = discoverItemsResult.mainText;
            extractedDiscoverItems = discoverItemsResult.discoverItems;
        }

        // Process source buttons if detection is enabled
        let sourcesItems: SourceItem[] = [];

        if (detectSourceButtons) {
            // Use the new parseSourceButtons utility
            const sourceButtonsResult = parseSourceButtons(processedText);
            processedText = sourceButtonsResult.mainText;
            sourcesItems = sourceButtonsResult.sourceItems;

            // Also process any sources from the input attribute
            if (sources.length > 0 && sourcesItems.length === 0) {
                sources.forEach(source => {
                    source = source.replace('<source_button>', '');
                    source = source.replace('</source_button>', '');
                    // Use the new parser for consistency
                    const parsedSource = parseSourceButtons(`<source_button>${source}</source_button>`);
                    if (parsedSource.sourceItems.length > 0) {
                        sourcesItems.push(parsedSource.sourceItems[0]);
                    }
                });
            }
        }

        // replace all \u2022 with - due to markdown ignoring the char
        setMainText(preprocessLatex(processedText).replace(/\u2022/g, '-'));
        setSourceItems(sourcesItems);
        setDiscoverItems(extractedDiscoverItems);
    }, [text, sources, detectSourceButtons, detectDiscoverItems]);

    useEffect(() => {
        // Set liked and disliked states based on the reaction prop
        if (reaction === "liked") {
            setLiked(true);
            setDisliked(false);
        } else if (reaction === "disliked") {
            setLiked(false);
            setDisliked(true);
        } else {
            setLiked(false);
            setDisliked(false);
        }
    }, [reaction]);

    function handleLike() {
        setLikeLoading(true);
        setDisliked(false);
        likeMessage(conversationId, msgId)
            .then(() => {
                message.success("Thank you for the feedback!");
                setLiked(true);
            })
            .catch(err => {
                message.error("Error liking message: " + err);
                console.error(err);
            })
            .finally(() => setLikeLoading(false));
    }

    function handleDislike() {
        setDislikeLoading(true);
        setLiked(false);
        dislikeMessage(conversationId, msgId)
            .then(() => {
                message.success("Thank you for the feedback!");
                setDisliked(true);
            })
            .catch(err => {
                message.error("Error disliking message: " + err);
                console.error(err);
            })
            .finally(() => setDislikeLoading(false));
    }

    function handleCopy() {
        navigator.clipboard.writeText(mainText);
        message.success("Message copied to clipboard");
    }

    return (
        <MessageBubbleContainer style={{
            flexDirection: user === "user" ? 'row-reverse' : 'row',
            width: '100%'
        }}>
            <Avatar
                size="small"
                src={(user === "bot" || user === "system") && getBotAvatar(type)}
                style={{
                    flexShrink: 0,
                    alignSelf: 'flex-start',
                    marginTop: "-3px",
                    marginLeft: user === "user" ? '8px' : '0',
                    marginRight: user === "user" ? '0' : '8px',
                    backgroundColor: user === "user" ? token.colorBgSpotlight : "transparent"
                }}
            >
                {user === "user" && userData.name[0]}
            </Avatar>
            <StyledFlex style={{
                alignItems: 'flex-start',
                width: '100%'
            }}>
                <div style={{
                    textAlign: user === "user" ? 'right' : 'left',
                    width: '100%',
                    marginBottom: '4px',
                    paddingRight: user === "user" ? '0' : '8px',
                    paddingLeft: user === "user" ? '8px' : '0'
                }}>
                    <b>{user === "user" ? "You" : getBotName(type)}</b>
                </div>
                <div style={{
                    width: '100%', 
                    display: 'flex', 
                    justifyContent: user === "user" ? 'flex-end' : 'flex-start'
                }}>
                    <div style={{ maxWidth: '85%' }}>
                        <StreamingContent
                            text={mainText}
                            sourceItems={sourceItems}
                            discoverItems={discoverItems}
                            conversationId={conversationId}
                            messageId={msgId}
                            detectSourceButtons={detectSourceButtons}
                            detectDiscoverItems={detectDiscoverItems}
                            detectCodeSegments={detectCodeSegments}
                            setIsSourcesModalOpen={setIsSourcesModalOpen}
                        />
                    </div>
                </div>
                {user === "bot" && !isLoading && (
                    <MessageActionsContainer style={{ width: '85%' }}>
                        <LikeDislikeContainer>
                            <Button
                                loading={likeLoading}
                                type="text"
                                icon={<LikeOutlined />}
                                onClick={handleLike}
                                style={{ backgroundColor: liked ? 'rgba(255, 255, 255, 0.1)' : (likeLoading ? 'rgba(255, 255, 255, 0.1)' : 'transparent') }}
                            />
                            <Button
                                loading={dislikeLoading}
                                type="text"
                                icon={<DislikeOutlined />}
                                onClick={handleDislike}
                                style={{ backgroundColor: disliked ? 'rgba(255, 255, 255, 0.1)' : (dislikeLoading ? 'rgba(255, 255, 255, 0.1)' : 'transparent') }}
                            />
                            <Button
                                type="text"
                                icon={<CopyOutlined />}
                                onClick={handleCopy}
                                style={{ backgroundColor: disliked ? 'rgba(255, 255, 255, 0.1)' : (dislikeLoading ? 'rgba(255, 255, 255, 0.1)' : 'transparent') }}
                            />
                            <Button
                                type="text"
                                icon={<MessageOutlined />}
                                onClick={() => setIsFeedbackModalOpen(true)}
                                style={{ backgroundColor: disliked ? 'rgba(255, 255, 255, 0.1)' : (dislikeLoading ? 'rgba(255, 255, 255, 0.1)' : 'transparent') }}
                            />
                        </LikeDislikeContainer>
                        <FeedbackModal
                            isOpen={isFeedbackModalOpen}
                            onClose={() => setIsFeedbackModalOpen(false)}
                            originalMessage={mainText}
                            userId={userData._id}
                            conversationId={conversationId}
                        />
                        <SourcesModal
                            isOpen={isSourcesModalOpen}
                            onClose={() => setIsSourcesModalOpen(false)}
                            sources={sourceItems}
                            conversationId={conversationId}
                        />
                    </MessageActionsContainer>
                )}
            </StyledFlex>
        </MessageBubbleContainer>
    );
};