import React, { useRef, useState } from 'react';
import { Typography, Space, Tag, Carousel } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { PortfolioItem } from '../../../types/user';
import { DescriptionSection, formatUrl, PortfolioSlide, TagsRow } from '../../settings/components/PortfolioSection';
import { FormSection, SectionTitle } from '../../settings/styles';

const { Text, Paragraph } = Typography;

interface CommunityPortfolioSectionProps {
    portfolio: PortfolioItem[];
}

const CommunityPortfolioSection: React.FC<CommunityPortfolioSectionProps> = ({ portfolio }) => {
    const carouselRef = useRef<any>(null);

    return (
        <FormSection>
            <SectionTitle>Portfolio</SectionTitle>
            <Carousel
                ref={carouselRef}
                dots={false}
                infinite={false}
                arrows={true}
            >
                {portfolio.map((item) => (
                    <PortfolioSlide key={item.id}>
                        <Text strong style={{ marginBottom: "8px" }}>
                            {item.title}
                        </Text>
                        <DescriptionSection>
                            <Paragraph style={{ flex: 1 }}>
                                {item.description}
                            </Paragraph>
                        </DescriptionSection>

                        <TagsRow>
                            <Space wrap>
                                {item.urls?.map((urlItem, index) => (
                                    <Tag icon={<LinkOutlined />}>
                                        <a
                                            href={urlItem.url.startsWith('http') ? urlItem.url : `https://${urlItem.url}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {formatUrl(urlItem.url)}
                                        </a>
                                    </Tag>
                                ))}
                            </Space>
                        </TagsRow>
                    </PortfolioSlide>
                ))}
            </Carousel>
        </FormSection>
    );
};

export default CommunityPortfolioSection;