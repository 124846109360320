import { Alert, Button, Form, Input, message } from 'antd';
import { useFormik } from 'formik';
import { useState } from 'react';
import { login } from '../services/auth-axios';

const LoginForm = ({ loginCallback }) => {
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validate: (values) => {
            let errors = {};
            if (!values.username) {
                errors.username = 'Please enter your email.';
            }
            if (!values.password) {
                errors.password = 'Please enter your password.';
            }
            return errors;
        },

        onSubmit: async (values) => {
          setIsLoading(true);
          try {
            await login(values.username, values.password);
            loginCallback();
          } catch (error) {
            message.error(error.response?.data?.detail || 'Oops, something went wrong.');
            setIsLoading(false);
          } finally {
            setIsLoading(false);
          }
        },
        validateOnChange: false,  // Prevent automatic validation on change
        validateOnBlur: false
    });

    return (
        < >
            <Form onSubmitCapture={formik.handleSubmit} style={{ width: "100%" }}>
                <Form.Item
                    help={formik.errors.username}
                    validateStatus={formik.errors.username ? "error" : undefined}
                >
                    <Input
                        size="large"
                        type="text"
                        id="username"
                        placeholder='Enter your email'
                        name="username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Form.Item>

                <Form.Item
                    help={formik.errors.password}
                    validateStatus={formik.errors.password ? "error" : undefined}
                >
                    <Input.Password
                        size="large"
                        id="password"
                        placeholder='Enter your password'
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Form.Item>

                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    style={{ width: "100%" }}
                >
                    Log in
                </Button>
            </Form>
            {formik.errors.general && (
                <Alert
                    style={{ marginTop: '16px' }}
                    message={formik.errors.general}
                    type="error"
                    showIcon
                />
            )}
        </>
    );
};

export default LoginForm;
