import React, { useState } from "react";
import { getFileInConversation } from "../../services/conversations";
import { Button, message, Tooltip, Progress } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { 
    SourceButtonContainer,
    SourceButtonTitle,
    SourceButtonFooter
} from "../../styles/chat";
import { sourceNotDownloadableKey } from "../../config";



export interface SourceItem {
    fileId: string;
    title?: string;
    content?: string;
    relevancy?: number;
    module?: string;
    page?: string;
    time?: string;
}


const SourceButton = ({ 
    conversationId, 
    fileId, 
    title,
    content,
    relevancy = 0,
    module = '',
    page = '',
    time = ''
}: SourceItem & {
    conversationId: string;
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleDownload = async () => {
        try {
            setIsLoading(true);
            const response = await getFileInConversation(conversationId, fileId);

            // Extract filename from Content-Disposition header
            const fileNameHeader = response.headers.get("X-Filename");
            let filename = `source_${fileId}`; // fallback filename

            if (fileNameHeader) {
                const filenameMatch = fileNameHeader.match(/filename="(.+?)"/);
                if (filenameMatch) {
                    filename = filenameMatch[1];
                }
            }

            // Get the correct mime type from Content-Type header
            const contentType = response.headers.get('content-type') || 'application/octet-stream';

            // Convert response to blob
            const blob = new Blob([response.data], { type: contentType });

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            link.remove();

            // in case the Blob uses a lot of memory
            setTimeout(() => URL.revokeObjectURL(link.href), 30000);

            // Cleanup
            URL.revokeObjectURL(link.href);
            message.success('Download started');
        } catch (error) {
            console.error('Error downloading file:', error);
            message.error('Failed to download file');
        } finally {
            setIsLoading(false);
        }
    };

    // Format content for display (limit to 100 chars)
    const formatContent = (content?: string): string => {
        if (!content) return '';
        if (content.length > 100) {
            return content.substring(0, 100) + '...';
        }
        return content;
    };

    // Use the title if provided, otherwise use the sourceText
    const displayTitle = title;
    const displayContent = formatContent(content);
    
    // Ensure relevancy is within 0-100 range
    const normalizedRelevancy = Math.min(100, Math.max(0, relevancy));
    
    // Determine color based on relevancy percentage
    const getRelevancyColor = (value: number) => {
        if (value >= 80) return '#52c41a'; // High relevancy - green
        if (value >= 50) return '#1890ff'; // Medium relevancy - blue
        if (value >= 30) return '#faad14'; // Low relevancy - yellow
        return '#f5222d'; // Very low relevancy - red
    };
    
    const relevancyColor = getRelevancyColor(normalizedRelevancy);
    
    return (
        <SourceButtonContainer>
            <SourceButtonTitle>
                <Button
                    type="text"
                    icon={<DownloadOutlined />}
                    disabled={fileId === sourceNotDownloadableKey}
                    onClick={handleDownload}
                    loading={isLoading}
                    style={{
                    textAlign: "left",
                    justifyContent: "left",
                    whiteSpace: "normal",
                    height: "auto",
                    padding: "8px 0",
                    wordBreak: "break-word",
                    flex: 1,
                    marginRight: "8px"
                    }}
                >
                    {displayTitle}
                </Button>
                
                <Tooltip title={`Relevancy: ${normalizedRelevancy.toFixed(1)}%`} placement="right">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '10px' }}>
                        <Progress 
                            type="circle" 
                            percent={normalizedRelevancy} 
                            size={28}
                            strokeWidth={12}
                            strokeColor={relevancyColor}
                            showInfo={false}
                        />
                    </div>
                </Tooltip>
            </SourceButtonTitle>
            
            {/* Display content if available */}
            {displayContent && (
                <div style={{
                    padding: "0 12px 4px",
                    fontSize: "12px", 
                    lineHeight: "1.4",
                    borderBottom: "1px solid",
                    marginBottom: "4px",
                    fontStyle: "italic"
                }}>
                    {displayContent}
                </div>
            )}
            
            {/* Only show footer if we have any metadata to display */}
            {(module || page || time) && (
                <SourceButtonFooter>
                    {module && <span>Module: {module}</span>}
                    {page && <span>{page}</span>}
                    {time && <span>{time}</span>}
                </SourceButtonFooter>
            )}
        </SourceButtonContainer>
    );
};


// This handles both old and new formats of props
export default SourceButton;