import { styled } from '@stitches/react';
import { HorizontalScrollContainer, CardWrapper, SectionContainer, SectionTitle } from '../../components/common/horizontal-scroll-container';
import { Spin, Button, Typography } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import ProjectCard from '../../components/cards/project-card';
import AutodidactCourseCard from '../../components/cards/autodidact-course-card';
import { CourseOverview, getCourseUniverse } from '../../services/courses';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';
import SearchAndAddCourse from '../../components/course/SearchAndAddCourse';
import { ScrollableCourseOverviewContent } from '../settings/styles';
import { QuestionCircleOutlined } from "@ant-design/icons";
import { MarketplaceTour } from '../../components/course/course-marketplace-tour';
import { professor_type, autodidact_type, admin_type, technical_admin_type } from '../../services/user';
import CommunitiesSection from '../communities/communities-section';


const MarketplaceGallery: React.FC<{}> = () => {
    const user = getUserDataFromLocalStorage();
    const [courses, setCourses] = useState<CourseOverview[]>([]);
    const [projects, setProjects] = useState<CourseOverview[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedDate, setSelectedDate] = useState<any>(null);
    const [selectedProfessor, setSelectedProfessor] = useState<string | undefined>(undefined);
    const [selectedType, setSelectedType] = useState<string | undefined>(undefined);
    const [tourOpen, setTourOpen] = useState<boolean>(false);

    // Refs for tour targets
    const communityCourseChatRef = useRef<HTMLButtonElement>(null);
    const userCourseChatRef = useRef<HTMLButtonElement>(null);
    const userCourseEditRef = useRef<HTMLButtonElement>(null);
    const searchBarRef = useRef<HTMLDivElement>(null);
    const projectsSectionRef = useRef<HTMLDivElement>(null);
    const coursesSectionRef = useRef<HTMLDivElement>(null);

    // Tour flags
    const [isFirstCommunityCourse, setIsFirstCommunityCourse] = useState<boolean>(false);
    const [isFirstUserCourse, setIsFirstUserCourse] = useState<boolean>(false);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const coursesData = await getCourseUniverse(user._id);

                // Separate courses and projects
                const projectsData = coursesData.filter(course => course.config?.is_project);
                const regularCoursesData = coursesData.filter(course => !course.config?.is_project);

                setProjects(projectsData);
                setCourses(regularCoursesData);

                // Check for first community course and first user course
                let foundCommunityCourse = false;
                let foundUserCourse = false;

                for (const course of [...projectsData, ...regularCoursesData]) {
                    const isEditable = course.professors.some(prof => prof._id === user._id);

                    if (!isEditable && !foundCommunityCourse) {
                        setIsFirstCommunityCourse(true);
                        foundCommunityCourse = true;
                    }

                    if (isEditable && !foundUserCourse) {
                        setIsFirstUserCourse(true);
                        foundUserCourse = true;
                    }

                    if (foundCommunityCourse && foundUserCourse) break;
                }
            } catch (error) {
                console.error(`Error fetching courses:`, error);
            }
        }
        fetchCourses().then(() => setIsLoading(false));
    }, []);

    // Filter projects based on search query, last_updated, and professor names
    const filteredProjects = projects.filter(project => {
        const matchesSearchQuery = project.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            project.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
            project.last_updated.toLowerCase().includes(searchQuery.toLowerCase()) ||
            project.professors.some(professor => professor.name.toLowerCase().includes(searchQuery.toLowerCase()));

        const matchesDateFilter = selectedDate ? new Date(project.last_updated) >= selectedDate.toDate() : true;
        const matchesProfessorFilter = selectedProfessor ? project.professors.some(professor => professor.name === selectedProfessor) : true;

        return matchesSearchQuery && matchesDateFilter && matchesProfessorFilter;
    });

    // Filter courses based on search query, last_updated, and professor names
    const filteredCourses = courses.filter(course => {
        const matchesSearchQuery = course.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            course.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
            course.last_updated.toLowerCase().includes(searchQuery.toLowerCase()) ||
            course.professors.some(professor => professor.name.toLowerCase().includes(searchQuery.toLowerCase()));

        const matchesDateFilter = selectedDate ? new Date(course.last_updated) >= selectedDate.toDate() : true;
        const matchesProfessorFilter = selectedProfessor ? course.professors.some(professor => professor.name === selectedProfessor) : true;

        return matchesSearchQuery && matchesDateFilter && matchesProfessorFilter;
    });

    const canEditCourse = (course: CourseOverview) => {
        const userTypes = [professor_type, autodidact_type, admin_type, technical_admin_type];
        return course.professors.some(prof => prof._id === user._id) && userTypes.includes(user.type);
    };

    // Function to get ref for chat button based on course type and index
    const getChatButtonRef = (isEditable: boolean, index: number) => {
        if (!isEditable && !communityCourseChatRef.current && index === 0) {
            return communityCourseChatRef;
        } else if (isEditable && !userCourseChatRef.current && index === 0) {
            return userCourseChatRef;
        }
        return undefined;
    };

    // Function to get ref for edit button based on course editability and index
    const getEditButtonRef = (isEditable: boolean, index: number) => {
        if (isEditable && !userCourseEditRef.current && index === 0) {
            return userCourseEditRef;
        }
        return undefined;
    };

    // Get all professors for the filter
    const allProfessors = [...new Set([...courses, ...projects].flatMap(item => item.professors.map(prof => prof.name)))];

    return (
        <ScrollableCourseOverviewContent>
            <div style={{ flexShrink: 0 }}> {/* Prevent search from shrinking */}
                <div ref={searchBarRef}>
                    <SearchAndAddCourse
                        userType={user.type}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        professors={[...new Set([...courses, ...projects].flatMap(item => item.professors.map(prof => prof.name)))]}
                        setSelectedDate={setSelectedDate}
                        setSelectedProfessor={setSelectedProfessor}
                        setSelectedType={setSelectedType}
                    />
                </div>
            </div>
            {isLoading && <Spin size="large" />}
            {!isLoading && (
                <>
                    {/* Projects Section */}
                    {(selectedType === undefined || selectedType === 'both' || selectedType === 'project') && (
                        <SectionContainer
                            style={{
                                minHeight: '270px',
                                maxHeight: '270px',
                            }}>
                            <SectionTitle>
                                <Typography.Title level={2} style={{ marginBottom: '0' }}>Projects</Typography.Title>
                            </SectionTitle>
                            {filteredProjects.length === 0 ? (
                                <div style={{ textAlign: 'center', margin: '20px' }}>
                                    <Typography.Paragraph>No projects available in the marketplace.</Typography.Paragraph>
                                </div>
                            ) : (
                                <HorizontalScrollContainer
                                    ref={projectsSectionRef}
                                    style={{
                                        minHeight: '270px',
                                        maxHeight: '270px',
                                    }}
                                >
                                    {filteredProjects.map((project, index) => {
                                        const isEditable = canEditCourse(project);
                                        // Check for first community/user course (don't use setState in render)

                                        return (
                                            <CardWrapper key={project._id}>
                                                <ProjectCard
                                                    id={project._id}
                                                    title={project.name}
                                                    description={project.description}
                                                    professors={project.professors}
                                                    removable={false}
                                                    chatButtonRef={getChatButtonRef(isEditable, index)}
                                                    editButtonRef={getEditButtonRef(isEditable, index)}
                                                />
                                            </CardWrapper>
                                        );
                                    })}
                                </HorizontalScrollContainer>
                            )}
                        </SectionContainer>
                    )}

                    {/* Courses Section */}
                    {(selectedType === undefined || selectedType === 'both' || selectedType === 'course') && (
                        <SectionContainer
                            style={{
                                minHeight: '400px',
                                maxHeight: '400px',
                            }}>
                            <SectionTitle>
                                <Typography.Title level={2} style={{ marginBottom: '0' }}>Courses</Typography.Title>
                            </SectionTitle>
                            {filteredCourses.length === 0 ? (
                                <div style={{ textAlign: 'center', margin: '20px' }}>
                                    <Typography.Paragraph>No courses available in the marketplace.</Typography.Paragraph>
                                </div>
                            ) : (
                                <HorizontalScrollContainer ref={coursesSectionRef}>
                                    {filteredCourses.map((course, index) => {
                                        const isEditable = canEditCourse(course);
                                        // Check for first community/user course (don't use setState in render)

                                        return (
                                            <CardWrapper key={course._id}>
                                                <AutodidactCourseCard
                                                    id={course._id}
                                                    title={course.name}
                                                    description={course.description}
                                                    professors={course.professors}
                                                    lastUpdated={course.last_updated}
                                                    removable={false}
                                                    chatButtonRef={getChatButtonRef(isEditable, index)}
                                                    editButtonRef={getEditButtonRef(isEditable, index)}
                                                />
                                            </CardWrapper>
                                        );
                                    })}
                                </HorizontalScrollContainer>
                            )}
                        </SectionContainer>
                    )}

                    {(selectedType === undefined || selectedType === 'both' || selectedType === 'community') && (
                        <CommunitiesSection isMarketplace={true} />
                    )}
                </>
            )}
            <Button
                icon={<QuestionCircleOutlined />}
                onClick={() => setTourOpen(true)}
                style={{
                    marginLeft: '16px',
                    width: '5%',
                    minWidth: '40px',
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px'
                }}
            />
            <MarketplaceTour
                open={tourOpen}
                onClose={() => setTourOpen(false)}
                communityCourseChatRef={communityCourseChatRef}
                userCourseChatRef={userCourseChatRef}
                userCourseEditRef={userCourseEditRef}
                searchBarRef={searchBarRef}
                projectsSectionRef={projectsSectionRef}
                coursesSectionRef={coursesSectionRef}
                userType={user.type}
                isFirstCommunityCourse={isFirstCommunityCourse}
                isFirstUserCourse={isFirstUserCourse}
            />
        </ScrollableCourseOverviewContent>
    );
};

export default MarketplaceGallery;