import { TourProps, Tour } from "antd";
import React from "react";

interface DiscoverChatTourProps {
    open: boolean;
    onClose: () => void;
    inputRef: React.RefObject<HTMLDivElement | null>;
    templateRef: React.RefObject<HTMLDivElement | null>;
    navigatorRef: React.RefObject<HTMLDivElement | null>;
}

const DiscoverChatTour: React.FC<DiscoverChatTourProps> = ({
    open,
    onClose,
    inputRef,
    templateRef,
    navigatorRef,
}) => {
    const steps: TourProps['steps'] = [
        {
            title: "Navigator",
            description: "Use the navigator to explore different features and topics.",
            target: () => navigatorRef.current!,
        },
        {
            title: "Templates",
            description: "Use templates to quickly send common questions or inquiries.",
            target: () => templateRef.current!,
        },
        {
            title: "Chat Input",
            description: "Type your message here and press Enter to send.",
            target: () => inputRef.current!,
        },
    ];

    return (
        <Tour
            open={open}
            onClose={onClose}
            steps={steps}
        />
    );
};

export default DiscoverChatTour;
