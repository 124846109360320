import React, { useState } from 'react';
import { Input, Button, message, Tooltip } from 'antd';
import { CopyOutlined, CheckOutlined } from '@ant-design/icons';

interface CourseInviteLinkProps {
  courseId: string;
}

const CourseInviteLink: React.FC<CourseInviteLinkProps> = ({ courseId }) => {
  const [copied, setCopied] = useState(false);
  const inviteLink = `${window.location.origin}/join-course/${courseId}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(inviteLink).then(() => {
      setCopied(true);
      message.success('Link copied to clipboard!');
      setTimeout(() => setCopied(false), 3000);
    });
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Input 
        value={inviteLink} 
        readOnly 
        style={{ flex: 1 }} 
      />
      <Tooltip title={copied ? 'Copied!' : 'Copy to clipboard'}>
        <Button 
          icon={copied ? <CheckOutlined /> : <CopyOutlined />} 
          onClick={copyToClipboard}
          type={copied ? 'primary' : 'default'}
          style={{ marginLeft: '8px' }}
        />
      </Tooltip>
    </div>
  );
};

export default CourseInviteLink;
