import { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { PortfolioItem, SocialLinks, UserNetworking } from "../../types/user";
import CommunityPortfolioSection from './components/CommunityPortfolioSection';
import CommunityProfileOverviewSection from './components/CommunityProfileOverviewSection';
import CommunitySocialLinksSection from './components/CommunitySocialLinksSection';
import { useParams } from 'react-router-dom';
import { getUserProfile } from '../../services/user';
import { Container, FormSection, ScrollableContent, SectionTitle } from '../settings/styles';

const { Paragraph } = Typography;

const UserCommunityPage = () => {
    const { userID } = useParams();
    const [newUserName, setNewUserName] = useState('');
    const [bio, setBio] = useState('');
    const [location, setLocation] = useState('');
    const [profilePicture, setProfilePicture] = useState<string>('');
    const [tldr, setTldr] = useState<string>('');
    const [portfolio, setPortfolio] = useState<PortfolioItem[]>([]);
    const [social_links, setSocialLinks] = useState<SocialLinks>({
        twitter: '',
        linkedin: '',
        github: '',
        website: ''
    });
    const [networking, setNetworking] = useState<UserNetworking>({
        visibility: 'public',
        open_to_mentoring: false,
        seeking_cofounders: false,
        connections: []
    });

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const user_profile = await getUserProfile(userID!);
                setNewUserName(user_profile.name);
                setBio(user_profile.bio);
                setLocation(user_profile.location);
                setProfilePicture(user_profile.photo_url);
                setTldr(user_profile.tldr);
                setPortfolio(user_profile.portfolio || []);
                setSocialLinks(user_profile.social_links || {
                    twitter: '',
                    linkedin: '',
                    github: '',
                    website: ''
                });
                setNetworking(user_profile.networking || {
                    visibility: 'public',
                    open_to_mentoring: false,
                    seeking_cofounders: false,
                    connections: []
                });
            } catch (error) {
                console.error('Failed to fetch user profile:', error);
            }
        };

        if (!userID) {
            throw new Error('User ID is undefined');
        }

        fetchUserData();
    }, [userID]);
    


    return (
        <ScrollableContent>
            <Container>
                <CommunityProfileOverviewSection
                    newUserName={newUserName}
                    location={location}
                    profilePicture={profilePicture}
                    tldr={tldr}
                />

                <CommunitySocialLinksSection
                    social_links={social_links}
                    networking={networking}
                />

                <FormSection>
                    <SectionTitle>About Me</SectionTitle>
                    <Paragraph 
                        style={{ 
                            padding: '12px',
                            borderRadius: '6px',
                            minHeight: '100px',
                            whiteSpace: 'pre-wrap'
                        }}
                    >
                        {bio || 'No bio provided'}
                    </Paragraph>
                </FormSection>

                <CommunityPortfolioSection
                    portfolio={portfolio}
                />

            </Container>
        </ScrollableContent>
    );
};

export default UserCommunityPage;