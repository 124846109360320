import React, {useEffect, useState, useMemo, useCallback, useRef} from 'react';
import {
    Spin,
    Row,
    Col,
    Table,
    Typography,
    Select,
    DatePicker,
    theme,
    Divider,
    Modal,
    Space,
    Checkbox,
    Button
} from 'antd';
import {Bar, Line, Scatter} from 'react-chartjs-2';
import chroma from 'chroma-js';
import {Dayjs} from 'dayjs';
import dayjs from 'dayjs';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartData,
    ChartOptions,
    PointElement,
    LineElement
} from 'chart.js';
import {PCA} from 'ml-pca';
import {CourseContext} from '../courses/course';
import {useCourse} from '../../hooks/useCourse';
import {FlexMarginButton} from '../../components/basic/buttons';
import {capitalize} from '../../utils/utils';
import {getUserDataFromLocalStorage} from '../../utils/useLocalStorage';
import CourseReportsPage from './reports/CourseReportsPage';
import {
    QueryPCADataPoint,
    fetchCoverageData,
    fetchSatisfactionData,
    fetchMotivationData,
    fetchSourceCountData,
    getQueryData,
    QueryData,
    RagCoveragePlotResponse,
    RagCoverageTableDataType,
    SatisfactionPlotResponse,
    SatisfactionTableDataType,
    MotivationPlotResponse,
    MotivationTableDataType,
    SourceCountPlotResponse,
    SourceCountTableDataType,
    QueryPCAData,
    MessageStatisticsPlotResponse,
    fetchMessageStatistics,
    MessageStatisticsTableDataType,
} from '../../services/analytics';
import {Content} from 'antd/es/layout/layout';
import CourseAnalyticsTour from '../../components/tours/CourseAnalyticsTour';
import {QuestionCircleOutlined} from "@ant-design/icons";

const {RangePicker} = DatePicker;
const {Option} = Select;

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
);

interface StatisticVisibility {
    template: boolean;
    module: boolean;
    group: boolean;
    material: boolean;
}

interface LoadingWrapperProps {
    loading: boolean;
    children: React.ReactNode;
}

const LoadingWrapper: React.FC<LoadingWrapperProps> = ({loading, children}) => {
    return (
        <div className="relative w-full h-full">
            {children}
            {loading && (
                <div className="absolute inset-0 bg-gray-500/30 flex items-center justify-center">
                    <Spin size="large"/>
                </div>
            )}
        </div>
    );
};


interface EmbeddingScatterPlotProps {
    data: ChartData<'scatter'>;
    options?: ChartOptions<'scatter'>;
    loading: boolean;
}

const EmbeddingScatterPlot: React.FC<EmbeddingScatterPlotProps> = ({data, options, loading}) => {
    const defaultOptions: ChartOptions<'scatter'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const,
            },
        },
        scales: {
            x: {
                display: true,
                border: {
                    display: true
                },
                ticks: {
                    display: false,
                    maxTicksLimit: 20,
                },
                beginAtZero: true,
            },
            y: {
                display: true,
                border: {
                    display: true
                },
                ticks: {
                    display: false,
                    maxTicksLimit: 20,
                },
                beginAtZero: true,
            },
        },
    };

    const mergedOptions = {...defaultOptions, ...options};

    return (
        <div style={{width: '100%', height: '100%'}}>
            <LoadingWrapper loading={loading}>
                <Scatter data={data} options={mergedOptions}/>
            </LoadingWrapper>
        </div>
    );
};

export const CourseAnalytics: React.FC = () => {
    const courseHook = useCourse();
    const {tempCourse} = courseHook;
    const [selectedWeeks, setSelectedWeeks] = useState<number[]>([]);
    const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(null);
    const {token} = theme.useToken();
    const user = getUserDataFromLocalStorage();
    const [isReportsModalVisible, setIsReportsModalVisible] = useState(false);

    const [tourOpen, setTourOpen] = useState<boolean>(false);
    const moduleSelectRef = useRef<HTMLDivElement>(null);
    const dateRangeRef = useRef<HTMLDivElement>(null);
    const reloadButtonRef = useRef<HTMLButtonElement>(null);

    const [isLoading, setIsLoading] = useState<{
        satisfaction: boolean;
        ragCoverage: boolean;
        messageStatistics: boolean;
        sourceCounts: boolean;
        queryPCA: boolean;
        motivation: boolean;
    }>({
        satisfaction: false,
        ragCoverage: false,
        messageStatistics: false,
        sourceCounts: false,
        queryPCA: false,
        motivation: false
    });
    const isAnyLoading = Object.values(isLoading).some(loading => loading);
    const [hasLoaded, setHasLoaded] = useState(false);

    // Individual data states
    const [satisfactionData, setSatisfactionData] = useState<SatisfactionPlotResponse[]>([]);
    const [ragCoverageData, setRagCoverageData] = useState<RagCoveragePlotResponse[]>([]);
    const [messageStatisticsData, setMessageStatisticsData] = useState<MessageStatisticsPlotResponse[]>([]);
    const [sourceCountsData, setSourceCountsData] = useState<SourceCountPlotResponse[]>([]);
    const [queryData, setQueryData] = useState<QueryData>({queries: [], topics: []});
    const [queryPCAData, setQueryPCAData] = useState<QueryPCAData>({queriesPCA: [], topicsPCA: []});
    const [motivationData, setMotivationData] = useState<MotivationPlotResponse[]>([]);

    // Selected users state for each chart
    const [selectedUsers, setSelectedUsers] = useState<{
        satisfaction: string[];
        ragCoverage: string[];
        messageStatistics: string[];
        sourceCounts: string[];
        motivation: string[];
    }>({
        satisfaction: [],
        ragCoverage: [],
        messageStatistics: [],
        sourceCounts: [],
        motivation: []
    });

    // Table heights
    const tableHeaderHeight = 39;
    const [tableHeight80] = useState(() => Math.floor(window.innerHeight * 0.8) - tableHeaderHeight);
    const [tableHeight50] = useState(() => Math.floor(window.innerHeight * 0.5) - tableHeaderHeight);

    // Validation helper
    const validateLoadConditions = useCallback(() => {
        return selectedWeeks.length > 0 && dateRange !== null && tempCourse;
    }, [selectedWeeks, dateRange, tempCourse]);

    // Get timeframe helper
    const getTimeframe = useCallback(() => {
        if (!dateRange) return null;
        const [start, end] = dateRange;
        return [`${start.toISOString()}`, `${end.toISOString()}`];
    }, [dateRange]);

    // Load functions for each chart
    const loadSatisfactionData = async () => {
        if (!validateLoadConditions()) return;

        setIsLoading(prev => ({...prev, satisfaction: true}));
        try {
            const timeframe = getTimeframe();
            const student_ids = tempCourse!.enrolled_students.map(student => student._id);

            const data = await fetchSatisfactionData(tempCourse!._id, timeframe!, student_ids);
            setSatisfactionData(data);
            setSelectedUsers(prev => ({
                ...prev,
                satisfaction: data.filter(item => typeof item.satisfaction === 'number')
                    .map(item => item.user_id)
            }));
        } catch (error) {
            console.error("Error loading satisfaction data:", error);
        } finally {
            setIsLoading(prev => ({...prev, satisfaction: false}));
        }
    };

    const loadRagCoverageData = async () => {
        if (!validateLoadConditions()) return;

        setIsLoading(prev => ({...prev, ragCoverage: true}));
        try {
            const timeframe = getTimeframe();
            const student_ids = tempCourse!.enrolled_students.map(student => student._id);
            const relevant_weeks = selectedWeeks.map(weekNum => tempCourse!.weeks[weekNum - 1].number.toString());

            const data = await fetchCoverageData(tempCourse!._id, timeframe!, relevant_weeks, student_ids);
            setRagCoverageData(data);
            setSelectedUsers(prev => ({
                ...prev,
                ragCoverage: data.filter(item => typeof item.coverage === 'number')
                    .map(item => item.user_id)
            }));
        } catch (error) {
            console.error("Error loading RAG coverage data:", error);
        } finally {
            setIsLoading(prev => ({...prev, ragCoverage: false}));
        }
    };

    const loadMessageStatisticsData = async () => {
        if (!validateLoadConditions()) return;

        setIsLoading(prev => ({...prev, messageStatistics: true}));
        try {
            const timeframe = getTimeframe();
            const student_ids = tempCourse!.enrolled_students.map(student => student._id);

            const data = await fetchMessageStatistics(tempCourse!._id, timeframe!, student_ids);
            setMessageStatisticsData(data);
            setSelectedUsers(prev => ({
                ...prev,
                messageStatistics: data.map(item => item.user_id)
            }));
        } catch (error) {
            console.error("Error loading message statistics data:", error);
        } finally {
            setIsLoading(prev => ({...prev, messageStatistics: false}));
        }
    };

    const loadSourceCountsData = async () => {
        if (!validateLoadConditions()) return;

        setIsLoading(prev => ({...prev, sourceCounts: true}));
        try {
            const timeframe = getTimeframe();
            const student_ids = tempCourse!.enrolled_students.map(student => student._id);

            const data = await fetchSourceCountData(tempCourse!._id, timeframe!, student_ids);
            setSourceCountsData(data);
            setSelectedUsers(prev => ({
                ...prev,
                sourceCounts: data.map(item => item.user_id)
            }));
        } catch (error) {
            console.error("Error loading source counts data:", error);
        } finally {
            setIsLoading(prev => ({...prev, sourceCounts: false}));
        }
    };

    const loadQueryPCAData = async () => {
        if (!validateLoadConditions()) return;

        setIsLoading(prev => ({...prev, queryPCA: true}));
        try {
            const timeframe = getTimeframe();
            const data = await getQueryData(tempCourse!._id, timeframe!);
            setQueryData(data);
            setQueryPCAData(toCombinedPca(data.queries, data.topics, 2));
        } catch (error) {
            console.error("Error loading PCA data:", error);
        } finally {
            setIsLoading(prev => ({...prev, queryPCA: false}));
        }
    };

    const loadMotivationData = async () => {
        if (!validateLoadConditions()) return;

        setIsLoading(prev => ({...prev, motivation: true}));
        try {
            const timeframe = getTimeframe();
            const student_ids = tempCourse!.enrolled_students.map(student => student._id);

            const data = await fetchMotivationData(tempCourse!._id, timeframe!, student_ids);
            setMotivationData(data);
            setSelectedUsers(prev => ({
                ...prev,
                motivation: data.filter(item => typeof item.motivation === 'number')
                    .map(item => item.user_id)
            }));
        } catch (error) {
            console.error("Error loading motivation data:", error);
        } finally {
            setIsLoading(prev => ({...prev, motivation: false}));
        }
    };

    // Initial load effect
    useEffect(() => {
        loadData();
    }, [validateLoadConditions]);

    const loadData = useCallback(async () => {
        if (validateLoadConditions()) {
            await Promise.all([
                loadSatisfactionData(),
                loadRagCoverageData(),
                loadMessageStatisticsData(),
                loadSourceCountsData(),
                loadQueryPCAData(),
                loadMotivationData()
            ]);
            setHasLoaded(true); // Set hasLoaded to true after all data is loaded
        }
    }, [selectedWeeks, dateRange, tempCourse]);


    // Event handlers
    const handleWeekChange = (values: number[]) => {
        setSelectedWeeks(values);
    };

    const handleDateRangeChange = (dates: [Dayjs, Dayjs] | null) => {
        setDateRange(dates);
    };

    const handleOpenReports = () => {
        setIsReportsModalVisible(true);
    };

    const handleCloseReports = () => {
        setIsReportsModalVisible(false);
    };

    const getSelectedDates = useMemo(() => {
        if (selectedWeeks.length > 0 && dateRange !== null && tempCourse) {
            const selectedWeekObjects = selectedWeeks.map(weekNum => tempCourse.weeks[weekNum - 1]);
            const startDate = selectedWeekObjects.reduce((min, week) =>
                    dayjs(week.start_date).isBefore(min) ? dayjs(week.start_date) : min,
                dayjs(selectedWeekObjects[0].start_date)
            );
            const endDate = selectedWeekObjects.reduce((max, week) =>
                    dayjs(week.end_date).isAfter(max) ? dayjs(week.end_date) : max,
                dayjs(selectedWeekObjects[0].end_date)
            );
            return `Selected analytics for interactions between ${startDate.format('YYYY-MM-DD')} and ${endDate.format('YYYY-MM-DD')} with materials of weeks: ${selectedWeeks.join(', ')}.`;
        }
        return 'Please select a date range and one or multiple weeks to view analytics.';
    }, [selectedWeeks, dateRange, tempCourse]);


    function toCombinedPca(queries: QueryPCADataPoint[], topics: QueryPCADataPoint[], dimensions: number): {
        queriesPCA: number[][],
        topicsPCA: number[][]
    } {
        // Extract embeddings and combine them
        const queriesEmbeddings = queries.map(q => q.embedding);
        const topicsEmbeddings = topics.map(t => t.embedding);
        const combinedData = [...queriesEmbeddings, ...topicsEmbeddings];

        // Check if the combined data is valid
        if (!combinedData || combinedData.length === 0 || combinedData.length === 1 || combinedData.some(row => row.length === 0)) {
            return {queriesPCA: [[]], topicsPCA: [[]]};
        }

        // Perform PCA on the combined data
        const pca = new PCA(combinedData);
        const transformed = pca.predict(combinedData, {nComponents: dimensions});

        // Split the transformed data back into queries and topics
        const transformedArray = transformed.to2DArray();
        const queriesPCA = transformedArray.slice(0, queries.length);
        const topicsPCA = transformedArray.slice(queries.length);

        return {queriesPCA, topicsPCA};
    }

    const pcaData: ChartData<'scatter'> = {
        datasets: [
            {
                label: 'Queries',
                data: queryPCAData.queriesPCA.map((d, i) => ({x: d[0], y: d[1], id: i})),
                backgroundColor: '#27aeef',
            },
            {
                label: 'Topics',
                data: queryPCAData.topicsPCA.map((d, i) => ({x: d[0], y: d[1], id: i})),
                backgroundColor: '#ea5545',
            },
        ],
    };

    const pcaOptions: ChartOptions<'scatter'> = {
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Similarity between Queries and Topics',
                color: token.colorPrimaryText,
                font: {
                    size: 20,
                },
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const datasetLabel = context.dataset.label || '';
                        const index = context.dataIndex;
                        const text = datasetLabel === 'Queries'
                            ? queryData.queries[index].text
                            : queryData.topics[index].text;
                        return `${datasetLabel}: ${text}`;
                    },
                },
            },
        },
    };

    const SatisfactionBar = React.memo(() => {
        const filteredData = useMemo(() => satisfactionData.filter(item =>
            typeof item.satisfaction === 'number' && selectedUsers.satisfaction.includes(item.user_id)
        ), [satisfactionData, selectedUsers.satisfaction]);

        const data = {
            labels: filteredData.map(item => item.user_name),
            datasets: [
                {
                    label: 'Satisfaction',
                    data: filteredData.map(item => (item.satisfaction as number) * 100), // satisfaction in %
                    backgroundColor: token.colorPrimaryActive,
                    borderColor: token.colorBgContainer,
                    borderWidth: 1,
                    barThickness: 30,
                },
            ],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            indexAxis: 'y' as const,
            scales: {
                x: {
                    beginAtZero: true,
                    max: 100,
                    title: {
                        display: true,
                        text: 'Satisfaction (%)',
                        color: token.colorInfoText,
                    },
                    ticks: {
                        color: token.colorPrimaryText,
                        maxTicksLimit: 20,
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: `${capitalize(user.config.student_terminology)} Name`,
                        color: token.colorInfoText,
                    },
                    ticks: {
                        color: token.colorPrimaryText,
                        maxTicksLimit: 20,
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Satisfaction',
                    color: token.colorPrimaryText,
                    font: {
                        size: 20,
                    },
                },
            },
        };

        return <LoadingWrapper loading={isLoading.satisfaction}>
            <Bar data={data} options={options}/>;
        </LoadingWrapper>
    });

    const satisfactionTableColumns = [
        {
            title: `${capitalize(user.config.student_terminology)} Name`,
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: 'Satisfaction',
            dataIndex: 'satisfaction',
            key: 'satisfaction',
            render: (satisfaction: number | string) =>
                typeof satisfaction === 'number'
                    ? `${(satisfaction * 100).toFixed(0)}%`
                    : satisfaction,
        },
    ];

    const satisfactionTableData: SatisfactionTableDataType[] = useMemo(() =>
            satisfactionData.map(item => ({
                key: item.user_id,
                userName: item.user_name,
                satisfaction: typeof item.satisfaction === 'number' ? item.satisfaction : 'Too few interactions',
            }))
        , [satisfactionData]);

    const satisfactionRowSelection = {
        selectedRowKeys: selectedUsers.satisfaction,
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedUsers(prev => ({...prev, satisfaction: selectedRowKeys as string[]}));
        },
        getCheckboxProps: (record: SatisfactionTableDataType) => ({
            disabled: typeof record.satisfaction !== 'number',
        }),
    };
    const MotivationBar = React.memo(() => {
        const filteredData = useMemo(() => motivationData.filter(item =>
            selectedUsers.motivation.includes(item.user_id)
        ), [motivationData, selectedUsers.motivation]);
        const colors = chroma.scale([token.colorPrimaryActive, 'white']).mode('lch').colors(6);

        const data = {
            labels: filteredData.map(item => item.user_name),
            datasets: [
                {
                    label: 'Daily Message Count (normalized to 10)',
                    data: filteredData.map(item => item.daily_message_count),
                    backgroundColor: colors[0],
                    borderWidth: 1,
                    barThickness: 30,
                },
                {
                    label: 'Lexical Diversity (normalized to 50%)',
                    data: filteredData.map(item => item.lexical_diversity),
                    backgroundColor: colors[1],
                    borderWidth: 1,
                    barThickness: 30,
                },
                {
                    label: 'Average Reengagement Speed (normalized to 24h)',
                    data: filteredData.map(item => item.average_reengagement_speed),
                    backgroundColor: colors[2],
                    borderWidth: 1,
                    barThickness: 30,
                },
            ],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            indexAxis: 'y' as const,
            scales: {
                x: {
                    stacked: true,
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Count',
                        color: token.colorInfoText,
                    },
                    ticks: {
                        color: token.colorPrimaryText,
                        maxTicksLimit: 20,
                    },
                },
                y: {
                    stacked: true,
                    title: {
                        display: true,
                        text: `${capitalize(user.config.student_terminology)} Name`,
                        color: token.colorInfoText,
                    },
                    ticks: {
                        color: token.colorPrimaryText,
                        maxTicksLimit: 20,
                    },
                },
            },
            plugins: {
                legend: {
                    display: true,
                    labels: {
                        color: token.colorPrimaryText,
                        fill: false,
                    },
                },
                title: {
                    display: true,
                    text: 'Motivation Breakdown',
                    color: token.colorPrimaryText,
                    font: {
                        size: 20,
                    },
                },
            },
            elements: {
                bar: {
                    borderWidth: 2,
                },
            },
        };

        return <LoadingWrapper loading={isLoading.motivation}>
            <Bar data={data} options={options}/>;
        </LoadingWrapper>
    });

    const motivationTableColumns = [
        {
            title: `${capitalize(user.config.student_terminology)} Name`,
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: 'Motivation (normalized to 100)',
            dataIndex: 'motivation',
            key: 'motivation',
            render: (motivation: number | string) =>
                typeof motivation === 'number'
                    ? `${(motivation * 100).toFixed(0)}`
                    : motivation,
        },
    ];

    const motivationTableData: MotivationTableDataType[] = useMemo(() =>
            motivationData.map(item => ({
                key: item.user_id,
                userName: item.user_name,
                motivation: typeof item.motivation === 'number' ? item.motivation : 'Too few interactions',
            }))
        , [motivationData]);

    const motivationRowSelection = {
        selectedRowKeys: selectedUsers.motivation,
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedUsers(prev => ({...prev, motivation: selectedRowKeys as string[]}));
        },
        getCheckboxProps: (record: MotivationTableDataType) => ({
            disabled: typeof record.motivation !== 'number',
        }),
    };

    const RagCoverageBar = React.memo(() => {
        const filteredData = useMemo(() => ragCoverageData.filter(item =>
            typeof item.coverage === 'number' && selectedUsers.ragCoverage.includes(item.user_id)
        ), [ragCoverageData, selectedUsers.ragCoverage]);

        const data = {
            labels: filteredData.map(item => item.user_name),
            datasets: [
                {
                    label: `${capitalize(user.config.material_terminology)} Coverage`,
                    data: filteredData.map(item => (item.coverage as number) * 100), // satisfaction in %
                    backgroundColor: token.colorPrimaryActive,
                    borderColor: token.colorBgContainer,
                    borderWidth: 1,
                    barThickness: 30,
                },
            ],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            indexAxis: 'y' as const,
            scales: {
                x: {
                    beginAtZero: true,
                    max: 100,
                    title: {
                        display: true,
                        text: 'Coverage (%)',
                        color: token.colorInfoText,
                    },
                    ticks: {
                        color: token.colorPrimaryText,
                        maxTicksLimit: 20,
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: `${capitalize(user.config.student_terminology)} Name`,
                        color: token.colorInfoText,
                    },
                    ticks: {
                        color: token.colorPrimaryText,
                        maxTicksLimit: 20,
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: `${capitalize(user.config.material_terminology)} Coverage`,
                    color: token.colorPrimaryText,
                    font: {
                        size: 20,
                    },
                },
            },
        };

        return <Bar data={data} options={options}/>;
    });

    const ragCoverageTableColumns = [
        {
            title: `${capitalize(user.config.student_terminology)} Name`,
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: `${capitalize(user.config.material_terminology)} Coverage`,
            dataIndex: 'coverage',
            key: 'coverage',
            render: (coverage: number | string) =>
                typeof coverage === 'number'
                    ? `${(coverage * 100).toFixed(0)}%`
                    : coverage,
        },
    ];

    const ragCoverageTableData: RagCoverageTableDataType[] = useMemo(() =>
            ragCoverageData.map(item => ({
                key: item.user_id,
                userName: item.user_name,
                coverage: typeof item.coverage === 'number' ? item.coverage : 'Too few interactions',
            }))
        , [ragCoverageData]);

    const ragCoverageRowSelection = {
        selectedRowKeys: selectedUsers.ragCoverage,
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedUsers(prev => ({...prev, ragCoverage: selectedRowKeys as string[]}));
        },
        getCheckboxProps: (record: RagCoverageTableDataType) => ({
            disabled: typeof record.coverage !== 'number',
        }),
    };

    const [messageStatisticsVisibility, setMessageStatisticsVisibility] = useState<StatisticVisibility>({
        template: true,
        module: true,
        group: true,
        material: true
    });

    const messageStatisticsFilteredData = useMemo(() =>
            messageStatisticsData.filter(item => selectedUsers.messageStatistics.includes(item.user_id))
        , [messageStatisticsData, selectedUsers]);

    const messageStatisticsTableColumns = [
        {
            title: `${capitalize(user.config.student_terminology)} Name`,
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: 'Record Count',
            dataIndex: 'recordCount',
            key: 'recordCount',
        }
    ];

    const messageStatisticsRowSelection = useMemo(() => ({
        selectedRowKeys: selectedUsers.messageStatistics,
        onChange: (selectedRowKeys: React.Key[]) => {
            if (selectedRowKeys.length <= 5) {
                setSelectedUsers(prev => ({...prev, messageStatistics: selectedRowKeys as string[]}));
            }
        },
        getCheckboxProps: (record: MessageStatisticsTableDataType) => ({
            disabled: selectedUsers.messageStatistics.length >= 5 && !selectedUsers.messageStatistics.includes(record.key),
        }),
    }), [selectedUsers.messageStatistics]);

    const messageStatisticsTableData = useMemo(() =>
            Array.from(new Set(messageStatisticsData.map(d => d.user_id))).map(userId => {
                const userMessages = messageStatisticsData.filter(d => d.user_id === userId);
                const userName = userMessages[0]?.user_name || userId;
                const recordCount = userMessages.reduce((sum, msg) => {
                    const counts = [
                        ...Object.values(msg.template_statistic || {}),
                        ...Object.values(msg.module_statistic || {}),
                        ...Object.values(msg.group_statistic || {}),
                        ...Object.values(msg.material_statistic || {})
                    ];
                    return sum + counts.reduce((a, b) => (a as number) + (b as number), 0);
                }, 0);

                return {
                    key: userId,
                    userName,
                    recordCount
                };
            })
        , [messageStatisticsData]);


    const MessageStatisticsChart: React.FC<{
        data: MessageStatisticsPlotResponse[];
        visibility: StatisticVisibility;
        dateRange: [Dayjs, Dayjs] | null;
    }> = ({data, visibility, dateRange}) => {
        const {token} = theme.useToken();

        const chartData = useMemo(() => {
            if (!dateRange) return {labels: [], datasets: []};

            const [startDate, endDate] = dateRange;
            const allDates: string[] = [];

            // Generate all dates in range
            let currentDate = startDate.startOf('day');
            while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
                allDates.push(currentDate.format('YYYY-MM-DD'));
                currentDate = currentDate.add(1, 'day');
            }

            const datasets: {
                label: string;
                data: number[];
                borderColor: string;
                tension: number;
                borderDash?: number[];
            }[] = [];

            const addDatasets = (statType: keyof StatisticVisibility, baseColor: string, lineType: number[] = []) => {
                if (!visibility[statType]) return;

                const uniqueKeys = new Set<string>();
                data.forEach(d => {
                    const stats = d[`${statType}_statistic`] as Record<string, number>;
                    Object.keys(stats).forEach(key => uniqueKeys.add(key));
                });

                const colors = chroma.scale([baseColor, 'white']).mode('lch').colors(Math.max(6, uniqueKeys.size));

                Array.from(uniqueKeys).forEach((key, index) => {
                    if (!datasets.some(ds => ds.label === `${statType}: ${key}`)) {
                        datasets.push({
                            label: `${statType}: ${key}`,
                            data: allDates.map(date => {
                                const dayData = data.find(d => dayjs(d.date).format('YYYY-MM-DD') === date);
                                return dayData ? (dayData[`${statType}_statistic`] as Record<string, number>)[key] || 0 : 0;
                            }),
                            borderColor: colors[index],
                            tension: 0.4,
                            borderDash: lineType
                        });
                    }
                });
            };

            addDatasets('template', '#1890ff', [5, 5]);
            addDatasets(user.config.module_terminology.toLowerCase(), '#52c41a', [10, 5]);
            addDatasets('group', '#722ed1', []);
            addDatasets('material', '#faad14', [2, 2]);

            return {
                labels: allDates,
                datasets
            };
        }, [data, visibility, dateRange]);


        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'top' as const,
                    labels: {
                        color: token.colorPrimaryText,
                        fill: false,
                    },
                },
                title: {
                    display: true,
                    text: 'Message Statistics',
                    color: token.colorInfoText,
                    font: {
                        size: 20,
                    },
                }
            },
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: 'Date',
                        color: token.colorInfoText,
                    },
                    ticks: {
                        color: token.colorPrimaryText,
                        minRotation: 50,
                        maxRotation: 85,
                        autoSkip: true,
                        maxTicksLimit: 20,
                    },
                },
                y: {
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Count',
                        color: token.colorInfoText,
                    },
                    ticks: {
                        color: token.colorPrimaryText,
                        maxTicksLimit: 20,
                    },
                }
            }
        };

        return <Line data={chartData} options={options} style={{maxHeight: "95%"}}/>;
    };


    const SourceCountsChart = React.memo(() => {
        const {token} = theme.useToken();

        const filteredData = useMemo(() =>
                sourceCountsData.filter(item =>
                    selectedUsers.sourceCounts.includes(item.user_id)
                ),
            [sourceCountsData, selectedUsers.sourceCounts]);

        const allSources = useMemo(() =>
                [...new Set(filteredData.flatMap(student => Object.keys(student.source_counts)))],
            [filteredData]);

        const colors = useMemo(() =>
                ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#8A2BE2', '#20B2AA', '#FF69B4', '#00CED1'],
            []);

        const data = useMemo(() => ({
            labels: allSources.map(count => `${count.slice(0, 10)}...${count.slice(-7)}`),
            datasets: filteredData.map((student, index) => ({
                label: student.user_name,
                data: allSources.map(source => student.source_counts[source] || 0),
                backgroundColor: colors[index % colors.length],
                borderColor: token.colorBgContainer,
                borderWidth: 1,
                barThickness: 30,
            })),
        }), [filteredData, allSources, colors, token.colorBgContainer]);

        const options = useMemo(() => ({
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Source Names',
                        color: token.colorInfoText,
                    },
                    ticks: {
                        color: token.colorPrimaryText,
                        minRotation: 50,
                        maxRotation: 85,
                        autoSkip: true,
                        maxTicksLimit: 20,
                    },
                },
                y: {
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Count',
                        color: token.colorInfoText,
                    },
                    ticks: {
                        color: token.colorPrimaryText,
                        maxTicksLimit: 20,
                    },
                },
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top' as const,
                    labels: {
                        color: token.colorPrimaryText,
                    },
                },
                title: {
                    display: true,
                    text: 'Source Counts by User',
                    color: token.colorPrimaryText,
                    font: {
                        size: 20,
                    },
                },
            },
        }), [token]);

        return <Bar data={data} options={options} style={{maxHeight: "95%"}}/>;
    });


    const sourceCountsColumns = useMemo(() => [
        {
            title: `${capitalize(user.config.student_terminology)} Name`,
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: 'Sources',
            dataIndex: 'source_counts',
            key: 'source_counts',
            render: (source_counts: Record<string, number>) => Object.keys(source_counts).join(', '),
        },
    ], []);

    const sourceCountsTableData: SourceCountTableDataType[] = useMemo(() =>
            sourceCountsData.map(item => ({
                key: item.user_id,
                userName: item.user_name,
                source_counts: item.source_counts,
            })),
        [sourceCountsData]);

    const sourceCountsRowSelection = useMemo(() => ({
        selectedRowKeys: selectedUsers.sourceCounts,
        onChange: (selectedRowKeys: React.Key[]) => {
            if (selectedRowKeys.length <= 5) {
                setSelectedUsers(prev => ({...prev, sourceCounts: selectedRowKeys as string[]}));
            }
        },
        getCheckboxProps: (record: SourceCountTableDataType) => ({
            disabled: selectedUsers.sourceCounts.length >= 5 && !selectedUsers.sourceCounts.includes(record.key),
        }),
    }), [selectedUsers.sourceCounts]);

    return (
        <Content style={{padding: "2rem"}}>
            {tempCourse && <>
                <Typography.Title
                    level={1}>
                    {`${capitalize(user.config.course_terminology)} Analytics`}
                </Typography.Title>
                <Row gutter={24}>
                    <Col span={16}>
                        <Typography.Title
                            level={3}>
                            Course data
                        </Typography.Title>
                        <Typography.Paragraph>
                            Name: {tempCourse.name}
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            Description: {tempCourse.description}
                        </Typography.Paragraph>
                    </Col>
                    <Col span={8}>
                        {process.env.REACT_APP_COURSE_IDS?.split(',').includes(user._id) && (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                alignItems: 'bottom',
                                alignContent: 'bottom',
                                height: '100%'
                            }}>
                                <FlexMarginButton
                                    type="default"
                                    onClick={handleOpenReports}
                                    style={{marginBottom: '1rem', width: '100%'}}
                                >
                                    Open Reports
                                </FlexMarginButton>
                            </div>
                        )}

                        <Modal
                            title="Course Reports"
                            open={isReportsModalVisible}
                            onCancel={handleCloseReports}
                            footer={null}
                            width="60%"
                            style={{
                                display: 'flex',
                                top: 20,
                                justifyContent: 'center'
                            }}
                        >
                            <CourseReportsPage/>
                        </Modal>
                    </Col>
                </Row>
                <CourseContext.Provider value={courseHook}>
                    <Divider orientation="right" orientationMargin={50}/>
                    <Typography.Title level={3}>Select Scope</Typography.Title>
                    <Row gutter={24} align="middle" style={{marginBottom: '1rem'}}>
                        <Col span={8}>
                            <div ref={moduleSelectRef}>
                                <Select
                                    data-testid="week-select"
                                    mode="multiple"
                                    style={{width: '100%'}}
                                    placeholder="Please select one or multiple Weeks"
                                    onChange={handleWeekChange}
                                    value={selectedWeeks}
                                >
                                    {tempCourse!.weeks.map((week, index) => (
                                        <Option key={week.number} value={week.number}>
                                            {week.number}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div ref={dateRangeRef}>
                                <RangePicker
                                    data-testid="date-range-picker"
                                    style={{width: '100%'}}
                                    value={dateRange}
                                    onChange={(dates: any) => handleDateRangeChange(dates as [Dayjs, Dayjs] | null)}
                                />
                            </div>
                        </Col>
                        <Col span={8}>
                            <FlexMarginButton
                                ref={reloadButtonRef}
                                style={{width: '100%'}}
                                disabled={!(selectedWeeks.length > 0 && dateRange !== null && tempCourse) || isAnyLoading}
                                type="primary"
                                onClick={() => loadData()}>
                                {(selectedWeeks.length > 0 && dateRange !== null && tempCourse) ? 'Reload data' : 'Please select weeks and data range'}
                            </FlexMarginButton>
                        </Col>
                    </Row>


                    <Typography.Paragraph>
                        {getSelectedDates}
                    </Typography.Paragraph>
                    <Divider
                        orientation="right"
                        orientationMargin={50}>
                    </Divider>
                    {isAnyLoading ? (
                        <Spin size='large'/>
                    ) : hasLoaded ? (
                        <Col>
                            <LoadingWrapper loading={isLoading.satisfaction}>
                                <Row gutter={16} align="top">
                                    <Col span={16}>
                                        <div style={{
                                            height: '50vh',
                                            border: `1px solid ${token.colorBgContainer}`,
                                            borderRadius: '8px',
                                            padding: '20px',
                                            boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                                        }}>
                                            <SatisfactionBar/>
                                        </div>
                                        <Typography.Paragraph style={{
                                            marginTop: '1rem',
                                        }}>
                                            The satisfaction indicator is based on explicit feedback (clicking thumbs up
                                            / down) and implied feedback (based on chat messages) from student
                                            interactions with the course {user.config.material_terminology}s. A minimum of 10 interactions is
                                            required.
                                        </Typography.Paragraph>
                                    </Col>
                                    <Col span={8}>
                                        <div style={{
                                            height: '50vh',
                                            borderRadius: '0px',
                                            padding: '0px',
                                            boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                                            background: token.colorBgContainer,
                                        }}>
                                            <Table
                                                rowSelection={satisfactionRowSelection}
                                                columns={satisfactionTableColumns}
                                                dataSource={satisfactionTableData}
                                                size="small"
                                                scroll={{y: tableHeight50}}
                                                pagination={false}
                                            />
                                        </div>
                                        <FlexMarginButton
                                            type="default"
                                            onClick={loadSatisfactionData}
                                            style={{marginTop: '1rem', width: '100%'}}
                                        >
                                            Reload
                                        </FlexMarginButton>
                                    </Col>
                                </Row>
                            </LoadingWrapper>
                            <Divider
                                orientation="right"
                                orientationMargin={50}>
                            </Divider>
                            <LoadingWrapper loading={isLoading.motivation}>
                                <Row gutter={16} align="top">
                                    <Col span={16}>
                                        <div style={{
                                            height: '50vh',
                                            border: `1px solid ${token.colorBgContainer}`,
                                            borderRadius: '8px',
                                            padding: '20px',
                                            boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                                        }}>
                                            <MotivationBar/>
                                        </div>
                                        <Typography.Paragraph style={{
                                            marginTop: '1rem',
                                        }}>
                                            The motivation indicator shows the motivation based on {user.config.student_terminology} interactions
                                            with the course {user.config.material_terminology}s. It is comprised of the following components:
                                            <ul>
                                                <li>Daily Message Count (assuming the average {user.config.student_terminology} asks 10 questions
                                                    per day)
                                                </li>
                                                <li>Lexical Diversity (assuming that the {user.config.student_terminology} uses the average
                                                    vocabulary range in the interactions with Sona)
                                                </li>
                                                <li>Average Reengagement Speed (assuming that the average {user.config.student_terminology} will
                                                    re-engage with Sona once a day)
                                                </li>
                                            </ul>
                                        </Typography.Paragraph>
                                    </Col>
                                    <Col span={8}>
                                        <div style={{
                                            height: '50vh',
                                            borderRadius: '0px',
                                            padding: '0px',
                                            boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                                            background: token.colorBgContainer,
                                        }}>
                                            <Table
                                                rowSelection={motivationRowSelection}
                                                columns={motivationTableColumns}
                                                dataSource={motivationTableData}
                                                size="small"
                                                scroll={{y: tableHeight50}}
                                                pagination={false}
                                            />
                                        </div>
                                        <FlexMarginButton
                                            type="default"
                                            onClick={loadMotivationData}
                                            style={{marginTop: '1rem', width: '100%'}}
                                        >
                                            Reload
                                        </FlexMarginButton>
                                    </Col>
                                </Row>
                            </LoadingWrapper>
                            <Divider
                                orientation="right"
                                orientationMargin={50}>
                            </Divider>
                            <LoadingWrapper loading={isLoading.ragCoverage}>
                                <Row gutter={16} align="top">
                                    <Col span={16}>
                                        <div style={{
                                            height: '50vh',
                                            border: `1px solid ${token.colorBgContainer}`,
                                            borderRadius: '8px',
                                            padding: '20px',
                                            boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                                        }}>
                                            <RagCoverageBar/>
                                        </div>
                                        <Typography.Paragraph style={{
                                            marginTop: '1rem',
                                        }}>
                                            The {user.config.material_terminology} converage indicator shows, whatpercentages of the {user.config.material_terminology}s
                                            specified for the parts in the selected date range has been presented to the
                                            {user.config.student_terminology} at least once.
                                        </Typography.Paragraph>
                                    </Col>
                                    <Col span={8}>
                                        <div style={{
                                            height: '50vh',
                                            borderRadius: '0px',
                                            padding: '0px',
                                            boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                                            background: token.colorBgContainer,
                                        }}>
                                            <Table
                                                rowSelection={ragCoverageRowSelection}
                                                columns={ragCoverageTableColumns}
                                                dataSource={ragCoverageTableData}
                                                size="small"
                                                scroll={{y: tableHeight50}}
                                                pagination={false}
                                            />
                                        </div>
                                        <FlexMarginButton
                                            type="default"
                                            onClick={loadRagCoverageData}
                                            style={{marginTop: '1rem', width: '100%'}}
                                        >
                                            Reload
                                        </FlexMarginButton>
                                    </Col>
                                </Row>
                            </LoadingWrapper>
                            <Divider
                                orientation="right"
                                orientationMargin={50}>
                            </Divider>
                            <LoadingWrapper loading={isLoading.messageStatistics}>
                                <Row gutter={16} align="top">
                                    <Col span={16}>
                                        <div style={{
                                            height: '80vh',
                                            border: `1px solid ${token.colorBgContainer}`,
                                            borderRadius: '8px',
                                            padding: '20px',
                                            boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                                        }}>
                                            <Space direction="horizontal" style={{marginBottom: '16px'}}>
                                                {Object.entries(messageStatisticsVisibility).map(([key, value]) => (
                                                    <Checkbox
                                                        key={key}
                                                        checked={value}
                                                        onChange={e => setMessageStatisticsVisibility(prev => ({
                                                            ...prev,
                                                            [key]: e.target.checked
                                                        }))}
                                                    >
                                                        {capitalize(key)}
                                                    </Checkbox>
                                                ))}
                                            </Space>
                                            <MessageStatisticsChart data={messageStatisticsFilteredData}
                                                                    visibility={messageStatisticsVisibility}
                                                                    dateRange={dateRange}/>
                                        </div>
                                        <Typography.Paragraph
                                            style={{marginTop: '1rem', width: '100%'}}
                                        >
                                            The message statistics show the distribution of messages across templates,
                                            modules, groups, and {user.config.material_terminology}s over time.
                                            Toggle different statistics using the checkboxes and select specific
                                            students to analyze their interaction patterns.
                                        </Typography.Paragraph>
                                    </Col>
                                    <Col span={8}>
                                        <div style={{
                                            height: '80vh',
                                            borderRadius: '0px',
                                            padding: '0px',
                                            boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                                            background: token.colorBgContainer,
                                        }}>
                                            <Table
                                                rowSelection={messageStatisticsRowSelection}
                                                columns={messageStatisticsTableColumns}
                                                dataSource={messageStatisticsTableData}
                                                size="small"
                                                scroll={{y: tableHeight80}}
                                                pagination={false}
                                            />
                                        </div>
                                        <FlexMarginButton
                                            type="default"
                                            onClick={loadMessageStatisticsData}
                                            style={{marginTop: '1rem', width: '100%'}}
                                            loading={isLoading.messageStatistics}
                                        >
                                            Reload
                                        </FlexMarginButton>
                                    </Col>
                                </Row>
                            </LoadingWrapper>
                            <Divider
                                orientation="right"
                                orientationMargin={50}>
                            </Divider>
                            <LoadingWrapper loading={isLoading.sourceCounts}>
                                <Row gutter={16} align="top">
                                    <Col span={16}>
                                        <div style={{
                                            height: '80vh',
                                            border: `1px solid ${token.colorBgContainer}`,
                                            borderRadius: '8px',
                                            padding: '20px',
                                            boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                                        }}>
                                            <SourceCountsChart/>
                                        </div>
                                        <Typography.Paragraph style={{
                                            marginTop: '1rem',
                                        }}>
                                            The source counts present the ranking of the most presented sources to the
                                            students in the selected date range.
                                        </Typography.Paragraph>
                                    </Col>
                                    <Col span={8}>
                                        <div style={{
                                            height: '80vh',
                                            borderRadius: '0px',
                                            padding: '0px',
                                            boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                                            background: token.colorBgContainer,
                                        }}>
                                            <Table
                                                rowSelection={sourceCountsRowSelection}
                                                columns={sourceCountsColumns}
                                                dataSource={sourceCountsTableData}
                                                size="small"
                                                scroll={{y: tableHeight80}}
                                                pagination={false}
                                            />
                                        </div>
                                        <FlexMarginButton
                                            type="default"
                                            onClick={loadSourceCountsData}
                                            style={{marginTop: '1rem', width: '100%'}}
                                        >
                                            Reload
                                        </FlexMarginButton>
                                    </Col>
                                </Row>
                            </LoadingWrapper>
                            <Divider
                                orientation="right"
                                orientationMargin={50}
                            />
                            <div style={{
                                height: '50vh',
                                border: `1px solid ${token.colorBgContainer}`,
                                borderRadius: '8px',
                                padding: '20px',
                                boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                            }}>
                                <EmbeddingScatterPlot data={pcaData} options={pcaOptions} loading={isLoading.queryPCA}/>
                            </div>
                            <Row gutter={16} align="top">
                                <Col span={16}>
                                    <Typography.Paragraph style={{
                                        marginTop: '1rem',
                                    }}>
                                        The similarity between queries and sources is depicted here as the distance
                                        between points on the graph and gives an overview of the popularity of certain
                                        topics compared to others.
                                    </Typography.Paragraph>
                                </Col>
                                <Col span={8}>
                                    <FlexMarginButton
                                        type="default"
                                        onClick={loadQueryPCAData}
                                        style={{marginTop: '1rem', width: '100%'}}
                                    >
                                        Reload
                                    </FlexMarginButton>
                                </Col>
                            </Row>
                        </Col>
                    ) : null
                    }

                    <Button
                        icon={<QuestionCircleOutlined/>}
                        onClick={() => setTourOpen(true)}
                        style={{
                            marginLeft: '16px',
                            width: '5%',
                            minWidth: '40px',
                            position: 'fixed',
                            bottom: '20px',
                            right: '20px'
                        }}
                    />
                    <CourseAnalyticsTour
                        isOpen={tourOpen}
                        onClose={() => setTourOpen(false)}
                        moduleSelectRef={moduleSelectRef}
                        dateRangeRef={dateRangeRef}
                        reloadButtonRef={reloadButtonRef}
                    />

                </CourseContext.Provider>
            </>}
        </Content>
    );
};

export default CourseAnalytics;