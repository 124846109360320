import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { saveRedirectUrl } from '../utils/redirectUtils';
import { redirectUrlKey } from '../config';

const API_URL = `${process.env.REACT_APP_BACKEND_URL?.includes('://')
  ? process.env.REACT_APP_BACKEND_URL 
  : `https://${process.env.REACT_APP_BACKEND_URL}`}`;

// Create an Axios instance
const authedAxios: AxiosInstance = axios.create({
  baseURL: API_URL,
});

// Track if we're currently refreshing to prevent multiple refresh calls
let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

// Subscribe failed requests to be retried after token refresh
const subscribeTokenRefresh = (cb: (token: string) => void) => {
  refreshSubscribers.push(cb);
};

// Retry failed requests with new token
const onTokenRefreshed = (newToken: string) => {
  refreshSubscribers.map(cb => cb(newToken));
  refreshSubscribers = [];
};

// Clear subscribers if refresh fails
const onRefreshError = () => {
  refreshSubscribers = [];
};

// Single request interceptor
authedAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Single response interceptor with improved refresh logic
authedAxios.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config;

    if (!originalRequest) {
      return Promise.reject(error);
    }

    // Extend the InternalAxiosRequestConfig interface
    interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
      _retry?: boolean; // Add the _retry property
    }

    // Handle 401 errors
    if (error.response?.status === 401 && !(originalRequest as CustomAxiosRequestConfig)._retry) {
      (originalRequest as CustomAxiosRequestConfig)._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const refreshToken = localStorage.getItem('refresh_token');
          if (!refreshToken) {
            throw new Error('No refresh token available');
          }

          const response = await axios.post(`${API_URL}/refresh-access-token`, {
            refresh_token: refreshToken
          });

          const { access_token } = response.data;
          localStorage.setItem('access_token', access_token);

          // Update axios default headers
          authedAxios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

          // Notify subscribers and retry their requests
          onTokenRefreshed(access_token);
          isRefreshing = false;

          // Retry original request with new token
          originalRequest.headers.Authorization = `Bearer ${access_token}`;
          return authedAxios(originalRequest);

        } catch (refreshError) {
          isRefreshing = false;
          onRefreshError();

          // Clear auth state and redirect to login
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('user');
          
          // Save current path for redirection after login
          const currentPath = window.location.pathname + window.location.search;
          saveRedirectUrl(currentPath);
          
          window.location.href = '/login';

          return Promise.reject(refreshError);
        }
      }

      // If refresh is already in progress, wait for the new token
      return new Promise((resolve) => {
        subscribeTokenRefresh((token: string) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(authedAxios(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  }
);


const login = async (username: string, password: string) => {
  localStorage.removeItem(redirectUrlKey);
  localStorage.removeItem('isTechnicalSignup')
  localStorage.removeItem('isKeyAccount')
  const response = await axios.post(`${API_URL}/login`, { username, password });
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('user');
  localStorage.setItem('access_token', response.data.access_token);
  localStorage.setItem('refresh_token', response.data.refresh_token);
  localStorage.setItem('user', JSON.stringify(response.data.user));
  return response.data.user;
};

const loginWithToken = async() => {
  localStorage.removeItem(redirectUrlKey);
  localStorage.removeItem('isTechnicalSignup')
  localStorage.removeItem('isKeyAccount')
  const response = await authedAxios.get(`${API_URL}/login-with-token`);
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('user');
  localStorage.setItem('refresh_token', response.data.refresh_token);
  localStorage.setItem('user', JSON.stringify(response.data.user));
  return response.data.user;
}

const logout = () => {
  localStorage.removeItem(redirectUrlKey);
  localStorage.removeItem('isTechnicalSignup')
  localStorage.removeItem('isKeyAccount')
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('user');
};

export { authedAxios, login, logout, loginWithToken };
