import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getAndClearRedirectUrl, saveRedirectUrl } from '../utils/redirectUtils';


interface RedirectToLoginProps {
  isLoggedIn: boolean;
}

export const RedirectToLogin: React.FC<RedirectToLoginProps> = ({ isLoggedIn }) => {
  const location = useLocation();
  const publicPaths = ['/login', '/signup', '/user-type-selection', '/checkout', '/return', '/health'];

  // If not logged in
  useEffect(() => {
    const isPublicPath = publicPaths.some(path => location.pathname.startsWith(path));
    if (!isPublicPath) {
      saveRedirectUrl(location.pathname + location.search);
    }
  }, [location.pathname, location.search]);

  // If logged in, stay on current path unless it's a public path
  if (isLoggedIn) {
    const redirectUrl = getAndClearRedirectUrl();
    if (redirectUrl && !window.location.href.includes(redirectUrl)) {
      return <Navigate to={redirectUrl} replace />;
    } else if (['/login', '/user-type-selection', '/return'].includes(location.pathname)) {
      return <Navigate to="/" replace />;
    } else {
      return null; // Stay on current path
    }
  } else if (publicPaths.some(path => location.pathname.startsWith(path))) {
    return <Navigate to={location.pathname} replace />;
  }

  return <Navigate to="/login" replace />;
};

export default RedirectToLogin;
