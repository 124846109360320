import { styled } from "@stitches/react";
import { Content } from 'antd/es/layout/layout';
import { Card, Row, Col, Space, Input, Carousel } from 'antd';

export const Container = styled(Content, {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 auto",
    width: "100%",
    maxWidth: "800px",
    padding: "2rem",
    gap: "24px",
});

export const FormSection = styled(Card, {
    width: "100%",
});

export const SectionTitle = styled('h4', {
    textAlign: "center",
    marginBottom: "24px !important",
});

export const FormRow = styled(Row, {
    marginBottom: "16px",
    '&:last-child': {
        marginBottom: 0
    }
});

export const LabelCol = styled(Col, {
    display: "flex",
    alignItems: "center",
    fontWeight: "500",
});

export const InputCol = styled(Col, {
    display: "flex",
    alignItems: "center",
    gap: "8px",
});

export const CenteredSpace = styled(Space, {
    width: "100%",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "12px",
});

export const SocialInput = styled(Input, {
    width: "30vh !important",
});

export const ScrollableContent = styled(Content, {
    padding: "2rem",
    height: "calc(100vh - 64px)", // Subtract header height
    overflow: "auto"
});

export const ScrollableCourseOverviewContent = styled(Content, {
    padding: "2rem",
    height: "calc(100vh - 64px)", // Subtract header height
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "2rem"
});