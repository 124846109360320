import { SourceItem } from "../components/chat/SourceButton";
import { sourceNotDownloadableKey } from "../config";

/**
 * Parses source button information from text.
 * Expected new format: fileId:title:content:module_info:location_info:relevance Z%
 * Expected old format: fileId:sourceText, module X, page Y, relevance Z%
 * 
 * @param sourceContent The content string containing source information
 * @returns A SourceItem object with parsed data
 */
export const parseSourceContent = (sourceContent: string): SourceItem => {
    // Check if we have the new format with multiple colons
    const parts = sourceContent.split(':');
    if (parts.length >= 6) {
        // New format: fileId:title:content:module_info:location_info:relevance Z%
        const sourceItem: SourceItem = {
            fileId: parts[0].trim() || sourceNotDownloadableKey,
            title: parts[1].trim(),
            content: parts.slice(5).join(':').trim(),
            relevancy: 0,
            module: '',
            page: '',
            time: ''
        };
        
        // Extract module information
        const moduleInfo = parts[2].trim();
        if (moduleInfo && moduleInfo.startsWith("module")) {
            sourceItem.module = moduleInfo.substring("module".length).trim();
        }
        
        // Extract page/time information
        const locationInfo = parts[3].trim();
        if (locationInfo.startsWith("page")) {
            sourceItem.page = `Page ${locationInfo.substring("page".length).trim()}`;
        } else if (locationInfo.startsWith("minute")) {
            sourceItem.time = `Time ${locationInfo.substring("minute".length).trim()}`;
        }
        
        // Extract relevancy information
        const relevanceString = parts[4]?.trim() || '';
        const relevanceMatch = relevanceString.match(/relevance\s+([0-9.]+)%/i);
        if (relevanceMatch && relevanceMatch[1]) {
            sourceItem.relevancy = parseFloat(relevanceMatch[1]);
        }
        
        return sourceItem;
    }
    
    // If not new format, use old format parsing
    const [fileId, rawSourceText] = sourceContent.split(':');
    
    if (!fileId || !rawSourceText) {
        return {
            fileId: fileId?.trim() || sourceNotDownloadableKey,
            title: rawSourceText?.trim() || 'Unknown source',
            relevancy: 0,
            module: '',
            page: '',
            time: ''
        };
    }
    
    // Initialize source item with basic info for old format
    const sourceItem: SourceItem = {
        fileId: fileId.trim(),
        title: '', // Will be extracted from sourceText
        relevancy: 0,
        module: '',
        page: '',
        time: ''
    };
    
    // Extract title (everything before the first comma or the whole text if no comma)
    const firstCommaIndex = rawSourceText.indexOf(',');
    sourceItem.title = firstCommaIndex > 0 ? 
        rawSourceText.substring(0, firstCommaIndex).trim() : 
        rawSourceText.trim();
    
    // Extract module information
    const moduleMatch = rawSourceText.match(/module\s+([^,]+)/i);
    if (moduleMatch && moduleMatch[1]) {
        sourceItem.module = moduleMatch[1].trim();
    }
    
    // Extract page information
    const pageMatch = rawSourceText.match(/page\s+([0-9]+)/i);
    if (pageMatch && pageMatch[1]) {
        sourceItem.page = `Page ${pageMatch[1].trim()}`;
    }
    
    // Extract time information (for audio files)
    const timeMatch = rawSourceText.match(/minute\s+([0-9]+:[0-9]+)/i);
    if (timeMatch && timeMatch[1]) {
        sourceItem.time = `Time ${timeMatch[1].trim()}`;
    }
    
    // Extract relevancy information
    const relevanceMatch = rawSourceText.match(/relevance\s+([0-9.]+)%/i);
    if (relevanceMatch && relevanceMatch[1]) {
        sourceItem.relevancy = parseFloat(relevanceMatch[1]);
    }
    
    return sourceItem;
};

/**
 * Parses source buttons from a message text.
 * 
 * @param text The message text potentially containing source buttons
 * @returns An array of parsed SourceItem objects and the main text without source buttons
 */
export const parseSourceButtons = (text: string): { mainText: string, sourceItems: SourceItem[] } => {
    const sourceItems: SourceItem[] = [];
    
    // Split the text into main content and source items
    const parts = text.split('<source_button>');
    const mainText = parts[0];
    
    // Process each source button part after the first split
    if (parts.length > 1) {
        parts.slice(1).forEach(part => {
            const endIndex = part.indexOf('</source_button>');
            if (endIndex !== -1) {
                const sourceContent = part.substring(0, endIndex);
                sourceItems.push(parseSourceContent(sourceContent));
            }
        });
    }
    
    return { mainText, sourceItems };
};
