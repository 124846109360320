import React, { useEffect, useState } from 'react';
import { Button, Carousel } from 'antd';
import { styled } from '@stitches/react';

const SuggestionsContainer = styled("div", {
  position: "absolute",
  width: "100%",
  top: "-25px",
  height: "40px",
  alignItems: "center",
  overflow: "hidden",
});

const StyledCarousel = styled(Carousel, {
  width: "100%",
  height: "40px",
  "& .slick-track": {
    display: "flex",
    alignItems: "center",
  },
  "& .slick-slide": {
    textAlign: "center",
  }
});

interface PromptSuggestionsProps {
  suggestions: string[];
  onSelectSuggestion: (suggestion: string) => void;
}

const PromptSuggestions: React.FC<PromptSuggestionsProps> = ({
  suggestions,
  onSelectSuggestion
}) => {
  const [displayedSuggestions, setDisplayedSuggestions] = useState<string[]>([]);

  useEffect(() => {
    if (suggestions.length === 0) return;

    // Get 5 random suggestions or all if less than 5
    const count = Math.min(5, suggestions.length);
    const randomSuggestions: string[] = [];
    const indices = new Set<number>();

    while (indices.size < count) {
      const index = Math.floor(Math.random() * suggestions.length);
      if (!indices.has(index)) {
        indices.add(index);
        randomSuggestions.push(suggestions[index]);
      }
    }

    setDisplayedSuggestions(randomSuggestions);
  }, [suggestions]);

  if (displayedSuggestions.length === 0) return null;

  return (
    <SuggestionsContainer>
      <StyledCarousel
        autoplay
        dots={false}
        autoplaySpeed={5000}
        pauseOnHover
        waitForAnimate={false}
      >
        {displayedSuggestions.map((suggestion, index) => (
          <div key={index}>
            <Button
              onClick={() => onSelectSuggestion(suggestion)}
              size="small"
              type='default'
            >
              {suggestion}
            </Button>
          </div>
        ))}
      </StyledCarousel>
    </SuggestionsContainer>
  );
};

export default PromptSuggestions;