import React, { useState } from "react";
import { Table, Button, Typography, Space, message, Modal } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { capitalize, formatDate } from "../../utils/utils";
import { acceptFileContribution, rejectFileContribution } from "../../services/files";
import { CourseFile } from "../../services/files";
import { getUserDataFromLocalStorage } from "../../utils/useLocalStorage";

interface PendingContributionsProps {
  courseId: string;
  contributions: CourseFile[];
  onContributionUpdate: () => void;
}

const PendingContributions: React.FC<PendingContributionsProps> = ({
  courseId,
  contributions,
  onContributionUpdate,
}) => {
  const user = getUserDataFromLocalStorage();
  const [loading, setLoading] = useState(false);

  const handleAccept = async (id: string) => {
    setLoading(true);
    try {
      await acceptFileContribution(id, courseId);
      message.success("Contribution accepted successfully");
      onContributionUpdate();
    } catch (error) {
      console.error("Error accepting contribution:", error);
      message.error("Failed to accept contribution");
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async (id: string) => {
    Modal.confirm({
      title: "Reject Contribution",
      content: "Are you sure you want to reject this contribution?",
      onOk: async () => {
        setLoading(true);
        try {
          await rejectFileContribution(id, courseId);
          message.success("Contribution rejected");
          onContributionUpdate();
        } catch (error) {
          console.error("Error rejecting contribution:", error);
          message.error("Failed to reject contribution");
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
      width: '25%',
    },
    {
      title: "Contributor",
      dataIndex: "contribution_specifications.contributor_name",
      key: "contributor_name",
      width: '15%',
    },
    {
      title: `${capitalize(user.config.module_terminology)}`,
      key: "module",
      render: (_: any, record: CourseFile) => (
        <span>{record.contribution_specifications!.week_title || `${capitalize(user.config.module_terminology)} ${record.contribution_specifications!.week_index + 1}`}</span>
      ),
      width: '15%',
    },
    {
      title: "Range",
      key: "range",
      render: (_: any, record: CourseFile) => (
        <span>
          {record.specifications
            ? `${record.specifications!.startPage} - ${record.specifications!.endPage}`
            : "Entire file"}
        </span>
      ),
      width: '15%',
    },
    {
      title: "Submitted",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: string) => formatDate(text),
      width: '15%',
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: CourseFile) => (
        <Space>
          <Button
            type="primary"
            icon={<CheckOutlined />}
            onClick={() => handleAccept(record._id)}
            loading={loading}
          />
          <Button
            danger
            icon={<CloseOutlined />}
            onClick={() => handleReject(record._id)}
            loading={loading}
          />
        </Space>
      ),
      width: '15%',
    },
  ];

  const pendingContributions = contributions.filter(c => c.status === 'pending');

  return (
    <div>
      <Typography.Title level={4}>Pending Contributions</Typography.Title>
      {pendingContributions.length === 0 ? (
        <Typography.Paragraph>No pending contributions to review.</Typography.Paragraph>
      ) : (
        <Table
          dataSource={pendingContributions}
          columns={columns}
          rowKey="_id"
          pagination={{ pageSize: 5 }}
        />
      )}
    </div>
  );
};

export default PendingContributions;