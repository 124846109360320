import { useLocation } from 'react-router-dom';
import { Container, SignupFormContainer, TopImages } from '../../components/session-styles';
import SignupForm from '../../components/signup/signup-form';
import { AbsoluteCenter } from '../../components/styles';

export interface SignupProps {
    loginCallback: () => void;
    isDarkMode: boolean;
    toggleTheme: () => void;
    referralCode: string | null;
    isTechnical: boolean | null;
    isKeyAccount: boolean | null;
}

const SignupScreen: React.FC<SignupProps> = ({ loginCallback, isDarkMode, toggleTheme }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const referralCode = queryParams.get('referral_code');
    const isKeyAccount = queryParams.get('isKeyAccount') === 'true';
    localStorage.removeItem('isKeyAccount')
    const isTechnical = queryParams.get('isTechnical') === 'true';
    localStorage.removeItem('isTechnicalSignup')
    const isInfolabUserType1 = queryParams.get('isInfolabUserType1') === 'true';
    const isInfolabUserType2 = queryParams.get('isInfolabUserType2') === 'true';
    localStorage.setItem('isInfolabUserType1', isInfolabUserType1 ? 'true' : 'false');
    localStorage.setItem('isInfolabUserType2', isInfolabUserType2 ? 'true' : 'false');
    const isHuuhPayAsYouGo = queryParams.get('isHuuhPayAsYouGo') === 'true';
    const isHuuhBundle1 = queryParams.get('isHuuhBundle1') === 'true';
    const isHuuhBundle2 = queryParams.get('isHuuhBundle2') === 'true';
    localStorage.setItem('isHuuhPayAsYouGo', isHuuhPayAsYouGo ? 'true' : 'false');
    localStorage.setItem('isHuuhBundle1', isHuuhBundle1 ? 'true' : 'false');
    localStorage.setItem('isHuuhBundle2', isHuuhBundle2 ? 'true' : 'false');
    
    console.log("SignupScreen is rendering");
    return (
        <>
            <TopImages />
            <AbsoluteCenter>
                <Container>
                    <SignupFormContainer>
                        <SignupForm
                            loginCallback={loginCallback}
                            isDarkMode={isDarkMode}
                            toggleTheme={toggleTheme}
                            referralCode={referralCode}
                            isTechnical={isTechnical}
                            isKeyAccount={isKeyAccount}
                        />
                    </SignupFormContainer>
                </Container>
            </AbsoluteCenter>
        </>
    );
};

export default SignupScreen;