import React from 'react';
import { Typography, Tag, Space, Switch, Button, Upload } from 'antd';
import { LockOutlined, TeamOutlined, ReadOutlined, UploadOutlined } from '@ant-design/icons';
import { Community } from '../../services/communities';

interface CommunityInfoProps {
  community: Community;
  isLeader?: boolean;
  onNameChange?: (name: string) => void;
  onDescriptionChange?: (description: string) => void;
  onPrivacyChange?: (isPrivate: boolean) => void;
  directBannerUrl?: string;
  uploadingBanner?: boolean;
  onBannerUpload?: (file: File) => Promise<boolean | void>;
}

export const CommunityInfo: React.FC<CommunityInfoProps> = ({
  community,
  isLeader = false,
  onNameChange,
  onDescriptionChange,
  onPrivacyChange,
  directBannerUrl,
  uploadingBanner = false,
  onBannerUpload,
}) => {
  return (
    <div>
      <Typography.Title
        level={1}
        style={{ marginTop: 0 }}
        editable={isLeader ? {
          onChange: onNameChange,
          tooltip: 'Click to edit community name',
        } : false}
      >
        {community.name}
      </Typography.Title>
      <Space align="center">
        <Tag icon={<TeamOutlined />} color="processing">{community.member_count || 0} members</Tag>
        <Tag icon={<ReadOutlined />}>{community.project_count || 0} projects</Tag>
        <Tag icon={<ReadOutlined />}>{community.course_count || 0} courses</Tag>
        {isLeader && (
          <Space style={{ marginLeft: 8 }}>
            <Switch
              checkedChildren="Private"
              unCheckedChildren="Public"
              checked={community.config?.private}
              onChange={onPrivacyChange}
            />
            {isLeader && !directBannerUrl && onBannerUpload && (
              <Upload
                name="banner"
                showUploadList={false}
                beforeUpload={onBannerUpload}
                accept="image/*"
              >
                <Button 
                  icon={<UploadOutlined />} 
                  loading={uploadingBanner}
                  style={{ marginLeft: 8 }}
                  type='text'
                >
                  Banner
                </Button>
              </Upload>
            )}
          </Space>
        )}
      </Space>
      <Typography.Paragraph
        style={{ marginTop: '10px' }}
        editable={isLeader ? {
          onChange: onDescriptionChange,
          tooltip: 'Click to edit description',
        } : false}
      >{community.description}
      </Typography.Paragraph>

      {community.banner_image && (
        <div
          style={{
            height: 120,
            backgroundImage: `url(${community.banner_image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            marginBottom: 20,
            borderRadius: 8
          }}
        />
      )}
    </div>
  );
};

export default CommunityInfo;