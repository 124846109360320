import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { authedAxios } from "../services/auth-axios";
import { ChatServiceHookReturn, Conversation, Message } from "../types/chat";

export default function useChat(
    chatID: string,
    conversation?: Conversation,
    endpointUrl?: string,
    initialMessageText?: string
): ChatServiceHookReturn {
    const [messages, setMessages] = useState<Message[]>([]);
    const [messageLoadingStates, setMessageLoadingStates] = useState<boolean[]>([]);
    const [input, setInput] = useState<string>("");
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (!conversation) return;

        let initialMessage = {
            state_id: uuidv4(),
            msg_id: uuidv4(),
            user: "system",
            text: initialMessageText || `Hello, how are you?\n\nWhat would like to learn today?`,
            sources: [],
            timestamp: new Date().toISOString()
        } as Message;

        setMessages(
            (conversation.messages && conversation.messages.length > 0)
                ? conversation.messages
                : [initialMessage]
        );

        setMessageLoadingStates(
            (conversation.messages && conversation.messages.length > 0)
                ? new Array(conversation.messages.length).fill(false)
                : [false]
        );

        scrollMessagesToBottom();
    }, [conversation, initialMessageText]);

    useEffect(() => {
        scrollMessagesToBottom();
    }, [messages]);

    const scrollMessagesToBottom = () => {
        if (messagesEndRef.current) {
            setTimeout(() => {
                messagesEndRef.current!.scrollIntoView({ behavior: "smooth" });
            }, 0); // Delay the scroll to ensure the DOM is updated
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            if (e.shiftKey) {
                // Allow default behavior (new line) when Shift+Enter is pressed
                return;
            } else {
                e.preventDefault(); // Prevent the default action (new line)
                sendMessage();
            }
        }
    };

    function sendMessage() {
        if (input === "" || !chatID || !endpointUrl) return;

        let new_msg_id = uuidv4();
        const newUserMessage = {
            state_id: uuidv4(),
            msg_id: new_msg_id,
            user: "user",
            text: input,
            sources: [],
            timestamp: new Date().toISOString(),
        } as Message;

        setInput("");
        setMessageLoadingStates(prev => [...prev, false, true]);
        setMessages(prevMessages => [...prevMessages, newUserMessage]);
        scrollMessagesToBottom();

        const token = localStorage.getItem('access_token');
        if (!token) {
            throw new Error("No access token available");
        }

        (async () => {
            try {
                const textDecoder = new TextDecoder();
                const res = await authedAxios.get(`/${endpointUrl}/${chatID}/chat?user_query=${encodeURIComponent(input)}`,
                    {
                        responseType: 'stream',
                        headers: {
                            'Accept': 'text/event-stream',
                        },
                        adapter: 'fetch',
                    });

                if (res.data) {
                    const reader = res.data.getReader();

                    while (true) {
                        const { value, done } = await reader.read();
                        if (done) break;

                        const text_value = textDecoder.decode(value, { stream: true });

                        // Update the message with the accumulated text
                        updateOrAddMessage(new_msg_id, text_value);
                    }
                } else {
                    throw new Error("No body");
                }
            } catch (error: any) {
                console.error('Error:', error);
                setError(error.message);
            } finally {
                setMessageLoadingStates(prev => {
                    const newStates = [...prev];
                    newStates[newStates.length - 1] = false;
                    return newStates;
                });
                scrollMessagesToBottom();
            }
        })();
    }

    function updateOrAddMessage(new_msg_id: string, new_token: string) {
        setMessages(prevMessages => {
            const lastMessage = prevMessages[prevMessages.length - 1];

            // If there's no last message or the last message is from the user,
            // create a new bot message
            if (!lastMessage || lastMessage.user === "user") {
                return [...prevMessages, {
                    state_id: new_msg_id, // Use the same ID for the entire stream
                    msg_id: new_msg_id,
                    user: 'bot',
                    text: new_token,
                    sources: [],
                    timestamp: new Date().toISOString()
                }];
            }

            // Update existing bot message
            const prevMessagesExceptLast = prevMessages.slice(0, -1);
            return [...prevMessagesExceptLast, {
                ...lastMessage,
                text: lastMessage.text + new_token
            }];
        });
    }

    async function updateRelevantSelectedTemplateName(selected_template_name: string) {
        if (!chatID) return;
        
        try {
            const response = await authedAxios.put(`/conversations/${chatID}/update_selected_template_name`,
                selected_template_name,
            );

            if (response.status !== 200) {
                throw new Error("Failed to update conversation with selected_template_name");
            }

            console.log("Conversation updated successfully:", response.data);
        } catch (error) {
            console.error("Error updating conversation:", error);
        }
    }

    const isLoadingMessages = messageLoadingStates.some(state => state);

    return {
        messages,
        setMessages,
        messageLoadingStates,
        input,
        setInput,
        sendMessage,
        handleKeyDown,
        updateRelevantSelectedTemplateName,
        messagesEndRef,
        isLoadingMessages
    };
}
