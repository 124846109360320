import React, { useState, useEffect } from 'react';
import { 
    TwitterOutlined, LinkedinOutlined, 
    GithubOutlined, GlobalOutlined 
} from '@ant-design/icons';
import { Select, Switch, Row, Col } from 'antd';
import { SocialInput, CenteredSpace, FormSection, SectionTitle, FormRow, LabelCol, InputCol } from '../styles';
import { SocialLinks, UserNetworking } from '../../../types/user';

interface SocialLinksProps {
    social_links: SocialLinks;
    networking: UserNetworking;
    onChange: (links: any) => void;
    onNetworkingUpdate: (updates: Partial<UserNetworking>) => void;
}

const SocialLinksSection: React.FC<SocialLinksProps> = ({ 
    social_links, 
    networking,
    onChange,
    onNetworkingUpdate 
}) => {
    // Add local state for immediate feedback
    const [localNetworking, setLocalNetworking] = useState(networking);

    // Sync local state with props when they change
    useEffect(() => {
        setLocalNetworking(networking);
    }, [networking]);

    const handleVisibilityChange = (value: string) => {
        const updates = {
            visibility: value,
            ...(value === 'private' && {
                open_to_mentoring: false,
                seeking_cofounders: false
            })
        };
        setLocalNetworking(prev => ({ ...prev, ...updates }));
        onNetworkingUpdate(updates);
    };

    const handleSwitchChange = (field: 'open_to_mentoring' | 'seeking_cofounders', checked: boolean) => {
        // Update local state immediately
        setLocalNetworking(prev => ({ ...prev, [field]: checked }));
        // Then update parent
        onNetworkingUpdate({ [field]: checked });
    };

    return (
        <FormSection>
            <SectionTitle>Social & Network Settings</SectionTitle>
            <Row gutter={24}>
                <Col xs={24} md={12}>
                    <SectionTitle>Social Links</SectionTitle>
                    <CenteredSpace direction="vertical" style={{ width: '100%' }}>
                        <SocialInput
                            prefix={<TwitterOutlined />}
                            value={social_links.twitter}
                            onChange={(e) => onChange({ ...social_links, twitter: e.target.value })}
                            placeholder="Twitter URL"
                        />
                        <SocialInput
                            prefix={<LinkedinOutlined />}
                            value={social_links.linkedin}
                            onChange={(e) => onChange({ ...social_links, linkedin: e.target.value })}
                            placeholder="LinkedIn URL"
                        />
                        <SocialInput
                            prefix={<GithubOutlined />}
                            value={social_links.github}
                            onChange={(e) => onChange({ ...social_links, github: e.target.value })}
                            placeholder="GitHub URL"
                        />
                        <SocialInput
                            prefix={<GlobalOutlined />}
                            value={social_links.website}
                            onChange={(e) => onChange({ ...social_links, website: e.target.value })}
                            placeholder="Personal Website"
                        />
                    </CenteredSpace>
                </Col>
                <Col xs={24} md={12}>
                    <SectionTitle>Network Settings</SectionTitle>
                    <CenteredSpace direction="vertical" style={{ width: '100%' }}>
                        <FormRow>
                            <LabelCol span={8}>Profile Visibility</LabelCol>
                            <InputCol span={16}>
                                <Select
                                    value={localNetworking.visibility}
                                    onChange={handleVisibilityChange}
                                    style={{ width: '100%' }}
                                >
                                    <Select.Option value="public">Public</Select.Option>
                                    <Select.Option value="communities">Communities</Select.Option>
                                    <Select.Option value="private">Private</Select.Option>
                                </Select>
                            </InputCol>
                        </FormRow>
                        <FormRow>
                            <LabelCol span={8}>Open to Mentoring</LabelCol>
                            <InputCol span={16}>
                                <Switch 
                                    checked={localNetworking.open_to_mentoring}
                                    onChange={(checked) => handleSwitchChange('open_to_mentoring', checked)}
                                    disabled={localNetworking.visibility === 'private'}
                                />
                            </InputCol>
                        </FormRow>
                        <FormRow>
                            <LabelCol span={8}>Seeking Co-founders</LabelCol>
                            <InputCol span={16}>
                                <Switch 
                                    checked={localNetworking.seeking_cofounders}
                                    onChange={(checked) => handleSwitchChange('seeking_cofounders', checked)}
                                    disabled={localNetworking.visibility === 'private'}
                                />
                            </InputCol>
                        </FormRow>
                    </CenteredSpace>
                </Col>
            </Row>
        </FormSection>
    );
};

export default SocialLinksSection;