import { MessageBubble } from "./MessageBubble";
import { MessagesContainer } from "../../styles/chat";
import { ChatContentProps } from "../../types/chat";

const ChatContent: React.FC<ChatContentProps> = ({
    conversation,
    messages,
    messageLoadingStates,
    messagesEndRef,
    chatTypeSpecifier
}) => {
    return (
        <MessagesContainer>
            {messages.map((msg, index) => (
                <MessageBubble
                    key={msg.state_id}
                    conversationId={conversation?._id || ''}
                    msgId={msg.msg_id}
                    user={msg.user}
                    text={msg.text}
                    sources={msg.sources}
                    type={chatTypeSpecifier}
                    isLoading={messageLoadingStates[index]}
                    reaction={msg.reaction}
                />
            ))}
            <div ref={messagesEndRef} />
        </MessagesContainer>
    );
};

export default ChatContent;
