import React from 'react';
import { Modal, Typography, Button, Space, Divider } from 'antd';
import { CloseOutlined, FileSearchOutlined } from '@ant-design/icons';
import { SourceItem } from './SourceButton';
import SourceButton from './SourceButton';

const { Title } = Typography;

interface SourcesModalProps {
  isOpen: boolean;
  onClose: () => void;
  sources: SourceItem[];
  conversationId: string;
}

const SourcesModal: React.FC<SourcesModalProps> = ({ 
  isOpen, 
  onClose, 
  sources, 
  conversationId 
}) => {
  return (
    <Modal
      title={
        <Space>
          <FileSearchOutlined />
          <Title level={4} style={{ margin: 0 }}>Sources ({sources.length})</Title>
        </Space>
      }
      open={isOpen}
      onCancel={onClose}
      footer={
        <></>
      }
      width={'33%'}
      style={{ minWidth: '400px', maxWidth: '800px' }}
      destroyOnClose={true}
    >
      <div style={{ maxHeight: '70vh', overflowY: 'auto', padding: '0 10px' }}>
        {sources.length > 0 ? (
          <>
            <Divider style={{ margin: '0 0 16px 0' }}>
              <Typography.Text type="secondary">
                Click any source to download the original document
              </Typography.Text>
            </Divider>
            {sources.map((source, index) => (
              <div key={`${source.fileId}-${index}`} style={{ marginBottom: '16px' }}>
                <SourceButton
                  conversationId={conversationId}
                  {...source}
                />
              </div>
            ))}
          </>
        ) : (
          <p>No sources available for this message.</p>
        )}
      </div>
    </Modal>
  );
};

export default SourcesModal;
