import React from 'react';
import {
    Input, Button, Space, Typography,
    Divider, Tooltip, message
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { FormSection, FormRow, LabelCol, InputCol, SectionTitle } from '../styles';

const { Text } = Typography;

interface AccountSettingsSectionProps {
    newUserEmail: string;
    setNewUserEmail: (email: string) => void;
    onPasswordChange: () => void;
    referralLink: string;
    referralCount: number;
}

const AccountSettingsSection: React.FC<AccountSettingsSectionProps> = ({
    newUserEmail,
    setNewUserEmail,
    onPasswordChange,
    referralLink,
    referralCount
}) => {
    const handleCopyReferralLink = () => {
        navigator.clipboard.writeText(referralLink)
            .then(() => message.success('Referral link copied!'))
            .catch(() => message.error('Failed to copy link'));
    };

    return (
        <FormSection>
            <SectionTitle>Account Settings</SectionTitle>
            <Space direction="vertical" style={{width: '100%'}}>
                <FormRow>
                    <LabelCol span={6}>Email Address</LabelCol>
                    <InputCol span={18}>
                        <Input
                            value={newUserEmail}
                            onChange={(e) => setNewUserEmail(e.target.value)}
                            style={{maxWidth: 300}}
                        />
                    </InputCol>
                </FormRow>

                <FormRow>
                    <LabelCol span={6}>Password</LabelCol>
                    <InputCol span={18}>
                        <Button type="primary" onClick={onPasswordChange}>
                            Change Password
                        </Button>
                    </InputCol>
                </FormRow>

                <Divider />

                <FormRow>
                    <LabelCol span={6}>Referral Program</LabelCol>
                    <InputCol span={18} style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                        <Text>You have referred {referralCount} users</Text>
                        <Space.Compact style={{width: '100%', maxWidth: 400}}>
                            <Input
                                value={referralLink}
                                readOnly
                            />
                            <Tooltip title="Copy referral link">
                                <Button
                                    icon={<CopyOutlined/>}
                                    onClick={handleCopyReferralLink}
                                />
                            </Tooltip>
                        </Space.Compact>
                    </InputCol>
                </FormRow>
            </Space>
        </FormSection>
    );
};

export default AccountSettingsSection;