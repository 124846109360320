import React from 'react';
import { Modal, Space, Input, message } from 'antd';

interface PasswordChangeModalProps {
    isVisible: boolean;
    onClose: () => void;
    oldPassword: string;
    setOldPassword: (password: string) => void;
    newPassword: string;
    setNewPassword: (password: string) => void;
    confirmPassword: string;
    setConfirmPassword: (password: string) => void;
    userId: string;
    updatePassword: (userId: string, passwords: { old_password: string; new_password: string }) => Promise<boolean>;
}

const PasswordChangeModal: React.FC<PasswordChangeModalProps> = ({
    isVisible,
    onClose,
    oldPassword,
    setOldPassword,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    userId,
    updatePassword
}) => {
    const handleOk = async () => {
        if (newPassword !== confirmPassword) {
            message.error("New passwords do not match!");
            return;
        }
        try {
            await updatePassword(userId, {
                old_password: oldPassword,
                new_password: newPassword
            });
            message.success("Password updated successfully!");
            onClose();
        } catch (error) {
            message.error("Failed to update password. Please try again.");
        }
    };

    const handleCancel = () => {
        onClose();
    };

    return (
        <Modal
            title="Change Password"
            open={isVisible}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Space direction="vertical" style={{width: '100%'}}>
                <Input.Password
                    placeholder="Current Password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                />
                <Input.Password
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <Input.Password
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
            </Space>
        </Modal>
    );
};

export default PasswordChangeModal;