import { Typography, theme, Button } from 'antd';
import React from 'react';
import { SettingOutlined, CloseOutlined, UploadOutlined, MessageFilled } from '@ant-design/icons';
import { hexToRGBA } from '../../utils/utils';
import { newConversation } from '../../services/conversations';
import { getUserDataFromLocalStorage, setUserDataInLocalStorage } from '../../utils/useLocalStorage';
import { useNavigate } from 'react-router-dom';
import { admin_type, autodidact_type, marketpalce_admin_type, professor_type, technical_admin_type } from '../../services/user';
import { ButtonContainer, CardContent, ContentContainer, DescriptionContainer, InnerCard, StyledCard, StyledNoMarginButton, TitleContainer } from '../../styles/course-card';
import AnimatedTitle from './animated-title';


const maxLengthDescription = 100; //chars

interface AutodidactCourseCardProps {
    id: string;
    title: string;
    description: string;
    lastUpdated: string;
    professors: { _id: string; name: string; }[];
    removable?: boolean;
    onRemove?: (id: string) => void;
    chatButtonRef?: React.RefObject<HTMLButtonElement | null>;
    editButtonRef?: React.RefObject<HTMLButtonElement | null>;
    contributeButtonRef?: React.RefObject<HTMLButtonElement | null>;
}

const AutodidactCourseCard: React.FC<AutodidactCourseCardProps> = ({
    id,
    title,
    description,
    lastUpdated,
    professors,
    removable,
    onRemove,
    chatButtonRef,
    editButtonRef,
    contributeButtonRef
}) => {
    const { token } = theme.useToken();
    const navigate = useNavigate();
    const user = getUserDataFromLocalStorage();

    const handleRemoveCourse = () => {
        if (onRemove) onRemove(id);
    };

    const date = new Date(lastUpdated);
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;

    const handleNewConversation = () => {
        newConversation(user._id, "content", id).then(chat => {
            if (!user.course_id_list.includes(id)) {
                const updatedUser = {
                    ...user,
                    course_id_list: [...(user.course_id_list || []), id]
                };
                setUserDataInLocalStorage(updatedUser);
            }
            navigate("/chat/" + chat._id);
        });
    };

    return (
        <StyledCard
            style={{  
                minHeight: '310px', 
                maxHeight: '310px',
                background: token.colorBgElevated
            }}
        >
            <CardContent>
                <div style={{ position: 'relative' }}>
                    <TitleContainer>
                        <AnimatedTitle title={title} color={token.colorInfoText} />
                    </TitleContainer>
                </div>
                <ContentContainer
                    style={{ minHeight: '220px', maxHeight: '220px' }}
                >
                    <Typography.Paragraph style={{ 
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        color: token.colorTextSecondary 
                        }}>
                            {description.length > maxLengthDescription 
                                ? description.slice(0, maxLengthDescription).split(' ').slice(0, -1).join(' ') + "..." 
                                : description}
                    </Typography.Paragraph>
                    <div>
                        <InnerCard>
                            <p style={{ color: token.colorTextSecondary }}>Last Update</p>
                            <p style={{ color: token.colorTextSecondary }}>{formattedDate}</p>
                        </InnerCard>
                        <ButtonContainer>
                            {[professor_type, autodidact_type, admin_type, technical_admin_type, marketpalce_admin_type].includes(user.type)
                            && professors.map((professor) => professor._id).includes(user._id) ? (
                                <>
                                    <Button 
                                        ref={chatButtonRef}
                                        type="primary" 
                                        style={{ flex: 1 }}
                                        icon={<MessageFilled />}
                                        onClick={handleNewConversation}
                                    >
                                        Chat
                                    </Button>
                                    <Button 
                                        ref={editButtonRef}
                                        style={{ flex: 1 }}
                                        icon={<SettingOutlined />}
                                        onClick={() => navigate(`/courses/${id}`)}
                                    >
                                        Edit
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button 
                                        ref={chatButtonRef}
                                        type="primary" 
                                        style={{ flex: 1 }}
                                        icon={<MessageFilled />}
                                        onClick={handleNewConversation}
                                    >
                                        Chat
                                    </Button>
                                    <Button 
                                        ref={contributeButtonRef}
                                        style={{ flex: 1 }}
                                        icon={<UploadOutlined />}
                                        onClick={() => navigate(`/courses/contribute/${id}`)}
                                    >
                                        Contribute
                                    </Button>
                                </>
                            )}
                        </ButtonContainer>
                    </div>
                </ContentContainer>
            </CardContent>
            {removable && (
                <StyledNoMarginButton
                    onClick={handleRemoveCourse}
                >
                    <CloseOutlined
                        style={{
                            fontSize: '1.2rem',
                        }}
                    />
                </StyledNoMarginButton>
            )}
        </StyledCard>
    );
};

export default AutodidactCourseCard;