import { authedAxios } from './auth-axios';

export interface InvoiceInfo {
  id: string;
  plan: string;
  canceled: boolean;
  cancellation_date: string;
  next_billing_date: number;
  amount: number;
}

export interface SubscriptionInfo {
  id: string;
  plan: string;
  status: string;
  start_date: string;
  renewal_date: string;
}

export interface PlanSwitchRequest {
  new_plan_type: 'pay_as_you_go' | 'monthly';
  message_bundle?: number;
  payment_method_id?: string | null;
  set_as_default?: boolean;
}

export const getInvoiceInfo = async (userId: string): Promise<InvoiceInfo[]> => {
  try {
    const response = await authedAxios.get(`${process.env.REACT_APP_BACKEND_URL}/subscription/invoices/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching subscription info:', error);
    throw error;
  }
};

export const cancelSubscription = async (userId: string, subscriptionId: string): Promise<boolean> => {
  try {
    const response = await authedAxios.delete(`${process.env.REACT_APP_BACKEND_URL}/subscription/${userId}/${subscriptionId}`);
    return response.status === 200;
  } catch (error) {
    console.error('Error cancelling subscription:', error);
    throw error;
  }
};

export const switchPlan = async (userId: string, request: PlanSwitchRequest): Promise<boolean> => {
  try {
    const response = await authedAxios.post(
      `${process.env.REACT_APP_BACKEND_URL}/subscription/switch-plan/${userId}`, 
      request
    );
    return response.status === 200;
  } catch (error) {
    console.error('Error switching plan:', error);
    throw error;
  }
};

export const getCustomerPaymentMethods = async (userId: string): Promise<any[]> => {
  try {
    const response = await authedAxios.get(
      `${process.env.REACT_APP_BACKEND_URL}/subscription/payment-methods/${userId}`
    );
    return response.data.payment_methods || [];
  } catch (error) {
    console.error('Error fetching payment methods:', error);
    throw error;
  }
};

export const getActiveSubscriptions = async (userId: string): Promise<SubscriptionInfo[]> => {
  try {
    const response = await authedAxios.get(`${process.env.REACT_APP_BACKEND_URL}/subscription/active/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching active subscriptions:', error);
    throw error;
  }
};