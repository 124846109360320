import { styled } from "@stitches/react"
import { Flex } from "../components/styles"

export const Container = styled(Flex, {
    flexDirection: 'column',
    gap: "4rem",
})

export const LoginFormContainer = styled(Flex, {
    width: "50vh",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',

})

export const SignupFormContainer = styled(Flex, {
    width: "100%",  // Take full width of parent
    height: "100vh", // Take full viewport height
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
})


export const ImageContainer = styled(Flex, {
    position: "absolute",
    top: "20px",
    width: "100%",
    flexDirection: 'row',
    gap: "2rem"
})
export const TopImages = () => {
    return (
        <ImageContainer>
            {window.location.origin.includes("huuh.me") ? <img src="/huuh192.png" alt="Infolab" height="60" /> : <img src="/logo192.png" alt="Infolab" height="60" />}
        </ImageContainer>
    )
}

export const AtlaLogo = () => {
    return (
        <img 
        src="/atla_logo_192x192.png" 
        alt="Atla" 
        height="30"
        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
        />
    )
}
export const SonaLogo = () => {
    return (
        <img 
        src="/sona_logo_192x192.png" 
        alt="Sona" 
        height="30"
        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
        />
    )
}
export const Title = styled('h1', {
    marginBottom: "0.3rem"
})
export const Subtitle = styled('p', {
    marginTop: "0",
    marginBottom: "2rem",
    color: "gray"
})