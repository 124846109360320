import {
    FolderOutlined, HistoryOutlined, LeftOutlined, LockOutlined, QuestionCircleOutlined,
    RightOutlined, UserOutlined, SunOutlined, SunFilled, FormOutlined, ContactsOutlined, ArrowsAltOutlined,
    DollarOutlined,
    SettingOutlined,
    ShareAltOutlined,
    FileTextOutlined,
    DeploymentUnitOutlined,
    MessageOutlined, CompassOutlined,
    SearchOutlined,
    HomeOutlined
} from '@ant-design/icons';
import { styled } from '@stitches/react';
import { Layout, Menu, message, Tour, TourProps } from 'antd';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import QuickSupportModal from './quick-support-modal';
import { getUserDataFromLocalStorage, setUserDataInLocalStorage } from '../utils/useLocalStorage';
import { capitalize } from '../utils/utils';
import { siderWidthOpened } from '../config';
import { useUnsavedChanges } from '../contexts/UnsavedChangesContext';
import NavigationGuard from './NavigationGuard';
import { admin_type, autodidact_type, marketpalce_admin_type, professor_type, student_type, technical_admin_type, technical_type, update_last_updated } from '../services/user';
import { newConversation } from '../services/conversations';
import { darkLayout } from '@nextui-org/react';

const { Sider } = Layout;
const collapsedSiderWidth = 100;

const TopContainer = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '3rem'
})
const LogoContainer = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})
const Logo = styled('img', {
    height: "80px",
    padding: '1rem',
})

const StyledMenu = styled(Menu, {
    width: '100%',
    // Override with !important to ensure the right side border is removed
    '&&': {
        border: 'none !important',
        borderRight: 'none !important',
        borderInlineEnd: 'none !important',
    }
})

const StyledSider = styled(Sider, {
    "& > .ant-layout-sider-children": {
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    height: '100vh',
    overflow: 'auto',
    position: 'fixed !important',
    left: 0,
    top: 0,
    bottom: 0,
    borderRight: 'none',
})

export default function Sidebar({ onLogout, children, isDarkMode, toggleTheme, isDesktopPortraitView }: {
    onLogout: () => void;
    children: React.ReactNode;
    isDarkMode: boolean;
    toggleTheme: () => void;
    isDesktopPortraitView: boolean;
}) {
    const navigate = useNavigate();
    const [targetPath, setTargetPath] = React.useState<string | null>(null);
    const { hasUnsavedChanges } = useUnsavedChanges();
    const [logoData, setLogoData] = React.useState<string | undefined>(undefined);
    const [headerCollapsed, setHeaderCollapsed] = React.useState<boolean>(false);
    const [quickSupportModalOpen, setQuickSupportModalOpen] = React.useState(false);
    const [openTour, setOpenTour] = React.useState(false);
    const [tourSteps, setTourSteps] = React.useState<TourProps['steps']>([]);

    const location = useLocation();
    const currentPath = location.pathname;
    const activeTab = `/${currentPath.split("/")[1]}`;
    const user = getUserDataFromLocalStorage();
    const referralLink = `${window.location.origin}/signup?referral_code=${user.referrals.code}`;

    const getMenuItemDescription = (key: string): string => {
        const descriptions: Record<string, string> = {
            '/home': 'View all your projects and courses in one place',
            '/marketplace': 'Explore the broader universe of content',
            '/templates': 'Access and manage your templates',
            '/chat-history': 'Review and rejoin your past conversations',
            '/assigned': 'Manage and monitor your students',
            '/administration': 'Access administrative tools and settings',
        };
        return descriptions[key] || '';
    };

    const generateTourSteps = () => {
        const steps: TourProps['steps'] = [
            {
                title: 'Welcome!',
                description: 'Let us show you around your new workspace.',
                target: () => document.querySelector('.ant-layout-sider') as HTMLElement,
                placement: 'right',
            }
        ];

        // Add menu items based on user type
        const menuItems = getItems();
        menuItems.forEach((item: any) => {
            steps.push({
                title: item.label,
                description: getMenuItemDescription(item.key),
                target: () => document.querySelector(`[data-menu-id*="${item.key}"]`) as HTMLElement,
                placement: 'right',
            });
        });

        // Add profile section
        steps.push({
            title: 'Your Profile',
            description: 'Access your profile settings and manage your account.',
            target: () => document.querySelector('[data-menu-id*="/profile"]') as HTMLElement,
            placement: 'right',
        });

        // Add community features (final step)
        steps.push({
            title: 'Join Our Community',
            description: 'Connect with others through our WhatsApp group and invite friends to join the platform!',
            target: () => document.querySelector('[data-menu-id*="whatsapp_group"]') as HTMLElement,
            placement: 'right',
            nextButtonProps: { children: 'Finish Tour' },
        });

        setTourSteps(steps);
    };

    useEffect(() => {
        if (user.created_at === user.last_updated || localStorage.getItem('autodidactOnboardingFinished') === 'true') {
            generateTourSteps();
            setOpenTour(true);
        }
    }, [user.created_at, user.last_updated]);

    useEffect(() => {
        if (isDesktopPortraitView) {
            setHeaderCollapsed(true);
        } else {
            setHeaderCollapsed(false);
        }
    }, [isDesktopPortraitView]);

    const handleMenuClick = (path: string) => {
        if (hasUnsavedChanges && path !== location.pathname) {
            setTargetPath(path);
        } else {
            navigate(path);
        }
    };
    
  const handleNewDiscoverConversation = () => {
    newConversation(user._id, "discover").then(chat => {
        navigate("/discover/" + chat._id);
    });
}

    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralLink).then(() => {
            message.success('Referral link copied to clipboard!');
        }).catch(err => {
            message.error('Failed to copy referral link.');
        });
    };

    function getItems() {
        if ([technical_type].includes(user.type)) {
            return [
                { key: "/home", icon: <FolderOutlined />, label: `${capitalize(user.config.course_terminology)}s`, onClick: () => handleMenuClick("/home") }
            ]
        }
        if ([student_type].includes(user.type)) {
            return [
                { key: "/home", icon: <FolderOutlined />, label: `${capitalize(user.config.course_terminology)}s`, onClick: () => handleMenuClick("/home") },
                { key: "/templates", icon: <ArrowsAltOutlined />, label: "Templates", onClick: () => handleMenuClick("/templates") },
                { key: "/chat-history", icon: <HistoryOutlined />, label: "Chat History", onClick: () => handleMenuClick("/chat-history") },
            ]
        }
        if ([autodidact_type].includes(user.type)) {
            return [
                { key: "/home", icon: <HomeOutlined />, label: "Home", onClick: () => handleMenuClick("/home") },
                { key: "/discover", icon: <SearchOutlined />, label: `Discover`, onClick: () => handleNewDiscoverConversation() },
                { key: "/marketplace", icon: <DeploymentUnitOutlined />, label: "Marketplace", onClick: () => handleMenuClick("/marketplace") },
                { key: "/templates", icon: <ArrowsAltOutlined />, label: "Templates", onClick: () => handleMenuClick("/templates") },
                { key: "/chat-history", icon: <HistoryOutlined />, label: "Chat History", onClick: () => handleMenuClick("/chat-history") },
            ]
        }
        if (user.type === professor_type) {
            return [
                { key: "/home", icon: <FolderOutlined />, label: `${capitalize(user.config.course_terminology)}s`, onClick: () => handleMenuClick("/home") },
                { key: "/templates", icon: <ArrowsAltOutlined />, label: "Templates", onClick: () => handleMenuClick("/templates") },
                { key: "/chat-history", icon: <HistoryOutlined />, label: "Chat History", onClick: () => handleMenuClick("/chat-history") },
                { key: "/assigned ", icon: <ContactsOutlined />, label: `Assigned ${capitalize(user.config.student_terminology)}s`, onClick: () => handleMenuClick("/assigned") },
            ]
        }
        if ([marketpalce_admin_type].includes(user.type)) {
            return [
                { key: "/home", icon: <HomeOutlined />, label: "Home", onClick: () => handleMenuClick("/home") },
                { key: "/marketplace", icon: <DeploymentUnitOutlined />, label: "Marketplace", onClick: () => handleMenuClick("/marketplace") },
                { key: "/templates", icon: <ArrowsAltOutlined />, label: "Templates", onClick: () => handleMenuClick("/templates") },
                { key: "/chat-history", icon: <HistoryOutlined />, label: "Chat History", onClick: () => handleMenuClick("/chat-history") },
                { key: "/administration", icon: <FormOutlined />, label: "Administration", onClick: () => handleMenuClick("/administration") },
            ]
        }
        if ([technical_admin_type].includes(user.type)) {
            return [
                { key: "/home", icon: <FolderOutlined />, label: `${capitalize(user.config.course_terminology)}s`, onClick: () => handleMenuClick("/home") },
                { key: "/templates", icon: <ArrowsAltOutlined />, label: "Templates", onClick: () => handleMenuClick("/templates") },
                { key: "/chat-history", icon: <HistoryOutlined />, label: "Chat History", onClick: () => handleMenuClick("/chat-history") },
                { key: "/administration", icon: <FormOutlined />, label: "Administration", onClick: () => handleMenuClick("/administration") },
            ]
        }
        if ([admin_type].includes(user.type)) {
            return [
                { key: "/home", icon: <HomeOutlined />, label: 'Courses', onClick: () => handleMenuClick("/home") },
                { key: "/marketplace", icon: <DeploymentUnitOutlined />, label: "Marketplace", onClick: () => handleMenuClick("/marketplace") },
                { key: "/templates", icon: <ArrowsAltOutlined />, label: "Templates", onClick: () => handleMenuClick("/templates") },
                { key: "/chat-history", icon: <HistoryOutlined />, label: "Chat History", onClick: () => handleMenuClick("/chat-history") },
                { key: "/assigned", icon: <ContactsOutlined />, label: `Assigned ${capitalize(user.config.student_terminology)}s`, onClick: () => handleMenuClick("/assigned") },
                { key: "/administration", icon: <FormOutlined />, label: "Administration", onClick: () => handleMenuClick("/administration") },
            ]
        }
        else {
            return []
        }
    }

    const startTour = () => {
        generateTourSteps();
        setOpenTour(true);
    };

    useEffect(() => {
        if (isDarkMode && user.config.logo_dark !== "") {
            setLogoData(user.config.logo_dark)
        } else {
            setLogoData(user.config.logo)
        }
    }, [user.config.logo, isDarkMode])

    return (
        <Layout style={{ minHeight: '100vh' }}>
            {targetPath && (
                <NavigationGuard
                    onNavigate={() => setTargetPath(null)}
                    targetPath={targetPath}
                />
            )}
            <StyledSider
                trigger={null}
                collapsible
                collapsed={headerCollapsed}
                onCollapse={(value) => setHeaderCollapsed(value)}
                width={siderWidthOpened}
                collapsedWidth={collapsedSiderWidth}
            >
                <div>
                    <TopContainer>
                        <LogoContainer><Logo
                            src={logoData}
                            alt={user.config.organization} />
                        </LogoContainer>
                    </TopContainer>
                    <StyledMenu
                        selectedKeys={[activeTab]}
                        mode="vertical"
                        items={getItems()}
                    />
                </div>
                <StyledMenu
                    selectedKeys={[activeTab]}
                    mode="vertical"
                    items={[
                        {
                            key: "/profile",
                            icon: <UserOutlined />,
                            label: "Profile",
                            children: [
                                ...(user.type !== student_type && user.type !== technical_type ? [{
                                    key: "/billing",
                                    icon: <DollarOutlined />,
                                    label: "Billing",
                                    onClick: () => handleMenuClick("/billing")
                                }] : []),
                                {
                                    key: "quick-support",
                                    icon: <QuestionCircleOutlined
                                        twoToneColor="blue" />,
                                    label: "Get Quick Support",
                                    onClick: () => setQuickSupportModalOpen(true)
                                },
                                ...(user.type !== technical_type ? [{
                                    key: "/settings",
                                    icon: <SettingOutlined />,
                                    label: "Settings",
                                    onClick: () => handleMenuClick("/settings")
                                }] : []),
                                {
                                    key: "/changelog",
                                    icon: <FileTextOutlined />,
                                    label: "Updates",
                                    onClick: () => handleMenuClick("/changelog")
                                },
                                {
                                    key: "start-tour",
                                    icon: <CompassOutlined />,
                                    label: "Start Tour",
                                    onClick: startTour
                                },
                                {
                                    key: "/logout",
                                    icon: <LockOutlined />,
                                    label: "Log out",
                                    onClick: onLogout
                                },
                            ]
                        },
                        {
                            key: "whatsapp_group",
                            icon: <MessageOutlined />,
                            label: "Join WhatsApp Group",
                            onClick: () => window.open("https://chat.whatsapp.com/HN2jIKlap9RGAkuFIcrzf6", "_blank")
                        },
                        {
                            key: "share",
                            icon: <ShareAltOutlined />,
                            label: "Refer a Friend",
                            onClick: copyToClipboard
                        },
                        {
                            key: "theme_toggle",
                            icon: isDarkMode ? <SunOutlined style={{ color: 'white' }} /> : <SunFilled style={{ color: 'black' }} />,
                            label: "Switch Theme",
                            onClick: () => toggleTheme()
                        },
                        {
                            key: "collapse",
                            icon: headerCollapsed ? <RightOutlined /> : <LeftOutlined />,
                            onClick: () => setHeaderCollapsed(prev => !prev),
                            label: null,
                            title: "",
                            style: { textAlign: 'center' }
                        },
                    ]} />
            </StyledSider>
            <Layout
                data-testid="main-content"
                className="site-layout"
                style={{
                    transition: '0.2s ease-in-out',
                    marginLeft: headerCollapsed ? collapsedSiderWidth : siderWidthOpened
                }}>
                {children}
                <QuickSupportModal
                    isOpen={quickSupportModalOpen}
                    setIsOpen={setQuickSupportModalOpen} />
                <Tour
                    open={openTour}
                    onClose={() => {
                        user.last_updated = new Date().toISOString();
                        setUserDataInLocalStorage(user);
                        update_last_updated(user._id)
                        localStorage.removeItem('autodidactOnboardingFinished')
                        setOpenTour(false);
                    }}
                    steps={tourSteps}
                />
            </Layout>
        </Layout>
    );
}