import React, { useState } from 'react';
import { Steps, Button, message } from 'antd';
import { getUserDataFromLocalStorage, setUserDataInLocalStorage } from '../../utils/useLocalStorage';
import { updateUserProfile, update_last_updated } from '../../services/user';
import { PortfolioItem, SocialLinks } from '../../types/user';
import { styled } from '@stitches/react';
import BioStep from './steps/BioStep';
import SocialLocationStep from './steps/SocialLocationStep';
import PortfolioStep from './steps/PortfolioStep';
import { SunOutlined, SunFilled } from '@ant-design/icons';

const Container = styled('div', {
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden',
});

const StepsContainer = styled('div', {
  padding: '1rem 0',
  marginBottom: '2rem',
  zIndex: 10,
  display: 'flex',
  justifyContent: 'center',
  '& .ant-steps': {
    width: '66%',
    maxWidth: '800px',
  }
});

const ContentContainer = styled('div', {
  flex: 1,
  padding: '0 1rem',
  overflowY: 'auto',
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '4.5rem',
  '& > div': {
    maxWidth: '800px',
    width: '66%',
  },
});

const ButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  padding: '0.75rem 1rem',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 10,
  '& > div': {
    display: 'flex',
    justifyContent: 'space-between',
    width: '66%',
    maxWidth: '800px',
  }
});

interface OnboardingPageProps {
  onCompleted: () => void;
  isDarkMode: boolean;
  toggleTheme: () => void;
}

const OnboardingPage: React.FC<OnboardingPageProps> = ({ onCompleted, isDarkMode, toggleTheme }) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const user = getUserDataFromLocalStorage();
  
  // Initialize form state with user data
  const [userData, setUserData] = useState({
    bio: user.bio || '',
    location: user.location || '',
    social_links: user.social_links || {
      twitter: '',
      linkedin: '',
      github: '',
      website: ''
    },
    portfolio: user.portfolio || []
  });

  const handleBioChange = (bio: string) => {
    setUserData((prev) => ({ ...prev, bio }));
  };

  const handleLocationChange = (location: string) => {
    setUserData((prev) => ({ ...prev, location }));
  };

  const handleSocialLinksChange = (social_links: SocialLinks) => {
    setUserData((prev) => ({ ...prev, social_links }));
  };

  const handlePortfolioChange = (portfolio: PortfolioItem[]) => {
    setUserData((prev) => ({ ...prev, portfolio }));
  };

  const nextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const prevStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleSaveAndFinish = async () => {
    setLoading(true);
    try {
      // Update user profile in the backend
      await updateUserProfile(user._id, {
        name: user.name,
        email: user.email,
        bio: userData.bio,
        location: userData.location,
        social_links: userData.social_links,
        portfolio: userData.portfolio,
        usage: user.usage,
      });

      // Update user data in localStorage
      const updatedUser = {
        ...user,
        bio: userData.bio,
        location: userData.location,
        social_links: userData.social_links,
        portfolio: userData.portfolio,
        last_updated: new Date().toISOString() // Update the last_updated timestamp
      };
      setUserDataInLocalStorage(updatedUser);
      
      // Update the last_updated timestamp in backend
      await update_last_updated(user._id);

      // Show success message
      message.success('Profile updated successfully!');
      
      // Call onCompleted to go to courses
      onCompleted();
    } catch (error) {
      console.error('Failed to update profile:', error);
      message.error('Failed to update profile. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleComplete = () => {
    onCompleted();
  };

  const steps = [
    {
      title: 'About You',
      content: <BioStep bio={userData.bio} onChange={handleBioChange} />
    },
    {
      title: 'Social & Location',
      content: (
        <SocialLocationStep
          location={userData.location}
          onLocationChange={handleLocationChange}
          socialLinks={userData.social_links}
          onSocialLinksChange={handleSocialLinksChange}
        />
      )
    },
    {
      title: 'Portfolio',
      content: (
        <PortfolioStep 
          portfolio={userData.portfolio}
          onPortfolioChange={handlePortfolioChange}
        />
      )
    }
  ];

  return (
    <Container>
      <StepsContainer>
        <Steps current={currentStep} items={steps.map(item => ({ title: item.title }))} />
      </StepsContainer>
      <ContentContainer>
        <div>
          {steps[currentStep].content}
        </div>
      </ContentContainer>
      <ButtonContainer>
        <div>
          {currentStep > 0 ? (
            <Button 
              onClick={prevStep} 
              style={{ marginRight: '8px', 
              }}
            >
              Previous
            </Button>
          ) : (
            <div></div>
          )}
          
          {currentStep < steps.length - 1 ? (
            <Button 
              type="primary" 
              onClick={nextStep}
            >
              Next
            </Button>
          ) : (
            <Button 
              type="primary" 
              loading={loading} 
              onClick={handleSaveAndFinish}
            >
              Save & Finish
            </Button>
          )}
        </div>
      </ButtonContainer>

      <div className="absolute bottom-4 right-4 flex items-center">
          <button
            onClick={toggleTheme}
            className={`p-2 rounded-full ${isDarkMode ? 'bg-black hover:bg-gray-800' : 'bg-white hover:bg-gray-200'
              } transition-colors border-none`}
            aria-label="Toggle theme"
          >
            {isDarkMode ? (
              <SunOutlined style={{ fontSize: '24px', color: 'white' }} />
            ) : (
              <SunFilled style={{ fontSize: '24px', color: 'black' }} />
            )}
            <p className={`${isDarkMode ? 'text-white' : 'text-black'}`}>Switch theme</p>
          </button>
        </div>
    </Container>
  );
};

export default OnboardingPage;