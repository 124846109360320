import { theme } from 'antd';
import { Dayjs } from 'dayjs';
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import * as ChatAPI from "../../services/conversations";
import { Course, getCourse } from "../../services/courses";
import { BackendTemplate, Template } from "../../services/templates";
import { updateUserTemplates } from "../../services/user";
import useUserData from "../../hooks/useUser";
import useChat from "../../hooks/useChat";
import ChatInput from "../../components/chat/chat-input";
import ChatTour from "../../components/chat/ChatTour";
import ChatContent from "../../components/chat/ChatContent";
import AnalyticsNavigation from "../../components/chat/AnalyticsNavigation";
import CourseHeader from "../../components/chat/CourseHeader";
import ChatContainer from "../../components/chat/ChatContainer";
import AnalyticsOverlay from "../../components/chat/AnalyticsOverlay";
import BaseChatInterface from "./base-chat-interface";
import { BaseChatInterfaceProps, Conversation } from "../../types/chat";

const AnalyticsChatInterface: React.FC<BaseChatInterfaceProps> = ({
    type: chatTypeSpecifier,
    endpointUrl: endpointUrlSpecifier,
    initialMessage: initialMessageSpecifier,
    isDesktopPortraitView
}) => {
    const { chatID } = useParams();
    const { user, updateUserDataTemplates } = useUserData();
    const [tourOpen, setTourOpen] = useState(false);
    const [overlayVisible, setOverlayVisible] = useState<boolean>(false);

    // Refs for the tour
    const inputRef = useRef<HTMLDivElement | null>(null);
    const templateRef = useRef<HTMLDivElement | null>(null);
    const navigatorRef = useRef<HTMLDivElement | null>(null);
    const modulesRef = useRef<HTMLElement | null>(null);
    const dateRangeRef = useRef<HTMLDivElement | null>(null);

    const [conversation, setConversation] = useState<Conversation | undefined>(undefined);
    const [course, setCourse] = useState<Course | undefined>(undefined);
    const [templates, setTemplates] = useState<Template[]>([]);

    // Navigation state
    const [selectedWeeks, setSelectedWeeks] = useState<number[]>([]);
    const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(null);

    // Use our custom chat hook
    const {
        messages,
        setMessages,
        messageLoadingStates,
        input,
        setInput,
        sendMessage,
        handleKeyDown,
        updateRelevantSelectedTemplateName,
        messagesEndRef,
        isLoadingMessages
    } = useChat(chatID || '', conversation, endpointUrlSpecifier, initialMessageSpecifier);

    const { useToken } = theme;
    const { token } = useToken();

    // Load conversation data
    useEffect(() => {
        if (!chatID) return;
        
        ChatAPI.getConversation(chatID).then(c => {
            setConversation(c);
        });
    }, [chatID]);

    // Load course data and update templates
    useEffect(() => {
        if (!conversation) return;
        
        getCourse(conversation.course_id!).then((course) => {
            setCourse(course);
        });    
        
        // Update templates to ensure each has a ui_key
        const updatedTemplates = (conversation.templates || [])
            .filter(template => template.type === chatTypeSpecifier) // Filter by ChatInterface type
            .map(template => ({
                ...template,
                ui_key: template.ui_key || uuidv4()
            }));
        
        setTemplates(updatedTemplates);
    }, [conversation, chatTypeSpecifier]);

    // Handle menu item clicks
    const onWeekSelectionClick = async (e: any) => {
        if (!course) return;
        
        let updatedSelectedWeeks = [...selectedWeeks];
        const clickedWeekNumber = parseInt(e.key);

        if (clickedWeekNumber === 0) {
            // "Select All" logic
            if (updatedSelectedWeeks.length === course.weeks.length) {
                // If all weeks are already selected, deselect all
                updatedSelectedWeeks = [];
            } else {
                // Else, select all weeks
                updatedSelectedWeeks = course.weeks.map((_, index) => index + 1);
            }
        } else {
            // Individual week selection logic
            if (updatedSelectedWeeks.includes(clickedWeekNumber)) {
                // If already selected, remove from selection
                updatedSelectedWeeks = updatedSelectedWeeks.filter(week => week !== clickedWeekNumber);
            } else {
                // If not selected, add to selection
                updatedSelectedWeeks.push(clickedWeekNumber);
            }
        }

        if (updatedSelectedWeeks.length === 0 && course.weeks.length > 1) {
            if (clickedWeekNumber === 0) {
                // If all weeks are deselected via Select All, select the last week
                updatedSelectedWeeks.push(course.weeks.length);
            } else {
                // If no weeks are selected, select the clicked week
                updatedSelectedWeeks.push(clickedWeekNumber);
            }
        }

        setSelectedWeeks(updatedSelectedWeeks);
        await updateRelevantWeekNumbers(updatedSelectedWeeks);
    };

    const handleDateRangeChange = (dates: [Dayjs, Dayjs] | null) => {
        setDateRange(dates);
        // Convert dates to ISO strings if they are not null
        const isoStrings = dates ? dates.map(date => date.toISOString()) : [];
        updateRelevantDateRange(isoStrings);
    };

    const handleSetTemplate = (template_name: string, template_text: string) => {
        setInput(template_text);
        updateRelevantSelectedTemplateName(template_name);
    };

    // API functions
    async function updateRelevantWeekNumbers(weekNumbers: number[]) {
        if (!chatID) return;
        await ChatAPI.updateRelevantWeekNumbers(chatID, weekNumbers);
    }

    async function updateRelevantDateRange(date_range: string[]) {
        if (!chatID) return;
        await ChatAPI.updateRelevantDateRange(chatID, date_range);
    }

    // Template management
    const handleUpdateTemplates = async (updatedTemplates: Template[]) => {
        if (conversation && chatID) {
            const templatesToSend = updatedTemplates.map(({ ui_key, ...backend_template }) => backend_template);

            try {
                const conversationUpdated = await ChatAPI.updateConversationTemplates(conversation._id, templatesToSend);
                if (conversationUpdated) {
                    console.log("Templates updated successfully");
                    setTemplates(updatedTemplates);
                    setConversation(prevConversation => ({
                        ...prevConversation!,
                        templates: updatedTemplates
                    }));
                } else {
                    console.error("Failed to update conversation templates");
                }

                const userTemplatesToSend = [
                    // Include templates in templatesToSend that are also in user.templates
                    ...templatesToSend.filter(template =>
                        user.templates.some((userTemplate: BackendTemplate) => userTemplate.name === template.name)
                    ),
                    // Include all new templates that are not in course.templates
                    ...templatesToSend.filter(template =>
                        !conversation?.templates.some(courseTemplate => courseTemplate.name === template.name)
                    )
                ];

                updateUserDataTemplates(userTemplatesToSend);
                if (userTemplatesToSend.length > 0) {
                    const userUpdated = await updateUserTemplates(user._id, userTemplatesToSend);
                    if (userUpdated) {
                        console.log("User templates updated successfully");
                    } else {
                        console.error("Failed to update user templates");
                    }
                }
            } catch (error) {
                console.error('Error updating templates:', error);
            }
        }
    };

    const renderChatContent = (siderCollapsed: boolean) => {
        return (
            <ChatContainer siderCollapsed={siderCollapsed}>
                {course && (
                    <CourseHeader 
                        course={course} 
                        onTourOpen={() => setTourOpen(true)} 
                    />
                )}
                
                {course && (
                    <ChatContent 
                        conversation={conversation}
                        messages={messages}
                        messageLoadingStates={messageLoadingStates}
                        messagesEndRef={messagesEndRef}
                        chatTypeSpecifier={chatTypeSpecifier}
                    />
                )}
                
                {course && (
                    <ChatInput
                        input={input}
                        setInput={setInput}
                        isLoading={isLoadingMessages}
                        sendMessage={sendMessage}
                        handleKeyDown={handleKeyDown}
                        templates={templates}
                        onUpdateTemplates={handleUpdateTemplates}
                        chatTypeSpecifier={chatTypeSpecifier}
                        handleSetTemplate={handleSetTemplate}
                        selectedWeeks={selectedWeeks}
                        token={token}
                        siderCollapsed={siderCollapsed}
                        templateRef={templateRef}
                        inputRef={inputRef}
                    />
                )}
            </ChatContainer>
        );
    };

    return (
        <>
            {course && (
                <BaseChatInterface
                    isDesktopPortraitView={isDesktopPortraitView}
                    siderContent={
                        <AnalyticsNavigation
                            course={course}
                            conversation={conversation}
                            chatTypeSpecifier={chatTypeSpecifier}
                            siderCollapsed={false} // Will be provided by BaseChatInterface
                            setSiderCollapsed={() => {}} // Will be provided by BaseChatInterface
                            selectedWeeks={selectedWeeks}
                            setSelectedWeeks={setSelectedWeeks}
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                            onWeekSelectionClick={onWeekSelectionClick}
                            handleDateRangeChange={handleDateRangeChange}
                            updateRelevantWeekNumbers={updateRelevantWeekNumbers}
                            updateRelevantDateRange={updateRelevantDateRange}
                            modulesRef={modulesRef}
                            dateRangeRef={dateRangeRef}
                            navigatorRef={navigatorRef}
                            isLoadingMessages={isLoadingMessages}
                        />
                    }
                    overlayContent={
                        <AnalyticsOverlay 
                            visible={overlayVisible}
                            onClose={() => setOverlayVisible(false)}
                            userId={user._id}
                        />
                    }
                >
                    {renderChatContent}
                </BaseChatInterface>
            )}
            
            <ChatTour
                open={tourOpen}
                onClose={() => setTourOpen(false)}
                chatTypeSpecifier={chatTypeSpecifier}
                inputRef={inputRef}
                templateRef={templateRef}
                navigatorRef={navigatorRef}
                modulesRef={modulesRef}
                dateRangeRef={dateRangeRef}
            />
        </>
    );
};

export default AnalyticsChatInterface;
