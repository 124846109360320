import { ExclamationCircleOutlined } from '@ant-design/icons';
import { NextUIProvider } from "@nextui-org/react";
import { ConfigProvider, Modal, Spin, theme } from 'antd';
import { createContext, useEffect, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation, useNavigate } from 'react-router-dom';
import ChatHistory from './screens/chat/chat-history';
import Administration from './screens/administration/administration';
import AssignedStudents from './screens/assigned/assigned';
import Header from "./components/header";
import RedirectToLogin from "./components/RedirectToLogin";
import BackendUnavailable from './screens/backend-unavailable';
import OnboardingPage from './screens/onboarding/OnboardingPage';
import { AbsoluteCenter } from './components/styles';
import ContentChatInterface from './screens/chat/content-chat-interface';
import AnalyticsChatInterface from './screens/chat/analytics-chat-interface';
import DiscoverChatInterface from './screens/chat/discover-chat-interface';
import Course from './screens/courses/course';
import CourseGallery from './screens/courses/course-overview';
import Home from './screens/home/home';
import MarketplaceGallery from './screens/courses/course-marketplace';
import CourseViewer from './screens/courses/course-viewer';
import TemplatesPage from './screens/UserTemplatePage';
import HealthCheck from './screens/HealthCheck';
import Changelog from './screens/Changelog';
import LoginScreen from './screens/login/login';
import EmailLoginScreen from './screens/login/email-login';
import ChangePasswordScreen from './screens/login/change-password';
import SignupScreen from './screens/signup/signup';
import UserTypeSelectionScreen from './screens/user-type-selection/user-type-selection-screen';
import StripeCheckout from './screens/checkout/StripeCheckout';
import Return from './screens/checkout/Return';
import SubscriptionManagement from './screens/billing/BillingManagement';
import UserSettingsPage from './screens/settings/UserSettingsPage';
import CommunityPage from './screens/communities/CommunityPage';
import UserCommunityPage from './screens/communities/UserCommunityPage';
import JoinCourse from './screens/courses/join-course';
import { autodidact_type, marketpalce_admin_type, fetchUserType, verifyToken } from './services/user';
import { getUserDataFromLocalStorage } from './utils/useLocalStorage';
import { getAnalyticsCopilotInitialMessage } from './utils/utils';
import { CourseAnalytics } from './screens/analytics/course-analytics';
import { dark_theme_token, default_theme_token, dark_theme_components, default_theme_components } from './config';
import { loginWithToken, logout } from './services/auth-axios';
import { ErrorBoundary } from "react-error-boundary";
import FallbackComponent from './components/fallback';
import { portraitViewThreshold } from "./config";
import { UnsavedChangesProvider } from './contexts/UnsavedChangesContext';
import { isNewAutodidact } from './services/user';

const { confirm } = Modal;

export const UserContext = createContext(null);

// Component to handle route changes
function RouteChangeHandler({ updateFavicon }) {
  const location = useLocation();
  
  useEffect(() => {
    // Update favicon when the route changes
    updateFavicon();
  }, [location, updateFavicon]);
  
  return null; // This component doesn't render anything
}

// Function to update favicon based on URL
const updateStyleBasedOnUrl = () => {
  const currentURL = window.location.href;
  let favicon = document.querySelector("link[rel='icon']");
  
  if (!favicon) {
    favicon = document.createElement('link');
    favicon.rel = 'icon';
    document.head.appendChild(favicon);
  }
  
  if (currentURL.includes('huuh')) {
    favicon.href = '/huuh.ico';
    document.title = 'huuh?'
  } else {
    favicon.href = '/favicon.ico';
    document.title = 'infolab.ai'
  }
};

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [logoutOpen, setLogoutOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [userType, setUserType] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isBackendAvailable, setIsBackendAvailable] = useState(true);
  const [isDesktopPortraitView, setIsDesktopPortraitView] = useState(window.innerWidth < portraitViewThreshold);
  const [shouldRedirectToOnboarding, setShouldRedirectToOnboarding] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktopPortraitView(window.innerWidth < portraitViewThreshold);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const checkBackendHealth = async () => {
    try {
      if (!process.env.REACT_APP_BACKEND_URL) {
        console.error('REACT_APP_BACKEND_URL is not set');
        return;
      }
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/health`);
      if (res.ok) {
        setIsBackendAvailable(true);
      }
    } catch (error) {
      console.error('Backend health check failed:', error);
      setIsBackendAvailable(false);
    }
  };

  useEffect(() => {
    localStorage.removeItem('redirectAfterLogin');
    localStorage.removeItem('signupFormData');
    localStorage.removeItem('isTechnicalSignup')
    localStorage.removeItem('isKeyAccount')
    localStorage.removeItem('autodidactOnboardingFinished');
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme || window.location.href.includes('huuh')) {
      setIsDarkMode(savedTheme === 'dark');
    }
    checkBackendHealth();
    const healthCheckInterval = setInterval(checkBackendHealth, 30000);
    checkAuthStatus();
    
    // Set favicon based on URL
    // Run initially
    updateStyleBasedOnUrl();
    
    // Set up listener for URL changes (for single-page application)
    const handleUrlChange = () => {
      updateStyleBasedOnUrl();
    };
    
    window.addEventListener('popstate', handleUrlChange);
    
    return () => {
      clearInterval(healthCheckInterval);
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, []);

  const checkAuthStatus = async () => {
    const isValidToken = await verifyToken();
    if (isValidToken) {
      const userData = getUserDataFromLocalStorage();
      setUser(userData);
      if (userData && (userData.type === 'unregistered' || !userData.billing.paid)) {
        setIsLoggedIn(false);
        setUserType(userData.type);
        if (!['/user-type-selection', '/checkout', '/return'].some(path => window.location.href.includes(path))) {
          window.location.href = '/user-type-selection';
        }
      } else if (userData?._id && !['/change-password'].some(path => window.location.href.includes(path))) {
          try {
            const user = await loginWithToken();
            setIsLoggedIn(true);
            setUserType(user['type']);
            // Now that the user is logged in, check if they're a new autodidact
            if (isNewAutodidact(userData)) {
              setShouldRedirectToOnboarding(true);
            }
          } catch (error) {
            console.error('Autologin failed: ', error.message);
            setIsLoggedIn(false);
            logout();
          }
      }
    }
  };

  const loginCallback = async () => {
    try {
      const isValidToken = await verifyToken();
      if (isValidToken) {
        const userData = getUserDataFromLocalStorage();
        setUser(userData);
        // If user type is 'unregistered', redirect to user type selection page
        if (userData.type === 'unregistered') {
          setIsLoggedIn(false);
          window.location.href = '/user-type-selection';
        } else if (!userData.billing.paid) {
          setIsLoggedIn(true);
          window.location.href = '/user-type-selection'; // TODO make better repayment screen +  students / technical_users should not redirect to selection
        } else if (userData._id) {
          setIsLoggedIn(true);
          const userType = await fetchUserType(userData._id);
          setUserType(userType);
          // Check if this is a new autodidact
          if (isNewAutodidact(userData)) {
            setShouldRedirectToOnboarding(true);
          }
        }
      }
    } catch (error) {
      console.error('Login failed:', error);
      setIsLoggedIn(false);
      logout();
    }
  };

  const handleLogout = () => {
    logout();
    window.location.href = '/login';
    setIsLoggedIn(false);
  };

  const confirmLogout = () => {
    confirm({
      title: 'Are you sure you want to logout?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleLogout()
      },
      onCancel() {
        setLogoutOpen(false);
      },
    });
  };

  const toggleTheme = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem('theme', newTheme ? 'dark' : 'default');
  };

  return (
    <ErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={(error, info) => {
        console.error("Caught an error:", error, info);
      }}
      onReset={() => {
        window.location.reload();
      }}>
      <NextUIProvider>
        <ConfigProvider theme={{
          algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
          token: isDarkMode ? dark_theme_token : default_theme_token,
          components: isDarkMode ? dark_theme_components : default_theme_components,
        }}>
          <UnsavedChangesProvider>
            <main className={`${isDarkMode ? 'dark' : ''} text-foreground bg-background`} style={{ height: '100vh' }}>
              {!isBackendAvailable ? (
                <BackendUnavailable />
              ) : (
                isLoggedIn ? (
                  <>
                    {shouldRedirectToOnboarding ? (
                      <OnboardingPage
                        onCompleted={() => {
                          setShouldRedirectToOnboarding(false);
                          localStorage.setItem('autodidactOnboardingFinished', 'true');
                        }}
                        isDarkMode={isDarkMode}
                        toggleTheme={toggleTheme}
                      />
                    ) : (
                      (userType && userType !== 'unregistered') ? (
                        <>
                          <UserContext.Provider value={{ type: userType, ...user }}>
                            <Router>
                              <RouteChangeHandler updateFavicon={updateStyleBasedOnUrl} />
                              <Header
                                onLogout={() => confirmLogout()}
                                isDarkMode={isDarkMode}
                                toggleTheme={toggleTheme}
                                isDesktopPortraitView={isDesktopPortraitView}
                              >
                                <Routes>
                                  <Route path="/" element={<Navigate replace to='/home' />} />
                                  <Route path="/home" element={[autodidact_type, marketpalce_admin_type].includes(userType) ? <Home /> : <CourseGallery />} />
                                  <Route path="/courses/:courseID" element={<Course />} />
                                  <Route path="/marketplace" element={<MarketplaceGallery />} />
                                  <Route
                                    path="/discover/:chatID"
                                    element={
                                      <DiscoverChatInterface
                                        type="discover"
                                        endpointUrl="discover"
                                        isDesktopPortraitView={isDesktopPortraitView}
                                      />
                                    }
                                  />
                                  <Route path="/courses/contribute/:courseID" element={<CourseViewer />} />
                                  <Route path="/join-course/:courseId" element={<JoinCourse />} />
                                  <Route
                                    path="/chat/:chatID"
                                    element={
                                      <ContentChatInterface
                                        type="content"
                                        endpointUrl="conversations"
                                        isDesktopPortraitView={isDesktopPortraitView}
                                      />
                                    }
                                  />
                                  <Route path="/chat-history" element={<ChatHistory />} />
                                  <Route path="/assigned" element={<AssignedStudents endpointUrl="assigned" />} />
                                  <Route path="/administration" element={<Administration endpointUrl="administration" />} />
                                  <Route
                                    path="/analytics-copilot/:chatID"
                                    element={
                                      <AnalyticsChatInterface
                                        type="analytics"
                                        endpointUrl="analytics-copilot"
                                        initialMessage={getAnalyticsCopilotInitialMessage(user)}
                                        isDesktopPortraitView={isDesktopPortraitView}
                                      />
                                    }
                                  />
                                  <Route path="/analytics/:courseID" element={<CourseAnalytics />} />
                                  <Route path="/templates" element={<TemplatesPage />} />
                                  <Route path="/settings" element={<UserSettingsPage />} />
                                  <Route path="/communities/:communityID" element={<CommunityPage />} />
                                  <Route path="/communities/users/:userID" element={<UserCommunityPage />} />
                                  <Route path="/billing" element={<SubscriptionManagement />} />
                                  <Route path="/checkout" element={<StripeCheckout />} />
                                  <Route path="/changelog" element={<Changelog />} />
                                  <Route path="*" element={<RedirectToLogin isLoggedIn={isLoggedIn} />} />
                                </Routes>
                              </Header>
                            </Router>
                          </UserContext.Provider>
                        </>
                      ) : <AbsoluteCenter><Spin size="large" /></AbsoluteCenter>
                    )}
                  </>
                ) : (
                  <Router>
                    <RouteChangeHandler updateFavicon={updateStyleBasedOnUrl} />
                    <Routes>
                      <Route path="/login" element={<LoginScreen loginCallback={loginCallback} isDarkMode={isDarkMode} toggleTheme={toggleTheme} />} />
                      <Route path="/signup" element={<SignupScreen loginCallback={loginCallback} isDarkMode={isDarkMode} toggleTheme={toggleTheme} />} />
                      <Route path="/welcome-email-login" element={<EmailLoginScreen />} />
                      <Route path="/change-password" element={<ChangePasswordScreen loginCallback={loginCallback} />} />
                      <Route path="/user-type-selection" element={<UserTypeSelectionScreen loginCallback={loginCallback} isDarkMode={isDarkMode} toggleTheme={toggleTheme} />} />
                      <Route path="/checkout" element={<StripeCheckout />} />
                      <Route path="/return" element={<Return loginCallback={loginCallback} isDarkMode={isDarkMode} />} />
                      <Route path="*" element={<RedirectToLogin isLoggedIn={isLoggedIn} />} />
                      <Route path="/health" element={<HealthCheck />} />
                    </Routes>
                  </Router>
                )
              )}
            </main>
          </UnsavedChangesProvider>
        </ConfigProvider>
      </NextUIProvider>
    </ErrorBoundary>
  );
}

export default App;