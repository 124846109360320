import React from 'react';
import { Button, theme } from 'antd';
import type { ButtonProps } from 'antd';
import './NavMenuButton.css';

interface NavMenuButtonProps extends ButtonProps {
  selected?: boolean;
}

const NavMenuButton: React.FC<NavMenuButtonProps> = ({ selected, children, ...props }) => {
  const { token } = theme.useToken();

  return (
    <Button
      type="text"
      {...props}
      aria-selected={selected}
      style={{
        width: '98%',
        margin: '0 4px',
        height: '42px', 
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        padding: '8px 24px',
        background: selected ? token.Menu?.colorItemBgSelected : token.Menu?.colorItemBg,
        border: 'none',
        ...props.style,
      }}
      className="nav-menu-button"
    >
      {children}
    </Button>
  );
};

export default NavMenuButton;
