import { theme } from 'antd';
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import * as ChatAPI from "../../services/conversations";
import { BackendTemplate, Template } from "../../services/templates";
import { updateUserTemplates } from "../../services/user";
import useUserData from "../../hooks/useUser";
import useChat from "../../hooks/useChat";
import ChatInput from "../../components/chat/chat-input";
import DiscoverChatTour from "../../components/chat/DiscoverChatTour";
import ChatContent from "../../components/chat/ChatContent";
import DiscoverNavigation from "../../components/chat/DiscoverNavigation";
import DiscoverHeader from "../../components/chat/DiscoverHeader";
import ChatContainer from "../../components/chat/ChatContainer";
import BaseChatInterface from "./base-chat-interface";
import { BaseChatInterfaceProps, Conversation } from "../../types/chat";
import { CourseOverview } from '../../services/courses';

const DiscoverChatInterface: React.FC<BaseChatInterfaceProps> = ({
    type: chatTypeSpecifier,
    endpointUrl: endpointUrlSpecifier,
    initialMessage: initialMessageSpecifier,
    isDesktopPortraitView
}) => {
    const { chatID } = useParams();
    const { user, updateUserDataTemplates } = useUserData();
    const [tourOpen, setTourOpen] = useState(false);

    // Refs for the tour
    const inputRef = useRef<HTMLDivElement | null>(null);
    const templateRef = useRef<HTMLDivElement | null>(null);
    const navigatorRef = useRef<HTMLDivElement | null>(null);

    const [conversation, setConversation] = useState<Conversation | undefined>(undefined);
    const [templates, setTemplates] = useState<Template[]>([]);
    
    // State for all seeking flags
    const [seekingMentor, setSeekingMentor] = useState(false);
    const [seekingCofounders, setSeekingCofounders] = useState(false);
    const [seekingCourses, setSeekingCourses] = useState(false);
    const [seekingProjects, setSeekingProjects] = useState(false);
    const [seekingCommunities, setSeekingCommunities] = useState(false);
    const [selectedCommunityIds, setSelectedCommunityIds] = useState<string[]>([]);

    // Use our custom chat hook
    const {
        messages,
        setMessages,
        messageLoadingStates,
        input,
        setInput,
        sendMessage,
        handleKeyDown,
        updateRelevantSelectedTemplateName,
        messagesEndRef,
        isLoadingMessages
    } = useChat(chatID || '', conversation, endpointUrlSpecifier, initialMessageSpecifier);

    const { useToken } = theme;
    const { token } = useToken();

    // Load conversation data
    useEffect(() => {
        if (!chatID) return;

        ChatAPI.getConversation(chatID).then(c => {
            setConversation(c);
            
            // Initialize state with conversation values if they exist
            if (c.user_settings) {
                setSeekingMentor(c.user_settings.seeking_mentor || false);
                setSeekingCofounders(c.user_settings.seeking_cofounders || false);
                setSeekingCourses(c.user_settings.seeking_courses || false);
                setSeekingProjects(c.user_settings.seeking_projects || false);
                setSeekingCommunities(c.user_settings.seeking_communities || false);
                setSelectedCommunityIds(c.user_settings.community_ids || []);
            }
        });
    }, [chatID]);

    // Update templates
    useEffect(() => {
        if (!conversation) return;

        // Update templates to ensure each has a ui_key
        const updatedTemplates = (conversation.templates || [])
            .filter(template => template.type === chatTypeSpecifier) // Filter by ChatInterface type
            .map(template => ({
                ...template,
                ui_key: template.ui_key || uuidv4()
            }));

        setTemplates(updatedTemplates);
    }, [conversation, chatTypeSpecifier]);

    const handleSetTemplate = (template_name: string, template_text: string) => {
        setInput(template_text);
        updateRelevantSelectedTemplateName(template_name);
    };

    // Update seeking mentor flag
    const updateSeekingMentor = async (value: boolean) => {
        if (!chatID) return;
        
        // Update local state immediately for better UI responsiveness
        setSeekingMentor(value);
        
        try {
            await ChatAPI.updateSeekingMentor(chatID, value);
            console.log(`Seeking mentor flag updated: ${value}`);
            
            // Update local conversation state with the new value
            if (conversation) {
                // Create a deep copy to ensure React detects the change
                const updatedConversation = { ...conversation };
                
                // Update in both places to be safe
                updatedConversation.user_settings.seeking_mentor = value;
                                
                setConversation(updatedConversation);
            }
        } catch (error) {
            console.error("Failed to update seeking mentor:", error);
            // Revert UI state on failure
            setSeekingMentor(!value);
        }
    };

    // Update seeking cofounders flag
    const updateSeekingCofounders = async (value: boolean) => {
        if (!chatID) return;
        
        // Update local state immediately for better UI responsiveness
        setSeekingCofounders(value);
        
        try {
            await ChatAPI.updateSeekingCofounders(chatID, value);
            console.log(`Seeking cofounders flag updated: ${value}`);
            
            // Update local conversation state with the new value
            if (conversation) {
                // Create a deep copy to ensure React detects the change
                const updatedConversation = { ...conversation };
                
                // Update in both places to be safe
                updatedConversation.user_settings.seeking_cofounders = value;
                
                setConversation(updatedConversation);
            }
        } catch (error) {
            console.error("Failed to update seeking cofounders:", error);
            // Revert UI state on failure
            setSeekingCofounders(!value);
        }
    };

    // Update seeking courses flag
    const updateSeekingCourses = async (value: boolean) => {
        if (!chatID) return;
        
        // Update local state immediately for better UI responsiveness
        setSeekingCourses(value);
        
        try {
            await ChatAPI.updateSeekingCourses(chatID, value);
            console.log(`Seeking courses flag updated: ${value}`);
            
            // Update local conversation state with the new value
            if (conversation) {
                // Create a deep copy to ensure React detects the change
                const updatedConversation = { ...conversation };
                
                // Update in both places to be safe
                updatedConversation.user_settings.seeking_courses = value;
                
                setConversation(updatedConversation);
            }
        } catch (error) {
            console.error("Failed to update seeking courses:", error);
            // Revert UI state on failure
            setSeekingCourses(!value);
        }
    };
    
    // Update seeking projects flag
    const updateSeekingProjects = async (value: boolean) => {
        if (!chatID) return;
        
        // Update local state immediately for better UI responsiveness
        setSeekingProjects(value);
        
        try {
            await ChatAPI.updateSeekingProjects(chatID, value);
            console.log(`Seeking projects flag updated: ${value}`);
            
            // Update local conversation state with the new value
            if (conversation) {
                // Create a deep copy to ensure React detects the change
                const updatedConversation = { ...conversation };
                
                // Update in both places to be safe
                updatedConversation.user_settings.seeking_projects = value;
                
                setConversation(updatedConversation);
            }
        } catch (error) {
            console.error("Failed to update seeking projects:", error);
            // Revert UI state on failure
            setSeekingProjects(!value);
        }
    };

    // Update selected communities
    const updateSeekingCommunities = async (value: boolean, communityIds: string[] = []) => {
        if (!chatID) return;
        
        // Update local state immediately for better UI responsiveness
        setSeekingCommunities(value);
        setSelectedCommunityIds(communityIds);
        
        try {
            await ChatAPI.updateSeekingCommunities(chatID, value, communityIds);
            console.log(`Seeking communities flag updated: ${value}`);
            
            // Update local conversation state with the new value
            if (conversation) {
                // Create a deep copy to ensure React detects the change
                const updatedConversation = { ...conversation };
                
                // Update in both places to be safe
                updatedConversation.user_settings.seeking_communities = value;
                updatedConversation.user_settings.community_ids = communityIds;
                
                setConversation(updatedConversation);
            }
        } catch (error) {
            console.error("Failed to update seeking communities:", error);
            // Revert UI state on failure
            setSeekingCommunities(!value);
            setSelectedCommunityIds([]);
        }
    };
    
    // Update to show all types (unselect all specific filters)
    const updateSeekingAll = async (isSelected: boolean) => {
        if (!chatID) return;
        
        try {
            await ChatAPI.updateSeekingAll(chatID, isSelected);
            console.log(`Updated conversation to show all types: ${isSelected}`);
            
            // Update local state
            if (conversation) {
                // Create a deep copy to ensure React detects the change
                const updatedConversation = { ...conversation };
                
                // Update the user settings to reset all filters
                updatedConversation.user_settings.seeking_mentor = false;
                updatedConversation.user_settings.seeking_cofounders = false;
                updatedConversation.user_settings.seeking_courses = false;
                updatedConversation.user_settings.seeking_projects = false;
                updatedConversation.user_settings.seeking_communities = false;
                updatedConversation.user_settings.community_ids = [];
                
                // Reset local state for communities explicitly
                setSeekingCommunities(false);
                setSelectedCommunityIds([]);
                
                setConversation(updatedConversation);
            }
        } catch (error) {
            console.error("Failed to update conversation to show all types:", error);
        }
    };

    // Template management
    const handleUpdateTemplates = async (updatedTemplates: Template[]) => {
        if (conversation && chatID) {
            const templatesToSend = updatedTemplates.map(({ ui_key, ...backend_template }) => backend_template);

            try {
                const conversationUpdated = await ChatAPI.updateConversationTemplates(conversation._id, templatesToSend);
                if (conversationUpdated) {
                    console.log("Templates updated successfully");
                    setTemplates(updatedTemplates);
                    setConversation(prevConversation => ({
                        ...prevConversation!,
                        templates: updatedTemplates
                    }));
                } else {
                    console.error("Failed to update conversation templates");
                }

                const userTemplatesToSend = [
                    // Include templates in templatesToSend that are also in user.templates
                    ...templatesToSend.filter(template =>
                        user.templates.some((userTemplate: BackendTemplate) => userTemplate.name === template.name)
                    ),
                    // Include all new templates that are not in course.templates
                    ...templatesToSend.filter(template =>
                        !conversation?.templates.some(courseTemplate => courseTemplate.name === template.name)
                    )
                ];

                updateUserDataTemplates(userTemplatesToSend);
                if (userTemplatesToSend.length > 0) {
                    const userUpdated = await updateUserTemplates(user._id, userTemplatesToSend);
                    if (userUpdated) {
                        console.log("User templates updated successfully");
                    } else {
                        console.error("Failed to update user templates");
                    }
                }
            } catch (error) {
                console.error('Error updating templates:', error);
            }
        }
    };

    const renderChatContent = (siderCollapsed: boolean) => {
        return (
            <ChatContainer siderCollapsed={siderCollapsed}>
                <DiscoverHeader onTourOpen={() => setTourOpen(true)} />

                <ChatContent
                    conversation={conversation}
                    messages={messages}
                    messageLoadingStates={messageLoadingStates}
                    messagesEndRef={messagesEndRef}
                    chatTypeSpecifier={chatTypeSpecifier}
                />

                <ChatInput
                    input={input}
                    setInput={setInput}
                    isLoading={isLoadingMessages}
                    sendMessage={sendMessage}
                    handleKeyDown={handleKeyDown}
                    templates={templates}
                    onUpdateTemplates={handleUpdateTemplates}
                    chatTypeSpecifier={chatTypeSpecifier}
                    handleSetTemplate={handleSetTemplate}
                    selectedWeeks={[99]} // to unblock send button
                    token={token}
                    siderCollapsed={siderCollapsed}
                    templateRef={templateRef}
                    inputRef={inputRef}
                />
            </ChatContainer>
        );
    };

    return (
        <>
            {conversation ? (
                <BaseChatInterface
                    isDesktopPortraitView={isDesktopPortraitView}
                    siderContent={
                        <DiscoverNavigation
                            conversation={conversation}
                            chatTypeSpecifier={chatTypeSpecifier}
                            siderCollapsed={false} // Will be provided by BaseChatInterface
                            setSiderCollapsed={() => { }} // Will be provided by BaseChatInterface
                            navigatorRef={navigatorRef}
                            isLoadingMessages={isLoadingMessages}
                            seekingMentor={seekingMentor}
                            setSeekingMentor={setSeekingMentor}
                            seekingCofounders={seekingCofounders}
                            setSeekingCofounders={setSeekingCofounders}
                            seekingCourses={seekingCourses}
                            setSeekingCourses={setSeekingCourses}
                            seekingProjects={seekingProjects}
                            setSeekingProjects={setSeekingProjects}
                            seekingCommunities={seekingCommunities}
                            setSeekingCommunities={setSeekingCommunities}
                            selectedCommunityIds={selectedCommunityIds}
                            setSelectedCommunityIds={setSelectedCommunityIds}
                            updateSeekingMentor={updateSeekingMentor}
                            updateSeekingCofounders={updateSeekingCofounders}
                            updateSeekingCourses={updateSeekingCourses}
                            updateSeekingProjects={updateSeekingProjects}
                            updateSeekingCommunities={updateSeekingCommunities}
                            updateSeekingAll={updateSeekingAll}
                        />
                    }
                >
                    {renderChatContent}
                </BaseChatInterface>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    Loading...
                </div>
            )}
            

            <DiscoverChatTour
                open={tourOpen}
                onClose={() => setTourOpen(false)}
                inputRef={inputRef}
                templateRef={templateRef}
                navigatorRef={navigatorRef}
            />
        </>
    );
};

export default DiscoverChatInterface;