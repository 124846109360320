import React, { useState, useEffect } from 'react';
import { Progress, Typography, InputNumber, Button } from 'antd';
import { FormSection, SectionTitle } from '../styles';

const { Text } = Typography;

interface MessageUsageSectionProps {
    canIncreaseCap: boolean;
    currentSentMessages: number;
    maxSentMessages: number;
    onMaxMessagesChange?: (value: number) => void;
}

const MessageUsageSection: React.FC<MessageUsageSectionProps> = ({
    canIncreaseCap,
    currentSentMessages,
    maxSentMessages,
    onMaxMessagesChange
}) => {
    const [newMaxMessages, setNewMaxMessages] = useState<number>(maxSentMessages);

    useEffect(() => {
        setNewMaxMessages(maxSentMessages);
    }, [maxSentMessages]);

    const handleChange = (value: number | null) => {
        if (value !== null) {
            setNewMaxMessages(value);
            if (onMaxMessagesChange) {
                onMaxMessagesChange(value);
            }
        }
    };

    const percentUsed = maxSentMessages > 0
        ? 100 - Math.min(Math.round((currentSentMessages / maxSentMessages) * 100), 100)
        : 0;

    return (
        <FormSection>
            <SectionTitle>Message Usage</SectionTitle>
            <div style={{ marginBottom: '16px' }}>
                <Text>
                    {maxSentMessages !== 0
                        ? `${percentUsed}% remaining (${currentSentMessages} of ${maxSentMessages} message${maxSentMessages !== 1 ? 's' : ''} used)`
                        : `${currentSentMessages} message${currentSentMessages !== 1 ? 's' : ''} used`
                    }
                </Text>
                {maxSentMessages !== 0
                    ? <Progress
                        percent={percentUsed}
                        status={percentUsed === 0 ? 'exception' : 'active'}
                        strokeColor={percentUsed < 20 ? 'red' : 'green'}
                    />
                    : <></>
                }
            </div>

            {canIncreaseCap && (
                <div style={{ marginTop: '16px' }}>
                    <Text>Adjust Message Cap:</Text>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                        <InputNumber
                            min={currentSentMessages}
                            value={newMaxMessages !== 0 ? newMaxMessages : undefined}
                            onChange={handleChange}
                            style={{ width: '80%' }}
                            placeholder="Unlimited"
                        />
                        <Button 
                            onClick={() => handleChange(0)} 
                            style={{ marginLeft: '8px', flex: '1' }}
                        >
                            No Limit
                        </Button>
                    </div>
                    <Text type="secondary" style={{ display: 'block', marginTop: '8px' }}>
                        You can increase your message cap as needed. Changes will be applied after saving.
                    </Text>
                </div>
            )}
        </FormSection>
    );
};

export default MessageUsageSection;