// api.ts
import { authedAxios } from "./auth-axios";
import { UserOverview } from "./courses";

export interface CommunityConfig {
    private: boolean;
}

export interface CommunityApplication {
    _id: string;
    user: UserOverview;
    community_id: string;
    reason: string;
    status: 'pending' | 'approved' | 'rejected';
    created_at: string;
    updated_at: string;
}

export interface CommunityOverview {
    _id: string;
    last_updated: string;
    name: string;
    description: string;
    leaders: UserOverview[];
    member_count: number;
    project_count: number;
    course_count: number;
    config: CommunityConfig;
}

export interface Community {
    _id: string;
    created_at: string;
    last_updated: string;
    name: string;
    description: string;
    banner_image: string;
    leaders: UserOverview[];
    members: UserOverview[];
    project_ids: string[];
    course_ids: string[];
    project_count: number;
    course_count: number;
    member_count: number;
    visibility: string;
    config: CommunityConfig;
}

export const createCommunity = async (
    name: string, 
    description: string, 
    isPrivate: boolean = false
): Promise<Community> => {
    try {
        const response = await authedAxios.post(`/communities?name=${encodeURIComponent(name)}&description=${encodeURIComponent(description)}&is_private=${isPrivate}`);
        return response.data;
    } catch (error) {
        console.error(`Error creating community:`, error);
        throw error;
    }
};

export const getCommunity = async (id: string): Promise<Community> => {
    try {
        const response = await authedAxios.get(`/communities/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching community:`, error);
        throw error;
    }
};

export const getUserCommunities = async (userId: string): Promise<Community[]> => {
    try {
        const response = await authedAxios.get(`/communities/user/${userId}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching user communities:`, error);
        throw error;
    }
};

export const getCommunityUniverse = async (userId: string): Promise<CommunityOverview[]> => {
    try {
        const response = await authedAxios.get(`/communities/${userId}/get_public_communities`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching community universe:`, error);
        throw error;
    }
};

export const updateCommunity = async (community: Community): Promise<boolean> => {
    try {
        const response = await authedAxios.post(`/communities/${community._id}`, community);
        return response.data;
    } catch (error) {
        console.error(`Error updating community:`, error);
        throw error;
    }
};

export const deleteCommunity = async (communityId: string): Promise<boolean> => {
    try {
        const response = await authedAxios.delete(`/communities/${communityId}`);
        return response.data;
    } catch (error) {
        console.error(`Error deleting community:`, error);
        throw error;
    }
};

export const addMemberToCommunity = async (communityId: string, userId: string): Promise<boolean> => {
    try {
        const response = await authedAxios.post(`/communities/${communityId}/members/${userId}`);
        return response.data;
    } catch (error) {
        console.error(`Error adding member to community:`, error);
        throw error;
    }
};

export const removeMemberFromCommunity = async (communityId: string, userId: string): Promise<boolean> => {
    try {
        const response = await authedAxios.delete(`/communities/${communityId}/members/${userId}`);
        return response.data;
    } catch (error) {
        console.error(`Error removing member from community:`, error);
        throw error;
    }
};

export const addLeaderToCommunity = async (communityId: string, userId: string): Promise<boolean> => {
    try {
        const response = await authedAxios.post(`/communities/${communityId}/leaders/${userId}`);
        return response.data;
    } catch (error) {
        console.error(`Error adding leader to community:`, error);
        throw error;
    }
};

export const removeLeaderFromCommunity = async (communityId: string, userId: string): Promise<boolean> => {
    try {
        const response = await authedAxios.delete(`/communities/${communityId}/leaders/${userId}`);
        return response.data;
    } catch (error) {
        console.error(`Error removing leader from community:`, error);
        throw error;
    }
};

export const addCourseToCommunity = async (communityId: string, courseId: string, isProject: boolean): Promise<boolean> => {
    try {
        const response = await authedAxios.post(`/communities/${communityId}/courses/${courseId}/${isProject}`);
        return response.data;
    } catch (error) {
        console.error(`Error adding course to community:`, error);
        throw error;
    }
};

export const removeCourseFromCommunity = async (communityId: string, courseId: string, isProject: boolean): Promise<boolean> => {
    try {
        const response = await authedAxios.delete(`/communities/${communityId}/courses/${courseId}/${isProject.toString()}`);
        return response.data;
    } catch (error) {
        console.error(`Error removing course from community:`, error);
        throw error;
    }
};

export const uploadCommunityBanner = async (communityId: string, bannerImage: File): Promise<string> => {
    try {
        // Validate file type
        if (!bannerImage.type.match(/image\/(jpeg|jpg|png)/)) {
            throw new Error('Only JPEG, JPG, and PNG formats are allowed');
        }

        const formData = new FormData();
        formData.append('banner_image', bannerImage);
        
        const response = await authedAxios.post(`/communities/${communityId}/upload-banner`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        
        return response.data.url;
    } catch (error) {
        console.error(`Error uploading community banner:`, error);
        throw error;
    }
};

// Community Application Services
export const submitCommunityApplication = async (communityId: string, userId: string, reason: string): Promise<boolean> => {
    try {
        const response = await authedAxios.post(`/communities/${communityId}/applications`, {
            user_id: userId,
            reason: reason
        });
        return response.data;
    } catch (error) {
        console.error(`Error submitting community application:`, error);
        throw error;
    }
};

export const getApplicationStatus = async (communityId: string, userId: string): Promise<boolean> => {
    try {
        const response = await authedAxios.get(`/communities/${communityId}/applications/${userId}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching community applications status:`, error);
        throw error;
    }
};

export const getCommunityApplications = async (communityId: string): Promise<CommunityApplication[]> => {
    try {
        const response = await authedAxios.get(`/communities/${communityId}/applications`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching community applications:`, error);
        throw error;
    }
};

export const approveCommunityApplication = async (applicationId: string): Promise<boolean> => {
    try {
        const response = await authedAxios.post(`/community-applications/${applicationId}/approve`);
        return response.data;
    } catch (error) {
        console.error(`Error approving community application:`, error);
        throw error;
    }
};

export const rejectCommunityApplication = async (applicationId: string): Promise<boolean> => {
    try {
        const response = await authedAxios.post(`/community-applications/${applicationId}/reject`);
        return response.data;
    } catch (error) {
        console.error(`Error rejecting community application:`, error);
        throw error;
    }
};
