import React from 'react';
import { Typography } from 'antd';
import ReactMarkdown from 'react-markdown';
import { changelogContent } from '../changelog';
import { ScrollableContent } from './settings/styles';


const { Title } = Typography;

interface ChangelogProps { }

const Changelog: React.FC<ChangelogProps> = () => {
    return (
        <ScrollableContent>
            <Title level={1}>Changelog</Title>
            <ReactMarkdown>{changelogContent}</ReactMarkdown>
        </ScrollableContent>
    );
};

export default Changelog;