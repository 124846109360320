import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Button, Spin, Empty } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { CommunityOverview, getUserCommunities, getCommunityUniverse, getCommunityApplications, getApplicationStatus } from '../../services/communities';
import CommunityCard from '../../components/cards/community-card';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';
import { HorizontalScrollContainer, CardWrapper, SectionContainer, SectionTitle } from '../../components/common/horizontal-scroll-container';

const { Title } = Typography;

interface CommunitiesSectionProps {
    isMarketplace?: boolean;
}

const CommunitiesSection: React.FC<CommunitiesSectionProps> = ({ isMarketplace = false }) => {
    const [communities, setCommunities] = useState<CommunityOverview[]>([]);
    const [userCommunities, setUserCommunities] = useState<string[]>([]);
    const [leaderCommunities, setLeaderCommunities] = useState<string[]>([]);
    const [userApplications, setUserApplications] = useState<{[key: string]: boolean}>({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const user = getUserDataFromLocalStorage();

    useEffect(() => {
        loadData();
    }, [isMarketplace]);

    const loadData = async () => {
        setLoading(true);
        try {
            // Load communities based on whether we're in marketplace or home
            const data = isMarketplace 
                ? await getCommunityUniverse(user._id) 
                : await getUserCommunities(user._id);
            
            if (isMarketplace) {
                setCommunities(data);
                
                // Also load user's communities to know which ones they're a part of
                const userCommData = await getUserCommunities(user._id);
                setUserCommunities(userCommData.map(comm => comm._id));
                setLeaderCommunities(userCommData
                    .filter(comm => comm.leaders.some(leader => leader._id === user._id))
                    .map(comm => comm._id));
                
                // Check for pending applications for private communities
                const privateCommIds = data
                    .filter(comm => comm.config.private && !userCommData.some(userComm => userComm._id === comm._id))
                    .map(comm => comm._id);
                
                if (privateCommIds.length > 0) {
                    // For each private community, check if the user has a pending application
                    const applications: {[key: string]: boolean} = {};
                    
                    await Promise.all(privateCommIds.map(async (commId) => {
                        try {
                            const hasApplication = await getApplicationStatus(commId, user._id);
                            applications[commId] = hasApplication;
                        } catch (error) {
                            console.error(`Error checking applications for community ${commId}:`, error);
                            applications[commId] = false;
                        }
                    }));
                    
                    setUserApplications(applications);
                }
            } else {
                setCommunities(data);
                setUserCommunities(data.map(comm => comm._id));
                setLeaderCommunities(data
                    .filter(comm => comm.leaders.some(leader => leader._id === user._id))
                    .map(comm => comm._id));
            }
        } catch (error) {
            console.error("Error loading communities:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleCreateCommunity = () => {
        navigate('/communities/create');
    };

    return (
        <div>
            <SectionContainer
                style={{
                    minHeight: '400px',
                    maxHeight: '400px',
                }}
            >
                <SectionTitle>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Typography.Title level={2} style={{ marginTop: '10px', marginBottom: '0' }}>
                            Communities
                        </Typography.Title>
                    </div>
                </SectionTitle>
                
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                        <Spin size="large" />
                    </div>
                ) : communities.length === 0 ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                        <Empty description={
                            isMarketplace 
                                ? "No communities available" 
                                : "You're not part of any communities yet"
                        } />
                    </div>
                ) : (
                    <HorizontalScrollContainer
                        style={{
                            minHeight: '290px',
                            maxHeight: '290px',
                        }}
                    >
                        {communities.map((community) => (
                            <CardWrapper key={community._id}>
                                <CommunityCard
                                    id={community._id}
                                    title={community.name}
                                    description={community.description}
                                    leaders={community.leaders}
                                    memberCount={community.member_count}
                                    projectCount={community.project_count}
                                    courseCount={community.course_count}
                                    isPrivate={community.config.private}
                                    userIsMember={userCommunities.includes(community._id)}
                                    userIsLeader={leaderCommunities.includes(community._id)}
                                    userHasApplied={userApplications[community._id] || false}
                                    onRefresh={loadData}
                                />
                            </CardWrapper>
                        ))}
                    </HorizontalScrollContainer>
                )}
            </SectionContainer>
        </div>
    );
};

export default CommunitiesSection;