import React from 'react';
import { Tour } from 'antd';
import type { TourStepProps } from 'antd';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';
import { capitalize } from '../../utils/utils';

interface CourseTourProps {
    open: boolean;
    onClose: () => void;
    setActiveModulePanel: (index: number | null) => void;
    titleRef: React.RefObject<HTMLDivElement | null>;
    languageRef: React.RefObject<HTMLDivElement | null>;
    uploadRef: React.RefObject<HTMLDivElement | null>;
    materialsTableRef: React.RefObject<HTMLDivElement | null>;
    studentsTableRef: React.RefObject<HTMLDivElement | null>;
    modulesRef: React.RefObject<HTMLDivElement | null>;
    addModuleRef: React.RefObject<HTMLButtonElement | null>;
    removeModuleRef: React.RefObject<HTMLButtonElement | null>;
    templatesRef: React.RefObject<HTMLDivElement | null>;
    deleteButtonRef: React.RefObject<HTMLButtonElement | null>;
    // Module specific refs
    moduleDurationRef?: React.RefObject<HTMLDivElement | null>;
    moduleTableRef?: React.RefObject<HTMLDivElement | null>;
    moduleTopicsRef?: React.RefObject<HTMLDivElement | null>;
    moduleSummaryRef?: React.RefObject<HTMLDivElement | null>;
    moduleAssignmentsRef?: React.RefObject<HTMLDivElement | null>;
    hasModules?: boolean;
}

type PlacementType = 'center' | 'left' | 'leftTop' | 'leftBottom' | 'right' | 'rightTop' | 'rightBottom' | 
                     'top' | 'topLeft' | 'topRight' | 'bottom' | 'bottomLeft' | 'bottomRight';

export const CourseTour: React.FC<CourseTourProps> = ({
    open,
    onClose,
    setActiveModulePanel,
    titleRef,
    languageRef,
    uploadRef,
    materialsTableRef,
    studentsTableRef,
    modulesRef,
    addModuleRef,
    removeModuleRef,
    templatesRef,
    deleteButtonRef,
    // Module specific refs
    moduleDurationRef,
    moduleTableRef,
    moduleTopicsRef,
    moduleSummaryRef,
    moduleAssignmentsRef,
    hasModules,
}) => {
    const user = getUserDataFromLocalStorage();

    const createStep = (
        title: string, 
        description: string, 
        target: HTMLElement | null, 
        placement: PlacementType
    ): TourStepProps => ({
        title,
        description,
        target,
        placement,
    });

    // Helper function to safely get ref current value
    const getRefTarget = (ref: React.RefObject<HTMLElement | null> | undefined): HTMLElement | null => {
        if (!ref) return null;
        return ref.current;
    };

    // Only include module steps if there are no existing modules
    // This prevents showing internal module steps when no module is expanded
    const moduleSteps: TourStepProps[] = !hasModules ? [] : [
        createStep(
            `${capitalize(user.config.module_terminology)} Duration`,
            `Set when this ${user.config.module_terminology} should be available to ${user.config.student_terminology}s. Content will only be accessible during this time period, helping to maintain a structured learning pace.`,
            getRefTarget(moduleDurationRef),
            'bottomLeft'
        ),
        createStep(
            `${capitalize(user.config.module_terminology)} ${capitalize(user.config.material_terminology)}s`,
            [
                `Manage ${user.config.material_terminology}s for this ${user.config.module_terminology}:`,
                `• Click a ${user.config.material_terminology} name to add it`,
                '• Set page ranges or time limits to focus on specific content',
                `• Use group tags to help ${user.config.student_terminology}s filter and organize ${user.config.material_terminology}s`
            ].join('\n'),
            getRefTarget(moduleTableRef),
            'bottomLeft'
        ),
        createStep(
            `${capitalize(user.config.module_terminology)} Topics`,
            'Define topics covered in this ${user.config.module_terminology}. These are used for analytics and will soon enable content filtering by topic.',
            getRefTarget(moduleTopicsRef),
            'right'
        ),
        createStep(
            `${capitalize(user.config.module_terminology)} Summary`,
            `Generate a summary of all ${user.config.material_terminology}s in this ${user.config.module_terminology}. You will be able to adjust it yourself afterwards. The summary will help your ${user.config.student_terminology}s to get an overview.`,
            getRefTarget(moduleSummaryRef),
            'right'
        ),
        createStep(
            `${capitalize(user.config.module_terminology)} Assignments`,
            `Create assignments using a multi-step AI process or set it manually. These will be made available to ${user.config.student_terminology}s through their chats.`,
            getRefTarget(moduleAssignmentsRef),
            'right'
        )
    ];

    const steps: TourStepProps[] = [
        createStep(
            `${capitalize(user.config.course_terminology)} Information`,
            `Click the title or description to edit them. These will be visible to all enrolled ${user.config.student_terminology}s and help them understand the ${user.config.course_terminology} content.`,
            getRefTarget(titleRef),
            'bottomLeft'
        ),
        createStep(
            `${capitalize(user.config.course_terminology)} Language`,
            `Select the primary language of your ${user.config.course_terminology} content. This changes the display language for your ${user.config.student_terminology}s.`,
            getRefTarget(languageRef),
            'bottomLeft'
        ),
        createStep(
            `${capitalize(user.config.student_terminology)} Management`,
            `Manage ${user.config.student_terminology} access here. ${capitalize(user.config.student_terminology)}s must be enrolled to access the ${user.config.course_terminology}. Use the Enroll/Remove buttons to control access.`,
            getRefTarget(studentsTableRef),
            'topLeft'
        ),
        createStep(
            `Upload ${capitalize(user.config.material_terminology)}s`,
            `Upload your ${user.config.course_terminology} ${user.config.material_terminology}s here. Supported formats include PDF, DOCX, ODT (converted to PDF), and audio/video files (converted to WAV). \n\n In case of large files, you will be prompted to set page / time ranges to shorten the upload process.`,
            getRefTarget(uploadRef),
            'topLeft'
        ),
        createStep(
            `${capitalize(user.config.material_terminology)}s Status`,
            `Track your uploaded ${user.config.material_terminology}s here. A green checkmark means the file is ready to use, a yellow icon means it\'s unavailable and a grey one means it\'s still processing. You can remove ${user.config.material_terminology}s using the delete button.`,
            getRefTarget(materialsTableRef),
            'topLeft'
        ),
        createStep(
            `${capitalize(user.config.course_terminology)} ${capitalize(user.config.module_terminology)}s`,
            [
                `Organize your ${user.config.course_terminology} into ${user.config.module_terminology}s. Each ${user.config.module_terminology.toLowerCase()} contains ${user.config.material_terminology}s, topics, a summary, and assignments.`,
                hasModules ? `\nLet\'s look at your first ${user.config.module_terminology}!` : ''
            ].join(''),
            getRefTarget(modulesRef),
            'topLeft'
        ),
        ...moduleSteps,
        createStep(
            `Add ${capitalize(user.config.module_terminology)}`,
            `Click here to add a new ${user.config.module_terminology.toLowerCase()}.`,
            getRefTarget(addModuleRef),
            'rightTop'
        ),
        createStep(
            `Remove ${capitalize(user.config.module_terminology)}`,
            `Remove the last ${user.config.module_terminology.toLowerCase()} in your ${user.config.course_terminology} structure. This action cannot be undone.`,
            getRefTarget(removeModuleRef),
            'leftTop'
        ),
        createStep(
            `${capitalize(user.config.course_terminology)} Templates`,
            `Create templates that will be available to all ${user.config.student_terminology}s when chatting with this ${user.config.course_terminology}. Templates can help guide ${user.config.student_terminology} interactions and ensure consistent responses.`,
            getRefTarget(templatesRef),
            'topLeft'
        ),
        createStep(
            `Delete ${capitalize(user.config.course_terminology)}`,
            `Permanently delete this ${user.config.course_terminology} and all associated data. This action cannot be undone, and all ${user.config.course_terminology} ${user.config.material_terminology}s, ${user.config.module_terminology}s, and templates will be permanently removed.`,
            getRefTarget(deleteButtonRef),
            'topRight'
        ),
    ].filter(step => step.target != null);

    const handleBeforeChange = (current: number, next: number) => {
        // Check if we're entering or leaving the module steps section
        const moduleStepsStart = 5; // Index where module section begins (Course Modules step)
        const moduleStepsEnd = hasModules ? 10 : 5; // Index where module section ends

        if (next === moduleStepsStart && hasModules) {
            // Open the first module panel when entering module section
            setActiveModulePanel(0);
        } else if (current >= moduleStepsStart && current <= moduleStepsEnd && 
                   (next < moduleStepsStart || next > moduleStepsEnd)) {
            // Close module panel when leaving module section
            setActiveModulePanel(null);
        }
    };

    return (
        <Tour
            open={open}
            onClose={() => {
                setActiveModulePanel(null);
                onClose();
            }}
            steps={steps}
            onChange={(current) => handleBeforeChange(current, current + 1)}
        />
    );
};