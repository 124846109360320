import React, { useState, useRef, useEffect } from 'react';
import { CardTitle } from '../../styles/course-card';

interface AnimatedTitleProps {
    title: string;
    color?: string;
}

const AnimatedTitle: React.FC<AnimatedTitleProps> = ({ title, color }) => {
    const [isHovered, setIsHovered] = useState(false);
    const titleRef = useRef<HTMLDivElement>(null);
    const [titleWidth, setTitleWidth] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);
    const [shouldAnimate, setShouldAnimate] = useState(false);
    
    // Calculate animation duration based on title length
    // Longer titles need more time to scroll
    const calculateDuration = () => {
        if (titleWidth <= containerWidth) return 0; // No animation needed if title fits
        
        // Base duration is 5 seconds, add 1 second for every 20 characters
        const baseDuration = 5;
        const charactersPerSecond = 20;
        const additionalDuration = (title.length / charactersPerSecond);
        
        return baseDuration + additionalDuration;
    };
    
    useEffect(() => {
        if (titleRef.current) {
            // Get the width of the title and container
            const titleElement = titleRef.current.querySelector('h3');
            const titleRect = titleElement?.getBoundingClientRect();
            const containerRect = titleRef.current.getBoundingClientRect();
            
            if (titleRect && containerRect) {
                setTitleWidth(titleRect.width);
                setContainerWidth(containerRect.width);
                setShouldAnimate(titleRect.width > containerRect.width);
            }
        }
    }, [title]);
    
    const animationDuration = calculateDuration();
    
    return (
        <div
            ref={titleRef}
            style={{
                overflow: 'hidden',
                width: '100%',
                position: 'relative',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <CardTitle
                style={{
                    color: color,
                    transform: shouldAnimate && isHovered 
                        ? `translateX(calc(-100% + ${containerWidth}px))` 
                        : 'translateX(0)',
                    transition: shouldAnimate && isHovered 
                        ? `transform ${animationDuration}s linear` 
                        : 'transform 0.3s ease-out',
                    whiteSpace: 'nowrap',
                    display: 'inline-block',
                    width: 'auto',
                    maxWidth: 'none',
                }}
            >
                {title}
            </CardTitle>
        </div>
    );
};

export default AnimatedTitle;
