import { Typography, theme, Button } from 'antd';
import React from 'react';
import { SettingOutlined, CloseOutlined, UploadOutlined, ToolOutlined } from '@ant-design/icons';
import { removeCourse } from '../../services/courses';
import { useNavigate } from 'react-router-dom';
import { getUserDataFromLocalStorage, setUserDataInLocalStorage } from '../../utils/useLocalStorage';
import { admin_type, autodidact_type, marketpalce_admin_type, professor_type, technical_admin_type } from '../../services/user';
import { ButtonContainer, CardContent, ContentContainer, StyledCard, StyledNoMarginButton, TitleContainer } from '../../styles/course-card';
import AnimatedTitle from './animated-title';
import { newConversation } from '../../services/conversations';

const maxLengthDescription = 100; //chars

interface ProjectCardProps {
    id: string;
    title: string;
    description: string;
    professors: { _id: string; name: string; }[];
    removable?: boolean;
    onRemove?: (id: string) => void;
    chatButtonRef?: React.RefObject<HTMLButtonElement | null>;
    editButtonRef?: React.RefObject<HTMLButtonElement | null>;
    contributeButtonRef?: React.RefObject<HTMLButtonElement | null>;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
    id,
    title,
    description,
    professors,
    removable,
    onRemove,
    chatButtonRef,
    editButtonRef,
    contributeButtonRef
}) => {
    const { token } = theme.useToken();
    const navigate = useNavigate();
    const user = getUserDataFromLocalStorage();

    const handleNewConversation = () => {
        newConversation(user._id, "content", id).then(chat => {
            if (!user.course_id_list.includes(id)) {
                const updatedUser = {
                    ...user,
                    course_id_list: [...(user.course_id_list || []), id]
                };
                setUserDataInLocalStorage(updatedUser);
            }
            navigate("/chat/" + chat._id);
        });
    };

    const handleRemoveProject = () => {
        removeCourse(id, user._id).then(() => {
            const updatedUser = { ...user, course_id_list: user.course_id_list.filter((courseId: string) => courseId !== id) };
            setUserDataInLocalStorage(updatedUser);
            if (onRemove) onRemove(id);
        }).catch((error: any) => {
            console.error('Failed to remove project:', error);
        });
    };

    return (
        <StyledCard
            style={{
                minHeight: '210px',
                maxHeight: '210px',
                background: token.colorBgElevated
            }}
        >
            <CardContent>
                <div style={{ position: 'relative' }}>
                    <TitleContainer>
                        <AnimatedTitle title={title} color={token.colorInfoText} />
                    </TitleContainer>
                </div>
                <ContentContainer
                    style={{ minHeight: '120px', maxHeight: '120px' }}
                >
                    <Typography.Paragraph style={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        color: token.colorTextSecondary
                    }}>
                        {description.length > maxLengthDescription
                            ? description.slice(0, maxLengthDescription).split(' ').slice(0, -1).join(' ') + "..."
                            : description}
                    </Typography.Paragraph>
                    <ButtonContainer>
                        {[professor_type, autodidact_type, admin_type, technical_admin_type, marketpalce_admin_type].includes(user.type)
                            && professors.map((professor) => professor._id).includes(user._id) ? (
                            <>
                                <Button
                                    ref={chatButtonRef}
                                    type="primary"
                                    style={{ flex: 1 }}
                                    icon={<ToolOutlined />}
                                    onClick={handleNewConversation}
                                >
                                    Build
                                </Button>
                                <Button
                                    ref={editButtonRef}
                                    style={{ flex: 1 }}
                                    icon={<SettingOutlined />}
                                    onClick={() => navigate(`/courses/${id}`)}
                                >
                                    Edit
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    ref={chatButtonRef}
                                    type="primary"
                                    style={{ flex: 1 }}
                                    icon={<ToolOutlined />}
                                    onClick={handleNewConversation}
                                >
                                    Build
                                </Button>
                                <Button
                                    ref={contributeButtonRef}
                                    style={{ flex: 1 }}
                                    icon={<UploadOutlined />}
                                    onClick={() => navigate(`/courses/contribute/${id}`)}
                                >
                                    Contribute
                                </Button>
                            </>
                        )}
                    </ButtonContainer>
                </ContentContainer>
            </CardContent>
            {removable && (
                <StyledNoMarginButton
                    onClick={handleRemoveProject}
                >
                    <CloseOutlined
                        style={{
                            fontSize: '1.2rem',
                        }}
                    />
                </StyledNoMarginButton>
            )}
        </StyledCard>
    );
};

export default ProjectCard;