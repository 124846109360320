import { authedAxios } from "./auth-axios";

// export const getItemBannerUrl = (itemId: string, fileExtension?: string): string => {
//     // If an extension is provided, use it
//     if (fileExtension && fileExtension.startsWith('.')) {
//         return `${process.env.REACT_APP_BACKEND_URL}/api/image/${itemId}${fileExtension}`;
//     }
//     // Otherwise let the backend figure out the extension
//     return `${process.env.REACT_APP_BACKEND_URL}/api/image/${itemId}`;
// };

export const downloadImage = async (itemId: string, fileExtension?: string): Promise<string | undefined> => {
    try {
        // If an extension is provided, use it in the URL
        const imageUrl = fileExtension ? 
            `${process.env.REACT_APP_BACKEND_URL}/api/image/${itemId}${fileExtension}` : 
            `${process.env.REACT_APP_BACKEND_URL}/api/image/${itemId}`;
            
        // Get the response with the Azure URL
        const response = await authedAxios.get(imageUrl);
        
        // Return the URL from the response
        if (response.data && response.data.url) {
            return response.data.url;
        }
        
        return undefined;
    } catch (error) {
        console.error(`Error getting image URL:`, error);
        return undefined;
    }
};
