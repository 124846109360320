import { Table, Button } from 'antd';
import { SubscriptionInfo } from '../../services/subscription';

interface SubscriptionsTableProps {
  subscriptions: SubscriptionInfo[];
  loading: boolean;
  onSwitchBundle: () => void;
}

const SubscriptionsTable: React.FC<SubscriptionsTableProps> = ({
  subscriptions,
  loading,
  onSwitchBundle
}) => {
  const columns = [
    {
      title: 'Plan',
      dataIndex: 'plan',
      key: 'plan',
      width: '30%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      width: '15%',
      render: (date: string) => {
        const dateObj = new Date(date);
        return dateObj.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }).replace(/\//g, '-');
      },
    },
    {
      title: 'Renewal Date',
      dataIndex: 'renewal_date',
      key: 'renewal_date',
      width: '15%',
      render: (date: string) => {
        const dateObj = new Date(date);
        return dateObj.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }).replace(/\//g, '-');
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: '15%',
      render: () => (
        <Button
          type="primary"
          onClick={onSwitchBundle}
        >
          Switch Bundle
        </Button>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={subscriptions}
      loading={loading}
      rowKey="id"
      pagination={false}
    />
  );
};

export default SubscriptionsTable;