import { v4 as uuidv4 } from 'uuid';
import React, { useState } from 'react';
import { Modal, Form, Input, Button, Row, Col, message } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { PortfolioItem, PortfolioUrl } from '../../../types/user';

const { TextArea } = Input;

interface AddPortfolioModalProps {
    visible: boolean;
    onClose: () => void;
    onAdd: (item: PortfolioItem) => void;
}

const AddPortfolioModal: React.FC<AddPortfolioModalProps> = ({
    visible,
    onClose,
    onAdd
}) => {
    const [form] = Form.useForm();
    const [urls, setUrls] = useState<PortfolioUrl[]>([{ url: '', explanation: '' }]);

    const handleAddUrl = () => {
        if (urls.length < 10) {
            setUrls([...urls, { url: '', explanation: '' }]);
        }
    };

    const handleRemoveUrl = (index: number) => {
        if (urls.length > 1) {
            const newUrls = urls.filter((_, i) => i !== index);
            setUrls(newUrls);
        }
    };

    const handleUrlChange = (index: number, field: 'url' | 'explanation', value: string) => {
        const newUrls = urls.map((item, i) => {
            if (i === index) {
                return { ...item, [field]: value };
            }
            return item;
        });
        setUrls(newUrls);
    };

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            const filteredUrls = urls.filter(url => url.url.trim() !== '' && url.explanation.trim() !== '');

            if (filteredUrls.length === 0) {
                message.error('At least one URL with explanation is required');
            }

            const newItem: PortfolioItem = {
                id: uuidv4(),
                title: values.title,
                description: values.description,
                urls: filteredUrls as PortfolioUrl[]
            };

            onAdd(newItem);
            handleClose();
        } catch (error) {
            // Form validation will handle the error display
        }
    };

    const handleClose = () => {
        form.resetFields();
        setUrls([{ url: '', explanation: '' }]);
        onClose();
    };

    return (
        <Modal
            title="Add Portfolio Item"
            open={visible}
            onCancel={handleClose}
            width="60vw"
            footer={[
                <Button key="cancel" onClick={handleClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Add Item
                </Button>
            ]}
        >
            <Form
                form={form}
                layout="vertical"
            >
                <Form.Item
                    name="title"
                    label="Title"
                    required
                >
                    <Input placeholder="Enter title" />
                </Form.Item>

                <Form.Item
                    name="description"
                    label="Description"
                    required
                >
                    <TextArea rows={10} placeholder="Enter description" />
                </Form.Item>

                <div>URLs ({urls.length}/10)</div>

                {urls.map((url, index) => (
                    <div key={index}>
                        <Row gutter={32} style={{ marginBottom: '8px' }}>
                            <Col span={6}>
                                <Input
                                    placeholder="Enter URL"
                                    value={url.url}
                                    onChange={(e) => handleUrlChange(index, 'url', e.target.value)}
                                />
                            </Col>
                            <Col span={16}>
                                <Input
                                    placeholder="Enter explanation"
                                    value={url.explanation}
                                    onChange={(e) => handleUrlChange(index, 'explanation', e.target.value)}
                                />
                            </Col>
                            <Col span={2}>
                                <Button
                                    type="text"
                                    icon={<DeleteOutlined />}
                                    onClick={() => handleRemoveUrl(index)}
                                />
                            </Col>
                        </Row>
                    </div>
                ))}
                {urls.length < 10 && (
                    <Row gutter={32}
                    >
                        <Col span={22} />
                        <Col span={2}>
                            <Button
                                type="text"
                                icon={<PlusOutlined />}
                                onClick={handleAddUrl}
                            />
                        </Col>
                    </Row>
                )}
            </Form>
        </Modal>
    );
};

export default AddPortfolioModal;