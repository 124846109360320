import {styled} from '@stitches/react';
import {Layout, Spin, Typography, Button} from 'antd';
import React, {useEffect, useState, useRef} from 'react';
import CourseCard from '../../components/cards/course-card';
import {CourseTour} from '../../components/course/course-overview-tour';
import {CourseOverview, getUserCourseOverview} from '../../services/courses';
import {getUserDataFromLocalStorage} from '../../utils/useLocalStorage';
import SearchAndAddCourse from '../../components/course/SearchAndAddCourse';
import {admin_type, autodidact_type, marketpalce_admin_type, technical_admin_type} from '../../services/user';
import {ScrollableCourseOverviewContent} from '../settings/styles';
import {QuestionCircleOutlined} from "@ant-design/icons";

export const CardsContainer = styled("div", {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))", // Maintains a grid with a flexible number of columns
    gridGap: "50px", // Space between cards
    padding: "10px", // Padding inside the grid container
    alignItems: "start", // Aligns grid items to the top of the container
    justifyContent: "start", // Aligns grid items to the left of the container
    maxWidth: "100%",
    boxSizing: "border-box",
    minHeight: 0, // Important for proper scrolling in flex containers
    height: "100%", // Take up all available space
});

const CourseGallery: React.FC<{}> = () => {
    const user = getUserDataFromLocalStorage();
    const [courses, setCourses] = useState<CourseOverview[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedDate, setSelectedDate] = useState<any>(null);
    const [selectedProfessor, setSelectedProfessor] = useState<string | undefined>(undefined);
    const [tourOpen, setTourOpen] = useState<boolean>(false);

    // Refs for tour targets
    const chatButtonRef = useRef<HTMLButtonElement>(null);
    const editButtonRef = useRef<HTMLButtonElement>(null);
    const interactButtonRef = useRef<HTMLButtonElement>(null);
    const analyzeButtonRef = useRef<HTMLButtonElement>(null);


    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const user = getUserDataFromLocalStorage();
                const coursesData = await getUserCourseOverview(user._id, user.course_id_list);
                setCourses(coursesData);
            } catch (error) {
                console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
            }
        }
        const user = getUserDataFromLocalStorage();
        fetchCourses().then(() => setIsLoading(false));
    }, []);

    // Filter courses based on search query, last_updated, and professor names
    const filteredCourses = courses.filter(course => {
        const matchesSearchQuery = course.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            course.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
            course.last_updated.toLowerCase().includes(searchQuery.toLowerCase()) ||
            course.professors.some(professor => professor.name.toLowerCase().includes(searchQuery.toLowerCase()));

        const matchesDateFilter = selectedDate ? new Date(course.last_updated) >= selectedDate.toDate() : true; // Filter by last_updated
        const matchesProfessorFilter = selectedProfessor ? course.professors.some(professor => professor.name === selectedProfessor) : true; // Filter by professor name

        return matchesSearchQuery && matchesDateFilter && matchesProfessorFilter;
    });

    const handleRemoveCourse = (id: string) => {
        setCourses(prevCourses => prevCourses.filter(course => course._id !== id));
    };

    return (
        <ScrollableCourseOverviewContent>
            <div style={{flexShrink: 0}}> {/* Prevent search from shrinking */}
                <SearchAndAddCourse
                    userType={user.type}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    professors={[...new Set(courses.flatMap(course => course.professors.map(prof => prof.name)))]}
                    setSelectedDate={setSelectedDate}
                    setSelectedProfessor={setSelectedProfessor}
                />
            </div>
            {isLoading && <Spin size="large"/>}
            {!isLoading && courses.length === 0 && ( // Check if courses are empty
                <div style={{textAlign: 'center', marginTop: '20px'}}>
                    <Typography.Title level={2}>Nothing here yet :/</Typography.Title>
                    <Typography.Paragraph>Your educator will enroll you soon.</Typography.Paragraph>
                </div>
            )}
            {!isLoading && courses.length > 0 && ( // Render cards only if courses are available
                <CardsContainer>
                    {filteredCourses.map((course, index) => (
                        <CourseCard
                            key={`${course._id}-${index}`}
                            id={course._id}
                            title={course.name}
                            description={course.description}
                            professors={course.professors}
                            lastUpdated={course.last_updated}
                            removable={!(course.professors.map(professor => professor._id).includes(user._id))
                                && [admin_type, technical_admin_type, marketpalce_admin_type, autodidact_type].includes(user.type)}
                            onRemove={handleRemoveCourse}
                            // Only pass refs to the first card for the tour
                            chatButtonRef={index === 0 ? chatButtonRef : undefined}
                            editButtonRef={index === 0 ? editButtonRef : undefined}
                            interactButtonRef={index === 0 ? interactButtonRef : undefined}
                            analyzeButtonRef={index === 0 ? analyzeButtonRef : undefined}
                        />
                    ))}
                </CardsContainer>
            )}

            <Button
                icon={<QuestionCircleOutlined/>}
                onClick={() => setTourOpen(true)}
                style={{
                    marginLeft: '16px', width: '5%', minWidth: '40px', position: 'fixed', bottom: '20px', right: '20px'
                }}
            />
            <CourseTour
                open={tourOpen}
                onClose={() => setTourOpen(false)}
                chatRef={chatButtonRef}
                editRef={editButtonRef}
                interactRef={interactButtonRef}
                analyzeRef={analyzeButtonRef}
            />
        </ScrollableCourseOverviewContent>
    );
};

export default CourseGallery;