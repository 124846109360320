import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { HeaderContainer } from "../../styles/chat";

const { Title } = Typography;

interface DiscoverHeaderProps {
    onTourOpen: () => void;
}

const DiscoverHeader: React.FC<DiscoverHeaderProps> = ({ onTourOpen }) => {
    return (
        <HeaderContainer>
            <div style={{ width: '90%' }}>
                <Title
                    level={2}
                    style={{
                        margin: 0,
                        fontSize: '24px'
                    }}
                >
                    Discover
                </Title>
                <p style={{ margin: 0 }}>
                    Explore and learn about various topics
                </p>
            </div>
            <div style={{
                width: '10%',
                display: 'flex',
                justifyContent: 'center',
                flexWrap: "nowrap",
                maxWidth: "100%",
                flexDirection: "row",
                alignItems: "flex-start",
                padding: "0px 0px",
            }}>
                <Button
                    icon={<QuestionCircleOutlined />}
                    style={{
                        flexShrink: 0,
                        height: '100%',
                        margin: 5,
                        width: '100%',
                    }}
                    onClick={onTourOpen}
                />
            </div>
        </HeaderContainer>
    );
};

export default DiscoverHeader;
