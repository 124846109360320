import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Form, Input, Button, Typography, Alert, Spin } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { AbsoluteCenter } from '../../components/styles';
import { updateInitialPassword } from '../../services/user';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';

export interface SignupProps {
  loginCallback: () => void;
}

const ChangePasswordScreen: React.FC<SignupProps> = ({ loginCallback }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  
  const handleSubmit = async (values: { new_password: string; confirm_password: string }) => {
    try {
      if (values.new_password !== values.confirm_password) {
        setError('Passwords do not match');
        return;
      }
      
      setLoading(true);
      setError(null);
      
      const userData = getUserDataFromLocalStorage();
      
      if (!userData || !userData._id) {
        setError('User session not found. Please login again.');
        setLoading(false);
        return;
      }
      
      // Use our specialized function for magic link password updates
      await updateInitialPassword(userData._id, values.new_password);
      
      // On success, navigate to home
      loginCallback();
    } catch (error) {
      console.error('Password change failed:', error);
      setError('Failed to update password. Please try again.');
      setLoading(false);
    }
  };
  
  return (
    <AbsoluteCenter>
      <Card style={{ width: 400 }}>
        <Typography.Title level={3} style={{ textAlign: 'center' }}>
          Set Your Password
        </Typography.Title>
        <Typography.Paragraph style={{ textAlign: 'center' }}>
          Please set a new password to continue.
        </Typography.Paragraph>
        
        {error && (
          <Alert 
            message={error} 
            type="error" 
            showIcon 
            style={{ marginBottom: 16 }} 
          />
        )}
        
        <Form
          name="change_password"
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item
            name="new_password"
            label="New Password"
            rules={[
              { required: true, message: 'Please enter your new password' },
              { min: 8, message: 'Password must be at least 8 characters' }
            ]}
            hasFeedback
          >
            <Input.Password 
              prefix={<LockOutlined />} 
              placeholder="New Password" 
            />
          </Form.Item>
          
          <Form.Item
            name="confirm_password"
            label="Confirm Password"
            dependencies={['new_password']}
            rules={[
              { required: true, message: 'Please confirm your password' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match'));
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password 
              prefix={<LockOutlined />} 
              placeholder="Confirm Password" 
            />
          </Form.Item>
          
          <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit" 
              style={{ width: '100%' }}
              disabled={loading}
            >
              {loading ? <Spin size="small" /> : 'Set Password'}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </AbsoluteCenter>
  );
};

export default ChangePasswordScreen;
