import { DeleteOutlined, DoubleRightOutlined, MessageOutlined } from "@ant-design/icons";
import { Layout, Popconfirm, Spin, message, theme, Table, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from '@stitches/react';
import { deleteConversation, loadConversationHistory } from '../../services/conversations';
import { CourseOverview, getUserCourseOverview } from "../../services/courses";
import { getUserDataFromLocalStorage } from "../../utils/useLocalStorage";
import { capitalize, formatDate } from "../../utils/utils";
import { FlexMarginButton, NoMarginButton } from "../../components/basic/buttons";
import { ScrollableContent } from "../settings/styles";
import { chatFormatAnalytics, chatFormatContent, chatFormatDiscover } from "../../config";
import { ConversationHistoryItem } from "../../types/chat";

const { Content } = Layout;

const ContentWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'min-content', // Ensures content takes minimum required height
    gap: '1rem'
});

const LoadMoreContainer = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '1rem 0'
});

const get_chat_route = (type: string): string => {
    switch (type) {
        case chatFormatContent:
            return '/chat';
        case chatFormatAnalytics:
            return '/analytics-copilot';
        case chatFormatDiscover:
            return '/discover';
        default:
            return '/chat';
    }
};

const ChatHistory = () => {
    const user = getUserDataFromLocalStorage();
    const { token } = theme.useToken();
    const [chatHistory, setChatHistory] = useState<ConversationHistoryItem[]>([]);
    const [courses, setCourses] = useState<CourseOverview[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasLoadedMore, setHasLoadedMore] = useState(false);
    const navigate = useNavigate();

    async function fetchChatHistory(recent: boolean, currentCourses: CourseOverview[]) {
        if (recent !== true) {
            setHasLoadedMore(true);
        }
        const conversations = await loadConversationHistory(recent, user._id);
        const filteredConversations = conversations.filter(conversation =>
            currentCourses.some(course => course._id === conversation.course_id) || conversation.type === chatFormatDiscover
        );
        setChatHistory(filteredConversations);
    }

    useEffect(() => {
        setIsLoading(true);
        getUserCourseOverview(user._id, user.course_id_list)
            .then(fetchedCourses => {
                setCourses(fetchedCourses);
                return fetchedCourses;
            })
            .then(fetchedCourses => fetchChatHistory(true, fetchedCourses))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    function handleChatDelete(conversationId: string) {
        deleteConversation(conversationId).then(() => {
            setChatHistory(prev => prev.filter(c => c._id !== conversationId))
            message.success('Chat deleted successfully.');
        })
    }

    // Function to get the display type of a conversation
    const getDisplayType = (conversation: ConversationHistoryItem): string => {
        // If the conversation type is 'content', check if it's a project or course
        if (conversation.type === chatFormatContent) {
            const course = courses.find(c => c._id === conversation.course_id);
            if (course && course.config && course.config.is_project === true) {
                return 'project';
            }
            return 'course';
        }
        // Return the original type for non-content conversations
        return conversation.type;
    };

    // Get unique types for filter options
    const getUniqueTypes = () => {
        // Get all display types instead of raw types
        const displayTypes = chatHistory.map(item => getDisplayType(item));
        const uniqueTypes = [...new Set(displayTypes)];
        return [
            ...uniqueTypes.map(type => ({
                text: type.charAt(0).toUpperCase() + type.slice(1),
                value: type as React.Key
            }))
        ];
    };

    // Define columns for AntD Table
    const columns = [
        {
            title: 'Date',
            dataIndex: 'last_updated',
            key: 'last_updated',
            render: (text: string) => formatDate(text),
            width: '20%',
        },
        {
            title: 'Chat Name',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
        },
        {
            title: `${capitalize(user.config.course_terminology)} Name`,
            key: 'course_name',
            width: '20%',
            render: (_: any, record: ConversationHistoryItem) => {
                const course = courses.find(s => s._id === record.course_id);
                return course ? course.name : '';
            },
        },
        {
            title: 'Type',
            key: 'type',
            width: '15%',
            render: (_: any, record: ConversationHistoryItem) => {
                const displayType = getDisplayType(record);
                return displayType.charAt(0).toUpperCase() + displayType.slice(1);
            },
            filters: getUniqueTypes(),
            filterMultiple: false,
            onFilter: (value: React.Key | boolean, record: ConversationHistoryItem) => {
                const filterValue = String(value);
                const displayType = getDisplayType(record);
                return displayType === filterValue;
            },
        },
        {
            title: 'Length',
            dataIndex: 'number_of_messages',
            key: 'number_of_messages',
            width: '15%',
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '10%',
            render: (_: any, record: ConversationHistoryItem) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Popconfirm
                        title="Delete the chat"
                        description={<p>Are you sure to delete this chat?<br />This action is irreversible</p>}
                        onConfirm={() => handleChatDelete(record._id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <NoMarginButton icon={<DeleteOutlined />}></NoMarginButton>
                    </Popconfirm>
                    <NoMarginButton
                        type="primary"
                        onClick={() => navigate(`${get_chat_route(record.type)}/${record._id}`)}
                        icon={<DoubleRightOutlined />}
                        style={{ marginLeft: "10px" }}
                    >
                    </NoMarginButton>
                </div>
            ),
        },
    ];

    return (
        <ScrollableContent>
            <ContentWrapper>
                {(isLoading && !hasLoadedMore) ? (
                    <Spin size="large" />
                ) : (
                    <>
                        <Table
                            columns={columns}
                            dataSource={chatHistory.sort((a, b) => new Date(b.last_updated).getTime() - new Date(a.last_updated).getTime())}
                            rowKey="_id"
                            loading={isLoading}
                            pagination={false}
                            style={{ backgroundColor: token.colorBgContainer }}
                        />
                        {(isLoading && hasLoadedMore) ? (
                            <Spin size="large" />
                        ) : (
                            <LoadMoreContainer>
                                <FlexMarginButton
                                    onClick={() => fetchChatHistory(false, courses)}
                                    disabled={hasLoadedMore}
                                >
                                    Load More
                                </FlexMarginButton>
                            </LoadMoreContainer>
                        )}
                    </>
                )}
            </ContentWrapper>
        </ScrollableContent>
    );
};

export default ChatHistory;