import { styled } from "@stitches/react";
import { Button, Layout, Menu } from "antd";

const { Content } = Layout;

export const SourcesButton = styled(Button, {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 16px',
    height: 'auto',
    borderRadius: '4px',
    transition: 'color 0.3s',
});

export const SourceButtonContainer = styled("div", {
    display: "block",
    width: "100%",
    maxWidth: '100%',
    overflow: "hidden",
    overflowWrap: "break-word",
    margin: "0.5rem 0",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    // border: "1px solid rgba(0, 0, 0, 0.05)",
    borderRadius: "4px",
    paddingRight: "8px"
});

export const SourceButtonTitle = styled("div", {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch", // Changed from center to stretch for vertical alignment
    padding: "8px 12px 8px 12px",
    width: "100%",
});

export const SourceButtonFooter = styled("div", {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "4px 12px 8px",
    width: "100%",
    // borderTop: "1px solid rgba(0, 0, 0, 0.05)",
    fontSize: "12px",
    gap: "12px",
});

// Using Ant Design Progress component instead of custom relevancy bar

export const Container = styled(Content, {
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // Centers children horizontally
    // alignItems: "flex-start",
    // justifyContent: "center",
    height: "100vh",
    width: "100%",
    maxWidth: "100%",
    margin: "0 auto",
    gap: "20px",
    padding: "2rem",
    boxSizing: "border-box",
    overflow: "hidden", // Prevent container from scrolling
    '& > *': {
        maxWidth: '100%', // Ensure all direct children don't exceed container width
    },
})

export const HeaderContainer = styled("div", {
    display: "flex",
    width: '100%',
    height: 'auto',
    minHeight: "10vh",
    maxHeight: "auto",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "10px 0",
    borderBottom: "1px solid",
    boxSizing: "border-box",
    overflow: "hidden", // Prevent overflow
    flexWrap: "wrap", // Allow content to wrap
});

export const CourseDescription = styled("p", {
    margin: "1 0",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    wordWrap: "break-word",
    // Allow text to wrap on smaller screens
    '@media (max-width: 768px)': {
        whiteSpace: "normal",
    },
    // Keep nowrap on larger screens but with ellipsis
    '@media (min-width: 769px)': {
        whiteSpace: "nowrap",
    }
});

export const MessagesContainer = styled("div", {
    width: '100%',
    maxWidth: '100%',
    flex: "1 1 auto", // Allow container to grow and shrink
    minHeight: "0", // Required for flex child to scroll
    // height: "75vh",
    // maxHeight: "80vh",
    overflowY: "auto",
    overflowX: "hidden", // Prevent horizontal scrolling
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: "24px", // Account for input height (3 rows max) + padding
    '& > *': {
        maxWidth: '100%', // Ensure all direct children don't exceed container width
        wordWrap: 'break-word', // Ensure text wraps
    },
});

export const StyledSider = styled(Layout.Sider, {
    "& > .ant-layout-sider-children": {
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
})

export const StyledMenu = styled(Menu, {
    width: '100%',
    marginTop: 'auto',
})

export const MessageActionsContainer = styled("div", {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 0,
    marginBottom: '20px'
});