import React, { useRef, useState, useMemo } from 'react';
import { Button, Tooltip, Progress, theme } from 'antd';
import { CopyOutlined, CheckOutlined, PlayCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { styled } from '@stitches/react';

const CodeBlockContainer = styled('div', {
  width: '100%',
  borderRadius: '6px',
  overflow: 'hidden',
  backgroundColor: '#1e1e1e',
  marginBottom: '1rem',
});

const CodeHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0.5rem 1rem',
  borderBottom: '1px',
});

const CodeContent = styled('pre', {
  margin: 0,
  padding: '1rem',
  overflow: 'auto',
  '& code': {
    fontFamily: 'Monaco, Menlo, Consolas, "Courier New", monospace',
    fontSize: '0.9rem',
    lineHeight: '1.5',
  }
});

// const ExecutionResult = styled('div', {
//   padding: '1rem',
//   color: '#fff',
//   borderTop: '1px',

//   '& pre': {
//     margin: 0,
//     whiteSpace: 'pre-wrap',
//     wordWrap: 'break-word'
//   }
// });

interface ExecutionState {
  status: 'idle' | 'running' | 'success' | 'error';
  output?: string;
  error?: string;
  progress: number;
}

interface CodeBlockProps {
  language: string;
  code: string;
  enableExecution?: boolean;
  conversationId?: string;
  messageId?: string;
  blockIndex?: number;
}

const CodeBlock: React.FC<CodeBlockProps> = ({
  language,
  code,
  enableExecution = false,
  conversationId,
  messageId,
  blockIndex
}) => {
  const { token } = theme.useToken();
  const [copied, setCopied] = useState(false);
  const [execution, setExecution] = useState<ExecutionState>({
    status: 'idle',
    progress: 0
  });

  const codeRef = useRef<HTMLPreElement>(null);

  const handleCopy = () => {
    if (codeRef.current) {
      navigator.clipboard.writeText(code);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  // TODO implement that next
  // const showExecuteButton = useMemo(() => {
  //     return enableExecution && ['python', 'javascript', 'typescript', 'java'].includes(language.toLowerCase());
  // }, [enableExecution, language]);

  return (
    <CodeBlockContainer
      style={{ backgroundColor: token.colorBgElevated }}>
      <CodeHeader
        style={{ backgroundColor: token.colorBgSpotlight }}
      >
        <div className="flex items-center gap-2">
          <span style={{ color: 'rgba(249,249,249,1)' }}>{language}</span>
          {/* {execution.status === 'running' && (
            <Progress
              type="circle"
              percent={execution.progress}
              size={20}
            />
          )} */}
        </div>
        <div className="flex items-center gap-2">
          {/* {showExecuteButton && (
                        <Tooltip title={execution.status === 'running' ? 'Running...' : 'Run code'}>
                            <Button 
                                type="text" 
                                icon={<PlayCircleOutlined />} 
                                onClick={handleExecute}
                                disabled={execution.status === 'running'}
                                style={{ color: '#89CFF0' }}
                            />
                        </Tooltip>
                    )} */}
          <Tooltip title={copied ? 'Copied!' : 'Copy code'}>
            <Button
              type="text"
              icon={copied ? <CheckOutlined style={{ color: 'rgba(249,249,249,1)' }} /> : <CopyOutlined style={{ color: 'rgba(249,249,249,1)' }} />}
              onClick={handleCopy}
            // style={{ color: copied ? '#52c41a' : '#89CFF0' }}
            />
          </Tooltip>
        </div>
      </CodeHeader>

      <CodeContent ref={codeRef}>
        <code className={`language-${language}`}>
          {code}
        </code>
      </CodeContent>

      {/* {(execution.status === 'success' || execution.status === 'error') && (
        <ExecutionResult>
          <div className="flex items-center gap-2 mb-2">
            {execution.status === 'error' ? (
              <InfoCircleOutlined style={{ color: '#ff4d4f' }} />
            ) : (
              <CheckOutlined style={{ color: '#52c41a' }} />
            )}
            <span>{execution.status === 'error' ? 'Error' : 'Output'}</span>
          </div>
          <pre>
            {execution.status === 'error' ? execution.error : execution.output}
          </pre>
        </ExecutionResult>
      )} */}
    </CodeBlockContainer>
  );
};

export default CodeBlock;