import React, { useState } from 'react';
import { Typography, Avatar, Tooltip, theme, Button, Badge } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { UserOverview } from '../../services/courses';
import { Community } from '../../services/communities';
import UserManagementModal from './UserManagementModal';

interface CommunityMembersSectionProps {
  leaders: UserOverview[];
  members: UserOverview[];
  onMemberClick: (userId: string) => void;
  isLeader: boolean;
  communityId: string;
  applicationsCount?: number;
  onApplicationsChange?: () => void;
  onMembersUpdate?: (community: Community) => void;
}

export const CommunityMembersSection: React.FC<CommunityMembersSectionProps> = ({
  leaders,
  members,
  onMemberClick,
  isLeader,
  communityId,
  applicationsCount = 0,
  onApplicationsChange,
  onMembersUpdate,
}) => {
  const [isUserManagementModalVisible, setIsUserManagementModalVisible] = useState(false);
  const { token } = theme.useToken();
  
  const handleApplicationsChange = () => {
    if (onApplicationsChange) {
      onApplicationsChange();
    }
  };

  const handleMemberUpdate = (updatedCommunity: Community) => {
    if (onMembersUpdate) {
      onMembersUpdate(updatedCommunity);
    }
  };

  return (
    <div style={{ margin: '20px 0' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <Typography.Title level={2} style={{ margin: 0 }}>Members</Typography.Title>
        {isLeader && (
          <Badge count={applicationsCount} overflowCount={99} offset={[-5, 5]}>
            <Button 
              icon={<UserOutlined />} 
              onClick={() => setIsUserManagementModalVisible(true)}
            >
              Manage Members
            </Button>
          </Badge>
        )}
      </div>
      <Avatar.Group
        maxCount={20}
        size="large"
      >
        {/* Leaders first with special styling */}
        {leaders.map(leader => (
          <Tooltip title={`${leader.name} (Leader)`} key={leader._id}>
            <Avatar 
              src={leader.photo_url} 
              style={{ border: token.colorPrimary, cursor: 'pointer', width: '64px', height: '64px', fontSize: '24px', lineHeight: '64px' }}
              onClick={() => onMemberClick(leader._id)}
            >
              {leader.name.charAt(0)}
            </Avatar>
          </Tooltip>
        ))}
        
        {/* Regular members */}
        {members
          .filter(member => !leaders.some(leader => leader._id === member._id))
          .map(member => (
            <Tooltip title={member.name} key={member._id}>
              <Avatar 
                src={member.photo_url}
                style={{ cursor: 'pointer', width: '64px', height: '64px', fontSize: '24px', lineHeight: '64px' }}
                onClick={() => onMemberClick(member._id)}
              >
                {member.name.charAt(0)}
              </Avatar>
            </Tooltip>
          ))}
      </Avatar.Group>

      {/* User Management Modal */}
      <UserManagementModal
        visible={isUserManagementModalVisible}
        onClose={() => setIsUserManagementModalVisible(false)}
        communityId={communityId}
        onApplicationsChange={handleApplicationsChange}
        onMemberUpdate={handleMemberUpdate}
        community={{
          _id: communityId,
          leaders: leaders,
          members: members,
          created_at: '',
          last_updated: '',
          name: '',
          description: '',
          banner_image: '',
          project_ids: [],
          course_ids: [],
          project_count: 0,
          course_count: 0,
          member_count: members.length,
          visibility: '',
          config: {
            private: false
          }
        }}
      />
    </div>
  );
};

export default CommunityMembersSection;