import React, { useState, useEffect } from "react";
import { Modal, Upload, Form, Button, Checkbox, Input, InputNumber, Typography, message } from "antd";
import { DashboardFilled, InboxOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/lib/upload/interface";
import { supportedFormats, audioFormats, noPageFormats } from "../../config";
import { styled } from "@stitches/react";
import { convertTimeToSeconds } from "../../utils/string_functions";
import { submitFileContribution } from "../../services/files";
import { CourseFile } from "../../services/files";
import { generateSpecFileName, timePattern } from "./FileSpecificationOverlay";




const isSpecFileExists = (
  originalName: string,
  startPage: number,
  endPage: number,
  existingFiles: CourseFile[]
): boolean => {
  const specFileName = generateSpecFileName(originalName, startPage, endPage);
  return existingFiles.some(file => file.file_name === specFileName);
};

interface FileMetadata {
  fileType: 'document' | 'media' | 'other';
  pages?: number;
  duration?: number;
}

const { Dragger } = Upload;

const StyledDragger = styled(Dragger, {
  '& .ant-upload-drag': {
    height: 'auto'
  }
});

interface ContributionModalProps {
  visible: boolean;
  onClose: () => void;
  courseId: string;
  weekIndex: number;
  weekTitle: string;
  existingFiles?: CourseFile[];
}

const ContributionModal: React.FC<ContributionModalProps> = ({
  visible,
  onClose,
  courseId,
  weekIndex,
  weekTitle,
  existingFiles = []
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const [isAudio, setIsAudio] = useState(false);
  const [spansFullyChecked, setSpansFullyChecked] = useState(false);
  const [noPageFormat, setNoPageFormat] = useState(false);
  const [fileMetadata, setFileMetadata] = useState<FileMetadata | null>(null);

  useEffect(() => {
    if (visible) {
      // Reset form when modal becomes visible
      setFileList([]);
      setIsAudio(false);
      setSpansFullyChecked(false);
      setFileMetadata(null);
      form.resetFields();
    }
  }, [visible, form]);

  const detectFileMetadata = async (file: File) => {
    // Detect file type and properties
    const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
    let metadata: FileMetadata = {
      fileType: 'other'
    };

    if (audioFormats.some(ext => file.name.endsWith(ext))) {
      // Document type
      metadata.fileType = 'media';
    } else if (noPageFormats.some(ext => file.name.endsWith(ext))) {
      // Document type
      metadata.fileType = 'document';
      setSpansFullyChecked(true);
      setNoPageFormat(true);
    } else {
      // Audio type
      metadata.fileType = 'document';
    }

    setFileMetadata(metadata);
    return metadata;
  };

  const handleFileTypeChange = async (file: File) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
    setIsAudio(audioFormats.includes(fileExtension));
    setNoPageFormat(noPageFormats.includes(fileExtension));
    setSpansFullyChecked(false);
    await detectFileMetadata(file);
  };

  const validateValue = async (_: any, value: string) => {
    if (!value) return Promise.resolve();

    try {
      const formValues = form.getFieldsValue();

      if (fileMetadata?.fileType === 'document' && fileMetadata.pages) {
        // Validate document pages
        const startPage = parseInt(formValues.startPage);
        const endPage = parseInt(formValues.endPage);

        if (startPage && endPage && fileList[0]) {
          // Check if file exists with these specs
          if (isSpecFileExists(fileList[0].name, startPage, endPage, existingFiles)) {
            return Promise.reject(new Error('A file with these page specifications already exists'));
          }

          // Validate page range
          if (endPage <= startPage) {
            return Promise.reject(new Error('End page must be greater than start page'));
          }

          if (endPage > fileMetadata.pages) {
            return Promise.reject(new Error('End page cannot exceed document length'));
          }
        }
      } else if (fileMetadata?.fileType === 'media' && fileMetadata.duration) {
        // Validate media time
        const startTime = convertTimeToSeconds(formValues.startPage);
        const endTime = convertTimeToSeconds(formValues.endPage);

        if (startTime && endTime && fileList[0]) {
          // Check if file exists with these specs
          if (isSpecFileExists(fileList[0].name, startTime, endTime, existingFiles)) {
            return Promise.reject(new Error('A file with these time specifications already exists'));
          }

          // Validate time range
          if (endTime <= startTime) {
            return Promise.reject(new Error('End time must be greater than start time'));
          }

          if (endTime > fileMetadata.duration) {
            return Promise.reject(new Error('End time cannot exceed media duration'));
          }
        }
      }
    } catch (error) {
      return Promise.resolve();
    }

    return Promise.resolve();
  };

  const uploadProps = {
    onRemove: () => {
      setFileList([]);
      setIsAudio(false);
      setFileMetadata(null);
      form.resetFields();
    },
    beforeUpload: async (file: File) => {
      const fileFormat = `.${file.name.split('.').pop()?.toLowerCase()}`;
      if (!fileFormat || !supportedFormats.includes(fileFormat)) {
        message.error('Unsupported file format!');
        return false;
      }
      if (file.name in fileList || existingFiles.some(f => f.file_name === file.name)) {
        message.error('File already exists.');
        return false;
      }
      await handleFileTypeChange(file);
      setFileList([file as unknown as UploadFile]);
      return false;
    },
    fileList,
    multiple: false,
  };

  const handleSubmit = async () => {
    if (fileList.length === 0) {
      message.error('Please select a file to contribute');
      return;
    }

    try {
      await form.validateFields().then(async values => {
        setSubmitting(true);

        // Create FormData with file and metadata
        const formData = new FormData();
        formData.append('file', fileList[0] as any);
        formData.append('courseId', courseId);
        formData.append('weekIndex', weekIndex.toString());
        formData.append('weekTitle', weekTitle);
        formData.append('spansFullly', spansFullyChecked.toString());

        if (!spansFullyChecked) {
          // Handle time conversion for audio files
          if (isAudio) {
            const startTime = convertTimeToSeconds(values.startPage);
            const endTime = convertTimeToSeconds(values.endPage);
            formData.append('startPage', startTime.toString());
            formData.append('endPage', endTime.toString());
          } else {
            formData.append('startPage', values.startPage.toString());
            formData.append('endPage', values.endPage.toString());
          }
        }

        await submitFileContribution(formData);

        message.success('File contribution submitted successfully!');
        setFileList([]);
        setFileMetadata(null);
        form.resetFields();
        setSpansFullyChecked(false);
        onClose();
      });
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message || 'Failed to submit contribution');
      } else {
        console.error('Error submitting contribution:', error);
        message.error('Failed to submit contribution');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const resetState = () => {
    setFileList([]);
    setFileMetadata(null);
    form.resetFields();
    setSpansFullyChecked(false);
    setIsAudio(false);
  };

  const handleCancel = () => {
    resetState();
    onClose();
  };

  return (
    <Modal
      title={`Contribute to ${weekTitle}`}
      open={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          loading={submitting}
          disabled={fileList.length === 0}
        >
          Submit Contribution
        </Button>
      ]}
      width={700}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ spansFullly: spansFullyChecked }}
      >
        <StyledDragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to contribute</p>
          <p className="ant-upload-hint">
            Supported formats: {supportedFormats.join(', ')}.
          </p>
        </StyledDragger>

        {fileList.length > 0 && (
          <>
            <Form.Item
              name="spansFullly"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '16px'
              }}
            >
              <Checkbox
                checked={spansFullyChecked}
                onChange={(e) => setSpansFullyChecked(e.target.checked)}
                disabled={noPageFormat}
              >
                Use entire file
              </Checkbox>
            </Form.Item>
            {!spansFullyChecked && fileMetadata && (
              <div style={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
                {fileMetadata.fileType === 'document' ? (
                  // Document inputs with InputNumber
                  <>
                    <Form.Item
                      name="startPage"
                      label="Start Page"
                      rules={[
                        { required: true, message: 'Please specify the start page' },
                        {
                          validator: async (_, value) => {
                            if (!value) return Promise.resolve();
                            const endPage = form.getFieldValue('endPage');
                            if (endPage && value > endPage) {
                              return Promise.reject(new Error('End page must be greater than start page'));
                            }
                            if (value <= 0) {
                              return Promise.reject(new Error('End page must be greater than start page'));
                            }
                            if (value > fileMetadata.pages!) {
                              return Promise.reject(new Error('End page cannot exceed document length'));
                            }
                            return validateValue(_, value);
                          }
                        },
                        { validator: validateValue }
                      ]}
                      style={{ flex: 1 }}
                    >
                      <InputNumber
                        min={1}
                        max={fileMetadata.pages}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>

                    <Form.Item
                      name="endPage"
                      label="End Page"
                      rules={[
                        { required: true, message: 'Please specify the end page' },
                        {
                          validator: async (_, value) => {
                            if (!value) return Promise.resolve();
                            const startPage = form.getFieldValue('startPage');
                            if (startPage && value < startPage) {
                              return Promise.reject(new Error('End page must be greater than start page'));
                            }
                            if (value > fileMetadata.pages!) {
                              return Promise.reject(new Error('End page cannot exceed document length'));
                            }
                            return validateValue(_, value);
                          }
                        }
                      ]}
                      style={{ flex: 1 }}
                    >
                      <InputNumber
                        min={1}
                        max={fileMetadata.pages}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </>
                ) : (
                  // Audio inputs with time format
                  <>
                    <Form.Item
                      name="startPage"
                      label="Start Time (HH:MM:SS)"
                      rules={[
                        { required: true, message: 'Please specify the start time' },
                        { pattern: timePattern, message: 'Please use HH:MM:SS format' },
                        { validator: validateValue }
                      ]}
                      style={{ flex: 1 }}
                    >
                      <Input placeholder="MM:SS" />
                    </Form.Item>

                    <Form.Item
                      name="endPage"
                      label="End Time (HH:MM:SS)"
                      rules={[
                        { required: true, message: 'Please specify the end time' },
                        { pattern: timePattern, message: 'Please use HH:MM:SS format' },
                        { validator: validateValue }
                      ]}
                      style={{ flex: 1 }}
                    >
                      <Input placeholder="MM:SS" />
                    </Form.Item>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </Form>
    </Modal>
  );
};

export default ContributionModal;