import { message } from "antd";

export function isEmptyOrOnlyTags(str: string): boolean {
  // Trim the string to remove leading and trailing whitespace
  const trimmed = str.trim();

  // Check if the string is empty after trimming
  if (trimmed === '') {
    return true;
  }

  // Remove all HTML tags
  const withoutTags = trimmed.replace(/<[^>]*>/g, '');

  // Remove all whitespace characters (spaces, tabs, newlines)
  const withoutWhitespace = withoutTags.replace(/\s/g, '');

  // If the resulting string is empty, it means the original string
  // contained only HTML tags and/or whitespace
  return withoutWhitespace === '';
}

export function convertTimeToSeconds(timeString: string | number | undefined): number {
  if (typeof timeString === 'number') return timeString;
  if (!timeString) return 0; // Return 0 instead of undefined

  const parts = timeString.split(':');

  if (parts.length === 2) {
    const [minutes, seconds] = parts.map(Number);
    if (isNaN(minutes) || isNaN(seconds)) return Number(NaN); // Invalid numbers, return 0  
    return minutes * 60 + seconds;
  } else if (parts.length === 3) {
    const [hours, minutes, seconds] = parts.map(Number);
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) return Number(NaN); // Invalid numbers, return 0
    return hours * 3600 + minutes * 60 + seconds;
  } else {
    message.error('Time specifications should be either HH:MM:SS or MM:SS') // Invalid format, return 0
    return Number(timeString);
  }

}

export function convertSecondsToTime(seconds: number | undefined): string {
  if (seconds === undefined) return '';
  if (seconds === 0) return '0:00';
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
}