import {
    CheckCircleOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    InboxOutlined,
    LinkOutlined,
    SyncOutlined
} from "@ant-design/icons";
import { Spacer } from "@nextui-org/react";
import { styled } from "@stitches/react";
import { Button, Input, Modal, Table, Typography, Upload, UploadFile, UploadProps, message, Tabs, Checkbox } from "antd";
import { useContext, useEffect, useState } from "react";
import { CourseContext } from "../../screens/courses/course";
import { extractAudioAndConvertToAudioFile } from "../../services/courses";
import { getUserDataFromLocalStorage } from "../../utils/useLocalStorage";
import { capitalize, formatDate } from "../../utils/utils";
import React from "react";
import { supportedFormats } from "../../config";
import { FlexMarginButton } from "../basic/buttons";
import { authedAxios } from "../../services/auth-axios";
import FileSpecificationOverlay from './FileSpecificationOverlay';
import { analyzeFile } from "./file-analysis";
import { convertTimeToSeconds } from "../../utils/string_functions";
import PendingContributions from "./pending-contributions";
import { CourseFile, deleteCourseFile, FileSpecifications, setFilesDownloadable } from "../../services/files";

const { confirm } = Modal;
const { Dragger } = Upload;

const StyledDragger = styled(Dragger, {
    '& .ant-upload-drag': {
        height: 'auto'
    }
});

const UploadSection = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginBottom: '1rem'
});

const URLInputSection = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
});

const InputRow = styled('div', {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center'
});

interface UploadCallbacks {
    onSuccess?: (response: any) => void;
    onError?: (error: any) => void;
    onProgress?: (event: any) => void;
}

interface FileMetadata {
    fileType: 'document' | 'media' | 'other';
    pages?: number;
    duration?: number;
}

interface FileWithMetadata {
    file: UploadFile;
    metadata: FileMetadata;
    callbacks: UploadCallbacks;
}

interface MaterialsProps {
    materialsTableRef?: React.RefObject<HTMLDivElement | null>;
}

// YouTube URL validation regex
const YOUTUBE_URL_REGEX = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;

const isValidYoutubeUrl = (url: string): boolean => {
    try {
        return YOUTUBE_URL_REGEX.test(url);
    } catch {
        return false;
    }
};

export const Materials: React.FC<MaterialsProps> = ({ materialsTableRef }) => {
    const user = getUserDataFromLocalStorage();
    const {
        tempCourse: course,
        courseFiles,
        pendingCourseFiles,
        setCourseFiles,
        setPendingCourseFiles,
        fetchCourseFiles,
        checkFileStatusAfterUpload
    } = useContext(CourseContext);

    const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
    const [uploadTimer, setUploadTimer] = useState(new Date());
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [loading, setLoading] = useState(false);
    const [showSpecOverlay, setShowSpecOverlay] = useState(false);
    const [fileQueue, setFileQueue] = useState<FileWithMetadata[]>([]);
    const [currentFileIndex, setCurrentFileIndex] = useState<number>(-1);
    const [videoUrl, setVideoUrl] = useState<string>('');
    const [startTime, setStartTime] = useState<string | undefined>(undefined);
    const [endTime, setEndTime] = useState<string | undefined>(undefined);
    const [isUrlUploading, setIsUrlUploading] = useState(false);

    useEffect(() => {
        if (uploadTimer.getTime() > Date.now()) {
            checkFileStatusAfterUpload(uploadTimer);
        }
    }, [uploadTimer]);

    useEffect(() => {
        if (fileQueue.length > 0 && currentFileIndex === -1) {
            setCurrentFileIndex(0);
            setShowSpecOverlay(true);
        }
    }, [fileQueue, currentFileIndex]);

    useEffect(() => {
        fetchCourseFiles(course!._id);
    }, []);

    const handleUrlUpload = async () => {
        if (!videoUrl) {
            message.error('Please enter a video URL');
            return;
        }

        if (!isValidYoutubeUrl(videoUrl)) {
            message.error('Invalid YouTube URL');
            return;
        }

        setIsUrlUploading(true);
        try {
            const formData = new FormData();
            formData.append('url', videoUrl);
            formData.append('course_id', course!._id);
            formData.append('user_id', user?._id);
            formData.append('start_time', convertTimeToSeconds(startTime?.toString()).toString() || '');
            formData.append('end_time', convertTimeToSeconds(endTime?.toString()).toString() || '');

            const response = await authedAxios.post('/youtube-upload', formData);

            if (response.status === 200) {
                message.success('Video processing started');
                setVideoUrl('');
                setStartTime(undefined);
                setEndTime(undefined);
                setUploadTimer(new Date(Date.now() + 30 * 60000));
            } else {
                message.error('Video upload failed')
            }
        } catch (error) {
            message.error('Failed to process YouTube video');
            console.error('URL upload error:', error);
        } finally {
            setIsUrlUploading(false);
        }
    };

    const processNextFile = () => {
        if (currentFileIndex < fileQueue.length - 1) {
            setCurrentFileIndex(prev => prev + 1);
            setShowSpecOverlay(true);
        } else {
            // Reset queue when all files are processed
            setFileQueue([]);
            setCurrentFileIndex(-1);
            setShowSpecOverlay(false);
        }
    };

    const getCurrentFile = (): FileWithMetadata | null => {
        if (currentFileIndex === -1 || !fileQueue.length) return null;
        return fileQueue[currentFileIndex];
    };

    const uploadFile = async (file: UploadFile, fileSpecs?: FileSpecifications) => {
        const formData = new FormData();
        formData.append('course_files', file as any);

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    userId: user?._id || '',
                    courseId: course!._id || '',
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    ...(fileSpecs && { 'fileSpecifications': JSON.stringify(fileSpecs) })
                },
                onUploadProgress: (progressEvent: any) => {
                    const currentFile = getCurrentFile();
                    if (progressEvent.total && currentFile?.callbacks.onProgress) {
                        const percent = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        currentFile.callbacks.onProgress({ percent });
                    }
                }
            };

            const response = await authedAxios.post('/upload', formData, config);
            const currentFile = getCurrentFile();
            currentFile?.callbacks.onSuccess?.(response.data);
            message.success(`File uploaded successfully!`);
            setUploadTimer(new Date(Date.now() + 30 * 60000));

        } catch (error) {
            const currentFile = getCurrentFile();
            currentFile?.callbacks.onError?.(error);
            message.error('File upload failed!');
        }
    };

    const handleSpecificationSubmit = (specs: FileSpecifications) => {
        const currentFile = getCurrentFile();
        if (!currentFile) return;

        if (specs.startPage && specs.endPage && specs.startPage >= specs.endPage) {
            message.error('Invalid specifications!');
            return;
        }

        uploadFile(currentFile.file, specs);
        processNextFile();
    };

    const handleSpecificationSkip = () => {
        const currentFile = getCurrentFile();
        if (!currentFile) return;

        uploadFile(currentFile.file);
        processNextFile();
    };

    const handleSetDownloadable = async (fileId: string, downloadable: boolean) => {
        setLoading(true);
        try {
            // Check if the file is part of the selected files
            const isSelected = selectedRowKeys.includes(fileId);
            
            if (isSelected && selectedRowKeys.length > 1) {
                // If file is selected and there are multiple selections, update all selected files
                await setFilesDownloadable(selectedRowKeys as string[], downloadable);
                
                // Update all selected files in the UI
                const updatedFiles = courseFiles.map((file) => {
                    if (selectedRowKeys.includes(file._id)) {
                        return { ...file, downloadable };
                    }
                    return file;
                });
                setCourseFiles(updatedFiles);
            } else {
                // If file is not selected or it's the only selected file, update just this file
                await setFilesDownloadable([fileId], downloadable);
                
                // Update the single file in the UI
                const updatedFiles = courseFiles.map((file) => {
                    if (file._id === fileId) {
                        return { ...file, downloadable };
                    }
                    return file;
                });
                setCourseFiles(updatedFiles);
            }
        } catch (error) {
            message.error('Error updating file(s)');
        } finally {
            setLoading(false);
        }
    }

    const handleDeleteFile = (filename: string, fileId: string) => {
        confirm({
            title: React.createElement('span', null, `Are you sure you want to delete the file ${filename}?`),
            icon: <ExclamationCircleOutlined />,
            content: 'This operation cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleConfirmDelete(fileId);
            },
        });
    };

    const handleConfirmDelete = async (fileId: string) => {
        setLoading(true);
        try {
            await deleteCourseFile(fileId, course!._id);
            message.success('File deleted successfully.', 6);
            setCourseFiles((prevRows) => prevRows.filter((row: any) => row._id !== fileId));
        } catch (error) {
            message.error('Error deleting file.');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteSelected = () => {
        confirm({
            title: 'Are you sure you want to delete the selected files?',
            icon: <ExclamationCircleOutlined />,
            content: 'This operation cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleConfirmDeleteSelected();
            },
        });
    };

    const handleConfirmDeleteSelected = async () => {
        setLoading(true);
        try {
            const deletePromises = selectedRowKeys.map(fileId =>
                deleteCourseFile(fileId.toString(), course!._id)
            );
            await Promise.all(deletePromises);
            message.success('Files deleted successfully.', 6);
            setCourseFiles((prevRows) =>
                prevRows.filter((row: any) => !selectedRowKeys.includes(row._id))
            );
            setSelectedRowKeys([]);
        } catch (error) {
            message.error('Error deleting files.');
        } finally {
            setLoading(false);
        }
    };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleVideoFileBeforeUpload = async (file: UploadFile): Promise<boolean | Promise<UploadFile>> => {
        if (file.type === 'video/mp4') {
            try {
                // Extract audio from the video file
                const audioFile = await extractAudioAndConvertToAudioFile(file);
                // Return a promise that resolves to the audio file
                return audioFile;
            } catch (error) {
                // Handle the error appropriately, for example by showing a message
                message.error('Failed to extract audio from the video file.');
                return false; // Return false to stop the upload
            }
        }
        // If not a video, return the original file
        return file;
    };

    const uploadProps: UploadProps = {
        action: `${authedAxios.defaults.baseURL}/upload`,
        onChange(info: any) {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`File uploaded successfully!`);
                setUploadTimer(new Date(Date.now() + 30 * 60000));
            } else if (status === 'error') {
                let errorMessage = 'File upload failed!';
                if (info.file.response && info.file.response.detail) {
                    errorMessage += ' ' + info.file.response.detail;
                }
                message.error(errorMessage);
            }
            setTimeout(() => {
                setFileList([]);
            }, 10000);
            let newFileList: UploadFile<any>[] = [...info.fileList];
            setFileList(newFileList as UploadFile<any>[]);
        },
        fileList: fileList,
        headers: {
            userid: user?._id || '',
            courseid: course!._id || '',
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        name: 'course_files',
        multiple: true,
        customRequest: async ({ file, onSuccess, onError, onProgress }) => {
            const callbacks = { onSuccess, onError, onProgress };

            try {
                const analysis = await analyzeFile(file as File);

                if (analysis.requiresSpecification) {
                    setFileQueue(prev => [...prev, {
                        file: file as UploadFile,
                        metadata: {
                            fileType: analysis.type,
                            pages: analysis.pages,
                            duration: analysis.duration
                        },
                        callbacks
                    }]);
                    return;
                }

                await uploadFile(file as UploadFile);
            } catch (error) {
                onError?.(error as any);
                message.error('Error processing file');
            }
        },
        beforeUpload(file) {
            return new Promise((resolve, reject) => {
                // Check if the file already exists in the fileList
                const fileExists = courseFiles.some(existingFile => existingFile.file_name === file.name);
                if (fileExists) {
                    message.error('File already exists!');
                    reject();
                    return;
                }
                const fileFormat = `.${file.name.split('.').pop()?.toLowerCase()}`;
                if (!fileFormat || !supportedFormats.includes(fileFormat)) {
                    message.error('Unsupported file format!');
                    reject();
                    return;
                }

                handleVideoFileBeforeUpload(file).then((result) => {
                    resolve(result as any);
                });
            });
        },
    };

    return (
        <>
            <h2>{`${capitalize(user.config.material_terminology)}s`}</h2>
            <Tabs defaultActiveKey="upload">
                <Tabs.TabPane tab="Upload Files" key="upload">
                    <UploadSection>
                        <StyledDragger {...uploadProps} data-testid='file-upload'>
                            <p className="ant-upload-drag-icon" data-testid='file-upload-icon'>
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Supported formats: {supportedFormats.join(', ').toUpperCase()}. <br />
                                All video formats will be converted to WAV. <br />
                                The formats DOC and ODT will be converted to PDF.
                            </p>
                        </StyledDragger>

                        <URLInputSection>
                            <InputRow>
                                <Input
                                    prefix={<LinkOutlined />}
                                    placeholder="Enter YouTube video URL"
                                    value={videoUrl}
                                    onChange={(e) => setVideoUrl(e.target.value)}
                                    style={{ flex: 1 }}
                                />
                                <Input
                                    placeholder="Optional: start time (HH:MM:SS)"
                                    value={startTime}
                                    onChange={(e) => setStartTime(e.target.value)}
                                    style={{ flex: 1, maxWidth: '20%', minWidth: '10%' }}
                                />
                                <Input
                                    placeholder="Optional: end time (HH:MM:SS)"
                                    value={endTime}
                                    onChange={(e) => setEndTime(e.target.value)}
                                    style={{ flex: 1, maxWidth: '20%', minWidth: '10%' }}
                                />
                                <Button
                                    type="primary"
                                    onClick={handleUrlUpload}
                                    loading={isUrlUploading}
                                    style={{ flex: 1, maxWidth: '20%', minWidth: '10%' }}
                                    icon={<LinkOutlined />}
                                >
                                    Upload from URL
                                </Button>
                            </InputRow>
                        </URLInputSection>
                    </UploadSection>
                </Tabs.TabPane>

                <Tabs.TabPane
                    tab={
                        `Pending Contributions 
                        ${pendingCourseFiles.filter((c: CourseFile) => c.status === 'pending').length > 0
                            ? `(${pendingCourseFiles.filter((c: CourseFile) => c.status === 'pending').length})`
                            : ''}`
                    }
                    key="contributions"
                >
                    <PendingContributions
                        courseId={course!._id}
                        contributions={pendingCourseFiles}
                        onContributionUpdate={async () => {
                            try {
                                await fetchCourseFiles(course!._id);
                                setUploadTimer(new Date(Date.now() + 30 * 60000));
                            } catch (error) {
                                console.error('Error updating contributions:', error);
                            }
                        }}
                    />
                </Tabs.TabPane>
            </Tabs>

            <FileSpecificationOverlay
                file={getCurrentFile()?.file!}
                metadata={getCurrentFile()?.metadata!}
                visible={showSpecOverlay}
                existingFiles={courseFiles}
                onCancel={() => {
                    const currentFile = getCurrentFile();
                    if (currentFile) {
                        currentFile.callbacks.onError?.(new Error('Upload cancelled by user'));
                    }
                    setShowSpecOverlay(false);
                    setFileQueue([]);
                    setCurrentFileIndex(-1);
                }}
                onSubmit={handleSpecificationSubmit}
                onSkip={handleSpecificationSkip}
            />

            <Spacer y={10} />
            <Typography.Paragraph>
                Uploaded Files will appear in the table below. Processing of large files can take up to an hour, but you can
                already start using files in processing for the course specifications.
            </Typography.Paragraph>
            <Typography.Paragraph>
                Should the upload not be successful, please inform via the quick support button the development team to sort
                it out quickly!
            </Typography.Paragraph>
            <div ref={materialsTableRef}>
                <Table
                    loading={loading}
                    rowSelection={rowSelection}
                    dataSource={courseFiles}
                    columns={[
                        {
                            title: 'Name',
                            dataIndex: 'file_name',
                            key: 'file_name',
                            width: '40%',
                        },
                        {
                            title: 'Uploaded at',
                            dataIndex: 'created_at',
                            key: 'created_at',
                            render: (text: string) => {
                                return formatDate(text);
                            },
                            width: '30%',
                        },
                        {
                            title: 'Downloadable',
                            dataIndex: 'downloadable',
                            key: 'downloadable',
                            align: 'center',
                            width: '10%',
                            render: (_: any, record: CourseFile) => (
                                <Checkbox
                                    data-testid={`downloadable-checkbox-${courseFiles.indexOf(record)}`}
                                    checked={record.downloadable}
                                    onChange={e => handleSetDownloadable(record._id, Boolean(e.target.checked))}
                                />
                            ),
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            key: 'status',
                            align: 'center',
                            render: (status: string) => {
                                if (status === 'available') {
                                    return <CheckCircleOutlined style={{ color: 'green' }} />;
                                } else if (status === 'unavailable') {
                                    return <ExclamationCircleOutlined style={{ color: 'orange' }} />;
                                } else {
                                    return <SyncOutlined spin />;
                                }
                            },
                            width: '10%',
                        },
                        {
                            title: 'Delete',
                            dataIndex: 'delete',
                            key: 'delete',
                            align: 'center',
                            render: (_: any, record: CourseFile) => (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <FlexMarginButton
                                        data-testid="delete-button"
                                        icon={<DeleteOutlined />}
                                        onClick={() => {
                                            handleDeleteFile(record.file_name, record._id)
                                        }}
                                    />
                                </div>
                            ),
                            width: '10%',
                        },
                    ]}
                    rowKey={record => record._id}
                    pagination={{
                        defaultPageSize: 4,
                    }}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                    <Button
                        type="primary"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={handleDeleteSelected}
                        disabled={!selectedRowKeys.length}
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </>
    );
}