import { Conversation, ConversationHistoryItem } from "../types/chat";
import { getUserDataFromLocalStorage } from "../utils/useLocalStorage";
import { authedAxios } from "./auth-axios";
import { BackendTemplate, Template } from "./templates";

export const loadConversationHistory = async (recent: boolean, user_id: string): Promise<ConversationHistoryItem[]> => {
    try {
        const response = await authedAxios.get(`/conversations/history/${recent}/${user_id}`);
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
};

export const newConversation = async (user_id: string, type: string, course_id?: string): Promise<Conversation> => {
    try {
        const response = await authedAxios.post(`/conversations/new`, {
            user_id,
            type,
            ...(course_id && { course_id })
        });
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
}


export const deleteConversation = async (conversation_id: string): Promise<Conversation> => {
    try {
        const response = await authedAxios.delete(`/conversations/${conversation_id}`);
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
}

export const getConversation = async (conversation_id: string): Promise<Conversation> => {
    try {
        const response = await authedAxios.get(`/conversations/${conversation_id}`);
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
}

export const likeMessage = async (conversation_id: string, msgId: string): Promise<void> => {
    try {
        await authedAxios.patch(`/conversations/${conversation_id}/like-msg`, {
            msgId: msgId
        });
    } catch (error) {
        console.error('Error Liking Message:', error);
        throw error;
    }
}

export const dislikeMessage = async (conversation_id: string, msgId: string): Promise<void> => {
    try {
        await authedAxios.patch(`/conversations/${conversation_id}/dislike-msg`, {
            msgId: msgId
        });
    } catch (error) {
        console.error('Error Liking Message:', error);
        throw error;
    }
}


export const updateConversationTemplates = async (conversationId: string, templates: BackendTemplate[]): Promise<boolean> => {
    try {
        const response = await authedAxios.put(`/conversations/${conversationId}/update_templates`, {
            templates: templates
        });
        return response.status === 200;
    } catch (error) {
        console.error('Error updating conversation templates:', error);
        return false;
    }
}

export const getFileInConversation = async (conversation_id: string, file_id: string): Promise<any> => {
    try {
        const response = await authedAxios.get(
            `/conversations/${conversation_id}/file/${file_id}`,
            { responseType: 'blob' },
        );

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response;
    } catch (error) {
        console.error('Error fetching file:', error);
        throw error;
    }
}


/**
 * Updates the relevant week numbers for a conversation
 * @param chatId The ID of the conversation
 * @param weekNumbers Array of week numbers to set as relevant
 */
export async function updateRelevantWeekNumbers(chatId: string, weekNumbers: number[]) {
    try {
        const response = await authedAxios.put(`/conversations/${chatId}/update_relevant_week_numbers`,
            weekNumbers,
        );

        if (response.status !== 200) {
            throw new Error("Failed to update conversation with relevant weeks");
        }

        return response.data;
    } catch (error) {
        console.error("Error updating conversation week numbers:", error);
        throw error;
    }
}

/**
 * Updates the relevant date range for a conversation
 * @param chatId The ID of the conversation
 * @param dateRange Array of two ISO date strings [startDate, endDate]
 */
export async function updateRelevantDateRange(chatId: string, dateRange: string[]) {
    try {
        const response = await authedAxios.put(`/conversations/${chatId}/update_relevant_date_range`,
            dateRange,
        );

        if (response.status !== 200) {
            throw new Error("Failed to update conversation with date range");
        }

        return response.data;
    } catch (error) {
        console.error("Error updating conversation date range:", error);
        throw error;
    }
}

/**
 * Updates the relevant groups for a conversation
 * @param chatId The ID of the conversation
 * @param groups Array of group identifiers
 */
export async function updateRelevantGroups(chatId: string, groups: string[]) {
    try {
        const response = await authedAxios.put(`/conversations/${chatId}/update_relevant_groups`,
            groups,
        );

        if (response.status !== 200) {
            throw new Error("Failed to update conversation with groups");
        }

        return response.data;
    } catch (error) {
        console.error("Error updating conversation groups:", error);
        throw error;
    }
}

/**
 * Updates the relevant file IDs for a conversation
 * @param chatId The ID of the conversation
 * @param fileIds Array of file IDs
 */
export async function updateRelevantFileIds(chatId: string, fileIds: string[]) {
    try {
        const response = await authedAxios.put(`/conversations/${chatId}/update_relevant_file_ids`,
            fileIds,
        );

        if (response.status !== 200) {
            throw new Error("Failed to update conversation with file_ids");
        }

        return response.data;
    } catch (error) {
        console.error("Error updating conversation file IDs:", error);
        throw error;
    }
}

/**
 * Updates the selected template name for a conversation
 * @param chatId The ID of the conversation
 * @param selectedTemplateName The name of the selected template
 */
export async function updateSelectedTemplateName(chatId: string, selectedTemplateName: string) {
    try {
        const response = await authedAxios.put(`/conversations/${chatId}/update_selected_template_name`,
            selectedTemplateName,
        );

        if (response.status !== 200) {
            throw new Error("Failed to update conversation with selected_template_name");
        }

        return response.data;
    } catch (error) {
        console.error("Error updating conversation template name:", error);
        throw error;
    }
}

/**
 * Updates the Open to mentor flag for a conversation
 * @param chatId The ID of the conversation
 * @param seekingMentor Boolean indicating whether the user is Open to mentor
 */
export async function updateSeekingMentor(chatId: string, seekingMentor: boolean) {
    try {
        const response = await authedAxios.put(`/conversations/${chatId}/update_seeking_mentor`,
            { seeking_mentor: seekingMentor },
        );

        if (response.status !== 200) {
            throw new Error("Failed to update conversation with Open to mentor flag");
        }

        return response.data;
    } catch (error) {
        console.error("Error updating conversation Open to mentor flag:", error);
        throw error;
    }
}

/**
 * Updates the seeking cofounders flag for a conversation
 * @param chatId The ID of the conversation
 * @param seekingCofounders Boolean indicating whether the user is seeking cofounders
 */
export async function updateSeekingCofounders(chatId: string, seekingCofounders: boolean) {
    try {
        const response = await authedAxios.put(`/conversations/${chatId}/update_seeking_cofounders`,
            { seeking_cofounders: seekingCofounders },
        );

        if (response.status !== 200) {
            throw new Error("Failed to update conversation with seeking cofounders flag");
        }

        return response.data;
    } catch (error) {
        console.error("Error updating conversation seeking cofounders flag:", error);
        throw error;
    }
}

/**
 * Updates the seeking courses flag for a conversation
 * @param chatId The ID of the conversation
 * @param seekingCourses Boolean indicating whether the user is seeking courses
 */
export async function updateSeekingCourses(chatId: string, seekingCourses: boolean) {
    try {
        const response = await authedAxios.put(`/conversations/${chatId}/update_seeking_courses`,
            { seeking_courses: seekingCourses },
        );

        if (response.status !== 200) {
            throw new Error("Failed to update conversation with seeking courses flag");
        }

        return response.data;
    } catch (error) {
        console.error("Error updating conversation seeking courses flag:", error);
        throw error;
    }
}

/**
 * Updates the seeking projects flag for a conversation
 * @param chatId The ID of the conversation
 * @param seekingProjects Boolean indicating whether the user is seeking projects
 */
export async function updateSeekingProjects(chatId: string, seekingProjects: boolean) {
    try {
        const response = await authedAxios.put(`/conversations/${chatId}/update_seeking_projects`,
            { seeking_projects: seekingProjects },
        );

        if (response.status !== 200) {
            throw new Error("Failed to update conversation with seeking projects flag");
        }

        return response.data;
    } catch (error) {
        console.error("Error updating conversation seeking projects flag:", error);
        throw error;
    }
}

/**
 * Updates the conversation to show all types and unselect all specific filters
 * @param chatId The ID of the conversation
 */
export async function updateSeekingAll(chatId: string, seeking_all: boolean) {
    try {
        const response = await authedAxios.put(`/conversations/${chatId}/update_seeking_all`,
            { seeking_all: seeking_all },
        );

        if (response.status !== 200) {
            throw new Error("Failed to update conversation to show all types");
        }

        return response.data;
    } catch (error) {
        console.error("Error updating conversation to show all types:", error);
        throw error;
    }
}

/**
 * Updates the seeking communities flag for a conversation and sets the selected community IDs
 * @param chatId The ID of the conversation
 * @param seekingCommunities Boolean indicating whether the user is seeking communities
 * @param communityIds Array of community IDs that are selected
 */
export async function updateSeekingCommunities(chatId: string, seekingCommunities: boolean, communityIds: string[] = []) {
    try {
        const response = await authedAxios.put(`/conversations/${chatId}/update_seeking_communities`,
            { 
                seeking_communities: seekingCommunities,
                community_ids: communityIds 
            },
        );

        if (response.status !== 200) {
            throw new Error("Failed to update conversation with seeking communities flag");
        }

        return response.data;
    } catch (error) {
        console.error("Error updating conversation seeking communities flag:", error);
        throw error;
    }
}



/**
 * Create a new chat for a specific course
 * @param courseId The ID of the course to create a chat for
 */
export async function createCourseChat(courseId: string) {
    try {
        const response = await authedAxios.post(`/conversations/create_for_course/${courseId}`);

        if (response.status !== 200) {
            throw new Error("Failed to create chat for course");
        }

        return response.data;
    } catch (error) {
        console.error("Error creating chat for course:", error);
        throw error;
    }
}
