import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DiscoverItemsContainer, DiscoverItemsWrapper, DiscoverItemCard, DiscoverItemText } from '../../styles/message';
import { downloadImage } from '../../services/images';
import { newConversation } from '../../services/conversations';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';
import { theme, Avatar, Spin } from 'antd';
import { getUserProfile } from '../../services/user';

const discoverTypeUser = 'user';
const discoverTypeCourse = 'course';
const discoverTypeCommunity = 'community';

export interface DiscoverItem {
    itemId: string;
    itemType: string;
    itemText: string;
    // bannerUrl?: string;
    photoUrl?: string;
    directUrl?: string | null; // Direct Azure URL with SAS token
}

interface DiscoverItemsProps {
    items: DiscoverItem[];
}

const DiscoverItems: React.FC<DiscoverItemsProps> = ({ items }) => {
    const user = getUserDataFromLocalStorage();
    const { token } = theme.useToken();
    const navigate = useNavigate();
    const [itemsWithBanners, setItemsWithBanners] = useState<DiscoverItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    
    useEffect(() => {
        const fetchItemsData = async () => {
            setLoading(true);
            try {
                const preparedItems = await Promise.all(items.map(async (item) => {
                    if (item.itemType === discoverTypeUser) {
                        try {
                            const userProfile = await getUserProfile(item.itemId);
                            return {
                                ...item,
                                photoUrl: userProfile.photo_url,
                            };
                        } catch (error) {
                            console.error('Error fetching user profile:', error);
                            return item;
                        }
                    } else if (item.itemType === discoverTypeCommunity || item.itemType === discoverTypeCourse) {
                        try {
                            // Try different image extensions
                            const extensions = ['.jpg', '.jpeg', '.png'];
                            
                            // First try without extension
                            const directUrl = await downloadImage(item.itemId);
                            if (directUrl) {
                                return {
                                    ...item,
                                    directUrl: directUrl,
                                };
                            }
                            
                            // If that fails, try with common extensions
                            for (const ext of extensions) {
                                try {
                                    const extUrl = await downloadImage(item.itemId, ext);
                                    if (extUrl) {
                                        return {
                                            ...item,
                                            directUrl: extUrl,
                                        };
                                    }
                                } catch (innerError) {
                                    console.error(`Error with extension ${ext}:`, innerError);
                                }
                            }
                        } catch (error) {
                            console.error(`Error getting direct URL for ${item.itemType} ${item.itemId}:`, error);
                        }
                    }
                    return item;
                }));
                
                setItemsWithBanners(preparedItems);
            } finally {
                setLoading(false);
            }
        };
        
        fetchItemsData();
    }, [items]);

    const handleItemClick = async (itemId: string, itemType: string) => {
        if (itemType === discoverTypeCourse) {
            try {
                const newChat = await newConversation(user._id, 'content', itemId);
                navigate(`/chat/${newChat._id}`);
            } catch (error) {
                console.error("Failed to create chat for course:", error);
            }
        }
        else if (itemType === discoverTypeUser) {
            try {
                navigate(`/communities/users/${itemId}`);
            } catch (error) {
                console.error("Failed to open user profile:", error);
            }
        }
        else if (itemType === discoverTypeCommunity) {
            try {
                navigate(`/communities/${itemId}`);
            } catch (error) {
                console.error("Failed to open user profile:", error);
            }
        } else {
            throw new Error("Incorrect item type");
        }
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
                <Spin size="large" />
            </div>
        );
    }

    return (
        <DiscoverItemsContainer>
            <DiscoverItemsWrapper>
                {itemsWithBanners.map((item, index) => (
                    <DiscoverItemCard
                        style={{
                            backgroundColor: token.colorBgElevated,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            position: 'relative',
                        }}
                        key={`${item.itemId}-${index}`}
                        onClick={() => handleItemClick(item.itemId, item.itemType)}
                    >
                        {/* Overlay to ensure text is readable over any background */}
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            borderRadius: 'inherit',
                        }} />
                        
                        {/* Display Avatar for users or banner icons */}
                        <div style={{ 
                            position: 'absolute', 
                            top: '10px', 
                            right: '10px', 
                            zIndex: 2 
                        }}>
                            {item.itemType === discoverTypeUser && item.photoUrl ? (
                                <Avatar 
                                    src={item.photoUrl} 
                                    style={{ 
                                        border: `2px solid ${token.colorPrimary}`, 
                                        cursor: 'pointer', 
                                        width: '40px', 
                                        height: '40px', 
                                        fontSize: '16px', 
                                        lineHeight: '40px'
                                    }}
                                >
                                    {item.itemText.charAt(0)}
                                </Avatar>
                            ) : (item.directUrl) ? (
                                <Avatar 
                                    src={item.directUrl} 
                                    style={{ 
                                        border: `2px solid ${token.colorPrimary}`, 
                                        cursor: 'pointer', 
                                        width: '40px', 
                                        height: '40px'
                                    }}
                                />
                            ) : null}
                        </div>
                        
                        <DiscoverItemText style={{ 
                            position: 'relative', 
                            zIndex: 1, 
                            color: item.directUrl ? '#fff' : 'inherit',
                            // Add right padding to avoid text overlapping with the avatar
                            paddingRight: '50px'
                        }}>
                            {item.itemText}
                        </DiscoverItemText>
                    </DiscoverItemCard>
                ))}
            </DiscoverItemsWrapper>
        </DiscoverItemsContainer>
    );
};

export default DiscoverItems;