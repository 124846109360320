import React from 'react';
import { Tour } from 'antd';
import type { TourProps } from 'antd';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';
import { capitalize } from '../../utils/utils';

interface CourseAnalyticsTourProps {
  isOpen: boolean;
  onClose: () => void;
  moduleSelectRef: React.RefObject<HTMLDivElement | null>;
  dateRangeRef: React.RefObject<HTMLDivElement | null>;
  reloadButtonRef: React.RefObject<HTMLButtonElement | null>;
}

const CourseAnalyticsTour: React.FC<CourseAnalyticsTourProps> = ({
  isOpen,
  onClose,
  moduleSelectRef,
  dateRangeRef,
  reloadButtonRef,
}) => {
  const user = getUserDataFromLocalStorage();
  const steps: TourProps['steps'] = [
    {
      title: `${capitalize(user.config.module_terminology)} Selection`,
      description: `Select one or multiple ${user.config.module_terminology}s to analyze. The analytics will be generated based on the interactions within the selected ${user.config.module_terminology}s.`,
      target: () => moduleSelectRef.current!,
      placement: 'bottom',
    },
    {
      title: 'Date Range',
      description: 'Choose a date range for your analysis. Only interactions within this timeframe will be included in the analytics.',
      target: () => dateRangeRef.current!,
      placement: 'bottom',
    },
    {
      title: 'Reload Data',
      description: `Data will load automatically when you select ${user.config.module_terminology}s and date range. Use this button to refresh all charts at once. Each chart also has its own reload button for individual updates.`,
      target: () => reloadButtonRef.current!,
      placement: 'bottom',
    },
  ];

  return (
    <Tour 
      open={isOpen}
      onClose={onClose}
      steps={steps}
      placement="bottom"
    />
  );
};

export default CourseAnalyticsTour;