import { Modal } from "antd";
import CourseReportsPage from "../../screens/analytics/reports/CourseReportsPage";

interface AnalyticsOverlayProps {
    visible: boolean;
    onClose: () => void;
    userId: string;
}

const AnalyticsOverlay: React.FC<AnalyticsOverlayProps> = ({
    visible,
    onClose,
    userId
}) => {
    return (
        <Modal
            title="Course Reports"
            open={visible}
            onCancel={onClose}
            footer={null}
            width="60%"
            destroyOnClose={true}
            style={{
                display: 'flex',
                top: 20,
                justifyContent: 'center'
            }}
        >
            {process.env.REACT_APP_COURSE_IDS?.split(',').includes(userId) ? (
                <CourseReportsPage />
            ) : (
                <div style={{
                    display: 'flex',
                    top: 20,
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    minWidth: '20%',
                    flexDirection: 'column',
                }}>
                    Coming soon!
                </div>
            )}
        </Modal>
    );
};

export default AnalyticsOverlay;
