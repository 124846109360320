// StudentTable.tsx
import React from 'react';
import { Table } from 'antd';
import { UserOverview } from '../../services/courses';
import { FlexMarginButton } from '../basic/buttons';
import { capitalize } from '../../utils/utils';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';

interface StudentTableProps {
    assigned_students: UserOverview[];
    enrolledStudents: UserOverview[];
    onStudentEnroll: (student: UserOverview) => void;
    onStudentUnenroll: (student: UserOverview) => void;
}

const StudentTable: React.FC<StudentTableProps> = ({ assigned_students, enrolledStudents, onStudentEnroll, onStudentUnenroll }) => {
    const user = getUserDataFromLocalStorage();
    const columns = [
        {
            title: 'Action',
            key: 'action',
            render: (text: string, student: UserOverview) => (
                enrolledStudents.find(s => s._id === student._id)
                    ? <FlexMarginButton onClick={() => onStudentUnenroll(student)}>Remove</FlexMarginButton>
                    : <FlexMarginButton onClick={() => onStudentEnroll(student)}>Enroll</FlexMarginButton>
            ),
            width: '10%',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '45%',
        },
        {
            title: 'Status',
            key: 'status',
            render: (text: string, student: UserOverview) => (
                enrolledStudents.find(s => s._id === student._id) ? 'Enrolled' : 'Available'
            ),
            width: '45%',
        },
    ];

    return (
        <div>
            <h2>{`${capitalize(user.config.student_terminology)}s`}</h2>
            <Table
                columns={columns}
                dataSource={assigned_students}
                rowKey="_id"
                pagination={{ pageSize: 4 }}
            />
        </div>
    );
};

export default StudentTable;