import React from 'react';
import {Tour} from 'antd';
import type {TourProps} from 'antd';
import {getUserDataFromLocalStorage} from '../../utils/useLocalStorage';
import {admin_type, autodidact_type, professor_type, technical_admin_type} from '../../services/user';

interface CourseTourProps {
    open: boolean;
    onClose: () => void;
    chatRef: React.RefObject<HTMLButtonElement | null> | undefined;
    editRef?: React.RefObject<HTMLButtonElement | null> | undefined;
    interactRef?: React.RefObject<HTMLButtonElement | null> | undefined;
    analyzeRef?: React.RefObject<HTMLButtonElement | null> | undefined;
}

export const CourseTour: React.FC<CourseTourProps> = ({
                                                          open,
                                                          onClose,
                                                          chatRef,
                                                          editRef,
                                                          interactRef,
                                                          analyzeRef,
                                                      }) => {
    const user = getUserDataFromLocalStorage();
    const isEducator = [professor_type, admin_type, technical_admin_type].includes(user.type);
    const canEdit = [professor_type, autodidact_type, admin_type, technical_admin_type].includes(user.type);

    // Build steps based on user type and available buttons
    const steps: TourProps['steps'] = [
        {
            title: 'Chat with Sona',
            description: 'Start a conversation about your course content. Ask questions, get explanations, or discuss any topic related to the course.',
            target: chatRef?.current,
            placement: 'right',
        },
    ];

    // Add Edit button tour step if user has permission
    if (canEdit && editRef?.current) {
        steps.push({
            title: 'Edit Course',
            description: 'Modify course content, materials, and structure. Update information or add new content to your course.',
            target: editRef?.current,
            placement: 'right',
        });
    }

    // Add Interact and Analyze steps for educators
    if (isEducator) {
        if (interactRef?.current) {
            steps.push({
                title: 'Interact with Atla',
                description: 'Use Atla to help analyze engagement, generate assignments, and get insights about your course.',
                target: interactRef?.current,
                placement: 'right',
            });
        }

        if (analyzeRef?.current) {
            steps.push({
                title: 'Course Analytics',
                description: 'View detailed analytics about your course, including progress, engagement metrics, and content effectiveness.',
                target: analyzeRef?.current,
                placement: 'right',
            });
        }
    }

    return (
        <Tour
            open={open}
            onClose={onClose}
            steps={steps}
        />
    );
};
