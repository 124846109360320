import {
    AppstoreOutlined,
    FileOutlined,
    FolderOpenOutlined,
    LeftOutlined,
    RightOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from 'uuid';
import { Menu, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { Week } from "../../services/courses";
import { capitalize } from "../../utils/utils";
import AssignmentMenu from "./AssignmentMenu";
import { collapsedSiderWidth, siderWidthOpened } from "../../config";
import { StyledMenu, StyledSider } from "../../styles/chat";
import { ContentNavigationProps, MenuItem } from "../../types/chat";
import { getUserDataFromLocalStorage } from "../../utils/useLocalStorage";

const { Title } = Typography;

const ContentNavigation: React.FC<ContentNavigationProps> = ({
    course,
    conversation,
    setMessages,
    chatTypeSpecifier,
    siderCollapsed,
    setSiderCollapsed,
    selectedWeeks,
    setSelectedWeeks,
    selectedGroups,
    setSelectedGroup,
    selectedMaterials,
    setSelectedMaterials,
    onWeekSelectionClick,
    onGroupSelectionClick,
    onMaterialSelectionClick,
    updateRelevantWeekNumbers,
    modulesRef,
    assignmentsRef,
    groupsRef,
    materialsRef,
    navigatorRef,
    isLoadingMessages
}) => {
    const user = getUserDataFromLocalStorage();
    const [weekMenuItems, setWeekMenuItems] = useState<MenuItem[]>([]);
    const [groupMenuItems, setGroupMenuItems] = useState<MenuItem[]>([]);
    const [materialMenuItems, setMaterialMenuItems] = useState<MenuItem[]>([]);

    useEffect(() => {
        if (course && course.weeks) {
            const currentDate = new Date();
            const relevantWeekItems = course.weeks
                .filter(week => new Date(week.start_date) <= currentDate)
                .map((week) => ({
                    label: week.title?.length > 0 
                        ? week.title 
                        : `${capitalize(user.config?.module_terminology)} ${week.number}`,
                    key: week.number,
                }));

            // Prepend "Select All" to the top if there are at least two weeks
            if (relevantWeekItems.length >= 2) {
                relevantWeekItems.unshift({
                    label: 'All',
                    key: 0, // Key for "Select All"
                });
            }

            let newWeekMenuItems = [{
                label: `${capitalize(user.config?.module_terminology)}s`,
                key: 'select-modules',
                children: [
                    ...relevantWeekItems,
                ],
                icon: <FolderOpenOutlined ref={modulesRef} />,
            }];

            if (relevantWeekItems.length > 0 && selectedWeeks.length === 0) {
                const indexToSelect = Math.min(1, relevantWeekItems.length - 1);
                let initialSelectedWeeks = [relevantWeekItems[indexToSelect].key as number];
                setSelectedWeeks(initialSelectedWeeks);
                updateRelevantWeekNumbers(initialSelectedWeeks);
                const selectedWeekData = course.weeks.filter(week => initialSelectedWeeks.includes(week.number));
                generateMenuItems(selectedWeekData);
            }
            
            setWeekMenuItems(newWeekMenuItems);
        }
    }, [course]);

    useEffect(() => {
        if (course && selectedWeeks.length > 0) {
            const selectedWeekData = course.weeks.filter(week => selectedWeeks.includes(week.number));
            generateMenuItems(selectedWeekData);
        }
    }, [selectedWeeks, course]);

    function generateMenuItems(selectedWeekData: Week[]) {
        if (!selectedWeekData.length) return;

        const uniqueGroups = Array.from(new Set(selectedWeekData.flatMap(week =>
            week.materials ? week.materials.map(material => material.group).filter(group => group !== "") : []
        )));

        const newGroupMenuItems = [{
            label: `Groups`,
            key: 'select-groups',
            children: uniqueGroups.map(group => ({
                label: `Group ${group}`,
                key: group,
            })),
            icon: <AppstoreOutlined ref={groupsRef} />,
        }];
        setGroupMenuItems(newGroupMenuItems);

        const allMaterials = selectedWeekData.flatMap(week => week.materials || []);
        const uniqueMaterialsMap = new Map(allMaterials.map(material => [material.file_id, material]));

        const newMaterialMenuItems = [{
            label: `${capitalize(user.config.material_terminology)}s`,
            key: 'select-materials',
            children: Array.from(uniqueMaterialsMap.values()).map(material => ({
                label: material.name,
                key: material.file_id,
            })),
            icon: <FileOutlined ref={materialsRef} />,
        }];

        setMaterialMenuItems(newMaterialMenuItems);
    }

    return (
        <StyledSider
            ref={navigatorRef}
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
            width={siderWidthOpened}
            collapsedWidth={collapsedSiderWidth}
            collapsible
            collapsed={siderCollapsed}
            onCollapse={(value) => setSiderCollapsed(value)}
            trigger={null}>
            <div style={{ flex: 1, overflow: 'auto', overflowX: 'hidden' }}>
                <Row justify="space-between">
                    <div style={{
                        textAlign: 'left',
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        boxShadow: 'none',
                        color: 'inherit',
                        backgroundColor: 'transparent',
                        marginBottom: '0.5rem',
                    }}>
                        <Title
                            level={4}
                            className="!mb-0"
                            style={{ textAlign: 'center', paddingBottom: 10 }}
                        >
                            {siderCollapsed ? "Nav" : "Navigator"}
                        </Title>
                    </div>
                </Row>
                
                <AssignmentMenu
                    assignmentsRef={assignmentsRef}
                    weeks={course.weeks || []}
                    onAssignmentSelect={(weekNumber) => {
                        const selectedWeek = course?.weeks.find(week => week.number === weekNumber);
                        if (selectedWeek) {
                            setMessages(prev => [...prev, {
                                state_id: uuidv4(),
                                msg_id: uuidv4(),
                                user: "bot",
                                text: selectedWeek.assignment || "No assignment for this week.",
                                sources: [],
                                timestamp: new Date().toISOString()
                            }]);
                        }
                    }}
                />
                
                {course && course.weeks.length > 0 &&
                    <div style={{ overflow: 'visible' }}>
                        <Menu
                            style={{
                                overflow: 'visible',
                                border: 'none',
                                boxShadow: 'none',
                                color: 'inherit',
                            }}
                            onClick={onWeekSelectionClick}
                            selectedKeys={selectedWeeks.map(week => week.toString())}
                            defaultOpenKeys={['select-modules']}
                            mode="inline"
                            items={weekMenuItems}
                        />
                    </div>
                }
                
                {course && course.weeks.length > 0 && selectedWeeks.length > 0 &&
                    <div style={{ overflow: 'visible' }}>
                        {user.config?.filter_by_group &&
                            <Menu
                                style={{
                                    overflow: 'visible',
                                    border: 'none',
                                    boxShadow: 'none',
                                    color: 'inherit',
                                }}
                                onClick={onGroupSelectionClick}
                                selectedKeys={selectedGroups.map(group => group.toString())}
                                defaultOpenKeys={['select-groups']}
                                mode="inline"
                                items={groupMenuItems}
                            />
                        }
                        {user.config?.filter_by_source &&
                            <Menu
                                style={{
                                    overflow: 'visible',
                                    border: 'none',
                                    boxShadow: 'none',
                                    color: 'inherit',
                                }}
                                onClick={onMaterialSelectionClick}
                                selectedKeys={selectedMaterials.map(material => material.toString())}
                                defaultOpenKeys={['select-materials']}
                                mode="inline"
                                items={materialMenuItems}
                            />
                        }
                    </div>
                }
            </div>
            <div style={{ marginTop: 'auto' }}>
                <StyledMenu
                mode="vertical"
                selectedKeys={[]}
                items={[
                    {
                        key: "collapse",
                        icon: siderCollapsed ? <LeftOutlined /> : <RightOutlined />,
                        onClick: () => setSiderCollapsed(prev => !prev),
                        label: null,
                        title: "",
                        style: { textAlign: 'center' }
                    },
                ]}
            />
            </div>
        </StyledSider>
    );
};

export default ContentNavigation;
